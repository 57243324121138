// @ts-ignore
import Timeout = NodeJS.Timeout;

interface AnimationObserverOptions {
  target: Element;
  callback: ()=>void;
  numRunningAnimations: number;
  animationStartCallback: (evt:AnimationEvent)=>void;
  animationEndCallback: (evt:AnimationEvent)=>void;
  endCheckTimeoutId: Timeout;
}

export class AnimationObserver {
  static whenAnimationStable(target:Element, callback:()=>void, maxWaitTime:number = 5000) {
    let options : AnimationObserverOptions = {
      target: target,
      callback: callback,
      numRunningAnimations: 0,
      animationStartCallback: (evt)=>{
        AnimationObserver.animationStarted(options, evt);
      },
      animationEndCallback: (evt)=>{
        AnimationObserver.animationEnded(options, evt);
      },
      endCheckTimeoutId: null
    };

    target.addEventListener("animationstart", options.animationStartCallback);
    target.addEventListener("animationend", options.animationEndCallback);

    setTimeout(()=>{
      if(options.endCheckTimeoutId != null) {
        clearTimeout(options.endCheckTimeoutId);
      }
      target.removeEventListener("animationstart", options.animationStartCallback);
      target.removeEventListener("animationend", options.animationEndCallback);
      callback();
    }, maxWaitTime);
  }

  private static animationStarted(options:AnimationObserverOptions, evt:AnimationEvent) {
    options.numRunningAnimations++;
    if(options.endCheckTimeoutId != null) {
      clearTimeout(options.endCheckTimeoutId);
    }
  }

  private static animationEnded(options:AnimationObserverOptions, evt:AnimationEvent) {
    options.numRunningAnimations--;

    if(options.numRunningAnimations === 0) {
      options.endCheckTimeoutId = setTimeout(()=>{
        console.log("Animations done");
        options.callback();
      }, 500);
    }
  }
}
