<div class="container-fluid page-max-width">
    <div class="justify-content-md-center">
        <div>
            <div class="format-one-container">
                <div class="format-one-column">
                    <app-catalog-menu></app-catalog-menu>

                    <div class="admin-section"  *ngIf="this.model.workshop && this.model.adminRole() && !finished">
                        <div *ngIf="!isMobile">
                            <button *ngIf="this.model.adminRole()" mat-button color="primary" (click)="enterEditWorkshop()">{{'Edit workshop description'|translate}}</button>
                        </div>

                        <div class="{{(isMobile?'':'admin-right')}}">
                            <mat-form-field  appearance="standard">
                                <mat-label>{{'Visibility'|translate}}</mat-label>
                                <mat-select [(ngModel)]="model.workshop.status" name="option" (selectionChange)="workshopStatusChanged()" class="workshop-select">
                                    <mat-option *ngFor="let option of wsType | keyvalue" [value]="option.value">
                                        {{option.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="admin-section"  *ngIf="this.model.workshop && this.model.adminRole() && finished">
                        <div>
                            <button mat-button color="primary" (click)="enterEditFinished()">{{'Edit thank you description'|translate}}</button>
                        </div>
                    </div>


                    <div class="format-column1-content">
                        <div *ngIf="model.process_service.process && model.process_service.process.workshops">
                            <div   *ngFor="let workshop of model.process_service.process.workshops; let i = index">
                                <div *ngIf="workshop_id==workshop.id" >
                                    <div *ngIf="!finished"> 
                                        <div class="workshop-summary">{{workshop.summary}} </div>
                                        <div class="workshop-text">
                                            <div *ngIf="edit_workshop">
                                                <mat-form-field class="edit_project_heading">
                                                    <mat-label>{{'Workshop title' | translate}}</mat-label>
                                                    <input matInput type="text" [(ngModel)]="workshop.title" matTooltip="{{'Workshop name'|translate}}">
                                                </mat-form-field><br/>

                                                <mat-form-field class="edit_project_heading">
                                                    <mat-label>{{'Workshop summary' | translate}}</mat-label>
                                                    <textarea matInput [(ngModel)]="workshop.summary" placeholder="Workshop summary'|translate}}" matTooltip="{{'Workshop summary'|translate}}"></textarea>
                                                </mat-form-field>
                                            </div>

                                            <div [ngSwitch]="edit_workshop" id="storyview" >
                                                <div *ngSwitchCase="true" class="catalog-description-edit">
                                                    <app-editor-js [content]="workshop" [projectId]="model.process_service.process.id"></app-editor-js>
                                                </div>
                                                <div *ngSwitchCase="false" class="catalog-description">
                                                    <div class="mobile-dot-menu" *ngIf="isMobile">    
                                                        <mat-icon [matMenuTriggerFor]="mobile_dot_menu">more_horiz</mat-icon>
                                                        
                                                        <mat-menu #mobile_dot_menu="matMenu">
                                                            <button mat-menu-item (click)="enterEditWorkshop()">{{'Edit workshop description'|translate}}</button>
                                                        </mat-menu>
                                                    </div>
                                                    
                                                    <div *ngIf="workshop.content">
                                                        <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="workshop.content.blocks"></app-editor-js-viewer>
                                                    </div>
                                                    <div *ngIf="!workshop.content || !workshop.content.blocks || workshop.content.blocks.length==0" class="no-subheading" translate>Add a workshop description ...</div>
                                                </div>
                                            </div>

                                            <div class="heading-div" translate><div class="heading-title">{{'Tasks'|translate}} </div><div class="heading-menu">        <button *ngIf="this.model.adminRole()" mat-icon-button [matMenuTriggerFor]="favor_menu">
                                                        <mat-icon>add_circle</mat-icon>
                                                    </button></div>
                                            </div>
                                            <div *ngIf="workshop_id && !finished">
                                                <app-workshop-widget [workshop_id]="workshop_id" favor_id=""></app-workshop-widget>
                                            </div>

                                            <br/>
                                        </div>
                                    </div >


                                    <!-- Finished text -->
                                    <div *ngIf="finished && finished=='true'" class="finished-text"> 
                                        <div class="heading2"><span  translate>Thanks</span> </div>
                                        <div [ngSwitch]="edit_finished_text">
                                            <div *ngSwitchCase="true" class="catalog-description">
                                                <app-editor-js [content]="workshop.finished_text" [projectId]="model.process_service.process.id"></app-editor-js>
                                            </div>

                                            <div *ngSwitchCase="false" class="catalog-description">
                                                <div *ngIf="workshop.finished_text">
                                                    <app-editor-js-viewer [blocks]="workshop.finished_text.content.blocks"></app-editor-js-viewer>
                                                </div>
                                                <div *ngIf="!workshop.finished_text || !workshop.finished_text.content || !workshop.finished_text.content.blocks" class="no-subheading" translate>Add a thank you description ...</div>
                                            </div>
                                        </div>

                                        <div class="button-right-div">
                                            <button mat-stroked-button color="primary" class="button-right"
                                                    [routerLink]="'/'+env.links.viewProject+'/'+model.project.id">{{'Home'|translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      

                        <div>
                            <div *ngIf="finished && finished=='true'"> 
                                <div *ngIf="model.process_service.process && model.process_service.process.workshops">
                                    <div class="heading2"><span  translate>Other Workshops</span> </div>
                                    <ul class="workshop-list">
                                        <span class="workshop" *ngFor="let workshop of model.process_service.process.workshops; let i = index">
                                            <li *ngIf="(workshop.status==wsType.promoted || workshop.status==wsType.active) && workshop.id != workshop_id">
                                                <a [routerLink]="'/'+env.links.workshop+'/'+model.project.id+'/'+workshop.id">
                                                    <div class="workshop-content">
                                                        <div class="workshop-title">{{workshop.title}}</div>
                                                        <div class="workshop-tagline"> {{workshop.summary}}</div>
                                                    </div>
                                                    <div class="workshop-join">{{'Visit'|translate}}</div>
                                                </a>
                                            </li>
                                        </span>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-workshop-navigator></app-workshop-navigator>
                </div>
            </div>
        </div>
    </div>
</div>


<mat-menu #workshop_menu="matMenu">
    <ng-template matMenuContent let-id="id" let-nr="nr">
        <button *ngIf="nr>0" mat-menu-item (click)="moveWorkshopUp(nr)">
            <mat-icon>north</mat-icon>
            <span>Move up</span>
        </button>
        <button *ngIf="model.process_service.process.workshops.length-1>nr" mat-menu-item (click)="moveWorkshopDown(nr)">
            <mat-icon>south</mat-icon>
            <span translate>Move down</span>
        </button>

        <button mat-menu-item (click)="deleteWorkshop(id)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>
    </ng-template>
</mat-menu>


<mat-menu #favor_menu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="newRatingFavor()">
            <mat-icon>assessment</mat-icon>
            <span translate>Rating favor</span>
        </button>

        <button mat-menu-item (click)="newDivergeFavor()">
            <mat-icon>call_split</mat-icon>
            <span translate>Diverge favor</span>
        </button>
    </ng-template>
</mat-menu>


<mat-menu #edit_favor_menu="matMenu">
    <ng-template matMenuContent let-id ="id">
        <button mat-menu-item (click)="deleteFavor(id)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>
    </ng-template>
</mat-menu>