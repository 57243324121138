<ng-container *ngIf="model.project && model.project.id && shouldShow()">
    <div class="toggler" (click)="toggleMenu()">
        <div class="title">
            <ng-container [ngSwitch]="activePageService.active_page">
                <ng-container *ngSwitchCase="env.links.viewProject">
                    {{"Overview"|translate}}
                </ng-container>
                <ng-container *ngSwitchCase="env.links.viewCatalog">
                    {{"Dashboard"|translate}}
                </ng-container>
                <ng-container *ngSwitchCase="env.links.sharing">
                    {{"Sharing"|translate}}
                </ng-container>
                <ng-container *ngSwitchCase="env.links.viewProjectSetting">
                    {{"Settings"|translate}}
                </ng-container>

            </ng-container>
        </div>
        <mat-icon *ngIf="!menuOpen">expand_more</mat-icon>
        <mat-icon *ngIf="menuOpen">expand_less</mat-icon>
    </div>
    <div (click)="closeMenu()" id="topMenuCatalogMobileMenuView" class="toggled {{menuOpen?'open':''}}">
        <button mat-button class="{{activePageService.active_page == env.links.viewProject?'active':''}}" routerLink="/{{env.links.viewProject}}/{{model.project.id}}" translate>Overview</button>
        <button mat-button class="{{activePageService.active_page == env.links.viewCatalog?'active':''}}" routerLink="/{{env.links.viewCatalog}}/{{model.project.id}}" translate>Dashboard</button>
        <button mat-button class="{{activePageService.active_page == env.links.sharing?'active':''}}" routerLink="/{{env.links.sharing}}/{{model.project.id}}" translate>Sharing</button>
        <button mat-button class="{{activePageService.active_page == env.links.viewProjectSetting?'active':''}}" routerLink="/{{env.links.viewProjectSetting}}/{{model.project.id}}" translate>Settings</button>

    </div>
</ng-container>
