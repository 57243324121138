
import {Component, OnInit, ElementRef, AfterViewInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from 'src/app/service/project.service';
import {ConceptService} from 'src/app/service/concept.service';
import {environment} from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ModelService, CurrentPage} from 'src/app/service/model.service';
import {kBlockType} from 'src/app/model/kblock.model';
import {AuthService} from 'src/app/service/auth.service';
import {ImageUploadDialogComponent} from '../../frontend/image-upload-dialog/image-upload-dialog.component';
import {ThumbnailService} from '../../frontend/thumbnail.service';
import {CaviDroppableHTML5} from "../../frontend/CaviDraggable";
import {KblockService} from "../../service/kblock.service";
import { EditorJsComponent } from 'src/app/frontend/editor-js/editor-js.component';

import { Subscription } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Process, Workshop, WorkshopStatusType} from 'src/app/model/process.model';
import { Favor, FavorType, StoryRating } from 'src/app/model/favor.model';

import * as uuid from 'uuid';
import { EditFavorComponent } from 'src/app/favors/edit-favor/edit-favor.component';
import { Concept } from 'src/app/model/concept.model';
import { EditDivergeFavorComponent } from 'src/app/favors/edit-diverge-favor/edit-diverge-favor.component';

@Component({
  selector: 'app-process-dashboard',
  templateUrl: './process-dashboard.component.html',
  styleUrls: ['./process-dashboard.component.scss'],
  host: {class:"scrollblock"}
})
export class ProcessDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;

  edit_project_heading: boolean = false;

  current_id: string;
  workshop_id : string;
  
  env = environment;
  FavorType = FavorType;
  save_sub : Subscription;
  cancel_sub : Subscription;
  edit_workshop : number = -1;
  
  workshop_options : WorkshopStatusType[];
  wsType = WorkshopStatusType;
  selectedValue : WorkshopStatusType;

  constructor(private authService: AuthService, public model: ModelService, private route: ActivatedRoute, private projectService: ProjectService, private conceptService: ConceptService, private router: Router, public dialog: MatDialog, private thumbnailService: ThumbnailService, private elementRef: ElementRef, private kblockService: KblockService,
     private _snackBar: MatSnackBar) {
      
  
    authService.userLoggedIn$.subscribe(user => {
      if (this.route.snapshot.paramMap.get('id') != this.current_id) {
        this.current_id = this.route.snapshot.paramMap.get('id');
        this.workshop_id = this.route.snapshot.paramMap.get('wid');

        console.log("Constructor - not equal");
        this.model.setSelectedProject(this.current_id);
        this.model.subscribekBlocks(this.projectService.getProjectReferenceById(this.current_id), kBlockType.Project);

        this.model.current_page = CurrentPage.catalog_overview;
      }

    });


  
    router.events.subscribe((val) => {
        if (this.route.snapshot.paramMap.get('id') != this.current_id && authService.getUser()) {
          this.current_id = this.route.snapshot.paramMap.get('id');
          this.workshop_id = this.route.snapshot.paramMap.get('wid');

          console.log("Constructor - not equal");
          this.model.setSelectedProject(this.current_id);
          this.model.subscribekBlocks(this.projectService.getProjectReferenceById(this.current_id), kBlockType.Project);

          this.model.current_page = CurrentPage.catalog_overview;
        }

      }
    )

    this.model.subscribeProjects();


  }

  ngAfterViewInit() {
    let observer = new MutationObserver((mutations)=>{
      mutations.forEach((mutation)=>{
        if(mutation.target instanceof Element && mutation.target.matches(".droppable")) {
          this.setupDrop(mutation.target);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement, {
      subtree: true,
      childList: true
    });

    this.elementRef.nativeElement.querySelectorAll(".droppable").forEach((story)=>{
      this.setupDrop(story);
    });
  }

  setupDrop(story): void {
    if(story.caviDroppable == null) {
      new CaviDroppableHTML5(story, {
        onDrop: (evt)=>{
          if(evt.dataTransfer.types.includes('application/x-editor-js')) {
            let kblockId = JSON.parse(evt.dataTransfer.getData('application/x-editor-js'))[0].data.id;
            let storyId = story.getAttribute("data-story-id");

            // Get the local version of the kblock since we are not listening on updates due to pagination.
            const kblock = this.model.getLocalkBlockVersion(kblockId);
            if (kblock==null) return;

            //let kblockSubscription = this.kblockService.getkBlock(kblockId).subscribe((kblock)=>{
              let storySubscription = this.conceptService.getConcept(storyId).subscribe((story)=>{
                //kblock.id = kblockId;
                story.id = storyId;
                this.model.connectConceptKblock(kblock, story);
              //  kblockSubscription.unsubscribe();
                storySubscription.unsubscribe();
              });
            //});
          }
        },
        onDragOver: (evt)=>{
          evt.dataTransfer.dropEffect = "copy";
        }
      });
    }
  }

  ngOnInit(): void {

  }




  enterEditmode() {
    this.edit_workshop = -1;
    this.edit_project_heading=true;
    setTimeout(()=>{
      if (this.save_sub) this.save_sub.unsubscribe();
      this.save_sub= this.editorjs.saved.subscribe((result)=>{
        this.model.process_service.process.content = result;
        this.updateProcess();

      });
      if (this.cancel_sub) this.cancel_sub.unsubscribe();
      this.cancel_sub = this.editorjs.canceled.subscribe(()=>{
        this.edit_project_heading=false;
      });
    }, 0);

  }


  enterEditWorkshop(id : number) {
    this.edit_project_heading=false;
    this.edit_workshop = id;
    console.log(this.edit_workshop);
    setTimeout(()=>{
      if (this.save_sub) this.save_sub.unsubscribe();
      this.save_sub= this.editorjs.saved.subscribe((result)=>{
        this.model.process_service.process.workshops[id].content = result;
        this.updateProcess();

      });
      if (this.cancel_sub) this.cancel_sub.unsubscribe();
      this.cancel_sub = this.editorjs.canceled.subscribe(()=>{
        this.edit_project_heading=false;
        this.edit_workshop = -1;
      });
    }, 0);

  }

  
createProcess() {
  if (this.model.process_service.process==null) {
    const process = new Process();
    process.catalog = this.model.project.id;
   process.title = "";
   process.content = "";
   this.model.process_service.create(process);
 } 
}


  createWorkshop() {

    if (!this.model.process_service.process.workshops)
    {
      this.model.process_service.process.workshops = [];
    }
    const workshop = {} as Workshop;
    workshop.title = "No title"; 
    workshop.status = WorkshopStatusType.hidden;
    workshop.id = uuid.v4();

    workshop.visible = true; 
    this.model.process_service.process.workshops.push(workshop);
    this.model.process_service.update();
  }

  deleteWorkshop(id : string) {

    if (this.model.favors.find(favor =>{ return favor.workshop_id == id}))
    {
      this._snackBar.open("Please remove all favors before deleting workshop", "", {
        duration: 3000,
      });
    } else {

      this.model.process_service.deleteWorkshop(id)
    }


  }

  deleteFavor(id : string) 
  {
    this.model.favorService.deleteFavor(id);
  }

  updateProcess() {
    this.model.process_service.update();
    this.edit_project_heading=false;
    this.edit_workshop = -1;

  }

  showUploadImageDialog() {
    ImageUploadDialogComponent.showUploadDialog().subscribe((image) => {
      if (image == null) {
        return;
      }

      //Delete old file
      if (this.model.project.img_url) {
        this.model.project.img_url = "";
        try {
          this.thumbnailService.delete(this.model.project.img_url);
        } catch (e) {
          //Ignore silently
        }
      }

      if (this.model.project.image) {
        this.thumbnailService.delete(this.model.project.image);
      }

      //Attach and save
      this.model.project.image = image;
      this.projectService.updateProject(this.model.project);
    });
  }

  getImage(obj, getSmall = false) {
    if (obj != null) {
      if (obj.image != null) {
        return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
      } else {
        if (obj.image_name) {
          return obj.image_name;
        }
        if (obj.img_url) {
          return obj.img_url;
        }
      }
    }
  }



  ratingCompleted(story:Concept) {
    return this.model.user_story_ratings.some(x => x.story.id == story.id);
  }
  

  favorString(favor : Favor)
  {

    if (this.model.user_story_ratings && this.model.concepts && FavorType.StoryRatings==favor.type)
       return "("+ this.model.countFavors(favor) +" / "+favor.stories.length + ")";
    else return "";
  }

  favorCompleted(favor : Favor) : boolean
  {
    if (favor.type == FavorType.StoryRatings)
      return this.model.countFavors(favor) == favor.stories.length;
    else return false;
  }


  workshopStatusChanged() {
    this.model.process_service.update();
  }


  swapWorkshops(fromIndex : number, toIndex : number) {
    this.model.process_service.process.workshops = this.model.process_service.process.workshops.map((val, idx) => {
    if (idx === fromIndex) return this.model.process_service.process.workshops[toIndex];
    if (idx === toIndex) return this.model.process_service.process.workshops[fromIndex];
    return val;
  });
}

moveWorkshopUp(id : number) {
  this.swapWorkshops(id, id-1);
  this.model.process_service.update();
}

moveWorkshopDown(id : number) {
  this.swapWorkshops(id, id+1);
  this.model.process_service.update();
}

changeVisibility(number: number, statusType: WorkshopStatusType) {
  this.model.process_service.process.workshops[number].status=statusType;
  this.model.process_service.update();
}


}
