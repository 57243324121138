import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from "@angular//fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { QuillModule } from 'ngx-quill';

import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { ViewConceptComponent } from './concepts/view-concept/view-concept.component';
import { CreateConceptComponent } from './concepts/create-concept/create-concept.component';
import { ProjectOverviewComponent } from './projects/project-overview/project-overview.component';
import { NewProjectComponent } from './projects/new-project/new-project.component';
import { StoryMenuComponent } from './menu/story-menu/story-menu.component';
import { LeftStartMenuComponent } from './menu/left-start-menu/left-start-menu.component';
import { LoginComponent } from './login/login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import { FrontpageComponent } from './frontpage/frontpage/frontpage.component';
import { KblocksOverviewComponent } from './kblocks/kblocks-overview/kblocks-overview.component';
import { CreateKblocksComponent } from './kblocks/create-kblocks/create-kblocks.component';
import { UserDataComponent } from './users/user-data/user-data.component';
import { ScrollableDirective } from './directive/scrollable.directive';
import { LoadingSpinnerComponent } from './common/loading-spinner/loading-spinner.component'
import { MobileMenuComponent } from './menu/mobile-menu/mobile-menu.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CommonModule} from "@angular/common";
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';



import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {FormsModule, ReactiveFormsModule } from '@angular/forms';



import {TopMenuComponent} from "./menu/top-menu/top-menu.component";
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {StoryCatalogViewComponent} from "./frontend/story-catalog-view/story-catalog-view.component";
import {QuestionnaireComponent} from "./frontend/questionnaire/questionnaire.component";
import {QresponseCommentViewComponent} from "./frontend/qresponse-comment-view/qresponse-comment-view.component";
import {CookieService} from "ngx-cookie-service";
import {FrontendComponent} from "./frontend/frontend.component";
import { QuestionnaireQuestionComponent } from './frontend/questionnaire-question/questionnaire-question.component';
import {StoryRatingMapComponent} from "./frontend/story-rating-map/story-rating-map.component";
import {ConceptPuzzleComponent} from "./frontend/concept-puzzle/concept-puzzle.component";
import { UserInlineViewComponent } from './frontend/user-inline-view/user-inline-view.component';
import { ImageUploadDialogComponent } from './frontend/image-upload-dialog/image-upload-dialog.component';
import {MaterialFileInputModule} from "ngx-material-file-input";
import {MatTabsModule} from "@angular/material/tabs";
import { EditorJsComponent } from './frontend/editor-js/editor-js.component';


import {HttpClient, HttpClientModule} from '@angular/common/http';
import { EditorJsViewerComponent } from './frontend/editor-js-viewer/editor-js-viewer.component';
import { EditorJSViewerKblockComponent } from './frontend/editor-jsviewer-kblock/editor-jsviewer-kblock.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { StorySelectDialogComponent } from './frontend/story-select-dialog/story-select-dialog.component';
import { StoryRatingEditorComponent } from './frontend/story-rating-editor/story-rating-editor.component';
import { StoryRatingViewComponent } from './frontend/story-rating-view/story-rating-view.component';
import { CatalogDashboardComponent, NewSectionDialog } from './catalogs/catalog-dashboard/catalog-dashboard.component';
import { CatalogSettingsComponent } from './catalogs/catalog-settings/catalog-settings.component';
import { PuzzleCommentDialogComponent } from './frontend/puzzle-comment-dialog/puzzle-comment-dialog.component';
import { JoinCatalogComponent } from './catalogs/join-catalog/join-catalog.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { EditFavorComponent } from './favors/edit-favor/edit-favor.component';
import { MainOverviewComponent } from './dashboard/main-overview/main-overview.component';
import { FavorWidgetComponent } from './favors/favor-widget/favor-widget.component';
import { CatalogWidgetComponent } from './catalogs/catalog-widget/catalog-widget.component';
import { ProcessDashboardComponent } from './process/process-dashboard/process-dashboard.component';
import { RatingFavorComponent } from './favors/rating-favor/rating-favor.component';
import { DivergeFavorComponent } from './favors/diverge-favor/diverge-favor.component';
import { CommenterComponent } from './frontend/commenter/commenter.component';
import { EditDivergeFavorComponent } from './favors/edit-diverge-favor/edit-diverge-favor.component';
import { CommenterEditorComponent } from './frontend/commenter-editor/commenter-editor.component';
import { LikeComponent } from './frontend/like/like.component';
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { WorkshopWidgetComponent } from './process/workshop-widget/workshop-widget.component';
import { ViewWorkshopComponent } from './process/view-workshop/view-workshop.component';
import { MemberInfoComponent } from './frontend/member-info/member-info.component';
import { MemberInfoServiceTestComponent } from './frontend/member-info-service-test/member-info-service-test.component';
import { CatalogMenuComponent } from './menu/catalog-menu/catalog-menu.component';
import { MemberInfoPageComponent } from './frontend/member-info-page/member-info-page.component';
import { InspirationBlockComponent } from './kblocks/inspiration-block/inspiration-block.component';
import { EditInspirationBlockComponent } from './kblocks/edit-inspiration-block/edit-inspiration-block.component';
import { SharingCatalogComponent } from './catalogs/sharing-catalog/sharing-catalog.component';
import { ViewCatalogComponent } from './catalogs/view-catalog/view-catalog.component';
import { InspirationGridViewComponent } from './kblocks/inspiration-grid-view/inspiration-grid-view.component';
import { EditorDialogComponent } from './dialogs/editor-dialog/editor-dialog.component';
import { HeaderDialogComponent } from './dialogs/header-dialog/header-dialog.component';
import { KblockSelectDialogComponent } from './frontend/kblock-select-dialog/kblock-select-dialog.component';
import { StoryRatingSettingComponent } from './frontend/story-rating-setting/story-rating-setting.component';
import { ParticipantViewComponent } from './frontend/participant-view/participant-view.component';
import { UserBlockViewComponent } from './frontend/user-block-view/user-block-view.component';
import { ParticipantViewPageComponent } from './frontend/participant-view-page/participant-view-page.component';
import { TopMenuMobileComponent } from './menu/top-menu-mobile/top-menu-mobile.component';
import { TopMenuCatalogMobileComponent } from './menu/top-menu-catalog-mobile/top-menu-catalog-mobile.component';
import { TopMenuStoryMobileComponent } from './menu/top-menu-story-mobile/top-menu-story-mobile.component';
import { StoryNavigatorComponent } from './frontend/story-navigator/story-navigator.component';
import { StoryNavigatorDialogComponent } from './frontend/story-navigator-dialog/story-navigator-dialog.component';
import { TopMenuWorkshopMobileComponent } from './menu/top-menu-workshop-mobile/top-menu-workshop-mobile.component';
import { WorkshopNavigatorComponent } from './frontend/workshop-navigator/workshop-navigator.component';
import { WorkshopNavigatorDialogComponent } from './frontend/workshop-navigator-dialog/workshop-navigator-dialog.component';
import { FavorNavigatorComponent } from './frontend/favor-navigator/favor-navigator.component';
import { FavorNavigatorDialogComponent } from './frontend/favor-navigator-dialog/favor-navigator-dialog.component';
import { KblocksDateOverviewComponent } from './kblocks/kblocks-date-overview/kblocks-date-overview.component';
import { StorySidebarComponent } from './menu/story-sidebar/story-sidebar.component';
import {LightboxModule} from "ngx-lightbox";
import { SafeHtmlPipe } from './safe-html.pipe';
import { StoriesListComponent } from './menu/stories-list/stories-list.component';
import { ColorGithubModule } from 'ngx-color/github';
import { SpinnerOverlayComponent } from './frontend/spinner-overlay/spinner-overlay.component';
import { NewSectionDialog2Component } from './catalogs/new-section-dialog2/new-section-dialog2.component';
import { TaglistComponent } from './kblocks/taglist/taglist.component';
import { FilterHiddenKblockPipe } from './frontend/pipes/filter-hidden-kblock.pipe';
import { FilterBlockdatesPipe } from './frontend/pipes/filter-blockdates.pipe';
import { SitePopupComponent } from './frontend/onboarding/site-popup/site-popup.component';
import { ShouldShowTagPipe } from './frontend/pipes/should-show-tag.pipe';
import { OnboardingAdminComponent } from './frontend/onboarding/onboarding-admin/onboarding-admin.component';
import { OnboardingStepEditorComponent } from './frontend/onboarding/onboarding-step-editor/onboarding-step-editor.component';
import { SidebarHeaderComponent } from './menu/sidebar-header/sidebar-header.component';
import { NonEmptyKblockPipe } from './frontend/pipes/non-empty-kblock.pipe';
import { KblocksSearchComponent } from './kblocks/kblocks-search/kblocks-search.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateConceptComponent,
    ViewConceptComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    FrontpageComponent,
    KblocksOverviewComponent,
    CreateKblocksComponent,
    ProjectOverviewComponent,
    NewProjectComponent,
    StoryMenuComponent,
    LeftStartMenuComponent,
    NewSectionDialog,
    UserDataComponent,
    ScrollableDirective,
    LoadingSpinnerComponent,
    MobileMenuComponent,
    TopMenuComponent,
    StoryCatalogViewComponent,
    QuestionnaireComponent,
    QresponseCommentViewComponent,
    FrontendComponent,
    QuestionnaireQuestionComponent,
    StoryRatingMapComponent,
    ConceptPuzzleComponent,
    UserInlineViewComponent,
    ImageUploadDialogComponent,
    EditorJsComponent,
    EditorJsViewerComponent,
    EditorJSViewerKblockComponent,
    StorySelectDialogComponent,
    StoryRatingEditorComponent,
    StoryRatingViewComponent,
    CatalogDashboardComponent,
    CatalogSettingsComponent,
    PuzzleCommentDialogComponent,
    JoinCatalogComponent,
    EditFavorComponent,
    MainOverviewComponent,
    FavorWidgetComponent,
    CatalogWidgetComponent,
    ProcessDashboardComponent,
    RatingFavorComponent,
    DivergeFavorComponent,
    CommenterComponent,
    EditDivergeFavorComponent,
    CommenterEditorComponent,
    LikeComponent,
    WorkshopWidgetComponent,
    ViewWorkshopComponent,
    MemberInfoComponent,
    MemberInfoServiceTestComponent,
    CatalogMenuComponent,
    MemberInfoPageComponent,
    InspirationBlockComponent,
    EditInspirationBlockComponent,
    SharingCatalogComponent,
    ViewCatalogComponent,
    InspirationGridViewComponent,
    EditorDialogComponent,
    HeaderDialogComponent,
    KblockSelectDialogComponent,
    StoryRatingSettingComponent,
    ParticipantViewComponent,
    UserBlockViewComponent,
    ParticipantViewPageComponent,
    TopMenuMobileComponent,
    TopMenuCatalogMobileComponent,
    TopMenuStoryMobileComponent,
    StoryNavigatorComponent,
    StoryNavigatorDialogComponent,
    TopMenuWorkshopMobileComponent,
    WorkshopNavigatorComponent,
    WorkshopNavigatorDialogComponent,
    FavorNavigatorComponent,
    FavorNavigatorDialogComponent,
    KblocksDateOverviewComponent,
    StorySidebarComponent,
    SafeHtmlPipe,
    StoriesListComponent,
    SpinnerOverlayComponent,
    NewSectionDialog2Component,
    TaglistComponent,
    FilterHiddenKblockPipe,
    FilterBlockdatesPipe,
    ShouldShowTagPipe,
    SitePopupComponent,
    OnboardingAdminComponent,
    OnboardingStepEditorComponent,
    SidebarHeaderComponent,
    NonEmptyKblockPipe,
    KblocksSearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    DragDropModule,
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCardModule,
    MatMenuModule,
    MaterialFileInputModule,
    MatCheckboxModule,
    FormsModule,
    MatStepperModule,
    QuillModule.forRoot(),
    MatTabsModule,
    HttpClientModule,
    MatTooltipModule,
    ClipboardModule,
    LightboxModule,
    ReactiveFormsModule,
    ColorGithubModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MatSlideToggleModule
  ],
  providers: [ UserTrackingService, ScreenTrackingService, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }, CookieService
        /*,
      { provide: USE_FIRESTORE_EMULATOR, useValue: ['localhost', 8080] },
      { provide: USE_FUNCTIONS_EMULATOR, useValue: ['localhost', 5001] }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
