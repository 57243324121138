import htmlTemplate from './image-block.html'
import {Image, ImageUploadDialogComponent} from '../../image-upload-dialog/image-upload-dialog.component';
import {EditorJSBlockTranslator} from "../EditorJSBlockTranslator";

export class ImageBlock {
  static get toolbox() {
    return {
      title: "Image",
      icon: "<span class='material-icons'>add_photo_alternate</span>"
    }
  }

  private readonly editorHtml : HTMLElement;
  private template;

  constructor({api, config, data}) {

    this.template = document.createElement('template');
    this.template.innerHTML = EditorJSBlockTranslator.translateHtmlTemplate(htmlTemplate);

    this.editorHtml = this.template.content.querySelector(".image-block") as HTMLElement;

    this.editorHtml.querySelector("button").addEventListener("click", ()=>{
      ImageUploadDialogComponent.showUploadDialog().subscribe((image)=>{
        if (image == null) {
          return;
        }

        this.insertImage(image);
      });
    });

    if(data.image != null && data.image.url != null) {
      this.insertImage(data.image);
    }
  }

  insertImage(image: Image) {
    let img = document.createElement("img");
    img.src = image.url.small.replace(/&amp;/g, "&");

    // @ts-ignore
    img.imgData = image;

    let imageDiv = this.editorHtml.querySelector(".image");
    imageDiv.innerHTML = "";
    imageDiv.append(img);
  }

  render() {
    return this.editorHtml;
  }

  save(blockContent) {
    let imgData = null;

    try {
      imgData = blockContent.querySelector(".image img").imgData;
    } catch(e) {
    }

    return {
      image: imgData
    }
  }
}
