import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import {ModelService} from "../../service/model.service";
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../onboarding-flow.scss', './login.component.css'],
  host: {'class': 'centerblock'}
})
export class LoginComponent implements OnInit {
  env = environment;
  constructor(private model : ModelService, public authService: AuthService) {
    authService.userLoggedIn$.subscribe(user => { this.model.subscribeProjects();})
  }

  ngOnInit(): void {
    if (this.authService.getUser())
    {
      this.model.subscribeProjects();
    }
  }
}
