<div>{{question.question}}</div>
<div [ngSwitch]="question.type" *ngIf="ready">
    <div *ngSwitchCase="'multiple_choice'" class="flippable-question {{showStats?'flipped':''}}">
        <div class="options">
            <div class="options-list">
                <button mat-stroked-button color="primary" class="option {{isSelected(option)?'mat-flat-button':''}}" *ngFor="let option of question.options" (click)="answerMultipleChoice(option)" [disabled]="this.currentlyAnswering">{{option.name}}</button>
            </div>
            <div class="flipper" (click)="toggleStats()" *ngIf="canSeeStats()">{{'See Results'|translate}} &gt;</div>
        </div>
        <ul class="statistics">
            <li class="stats-item {{isSelected(option)?'selected':''}}" *ngFor="let option of question.options">
                <div class="graph-area">
                    <div class="option-title">{{option.name}}</div>
                    <div class="graph" style="width:{{selectedPercentage(option)}}%"></div>
                    <div class="value-view">{{selectedPercentage(option)}}%</div>
                </div>
            </li>
            <li class="flipper" (click)="toggleStats()">&lt; {{'Your answer'|translate}}</li>
        </ul>
    </div>

  <div *ngSwitchCase="'comments'">
    <ng-container *ngIf="authService.isLoggedIn else notMember">
      <div [ngSwitch]="isAnswered() && !commentEditMode">
        <div *ngSwitchCase="true">
            <div class="comment-options">
                <button mat-button (click)="switchToEditMode()" class="edit-comment-button" title="{{'Edit'|translate}}">
                    <mat-icon class="material-icons">edit</mat-icon>
                </button>
                <button mat-button (click)="deleteAnswer()" class="delete-comment-button" title="{{'Delete'|translate}}">
                  <mat-icon class="material-icons">delete</mat-icon>
                </button>
            </div>
            <app-qresponse-comment-view [question]="question" [questionnaireRef]="questionnaireRef" [showResults]="questionnaire.show_result || model.adminRole()"></app-qresponse-comment-view>
        </div>
        <div *ngSwitchCase="false">
          <textarea cols="40" rows="8" (input)="updateCommentAnswer($event)">{{commentAnswer}}</textarea>
          <button mat-flat-button color="primary" (click)="answerComment()" *ngIf="!commentEditMode">{{'Comment'|translate}}</button>
          <button mat-flat-button (click)="cancelEditMode()" *ngIf="commentEditMode">{{'Cancel'|translate}}</button>
          <button mat-flat-button color="primary" (click)="answerComment()" *ngIf="commentEditMode">{{'Save'|translate}}</button>
        </div>
      </div>
    </ng-container>
    <ng-template #notMember>
      <div>{{'To answer comment questions, you have to be logged in!'|translate}}</div>
    </ng-template>
  </div>

    <div *ngSwitchCase="'rating'" class="rating flippable-question {{showStats?'flipped':''}}">
        <div class="options">
            <table>
                <tr>
                    <th *ngFor="let option of [1,2,3,4,5,6,7,8,9,10]" class="fixed-width">{{option}}</th>
                </tr>
                <tr class="critline">
                    <td *ngFor="let option of [1,2,3,4,5,6,7,8,9,10]" class="fixed-width">
                        <mat-radio-button color="primary" [name]="question.id" (change)="answerRating(option)" [checked]="isRatingSelected(option)"></mat-radio-button>
                    </td>
                </tr>
            </table>
            <div class="flipper" (click)="toggleStats()" *ngIf="canSeeStats()">{{'See Results'|translate}} &gt;</div>
        </div>
        <ul class="statistics">
            <li class="stats-item">
                <div class="graph-area">
                    <div class="graph" style="width: {{(ratingValue() / 10) * 100}}%"></div>
                    <div class="value-view avgRating">{{ratingValue()}}</div>
                </div>
            </li>
            <li class="flipper" (click)="toggleStats()">&lt; {{'Your answer'|translate}}</li>
        </ul>
    </div>
</div>
