import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ViewCatalogComponent} from '../view-catalog/view-catalog.component';

@Component({
  selector: 'app-new-section-dialog2',
  templateUrl: './new-section-dialog2.component.html',
  styleUrls: ['./new-section-dialog2.component.scss']
})
export class NewSectionDialog2Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewCatalogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  name: string;
  edit : boolean;
}
