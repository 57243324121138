<app-sidebar-header></app-sidebar-header>

<!--<a class="sidebar-entry" routerLink="/{{env.links.viewCatalog}}/{{this.model.project.id}}">
  <mat-icon>art_track</mat-icon>
  <span class="title" translate>View stories</span>
</a>-->
<div class="sidebar-divider hide-when-minimized"></div>

<a class="sidebar-entry padding-small" [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id"  [attr.title]="model.project.name" *ngIf="model.project && model.isProjectMember()">
  <img [src]="getImage(model.project)" class="menu-image"/>
  <span class="title" translate>{{model.project.name}}</span>
</a> 
<a class="sidebar-entry padding-small"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id"  [attr.title]="model.project.name" *ngIf="model.project && !model.isProjectMember()">
  <img [src]="getImage(model.project)" class="menu-image"/>
  <span class="title" translate>{{model.project.name}}</span>
</a> 
<div class="sidebar-divider hide-when-minimized"></div>
<app-stories-list *ngIf="!favor_id" [story]="story_id"></app-stories-list>
<app-workshop-widget *ngIf="favor_id" [favor_id]="favor_id"></app-workshop-widget>


<a href="javascript:void(null);" class="sidebar-entry" (click)="toggle()" title="Toggle Menu">
  <ng-container *ngIf="minimized">
    <mat-icon>chevron_right</mat-icon>
    <span class="title" translate>{{'Re-open Menu'}}</span>
  </ng-container>
  <ng-container *ngIf="!minimized">
    <mat-icon>chevron_left</mat-icon>
    <span class="title" translate>{{'Minimize Menu'}}</span>
  </ng-container>
</a>