import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {setOffsetToUTC} from "ngx-bootstrap/chronos/units/offset";
import {Image} from "./image-upload-dialog/image-upload-dialog.component";
import {User} from "../model/user.model";

export enum ThumbSize {
  LARGE,
  SMALL
}

@Injectable({
  providedIn: 'root'
})
export class ThumbnailService {

  constructor(private storage: AngularFireStorage) { }

  upload(file): Observable<string> {
    let subject = new Subject<string>();

    let uploadPath = `images/${Date.now()}_${file.name}`;

    let ref = this.storage.ref(uploadPath);
    ref.put(file).then(async ()=>{
      let url = await ref.getDownloadURL().toPromise();
      subject.next(url);
    });

    return subject;
  }

  delete(image : string|Image) {
    if(typeof image === "string") {
      let fileRef = this.storage.storage.refFromURL(image);

      return fileRef.delete();
    } else {
      let promises = [];

      if(image.type === 0) {
        promises.push(this.delete(image.url.original));
        promises.push(this.delete(image.url.small));
        promises.push(this.delete(image.url.normal));
      }

      return Promise.all(promises);
    }
  }

  getThumbnail(url: string, size: ThumbSize): Observable<string> {
    return new Observable<string>((subscriber)=>{

      try {
        let fileRef = this.storage.storage.refFromURL(url);

        let thumbnailPath = fileRef.fullPath;

        switch(size) {
          case ThumbSize.LARGE:
            thumbnailPath += "@thumb_1920.jpg";
            break;
          case ThumbSize.SMALL:
            thumbnailPath += "@thumb_512.jpg";
            break;
          default:
            console.warn("Unknown thumbnail size: ", size);
        }

        let self = this;

        let attempts = 0;

        function retrieveThumbnail() {
          self.storage.ref(thumbnailPath).getDownloadURL().subscribe((result)=>{
            subscriber.next(result);
            subscriber.complete();
          }, (err)=>{
            attempts++;

            if(attempts < 10) {
              setTimeout(retrieveThumbnail, 500);
            } else {
              subscriber.next(url);
              subscriber.complete();
            }
          });
        }

        retrieveThumbnail();

      } catch(e) {
        console.error(e);
        subscriber.next(url);
        subscriber.complete();
      }
    });
  }

  static webRegexp = new RegExp(
      "^" +
      // protocol identifier (optional)
      // short syntax // still required
      "(?:(?:(?:https?|ftp):)?\\/\\/)" +
      // user:pass BasicAuth (optional)
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      // IP address exclusion
      // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      // port number (optional)
      "(?::\\d{2,5})?" +
      // resource path (optional)
      "(?:[/?#]\\S*)?" +
      "$", "i"
  );

  static getCaption(image) {
    if(image != null) {
      if (image.author != null) {
        if (image.provider != null) {
          return "Photo by <a href='" + image.author.link + "'>" + image.author.name + "</a> on <a href='" + image.provider.link + "'>" + image.provider.name + "</a>";
        }
        return "Photo by <a href='" + image.author.link + "'>" + image.author.name + "</a>";
      }
    }

    return "";
  }

  static getPhotoFromUser(user: any) {
    let resultUrl = null;

    if(user != null) {
      if(user.image != null) {
        resultUrl = user.image.url.small.trim();
      } else if(user.photoURL != null && user.photoURL.trim().length > 0) {
        resultUrl = user.photoURL.trim();
      }
    }

    if(ThumbnailService.webRegexp.test(resultUrl)) {
      return resultUrl;
    }

    //TODO Change me to something more non shutterstock
    return null;
  }
}
