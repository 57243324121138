import { Injectable } from '@angular/core';
import {User} from 'src/app/model/user.model';
import { Router } from  "@angular/router";
import   firebase  from  'firebase/compat/app';
import { AngularFireAuth } from  "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import { environment } from 'src/environments/environment';
import DocumentReference = firebase.firestore.DocumentReference;

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  env = environment;
  userData: User; // Save logged in user data

  private userLoggedInSource = new Subject<User>();
  public userLoggedIn$ = this.userLoggedInSource.asObservable();
  public logout: boolean = false;
  public login: boolean = false;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private cookieService : CookieService,
  ) {


    /* Saving user data in cookie when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = new User();
        this.userData.uid = user.uid;
        this.userData.email = user.email;
        this.userData.emailVerified = user.emailVerified;

        const loadUserSubscription = this.loadUserByEmail(user.email).subscribe(result => {
          if (result.length > 0) {
            loadUserSubscription.unsubscribe();
            const remote_user = result[0] as User;
            this.userData.photoURL = remote_user.photoURL;
            this.userData.displayName = remote_user.displayName;
            this.userData.savedLanguage = remote_user.savedLanguage;
            this.userData.description = remote_user.description;
            this.userData.image = remote_user.image;
            this.userData.hiddenProjects = remote_user.hiddenProjects;
            this.userData.onboardingLastStep = remote_user.onboardingLastStep;

            console.log("User data ready!");

            if(this.userData.description == null) {
              this.userData.description = "";
            }

            if (this.userData.emailVerified && !remote_user.emailVerified)
            {
              remote_user.emailVerified = true;
              this.updateUser(remote_user as User);
            }
            this.updateUserCookie(this.userData);
            this.userLoggedInSource.next(this.getUser());
          }
        }
          );

      } else {
        this.setUserCookie(null);
      }
    })
  }

  getUser() : User {
    if (this.userData) {
      return this.userData as User;
    } else {
      const userCookie = this.cookieService.get("ideate-user");
      if (userCookie == null) {
        return null;
      }
      
      try {
        const user = JSON.parse(userCookie);

        if(user?.hiddenProjects != null) {
          user.hiddenProjects = user.hiddenProjects.map((project)=>{
            return this.afs.doc(project).ref;
          });
        }
        this.userData = user;
        return user;
      } catch (ex){
          return null;
      }
    }
  }

  private updateUserCookie(user:User) {
    this.setUserCookie(JSON.stringify(user, (key,value)=>{
      if(value instanceof DocumentReference) {
        return value.path;
      }

      return value;
    }, 2));
  }

  private setUserCookie(cookieValue) {
    if (cookieValue && (cookieValue as User).emailVerified== false)
      return;

    this.cookieService.set("ideate-user", cookieValue, 365, "/");
  }

  // Sign in with email/password
  SignIn(email, password) {
    this.login=true;
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserDataLogin(result.user);
        this.postLogin();
      }).catch((error) => {
        window.alert(error.message);
        this.login=false;
      })
  }

    // Sign up with email/password
    SignUp(displayName : string, email, password) {
      return this.afAuth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          /* Call the SendVerificaitonMail() function when new user sign
          up and returns promise */
          this.SendVerificationMail();

          this.SetUserDataSignUp(result.user, displayName);
        }).catch((error) => {
          window.alert(error.message)
        })
    }

    // Send email verfificaiton when new user sign up
    async  SendVerificationMail() {
      return (await this.afAuth.currentUser).sendEmailVerification()
      .then(() => {
        this.router.navigate([this.env.links.verifyEmail]);
      })
    }

    // Reset Forggot password
    ForgotPassword(passwordResetEmail) {
      return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
    }

    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
      try {
        const user = JSON.parse(this.cookieService.get("ideate-user"));
        return user !== null && user.emailVerified !== false;
      } catch(e) {
        //Error retrieving user from cookie, this means we are not logged in
        return false;
      }
    }

  FacebookAuth() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

    // Sign in with Google
    GoogleAuth() {
      return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
    }


  // Auth logic to run auth providers
  AuthLogin(provider) {
    this.login=true;
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
      this.SetUserDataLogin(result.user);
      this.postLogin();
    }).catch((error) => {
      window.alert(error);
      this.login=false;
    })
  }



  postLogin() {
    let previousPageBeforeLogin = window.localStorage.getItem('previousUrl');
    const queryParams = {};

    //Extract queryParameters
    if(previousPageBeforeLogin.indexOf("?") !== -1) {
      const queryPart = previousPageBeforeLogin.substring(previousPageBeforeLogin.indexOf("?")+1);
      const queryParameters = queryPart.split("&");

      queryParameters.forEach((queryParameter)=>{
        const split = queryParameter.split("=");
        queryParams[split[0]] = split[1];
      });

      previousPageBeforeLogin = previousPageBeforeLogin.substring(0, previousPageBeforeLogin.indexOf("?"));
    }

    if (this.getUser()) {
      this.login = false;
      this.router.navigate([previousPageBeforeLogin], {queryParams: queryParams});
      return;
    }

    //Wait for login to be complete before redirecting, instead of waiting some amount of time
    const loginSubscription = this.userLoggedIn$.subscribe(()=>{
      loginSubscription.unsubscribe();

      this.router.navigate([previousPageBeforeLogin], {queryParams: queryParams});
    });

    this.login=false;
  }

    /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserDataLogin(user) {
    return new Promise<void>((resolve, reject)=>{
      const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

      userRef.get().subscribe((userDoc)=>{
        if(userDoc.exists) {
          //We were already in the system

          resolve();
        } else {
          const userData: User = {
            uid: null,
            email: null,
            emailVerified: null
          };

          if (user.uid) {
            userData.uid = user.uid;
          }
          if (user.emailVerified) {
            userData.emailVerified = user.emailVerified;
          }
          if (user.displayName) {
            userData.displayName = user.displayName;
          }
          if (user.photoURL) {
            userData.photoURL = user.photoURL;
          }
          if(user.email) {
            userData.email = user.email;
          }

          userRef.set(userData, {
            merge: true
          }).then(()=>{
            resolve();
          });
        }
      });
    });
  }



    /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserDataSignUp(user, displayName : string) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    let userData: User = {
      email : user.email,
      uid : user.uid,
      emailVerified : user.emailVerified,
      displayName : displayName
    }

    return userRef.set(userData, {
      merge: true
    })
  }

  SaveUser(user: User)
  {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(user, {
      merge: true
    });
  }

  // Sign out
  SignOut() {

    this.logout = true;


    return this.afAuth.signOut().then(() => {
      this.cookieService.delete('ideate-user');

      this.userData = null;
      this.userLoggedInSource.next(null);
      this.logout = false;
      window.location.href = "https://www.ideate.nu";
    });
  }


  getUserReference() : DocumentReference {
    if (!this.getUser())
      return null;

    return this.afs.doc<User>('users/' + this.getUser().uid).ref;
  }

  getUserReferenceById(id : string) : DocumentReference {

    return this.afs.doc<User>('users/' + id).ref;
  }



  loadUser(userId: string) {
    return this.afs.doc<User>('users/' + userId).valueChanges();
  }

  loadUserByEmail(email : string) {
     return this.afs.collection('users', ref => ref.where('email', 'in', [email])).valueChanges(); //.pipe(take(1))

  }

  updateUser(user: User){
    if(user.onboardingLastStep == null) {
      user.onboardingLastStep = -1;
    }

    if(user.hiddenProjects == null) {
      user.hiddenProjects = [];
    }

    if(user.savedLanguage == null) {
      delete user.savedLanguage;
    }

    let cloneUser = Object.assign({}, user);
    delete cloneUser.uid;

    if(cloneUser.image == null) {
      cloneUser.image = null;
    }

    return this.afs.doc('users/' + user.uid).update(cloneUser).then(()=>{
      //Update values that user might have changed.
      this.userData.description = cloneUser.description;
      this.userData.image = cloneUser.image;
      this.userData.displayName = cloneUser.displayName;
      this.userData.hiddenProjects = cloneUser.hiddenProjects;
      this.userData.onboardingLastStep = cloneUser.onboardingLastStep;
      this.userData.savedLanguage = cloneUser.savedLanguage;

      //Update cookie aswell, to prevent wrong data being loaded on reload
      this.updateUserCookie(this.userData);
    });
 }

}
