import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../service/auth.service";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService, private authService: AuthService, private cookieService : CookieService) {

  }

  init() {
    let locale = this.translate.getBrowserLang();

    console.log("Browser locale:", locale);

    if(locale == null) {
      locale = "en";
    } else {
      locale = locale.substring(0, 2);
    }

    console.log("After trim:", locale);

    this.translate.setDefaultLang(locale);

    this.authService.userLoggedIn$.subscribe(()=>{
      this.switchToSavedLanguage();
    });

    this.switchToSavedLanguage();
  }

  useLanguage(lang) {
    console.log("useLanguage:", lang);

    switch(lang) {
      case 'da':
      case 'en':
        console.log("Switching to:", lang);
        this.translate.use(lang);
        this.saveLanguage(lang);
        break;
      default:
        //Unsupported language
    }
  }

  saveLanguage(lang: string) {
    const user = this.authService.getUser();

    if(user != null) {
      user.savedLanguage = lang;
      this.authService.updateUser(Object.assign({}, user));
    } else {
      this.cookieService.set("ideate-language", lang);
    }
  }

  switchToSavedLanguage() {
    const user = this.authService.getUser();

    let savedLanguage = null;

    console.group("Attempting to load saved language");

    if(user != null) {
      console.log("Had user...");
      if (user.savedLanguage != null) {
        savedLanguage = user.savedLanguage;
        console.log("Found language on user:", savedLanguage);
      }
    }

    if(savedLanguage == null) {
      savedLanguage = this.cookieService.get("ideate-language");
      console.log("No saved language from user, trying cookie:", savedLanguage);
    }

    if(savedLanguage == null) {
      console.log("No saved language from cookie, defaulting to 'en'");
      savedLanguage = "en";
    }

    console.groupEnd();

    if(savedLanguage != null && savedLanguage.trim().length === 2) {
      this.useLanguage(savedLanguage);
    }
  }
}
