<div class="top-inspiration hidden-on-mobile">
  <div class="participate-heading">{{'Create a new story' | translate }}</div>
  <div class="kblock-menu ">
        <span class="pointer grey" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </span>
  </div>
</div>

<div class="content" >
    <div class="input_header" translate>Story name</div>
    <mat-label class="input_description">{{'Provide a name for your story'|translate}}</mat-label>
    <br/>
    <mat-form-field class="input_form_field_small">
        <input class="input_field" matInput type="text" [(ngModel)]="concept.name" matTooltip="{{'Concept name'|translate}}">
    </mat-form-field>
    <br/><br/>

    <div class="input_header" translate>Story subtitle</div>
    <mat-label class="input_description">{{'Provide a subtitle for your story'|translate}}</mat-label>
    <br/>
    <mat-form-field class="input_form_field_small">
        <input class="input_field" matInput type="text" [(ngModel)]="concept.tagline" matTooltip="{{'Concept name'|translate}}">
    </mat-form-field>
    <br/><br/>

  <div>
    <mat-label>{{'Pick a color'|translate}}</mat-label>
    <div class="currentColor" #currentColor style="background-color: #fff"></div>
    <color-github [colors]="['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB']" color="#fff" (onChangeComplete)="colorChangeComplete($event)"></color-github>
  </div>

  <br/><br/>

    <div>
        <mat-form-field *ngIf="model.project">
            <mat-label>{{'Theme'|translate}}</mat-label>
            <mat-select [(value)]="concept.section_id">
                <mat-option *ngFor="let section of model.project.sections" [value]="section.id">
                    {{section.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div mat-dialog-actions>
            <button mat-button color="primary" (click)="cancel()">{{'Cancel'|translate}}</button>
            <button mat-raised-button color="primary" *ngIf="!edit_concept" (click)="createConcept()">{{'Create story'|translate}}</button>
            <button mat-raised-button color="primary" *ngIf="edit_concept" (click)="createConcept()">{{'Edit story'|translate}}</button>
        </div>
    </div>
</div>



