<div class="splashblock" *ngIf="catalog">
    <div class="splashblock-splash" [style.background-image]="getImageUrl()">
        <b>ideate</b>.nu
    </div>

    <!-- Join catalog page -->
    <div class="splashblock-content" *ngIf="currentPage==PageMode.JoinCatalog">
        <h2 translate>Invitation to</h2>
        <h1 translate>{{catalog.name}}</h1>
     <!--   <p translate>If you feel this invitation was sent to you by mistake or you consider it spam:</p>
        <a [routerLink]="" (click)="currentPage=PageMode.Spam" translate>Please let us know</a>-->

        <div class="onboarding-options">
            <button mat-flat-button color="primary" (click)="joinCatalog()">{{'Accept Invitation'|translate}}</button>
            <button mat-raised-button (click)="currentPage=PageMode.About">{{'What is ideate.nu?'|translate}}</button>
        </div>
    </div>

    <!-- About page -->
    <div class="splashblock-content" *ngIf="currentPage==PageMode.About">
        <h2 translate>What is ideate.nu</h2>
        <p translate>
            Please visit <a href="https://ideate.nu">ideate.nu</a> for more information.
        </p>
        
        <div class="onboarding-options">
            <button mat-flat-button color="primary" (click)="currentPage=PageMode.JoinCatalog">{{'Back'|translate}}</button>
        </div>
    </div>

    <!-- Member info page -->
    <div class="splashblock-content" *ngIf="currentPage==PageMode.MemberInfo">
        <app-member-info [catalog]="catalog"></app-member-info>
        
        <div class="onboarding-options">
            <button mat-flat-button color="primary" (click)="thankYou()">{{'Continue'|translate}}</button>
            <button mat-raised-button (click)="thankYou()">{{'Skip'|translate}}</button>
        </div>
    </div>

    <!-- Thank you -->
    <div class="splashblock-content" *ngIf="currentPage==PageMode.ThankYou">
        <div class="thanks" translate>Thank you</div>
    </div>
    
    <!-- SPAM/contact -->
    <div class="splashblock-content" *ngIf="currentPage==PageMode.Spam">
        <h2 translate>Decline invitation</h2>
        <p translate>
            You have not been added to this community.        
        </p>
        
        <div class="onboarding-options">
            <button mat-raised-button>{{'TODO: Report issue'|translate}}</button>
            <button mat-flat-button color="primary" (click)="currentPage=PageMode.JoinCatalog">{{'Back'|translate}}</button>            
        </div>
    </div>    

</div>
