<aside *ngIf="kblock" class="mb-4">  
    <div class="og-top" (click)="followLink()" title="{{kblock.link_url}}">
        <div class="og-text">
            <span *ngIf="kblock.open_graph && kblock.open_graph.ogTitle" class="break-long-texts"><b>{{kblock.open_graph.ogTitle}}</b><br/></span>
            <span *ngIf="kblock.open_graph && kblock.open_graph.ogDescription" class="kblocks_date break-long-texts">{{kblock.open_graph.ogDescription}}<br/></span>
            <span *ngIf="!kblock.open_graph" class="kblocks_date break-long-texts">{{kblock.link_url}}<br/></span>

        </div>       
        <img *ngIf="kblock.open_graph && kblock.open_graph.ogImage && kblock.open_graph.ogImage.url && ( kblock.open_graph.ogTitle || kblock.open_graph.ogDescription || kblock.link_url)" [src]="kblock.open_graph.ogImage.url" class="image_preview">
    </div>

    <div *ngIf="showAuthorSection" class="kblock-content">
        <div class="postheader">
            <app-user-inline-view *ngIf="kblock.owner" class="userinfo" [userReference]="kblock.owner"></app-user-inline-view>
            <span *ngIf="kblock.timestamp" class="kblocks_date">{{kblock.timestamp.toDate() | date}}</span>
        </div>
        <div [innerHTML]="kblock.text"></div>
    </div>

</aside>
