import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Story } from 'src/app/frontend/catalog.service';
import { EditorJsComponent } from 'src/app/frontend/editor-js/editor-js.component';
import { Favor, FavorType } from 'src/app/model/favor.model';
import { ModelService } from 'src/app/service/model.service';
import { DialogData } from '../edit-favor/edit-favor.component';

@Component({
  selector: 'app-edit-diverge-favor',
  templateUrl: './edit-diverge-favor.component.html',
  styleUrls: ['./edit-diverge-favor.component.scss']
})
export class EditDivergeFavorComponent implements OnInit {
  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;

  addFavor : boolean;
  favor : Favor; 
  selected_story: Story;


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public model: ModelService) { 
    if (this.data.favor)
    {
      this.favor = this.data.favor;
      this.addFavor = false;
      
    }
    else
    {
      this.addFavor = true;
      this.favor = new Favor();
      this.favor.type = FavorType.DivergeFavor;
      this.favor.story = "";
      this.favor.stories = [];

  
      this.favor.catalog = this.data.catalogID;
      if (this.data.workshopID)
        this.favor.workshop_id = this.data.workshopID;
      this.favor.content = "";
    }


 /*   setTimeout(()=>{
      this.editorjs.saved.subscribe((result)=>{
        this.favor.content = result;
      });
      this.editorjs.canceled.subscribe(()=>{
      });
    }, 10);*/


  }

  ngOnInit(): void {
  }

  updateFavor() {
    this.model.favorService.updateFavor(this.favor);
  }
  
  createFavor() {
    this.model.favorService.createFavor(this.favor);
  }
  


}








