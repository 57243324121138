import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Favor } from 'src/app/model/favor.model';


@Injectable({
  providedIn: 'root'
})
export class FavorService {

  uploadPercent: Observable<number>;
  downloadURLObserver: Observable<string>;
  private downloadURLObserverSource = new Subject<string>();
  imageURLFinished$ = this.downloadURLObserverSource.asObservable();


  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {

  }


  getFavors(reference : DocumentReference) {
    return this.firestore.collection('favors', ref => ref.where("catalog","==",reference.id)).snapshotChanges();
}

createFavor(favor: Favor){
    return this.firestore.collection("favors").add(Object.assign({}, favor));
}

getFavor(favorId: string) {
  return this.firestore.doc<Favor>('favors/' + favorId).valueChanges();
}

getReference(favor: Favor) : DocumentReference {
  return this.firestore.doc<Favor>('favors/' + favor.id).ref;
}

getReferenceById(id: string) {
  return this.firestore.doc<Favor>('favors/' + id).ref;
}

updateFavor(favor: Favor){
   let id = favor.id;
   delete favor.id;
   this.firestore.doc('favors/' + id).update(favor);
}

deleteFavor(favorId: string){
    this.firestore.doc('favors/' + favorId).delete();
}

uploadFile(file: File) {
    const filePath = `images/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(  finalize(() =>
    { this.downloadURLObserver = fileRef.getDownloadURL();
      this.downloadURLObserver.subscribe(data => {
        // to create an id for the document.
        console.log("reference: "+data)
        this.downloadURLObserverSource.next(data);
      });

    }))
    .subscribe();

}
}
