import {Component, ElementRef, OnInit} from '@angular/core';
import {CurrentPage, ModelService} from "../../service/model.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivePageServiceService} from "../../frontend/active-page-service.service";

@Component({
  selector: 'app-top-menu-story-mobile',
  templateUrl: './top-menu-story-mobile.component.html',
  styleUrls: ['./top-menu-story-mobile.component.scss']
})
export class TopMenuStoryMobileComponent implements OnInit {
  env = environment;
  currentPage = CurrentPage;

  constructor(public model : ModelService, public activePageService:ActivePageServiceService) {
  }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  shouldShow() : boolean {
    switch(this.activePageService.active_page) {
      case this.env.links.viewStory:
        return true;
      default:
        return false;
    }
  }
}
