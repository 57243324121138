import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../onboarding-flow.scss', './forgot-password.component.css'],
  host: {'class': 'centerblock'}
})
export class ForgotPasswordComponent implements OnInit {

  constructor(public authService: AuthService) { }

  env = environment;
  ngOnInit(): void {
  }

}
