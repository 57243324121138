<div *ngIf="model.project && model.project.sections && model.project.sections.length">
    <div class="section dropSection dragSection" *ngFor="let section of model.project.sections" [attr.data-sectionId]="section.id">
        <div class="menu-heading hide-when-minimized">
            {{section.name}}
            <button *ngIf="this.model.adminRole()" [matMenuTriggerFor]="section_menu" [matMenuTriggerData]="{section : section}" class="delete" mat-icon-button title="{{'More'|translate}}">
                <mat-icon>more_horiz</mat-icon>
              </button>

       <!--   <button *ngIf="this.model.adminRole()" (click)="deleteSection(section)" class="delete" mat-icon-button title="{{'Delete theme'|translate}}">
            <mat-icon>delete_forever</mat-icon>
          </button>-->
        </div>

        <ng-container *ngFor="let concept of model.concepts">
            <a *ngIf="concept.section_id==section.id" class="sidebar-entry dragStory dropStory" [attr.tag-color]="concept.color" [ngClass]="{'active' : story && story == concept.id}" routerLink="/{{env.links.viewStory}}/{{concept.id}}" [attr.title]="concept.name" [attr.data-conceptId]="concept.id">
                <img [src]="getImage(concept)" class="menu-image"/>
                <span class="title">{{concept.name}}</span>
            </a>
        </ng-container>

    </div>
</div>

<div class="section dropSectionUnassigned" data-SectionId="-1">
    <div class="menu-heading hide-when-minimized" translate>Stories

        <button *ngIf="this.model.adminRole()" [matMenuTriggerFor]="section_menu" [matMenuTriggerData]="{section : null}" class="delete" mat-icon-button title="{{'Add story or theme'|translate}}">
            <mat-icon>add</mat-icon>
          </button>

    </div>

    <ng-container *ngFor="let concept of model.concepts">
        <a *ngIf="concept.section_id==null" class="sidebar-entry dragStory dropStory" [attr.tag-color]="concept.color" routerLink="/{{env.links.viewStory}}/{{concept.id}}" [attr.title]="concept.name" [attr.data-conceptId]="concept.id">
            <img [src]="getImage(concept)" class="menu-image"/>
            <span class="title">{{concept.name}}</span>
        </a>
    </ng-container>
</div>

<div class="hide-when-minimized" *ngIf="authService.isLoggedIn && !model.isProjectMember() && model.isProjectPublicJoin()">

<div class="menu-heading">{{'Join project'|translate}}?</div>
<div >{{'Join the project, to add it to your home screen and get updates from the project in your dashboard:'|translate}} 
    <br/>
    <a href="javascript:void(null);" (click)="model.joinProject(model.project)">{{'Join project'|translate}}</a>
    <br/><br/>
</div>
</div>

<mat-menu #section_menu="matMenu">
    <ng-template matMenuContent let-section="section">

        <button mat-menu-item (click)="createStory(section)">
            <mat-icon>control_point</mat-icon>
            <span translate>Add story</span>
        </button>

        <button mat-menu-item (click)="createTheme()">
            <mat-icon>control_point</mat-icon>
            <span translate>Create theme</span>
        </button>

        <button *ngIf="section!=null" mat-menu-item (click)="deleteSection(section)">
            <mat-icon>delete_forever</mat-icon>
            <span translate>Delete theme</span>
        </button>





</ng-template>
</mat-menu> 
