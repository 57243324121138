
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBpmpuv2nyyyaQ_5u74bhmCJY9VGoPBYNM",
    authDomain: "ideate-project.firebaseapp.com",
    databaseURL: "http://localhost:8080",
    projectId: "ideate-project",
    storageBucket: "ideate-project.appspot.com",
    messagingSenderId: "177537231708",
    appId: "1:177537231708:web:b2693949668176ad29ef99",
    measurementId: "G-BDH8L7FRJM"
  },

  firebaseVariable: {
    concept : "concepts",
    project: "projects"
  },

  story_background_url : "/assets/img/storyback.png",
  story_new_background_url : "/assets/img/storynew.png",
  no_link_image_url : "/assets/img/colour.jpg",
  no_person_url : "/assets/img/person.png",
  
  public_link : "https://app.ideate.nu/in/catalog/",  
  privacy_policy:  "https://www.ideate.nu/privatlivspolitik",
  terms_conditions: "https://www.ideate.nu/vilkaar",

  public_link_browse : "https://app.ideate.nu/in/catalogs/", 
  base_url : "https://app.ideate.nu/",  
  front_url : "ideate.nu",
  links: {
    onboardingAdmin: "onboarding-admin",

    viewStory : "view-concept",
    viewProject : "project",
    startDashboard : "dashboard",
    createStory : "create-concept",
    createCatalog : "new-project",
    viewProjects: "projects",
    home: "",


    joinCatalog: "join-catalog",
    sharing : "sharing",
    userData : "user-data",    
    editFavor : "edit-favor",
    viewProjectSetting : "catalog-settings",
    register : "register",
    login : "login",
    mainOverview : "main-overview",  
    storiesOverview : "concept-overview",
    catalogsOverview : "project-overview",
    viewCatalog : "projectsviewCatalog",
    processOverview : "process",
    divergeFavor : "diverge",
    workshop : "workshop",
    participants: "participants",

    inspirationGridView : "i-grid-view",


    forgotPassword : "forgot-password",
    verifyEmail : "verify-email",
    projectMemberInfo: "project-member-info"
           
  }
};

