import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {StepInfo} from "../frontend/onboarding/site-popup/StepInfo";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private afs: AngularFirestore) { }

  findSteps(lastSeenStep: number) : Observable<StepInfo[]> {
    return this.afs.collection<StepInfo>("onboarding", (ref) => {
      return ref.where("timestamp", ">", lastSeenStep);
    }).valueChanges({
      idField: "id"
    });
  }

  addStep(step: StepInfo) {
    let clonedStep = Object.assign({}, step);
    return this.afs.collection<StepInfo>("onboarding").add(clonedStep);
  }

  updateStep(step: StepInfo) {
    let id = step.id;

    let clonedStep = Object.assign({}, step);
    delete clonedStep.id;

    return this.afs.collection<StepInfo>("onboarding").doc(id).update(clonedStep);
  }

  deleteStep(stepId) {
    return this.afs.collection<StepInfo>("onboarding").doc(stepId).delete();
  }
}
