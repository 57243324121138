import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonEmptyKblock'
})
export class NonEmptyKblockPipe implements PipeTransform {

  transform(text: string): boolean {
    const parser = new DOMParser();
    let parsedDom = parser.parseFromString(text, "text/html").documentElement;
    let textContent = parsedDom.textContent;

    if(textContent == null || textContent === "null") {
      textContent = "";
    }

    let textLength = textContent.trim().length;
    let domElements = parsedDom.querySelector("img, li") != null;

    return textLength > 0 || domElements;
  }

}
