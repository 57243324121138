<div *ngIf="ourResponse.length > 0">
  <div class="comment self-authored">
      <div class="postheader">
        <app-user-inline-view class="userinfo" [userReference]="ourResponse[0].user"></app-user-inline-view>
        <div class="time">{{ourResponse[0].time.toDate() | date:'medium'}}</div>
      </div>
        <div class="comment-content">
            {{ourResponse[0].response_name}}
        </div>
  </div>
  <a (click)="toggleOtherComments();" class="toggleLink" #toggleLink *ngIf="showResults && otherResponses.length > 0">Show other comments</a>
  <div *ngIf="showResults"  #otherComments class="otherComments">
    <ng-container *ngFor="let response of otherResponses">
      <div class="comment">
          <div class="postheader">
              <app-user-inline-view class="userinfo" [userReference]="response.user"></app-user-inline-view>

              <div class="time">{{response.time.toDate() | date:'medium'}}</div>
          </div>
          <div class="comment-content">
              {{response.response_name}}
          </div>
      </div>
    </ng-container>
  </div>
</div>
