import {Component, ElementRef, OnInit} from '@angular/core';
import {CurrentPage, ModelService} from "../../service/model.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivePageServiceService} from "../../frontend/active-page-service.service";

@Component({
  selector: 'app-top-menu-catalog-mobile',
  templateUrl: './top-menu-catalog-mobile.component.html',
  styleUrls: ['./top-menu-catalog-mobile.component.scss']
})
export class TopMenuCatalogMobileComponent implements OnInit {

  env = environment;

  currentPage = CurrentPage;

  menuOpen: boolean;

  constructor(public model : ModelService, public activePageService:ActivePageServiceService) {
    this.activePageService.routeChanged.subscribe(()=>{
      this.closeMenu();
    })
  }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  shouldShow() : boolean {
    switch(this.activePageService.active_page) {
      case this.env.links.sharing:
      case this.env.links.viewProject:
      case this.env.links.viewCatalog:
      case this.env.links.inspirationGridView:
      case this.env.links.processOverview:
      case this.env.links.participants:
        return true;
      default:
        return false;
    }
  }
}
