<!-- View block-->
<div *ngIf="kblock" class="kBlocks dragKblock" [attr.data-kblock-id]="kblock.id"  (click)="openKBlock()" #kblockDiv>
    <div class="kblock-menu">
        <app-like [kblock]="kblock"></app-like>
    </div>

    <div>
        <app-taglist [showDelete]="model.isOwner(kblock)" [kblock]="kblock" [topView]="true"></app-taglist>
    </div>

    <!-- Format Open graph block -->
    <div class="inspiration-text" #inspirationText>
        <div *ngIf="kblock.link_url" class="external-link" (click)="$event.stopPropagation()">
            <a [href]="kblock.link_url" target="_blank" rel="noopener noreferrer">
                <div *ngIf="kblock.open_graph?.ogImage?.url; else noImage" class="link-image"><img
                        [src]="kblock.open_graph.ogImage.url"> </div>

                <ng-template #noImage>
                    <!--          <div class="link-image">               <img [src]="env.no_link_image_url" width="150px" height="100px"> </div>-->
                </ng-template>

                <div class="link-text">
                    <div class="link-text-inner">
                        <span *ngIf="kblock.open_graph && kblock.open_graph.ogTitle; else noOgTitle"
                              class="og-title break-long-texts">{{kblock.open_graph.ogTitle}} </span>

                        <ng-template #noOgTitle>
                            <span class="og-title break-long-texts">Link <br/></span>
                        </ng-template>


                        <span *ngIf="kblock.open_graph && kblock.open_graph.ogDescription; else noDescription"
                              class="og-description break-long-texts">{{kblock.open_graph.ogDescription}}<br/></span>

                        <ng-template #noDescription>
                            <span class="og-description break-long-texts">{{shortLink(kblock.link_url)}} <br/></span>
                        </ng-template>

                        <!-- <div *ngIf="kblock.link_url" class="kblocks_date break-long-texts"><a
                            [href]="kblock.link_url">{{shortLink(kblock.link_url)}}</a></div>-->
                    </div>
                </div>
            </a>
        </div>
        <div class="photos"></div>
        <div class="inspiration-text-inside" *ngIf="kblock.text && kblock.text.length>0 && kblock.text!='&lt;p&gt;&lt;/p&gt;'" [innerHTML]="kblock.text | safeHtml"> </div>
    </div>



   <!--  <div class="inspiration-comments">
         <app-taglist [showDelete]="model.isOwner(kblock)" [kblock]="kblock"></app-taglist>
    </div>-->

    <div class="top-inspiration">
        <div class="avatar-div" *ngIf="hasPhoto(); else noProfileImage">
            <img class="avatar" width="36" height="36" [src]='getPhoto()'/>
        </div>
        <ng-template #noProfileImage>
            <div class="avatar-div"><img class="avatar" width="36" height="36" [src]='env.no_person_url'/></div>
        </ng-template>

        <span class="kblocks_date">
            <div *ngIf="kblock.owner_data">{{kblock.owner_data.displayName}}<br/>{{getkBlockDateTime(kblock)}}  &nbsp; -  &nbsp; {{numberOfComments}} Comments</div>
        </span> <br/>
    </div>

</div>


<!-- Edit block -->
<div class="kBlocks_edit white-background" *ngIf="false">
    <quill-editor [(ngModel)]="kblock.text">

        <div quill-editor-toolbar>
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>

            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>

            </span>

            <select class="ql-header">
                <option value="1">{{'Heading 1'|translate}}</option>
                <option value="2">{{'Heading 2'|translate}}</option>
                <option value="3">{{'Heading 3'|translate}}</option>
                <option value="">{{'Normal'|translate}}</option>
            </select>
            <span class="ql-formats">
                <button class="ql-link"></button>
            </span>
        </div>
    </quill-editor>
    <br/>
    <div class="flex-container">
        <div>
            <button mat-button color="primary" (click)="submitEdit(kblock)"><img src="./assets/img/done.png" height="18px" width="18px"/>{{'Accept'|translate}}</button>

        </div>


        <div>
            <button mat-button color="primary" (click)="cancelEdit()"><img src="./assets/img/close.png" height="18px"
                                                                           width="18px"/> {{'Cancel'|translate}}
            </button>
        </div>
        <div>
            <button *ngIf="model.user.uid == kblock.owner.id" mat-button color="primary" (click)="deletekBlock(kblock)"><img src="./assets/img/delete.png"
                                                                                                                             height="18px" width="18px"/> {{'Delete'|translate}}
            </button>
        </div>
    </div>

</div>



<!-- Reference Menu -->

<mat-menu #reference_menu="matMenu">
    <ng-template matMenuContent let-kblock="kblock" let-index="index">
        <button mat-menu-item [matMenuTriggerFor]="projects_menu" [matMenuTriggerData]="{kblock : kblock}">
            <mat-icon>link</mat-icon>
            <span translate>Add catalog reference ...</span>
        </button>

        <button mat-menu-item (click)="openEdit(index)">
            <mat-icon>edit</mat-icon>
            <span translate>Edit</span>
        </button>
        <button *ngIf="model.user.uid == kblock.owner.id" mat-menu-item (click)="deletekBlock(kblock)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>
    </ng-template>
</mat-menu>


<mat-menu #reference_menu_ext="matMenu">
    <ng-template matMenuContent let-kblock="kblock" let-index="index">
        <button mat-menu-item [matMenuTriggerFor]="projects_menu" [matMenuTriggerData]="{kblock : kblock}">
            <mat-icon>link</mat-icon>
            <span translate>Add catalog reference ...</span>
        </button>


        <button mat-menu-item [matMenuTriggerFor]="project_story_menu" [matMenuTriggerData]="{kblock : kblock}">
            <mat-icon>input</mat-icon>
            <span translate>Add story reference ...</span>
        </button>

        <button mat-menu-item (click)="openEdit(index)">
            <mat-icon>edit</mat-icon>
            <span translate>Edit</span>
        </button>
        <button *ngIf="model.user.uid == kblock.owner.id"  mat-menu-item (click)="deletekBlock(kblock)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>
    </ng-template>
</mat-menu>


<mat-menu #projects_menu="matMenu">
    <ng-template matMenuContent let-kblock="kblock">
        <button mat-menu-item *ngFor="let project of model.projects" (click)="addProjectReference(project,kblock)">
            <mat-icon>eco</mat-icon>
            <span>{{project.name}}</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #project_story_menu="matMenu">
    <ng-template matMenuContent let-kblock="kblock">
        <button mat-menu-item *ngFor="let concept of model.concepts" (click)="addStoryReference(concept, kblock)">
            <mat-icon>subject</mat-icon>
            <span>{{concept.name}}</span>
        </button>
    </ng-template>
</mat-menu>




<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-kblock="kblock" let-ref="ref">
        <button mat-menu-item (click)="openReference(ref)">{{'Open'|translate}}</button>
        <button mat-menu-item (click)="model.deleteReference(kblock, ref)">{{'Delete'|translate}}</button>
    </ng-template>
</mat-menu>
