<h1 mat-dialog-title >{{'Edit'|translate}}</h1>
<div mat-dialog-content>
  <mat-form-field class="text-input-length">
    <mat-label>{{'Title'|translate}}</mat-label>
    <input  matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{'Ok'|translate}}</button>
</div>
