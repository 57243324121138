import {Component, Input, OnInit} from '@angular/core';
import {Kblock} from "../../model/kblock.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {CatalogService} from '../catalog.service';

@Component({
  selector: 'app-editor-jsviewer-kblock',
  templateUrl: './editor-jsviewer-kblock.component.html',
  styleUrls: ['./editor-jsviewer-kblock.component.scss']
})
export class EditorJSViewerKblockComponent implements OnInit {
  @Input() kblockId : string;
  showAuthorSection: boolean;

  kblock : Kblock;

  constructor(private catalogService: CatalogService) { }

  ngOnInit(): void {
    this.catalogService.getKblock(this.kblockId).subscribe((kblock)=>{
      this.kblock = kblock;
      this.showAuthorSection = this.kblock && this.kblock.text && (this.kblock.text.replace(/<(.|\n)*?>/g, '').trim().length>0 || this.kblock.text.indexOf("<img src") > 0);
    })
  }

  followLink(): void {
    window.open(this.kblock.link_url,"_blank");
  }
}
