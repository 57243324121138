import { AfterViewInit, Component,  Inject,  OnDestroy,  OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EditorJsComponent, EditorJSContent } from 'src/app/frontend/editor-js/editor-js.component';
import { kBlockType } from 'src/app/model/kblock.model';
import { ModelService } from 'src/app/service/model.service';



export interface EditDialogData {
  projectId: string;
  conceptId?: string;
  ktype: kBlockType;
  content: EditorJSContent;

}

@Component({
  selector: 'app-editor-dialog',
  templateUrl: './editor-dialog.component.html',
  styleUrls: ['./editor-dialog.component.scss']
})
export class EditorDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;
  save_subscription : Subscription;
  edit_subscription : Subscription;
  ref_id : string;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditDialogData,public model: ModelService, 
    public dialogRef: MatDialogRef<EditorDialogComponent>) { 
      if (data.ktype == kBlockType.Concept)
      {
        this.ref_id = data.conceptId;
      } else {
      if (data.ktype == kBlockType.Project)
      {
        this.ref_id = data.projectId;
      }
    }


    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.save_subscription=this.editorjs.saved.subscribe((result)=>{
        this.dialogRef.close(result);
      });
      this.edit_subscription = this.editorjs.canceled.subscribe(()=>{ 
        this.dialogRef.close();
        });
    }, 0);
  }


  ngOnDestroy() {

    if (this.save_subscription)
      this.save_subscription.unsubscribe();

    if (this.edit_subscription)
      this.edit_subscription.unsubscribe();

  }


  closeDialog() {
    this.dialogRef.close();
  }

}
