import { Injectable } from '@angular/core';
import {AuthService} from "../service/auth.service";
import {Router} from "@angular/router";
import {Favor} from "../model/favor.model";
import {ModelService} from "../service/model.service";
import {environment} from "../../environments/environment";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActivePageServiceService {

  env = environment;

  active_page : string;

  currentFavor: Favor;

  private routeChangedSubject: Subject<any>;
  routeChanged: Observable<any>;

  constructor(private model : ModelService, private router:Router) {
    this.active_page = this.router.url.substring(1);
    this.active_page = this.active_page.substring(0, this.active_page.indexOf("/"));

    this.routeChangedSubject = new Subject<any>();
    this.routeChanged = this.routeChangedSubject.asObservable();

    this.router.events.subscribe((val)=>{
      this.routeChangedSubject.next(null);
      // @ts-ignore
      if(val.url != null) {
        // @ts-ignore
        // @ts-ignore
        this.active_page = val.url.substring(1);
        this.active_page = this.active_page.substring(0, this.active_page.indexOf("/"));
        this.checkWorkshop();
      }
    });

    this.model.favorLoaded$.toPromise().then(()=>{
      this.checkWorkshop();
    })
  }

  checkWorkshop() {
    let route = this.router.routerState.root;
    while(route.firstChild != null) {
      route = route.firstChild;
    }

    let favorId = route.snapshot.paramMap.get("fid");
    let workshopId = route.snapshot.paramMap.get("wid");

    this.model.workshop_id = null;
    this.currentFavor = null;

    if(favorId != null && this.model.favors != null) {
      this.currentFavor = this.model.favors.find((favor) => {
        return favor.id == favorId;
      });

      if (this.currentFavor != null) {
        this.model.workshop_id = this.currentFavor.workshop_id;
      }
    }

    if(workshopId != null) {
      this.model.workshop_id = workshopId;
    }
  }

  navigateTo(url:string) {
    document.querySelector(".scrollBlock").scrollTop = 0;
    this.router.navigate([url]);
  }
}
