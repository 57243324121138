import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CatalogService} from "../catalog.service";
import {Observable, Subject, Subscription} from "rxjs";
import {CurrentPage, ModelService} from "../../service/model.service";
import {Kblock} from "../../model/kblock.model";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {Image} from "../image-upload-dialog/image-upload-dialog.component";

@Component({
  selector: 'app-kblock-select-dialog',
  templateUrl: './kblock-select-dialog.component.html',
  styleUrls: ['./kblock-select-dialog.component.scss']
})
export class KblockSelectDialogComponent implements OnInit, OnDestroy {

  constructor(public dialogRef: MatDialogRef<KblockSelectDialogComponent>, private catalogService: CatalogService, private model : ModelService) { }

  subscription: Subscription;
  kblocks : Kblock[];

  ngOnInit(): void {
    let ref:DocumentReference = null;

    if(this.model.current_page == CurrentPage.story_view) {
      //Show concept kblocks?
      console.log("Using concept...");
      ref = this.catalogService.getStoryRefFromId(this.model.concept.id);
    } else if(this.model.current_page == CurrentPage.catalog_overview) {
      console.log("Using project...");
      ref = this.catalogService.getCatalogRefFromId(this.model.project.id);
    } else {
      console.log("Unknown kblock reference...", this.model.current_page);
    }

    console.log("Fetching kblocks:", ref);

    this.subscription = this.catalogService.getKblocks(ref).subscribe((kblocks)=>{
      this.kblocks = kblocks;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription != null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  select(kblock, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    this.dialogRef.close(kblock);
  }

  cancel() {
    this.dialogRef.close();
  }

  static showDialog() : Observable<Kblock> {
    let subject = new Subject<Kblock>();

    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(KblockSelectDialogComponent, {
      panelClass: ['reinvent-popup']
    });

    dialogRef.afterClosed().subscribe((kblock)=>{
      subject.next(kblock);
    });

    return subject.asObservable();
  }
}
