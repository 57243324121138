import { Component, OnInit, HostBinding } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { environment } from 'src/environments/environment';
import { ModelService } from 'src/app/service/model.service';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/frontend/language.service';
import { User } from 'src/app/model/user.model';
import { ThumbnailService } from 'src/app/frontend/thumbnail.service';
import {CookieService} from "ngx-cookie-service";
import {CreateConceptComponent} from '../../concepts/create-concept/create-concept.component';
import {MatDialog} from '@angular/material/dialog';
import {NewSectionDialog2Component} from '../../catalogs/new-section-dialog2/new-section-dialog2.component';

@Component({
    selector: 'app-story-menu',
    templateUrl: './story-menu.component.html',
    styleUrls: ['./story-menu.component.css', '../sidebar.scss']
})
export class StoryMenuComponent implements OnInit {
    @HostBinding('class.minimized') minimized: boolean = false;
    public env = environment;
    user : User;

    constructor(private dialog:MatDialog, private projectService: ProjectService, public authService: AuthService,public model: ModelService, private cookieService: CookieService, public languageService: LanguageService) {
        authService.userLoggedIn$.subscribe(user => {
            this.user = user;
          });

    }


    ngOnInit(): void {
        this.minimized = this.cookieService.get("menuMinimized") === "true";

        if (this.authService.getUser())
        this.user = this.authService.getUser();

    }


    toggle(){
      this.minimized = !this.minimized;
      this.cookieService.set("menuMinimized", ""+this.minimized);
    }



  createTheme() {
    const dialogRef = this.dialog.open(NewSectionDialog2Component, {
      width: '250px',
      panelClass: ['reinvent-popup'],
      data:  {name:"", edit:false},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.projectService.addSection(this.model.project, result.name);
    });
  }

  createStory() {
    CreateConceptComponent.showDialog();
  }


  getImage(obj, getSmall = false) {
    if (obj != null) {
      if (obj.image != null) {
        return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
      } else {
        if (obj.image_name) {
          return obj.image_name;
        }
        if (obj.img_url) {
          return obj.img_url;
        }
        return environment.story_background_url;
      }
    }
  }
}
