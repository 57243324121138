import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { ModelService } from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';
import { User } from 'src/app/model/user.model';
import {LanguageService} from "../../frontend/language.service";
import { ActivatedRoute, Router } from '@angular/router';
import {ThumbnailService} from "../../frontend/thumbnail.service";
import { testMatrix } from 'firebase-functions/lib/providers/testLab';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  user : User;
  @Input()current_page : string;
  @Input()page_title : string;


  isMobile : boolean = false;
  constructor(public authService: AuthService, public model : ModelService, public route : ActivatedRoute,  router: Router,public languageService: LanguageService) {
    authService.userLoggedIn$.subscribe(user => {
      this.model.subscribeProjects();
   //   this.model.subscribekBlocks(null, kBlockType.User);
      //Setup mobile check
      window.onresize = () => {
        this.isMobile = window.innerWidth <= 991;
      }
      this.isMobile = window.innerWidth <= 991;
    });

    authService.userLoggedIn$.subscribe(user => {
      this.user = user;
    });
 }

 env = environment;

    hasPhoto(): boolean {
        return ThumbnailService.getPhotoFromUser(this.model.user) != null;
    }

    getPhoto():string {
        return ThumbnailService.getPhotoFromUser(this.model.user);
    }

  ngOnInit(): void {

    if (this.authService.getUser())
      this.user = this.authService.getUser();

  }

  getTitle() : string {
    if (this.current_page==this.env.links.home)
      return "top-menu.home";

    if (this.current_page==this.env.links.viewProjects)
      return "top-menu.all-projects"

  }
}
