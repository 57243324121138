<!--<div class="container-fluid page-max-width">
    <div class="justify-content-md-center">
        <div class="format-one-container format-column1-content">
            <div class="format-one-column" *ngIf="model.project">-->

                <ng-container>

                <app-left-start-menu class="scrollblock"></app-left-start-menu>

                <div class="scrollblock" *ngIf="model.project" >
                
                
                <app-top-menu [current_page]="env.links.viewProjects"></app-top-menu>
                
                
                    
                    <div class="normal-page-content top-space">




                 <div class="heading-div">
                    <div class="heading-title">{{'project-overview.favored-catalogs'|translate}}</div>
                    <div class="heading-menu">
                        <button (click)="createProject(true)" mat-icon-button title="{{'project-overview.create-new-catalog'|translate}}">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>
                </div>


                <ul class="image-list">
                    <li *ngFor="let catalog of getFavoredProjects(true)" class="droppable"
                        onDragOver="this.classList.add('drag-n-drop--highlight')"
                        onDragLeave="this.classList.remove('drag-n-drop--highlight')"
                        onDrop="this.classList.remove('drag-n-drop--highlight')"
                        [attr.data-catalog-id]="catalog.id">
                        <a routerLink="/{{env.links.viewProject}}/{{catalog.id}}">
                            <img [src]="getImage(catalog)" width="150px" height="100px">
                            <div class="title">{{catalog.name}}</div>
                            <span *ngIf="model.getProjectFavored(catalog)">
                                <div class="icon" (click)="setProjectFavored(catalog,false);$event.preventDefault();$event.stopPropagation()"><mat-icon>star</mat-icon></div>
                            </span>
                            <span *ngIf="!model.getProjectFavored(catalog)">
                                <div class="icon" (click)="setProjectFavored(catalog,true);$event.preventDefault();$event.stopPropagation()"><mat-icon>star_border</mat-icon></div>
                            </span>
                        </a>
                    </li>
                    <a routerLink="/{{env.links.createCatalog}}"
                       *ngIf="authService.isLoggedIn && getFavoredProjects(true)?.length==0">
                        <li>
                            <a (click)="createProject(true)">
                                <div class="title">{{'project-overview.create-new-catalog'|translate}}</div>
                            </a>
                        </li>
                    </a>
                </ul>


                <div class="heading-div">
                    <div class="heading-title">{{'project-overview.other-catalogs'|translate}}</div>
                    <div class="heading-menu">
                        <button (click)="createProject()" mat-icon-button
                                title="{{'Create new project'|translate}}">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>
                </div>
                <ul class="image-list">
                    <li *ngFor="let catalog of getFavoredProjects(false)" class="droppable"
                        onDragOver="this.classList.add('drag-n-drop--highlight')"
                        onDragLeave="this.classList.remove('drag-n-drop--highlight')"
                        onDrop="this.classList.remove('drag-n-drop--highlight')"
                        [attr.data-catalog-id]="catalog.id">
                        <a routerLink="/{{env.links.viewProject}}/{{catalog.id}}">
                            <img [src]="getImage(catalog)" width="150px" height="100px">
                            <div class="title">{{catalog.name}}</div>
                            <span *ngIf="!model.getProjectFavored(catalog)">
                                <div class="icon" (click)="setProjectFavored(catalog,true);$event.preventDefault();$event.stopPropagation()"><mat-icon>star_border</mat-icon></div>
                            </span>
                        </a>
                    </li>
                    <a routerLink="/{{env.links.createCatalog}}"
                       *ngIf="authService.isLoggedIn && getFavoredProjects(false)?.length==0">
                        <li>
                            <a (click)="createProject()")>
                                <div class="title">{{'project-overview.create-new-catalog'|translate}}</div>
                            </a>
                        </li>
                    </a>
                </ul>


                <div *ngIf="getArchivedProjects() && getArchivedProjects().length>0">
                    <div class="heading-div" translate>
                        <div class="heading-title">{{'project-overview.archived-catalogs'|translate}}</div>
                    </div>                
                    <ul class="image-list">
                        <li *ngFor="let catalog of getArchivedProjects()">
                            <a routerLink="/{{env.links.viewProject}}/{{catalog.id}}">
                                <img [src]="getImage(catalog)" width="150px" height="100px">
                                <div class="title">{{catalog.name}}</div>
                                <div class="icon" title="Unhide Project" (click)="model.unhideProjectForUser(catalog);$event.preventDefault();$event.stopPropagation()"><mat-icon>unarchive</mat-icon></div>
                            </a>
                        </li>
                    </ul>                
                </div>
            </div>
        </div>
      <!--  </div>
</div>-->
</ng-container>