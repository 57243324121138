<div class="top-inspiration">
  <div class="avatar-div" *ngIf="hasPhoto(); else noProfileImage">
    <img class="avatar" width="36" height="36" [src]='getPhoto()'/>
  </div>

  <ng-template #noProfileImage>
    <div class="avatar-div"><img class="avatar" width="36" height="36" [src]='env.no_person_url'/></div>
  </ng-template>

  <span class="kblocks_date">
        <div *ngIf="kblock.owner_data">{{kblock.owner_data.displayName}}
          <br/>{{getkBlockDateTime(kblock)}}
        </div>
    </span>

  <div class="kblock-menu ">
    <button *ngIf="model.isOwner(kblock)" mat-icon-button (click)="deletekBlock()" title="Delete">
      <mat-icon>delete</mat-icon>
    </button>

    <app-like [kblock]="kblock"></app-like>
    <span class="pointer grey" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </span>
  </div>
</div>


<div class="i-container">
  <div class="i-column1">
    <!-- Format Open graph block -->
    <div class="inspiration-text" [class.controls-opened]="model.isOwner(kblock) && !editMode">
      <button class="controls" *ngIf="model.isOwner(kblock) && !editMode" mat-icon-button (click)="openEdit()"
              title="Edit">
        <mat-icon>edit</mat-icon>
      </button>

      <div *ngIf="kblock.link_url && !editMode" class="external-link">
        <a [href]="kblock.link_url">
          <div *ngIf="kblock.open_graph?.ogImage?.url; else noImage"
               class="link-image"><img
            [src]="kblock.open_graph.ogImage.url"></div>

          <ng-template #noImage>
            <div class="link-image"><img [src]="env.no_link_image_url" width="150px" height="100px"></div>
          </ng-template>

          <div class="link-text">
            <div class="link-text-inner">
                            <span *ngIf="kblock.open_graph && kblock.open_graph.ogTitle; else noOgTitle"
                                  class="og-title break-long-texts">{{kblock.open_graph.ogTitle}} </span>

              <ng-template #noOgTitle>
                <span class="og-title break-long-texts">Link <br/></span>
              </ng-template>


              <span *ngIf="kblock.open_graph && kblock.open_graph.ogDescription; else noDescription"
                    class="og-description break-long-texts">{{kblock.open_graph.ogDescription}}<br/></span>

              <ng-template #noDescription>
                <span class="og-description break-long-texts">{{shortLink(kblock.link_url)}} <br/></span>
              </ng-template>
            </div>
          </div>
        </a>
      </div>

      <ng-container *ngIf="!editMode">
        <div class="inspiration-text-inside" #inspirationTextInside *ngIf="kblock.text | nonEmptyKblock, else textEmpty" [innerHTML]="(kblock.text!=null?kblock.text:'') | safeHtml" (click)="openLightbox($event)"></div>
        <ng-template #textEmpty>
          <div (click)="openEdit()" *ngIf="!kblock.link_url" class="placeholder" #inspirationTextInside >{{"Click to edit this card"|translate}}...</div>
        </ng-template>
      </ng-container>
      <div class="kBlocks_edit" *ngIf="editMode">
        <div>
          <mat-form-field class="input_form_field_small">
            <mat-label
              class="input_description">{{"Opengraph link:"|translate}}</mat-label>
            <input class="input_field" matInput type="text" [(ngModel)]="linkUrl"
                   matTooltip="{{'Opengraph link'|translate}}"/>
          </mat-form-field>
          <br/>
        </div>
        <div class="quill-edit">
          <quill-editor [(ngModel)]="text" (onEditorCreated)="setFocus($event)">

            <div quill-editor-toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold"></button>
                                <button class="ql-italic"></button>

                            </span>
              <span class="ql-formats">
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>

                            </span>

              <select class="ql-header">
                <option value="1">{{'Heading 1'|translate}}</option>
                <option value="2">{{'Heading 2'|translate}}</option>
                <option value="3">{{'Heading 3'|translate}}</option>
                <option value="">{{'Normal'|translate}}</option>
              </select>
              <span class="ql-formats">
                                <button class="ql-link"></button>
                              <button (click)="openUploadDialog($event)" class="ql-image"></button>
                            </span>
            </div>
          </quill-editor>
          <br/>
        </div>
      </div>
      <div class="flex-inspiration-container" *ngIf="editMode">
        <button mat-stroked-button (click)="cancelEdit()">{{'Cancel'|translate}}</button>
        <button mat-stroked-button color="primary" (click)="submitEdit()">
          <mat-icon>done</mat-icon>
          {{'Accept'|translate}}</button>
      </div>

      <!-- Edit block -->
      <div class="kblock_ref_out kblocks_date" *ngIf="!editMode">
        <div class="controls" *ngIf="hasMenuPermission() && model.isOwner(kblock)">
          <button mat-icon-button *ngIf="showReferenceMenu()" [matMenuTriggerFor]="reference_menu_ext" title="Add tag"><mat-icon>control_point</mat-icon></button>
          <button mat-icon-button *ngIf="!showReferenceMenu()" [matMenuTriggerFor]="reference_menu" title="Add tag"><mat-icon>control_point</mat-icon></button>
        </div>

        <app-taglist [showAsTree]="true" [showAll]="true" [showDelete]="model.isOwner(kblock)" [kblock]="kblock"></app-taglist>
      </div>
    </div>
  </div>
  <div class="i-column2 inspirations">
    <app-commenter [owningEntity]="kblock"></app-commenter>
  </div>
</div>

<mat-menu #reference_menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [matMenuTriggerFor]="projects_menu" [matMenuTriggerData]="{kblock : kblock}">
      <mat-icon>link</mat-icon>
      <span translate>Add catalog reference ...</span>
    </button>
  </ng-template>
</mat-menu>


<mat-menu #reference_menu_ext="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [matMenuTriggerFor]="projects_menu">
      <mat-icon>link</mat-icon>
      <span translate>Add to catalog ...</span>
    </button>


    <button mat-menu-item [matMenuTriggerFor]="project_story_menu">
      <mat-icon>input</mat-icon>
      <span translate>Add to story ...</span>
    </button>
  </ng-template>
</mat-menu>


<mat-menu #projects_menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let project of model.projects" (click)="addProjectReference(project)">
      <mat-icon>eco</mat-icon>
      <span>{{project.name}}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #project_story_menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let concept of model.concepts" (click)="addStoryReference(concept)">
      <mat-icon>subject</mat-icon>
      <span>{{concept.name}}</span>
    </button>
  </ng-template>
</mat-menu>
