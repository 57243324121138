import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Favor, FavorType, StoryRating } from 'src/app/model/favor.model';
import { DocumentReference} from '@angular/fire/compat/firestore';
import { FavorService } from 'src/app/service/favor.service';
import { EditorJsComponent } from 'src/app/frontend/editor-js/editor-js.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormControl} from '@angular/forms';
import { StorySelectDialogComponent } from 'src/app/frontend/story-select-dialog/story-select-dialog.component';
import { ConceptService } from 'src/app/service/concept.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Concept } from 'src/app/model/concept.model';
import { ModelService } from 'src/app/service/model.service';

export interface DialogData {
  favor: Favor;
  catalogID : string;
  workshopID : string; 
}

@Component({
  selector: 'app-edit-favor',
  templateUrl: './edit-favor.component.html',
  styleUrls: ['./edit-favor.component.scss']
  
})
export class EditFavorComponent implements OnInit  {
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup : UntypedFormGroup;

  addFavor : boolean;
   favor : Favor; 
  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;
  
    all_concepts : Concept[]; 
    selected_concepts : Concept[];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private model: ModelService, private favorService : FavorService, private _formBuilder: UntypedFormBuilder, private storyService : ConceptService) {
    if (this.data.favor)
    {
      this.favor = this.data.favor;
      this.addFavor = false;
      
    }
    else
    {
      this.addFavor = true;
      this.favor = new Favor();
      this.favor.type = FavorType.StoryRatings;
      this.favor.stories = [];
      this.favor.storyRatings = {
        published: true,
        title: "",
        intro: "",
        highRatingText: "",
        lowRatingText: "",
        questions: []
      };
  
      this.favor.catalog = this.data.catalogID;
      if (this.data.workshopID)
        this.favor.workshop_id = this.data.workshopID;
      this.favor.content = "";
    }
    this.selected_concepts = [];
    this.all_concepts = [];
    this.model.concepts.forEach(value => {
      if (this.favor.stories.find(favor_ref => favor_ref == value.id))
      {
        this.selected_concepts.push(value);
      } else 
      {
        this.all_concepts.push(value);
      }

    })
  }


  addStoriesFromList()
  {
    this.favor.stories=this.selected_concepts.map(x => x.id);

  }


  updateFavor() {
    this.addStoriesFromList();
    this.favorService.updateFavor(this.favor);
  }

  createFavor() {
    this.addStoriesFromList();
    this.favorService.createFavor(this.favor);
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }



  drop(event: CdkDragDrop<Concept[], any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

}
