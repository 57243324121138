import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QResponse, Question, QuestionnaireService} from "../questionnaire.service";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-qresponse-comment-view',
  templateUrl: './qresponse-comment-view.component.html',
  styleUrls: ['./qresponse-comment-view.component.scss']
})
export class QresponseCommentViewComponent implements OnInit, OnDestroy {

  @Input() questionnaireRef : DocumentReference;
  @Input() question : Question;
  @Input() showResults : boolean;

  @ViewChild("otherComments") otherCommentsRef : ElementRef;
  @ViewChild("toggleLink") toggleLinkRef : ElementRef;

  ourResponse : QResponse[] = [];
  otherResponses : QResponse[] = [];

  qreponsesSubscription : Subscription;

  constructor(private questionnaireService:QuestionnaireService) { }

  ngOnInit(): void {
    this.qreponsesSubscription = this.questionnaireService.getQResponses(this.question.id, this.questionnaireRef).subscribe((qresponses)=>{

      this.ourResponse = this.questionnaireService.filterQresponses(qresponses, true, false);
      this.otherResponses = this.questionnaireService.filterQresponses(qresponses, false, true);

      this.otherResponses.sort((r1, r2)=>{
        return r2.time.toMillis() - r1.time.toMillis();
      });
    });
  }

  ngOnDestroy() {
    if(this.qreponsesSubscription != null) {
      this.qreponsesSubscription.unsubscribe();
    }
  }

  toggleOtherComments() {
    this.toggleLinkRef.nativeElement.classList.toggle("open");
    this.otherCommentsRef.nativeElement.classList.toggle("show");
  }
}
