/**
 * LiveElement can take a selector and dom root element, and then do different functions on
 * all elements that match the given selector from the root. Both current elements and elements
 * that appear in the future.
 */
import {Subject} from "rxjs";

export class LiveElement {
  private selector: string;
  private root: HTMLElement|Document;
  private addSubject: Subject<any>;
  private removeSubject: Subject<any>;
  private observer: MutationObserver;

  /**
   * Construct a new LiveElement
   *
   * @param {string} selector - The CSS selector used to find elements
   * @param {Document|HTMLElement=document} root - The dom element used as root for the selector query
   */
  constructor(selector, root = document) {
    let self = this;

    this.selector = selector;
    this.root = root;

    this.addSubject = new Subject();
    this.removeSubject = new Subject();

    //Find new elements and run aswell
    this.observer = new MutationObserver((mutations)=>{
      mutations.forEach((mutation)=>{
        function doCheck(nodes, action, checkChildren=true) {
          nodes.forEach((node)=>{
            if(node.matches != null && node.matches(self.selector)) {
              action(node)
            }

            if(checkChildren && node.querySelectorAll != null) {
              doCheck(Array.from(node.querySelectorAll("*")), action, false);
            }
          });
        }

        doCheck(Array.from(mutation.addedNodes), (node)=>{
          self.addSubject.next(node);
        });

        doCheck(Array.from(mutation.removedNodes), (node)=>{
          self.removeSubject.next(node);
        });
      });
    });

    this.observer.observe(this.root, {
      childList: true,
      subtree: true
    });
  }

  /**
   * Stop this LiveElement, any new element matching this LiveElement will not be handled.
   */
  stop() {
    this.observer.disconnect();
  }

  /**
   * @private
   */
  kickstart(callback) {
    //Kickstart with current elements
    const elements = this.root.querySelectorAll(this.selector);

    elements.forEach((element)=>{
      callback(element);
    });
  }

  /**
   * Runs the given callback for each element matched by this LiveElement, now and in the future.
   *
   * @param {function} callback - The callback to run for each element
   */
  forEach(callback) {
    this.addSubject.asObservable().subscribe(callback);
    this.kickstart(callback);
  }

  /**
   * Calls the given callback method when the given event is triggered on any of the elements
   * matched by this LiveElement
   *
   * @param {string} event - The event to listen for
   * @param {function} callback - The callback to run when the event is triggered.
   */
  on(event, callback) {
    function work(element) {
      element.addEventListener(event, (evt)=>{
        callback(element, evt);
      });
    }

    this.addSubject.asObservable().subscribe((element)=>{
      work(element);
    });

    this.kickstart(work);
  }

  /**
   * Called when a LiveElement member is removed
   * @param callback
   */
  removed(callback) {
    this.removeSubject.asObservable().subscribe(callback);
  }

  /**
   * Called when a LiveElement member is added
   * @param callback
   */
  added(callback) {
    this.addSubject.asObservable().subscribe(callback);
  }
}
