








<div class="container-fluid page-max-width">
    <div class="justify-content-md-center">
        <div class="format-one-container">
            <div class="format-one-column">
                <app-catalog-menu></app-catalog-menu>

                <div class="no-mobile m-container" *ngIf="!isMobile">
                    <div class="m-column1">
                        <div class="format-column1">
                            <div class="format-column1-content">
                                <div class="heading-div">
                                    <div class="heading-title" translate>Input</div>
                                    <div class="heading-menu">                           <button (click)="createInspirationBlock()"  mat-icon-button title="{{'Create new inspiration block'|translate}}">
                                            <mat-icon>add_circle</mat-icon>
                                        </button>    
                                    </div>
                                </div>







                                <app-kblocks-overview *ngIf="kblock_reference && kblock_reference_name" [type]="ktype.Concept" [blocks]="12" [id]="kblock_reference"  create="true" [name]="kblock_reference_name"></app-kblocks-overview>
                            </div>
                        </div>
                    </div>

                    <!-- TODO: Is this second column in the 1-column layout intentional? -->
                    <div class="m-column2">
                        <div class="format-column2">
                            <div>
                                <app-workshop-widget [current_favor]="favor" [favor_id]="favor_id" [workshop_id]="workshop_id"></app-workshop-widget>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="row no-gutters" *ngIf="isMobile">
                    <div class="col-12 ">
                        <div class="format-column1">
                            <div class="format-column1-content">
                                <app-workshop-widget [current_favor]="favor" [favor_id]="favor_id" [workshop_id]="workshop_id"></app-workshop-widget>

                                <div class="heading-div">
                                    <div class="heading-title" translate>Input</div>
                                    <div class="heading-menu">                           <button (click)="createInspirationBlock()"  mat-icon-button title="{{'Create new inspiration block'|translate}}">
                                            <mat-icon>add_circle</mat-icon>
                                        </button>    
                                    </div>
                                </div>
                                <app-kblocks-overview *ngIf="kblock_reference && kblock_reference_name" [type]="ktype.Concept" [blocks]="11" [id]="kblock_reference" create="true" [name]="kblock_reference_name"></app-kblocks-overview>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>