<div class="format-column1-content-ext">
    <div>
        <div class="date-list" #dateList>
            <ng-container *ngFor="let blockdate of model.kblockdates; let i = index" >
              <ng-container *ngIf="blockdate | filterBlockdates:authService.userData:model.project_id">
                <div class="same-date">
                    <div class="dateformat"> {{  getDateFromTimestamp(blockdate.date) }} </div>
                    <div class="kblock-flow">
                       <!--  <ng-template #notLoggedIn>
                            <div class="favor">
                                {{"To create Inspirations you need to be logged in: "|translate}}
                                <a  href="javascript:void(null);"
                                [routerLink]="'/'+env.links.login"
                                   (click)="closeDialog(); $event.stopPropagation()" translate>Register / Log-in</a>
                            </div>
                        </ng-template>

                        Iterate through kblocks -->
                        <div *ngFor="let kblock of blockdate.kblocks; let i = index">
                          <ng-container *ngIf="kblock | filterHiddenKblock:authService.userData:model.project_id">
                            <div class="kblock-outer">
                                <app-inspiration-block class="variable-size" [kblock]="kblock" [type]="type"></app-inspiration-block>
                            </div>
                          </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
          </ng-container>
        </div>

        <br/>
        <loading-spinner *ngIf="model.kBlockLoading() | async"></loading-spinner>
        <br/>
        <div class="loadyMcLoadFace" #loadMore></div>
    </div>
</div>
