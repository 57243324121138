<div class="questionnaire" *ngIf="questionnaire">
  <div class="participate">
  <div class="participate-heading" translate>Contribute</div>
  <div class="participate-text">

  <button class="admin-selfclear-button" mat-stroked-button *ngIf="admin" matTooltip="{{'Clear answers (Admin only)'|translate}}" (click)="clearAnswers()" [disabled]="this.alreadyClearing"><mat-icon class="admin-selfclear-icon">clear</mat-icon> {{'Clear My Answers'|translate}}</button>

  <!--<h2>{{questionnaire.title}}</h2>-->
  <div *ngIf="admin"><br/>
    <br/></div>

  <div class="questions">
    <div class="question" *ngFor="let question of questionnaire.questions; trackBy: trackByFn">
      <app-questionnaire-question [questionnaireRef]="questionnaireRef" [question]="question" [questionnaire]="questionnaire"></app-questionnaire-question>
    </div>
  </div>
  </div>
</div>
</div>
