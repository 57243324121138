import { Component, OnInit } from '@angular/core';
import {CatalogService} from "../catalog.service";
import {Router} from "@angular/router";
import {ModelService} from "../../service/model.service";
import {environment} from "../../../environments/environment";
import {Concept} from "../../model/concept.model";
import {StoryNavigatorDialogComponent} from "../story-navigator-dialog/story-navigator-dialog.component";
import {ActivePageServiceService} from "../active-page-service.service";

@Component({
  selector: 'app-story-navigator',
  templateUrl: './story-navigator.component.html',
  styleUrls: ['./story-navigator.component.scss']
})
export class StoryNavigatorComponent implements OnInit {
  env = environment;

  constructor(public model : ModelService, public catalogService: CatalogService, private router : Router, public activePageService:ActivePageServiceService) {
  }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  getPrevConcept() {
    let sortedConcepts = this.getSortedConcepts();

    let currentConceptIndex = sortedConcepts.findIndex((concept)=>{
      return concept.id == this.model.concept.id;
    });

    if(currentConceptIndex > 0) {
      return sortedConcepts[currentConceptIndex-1];
    }

    return null;
  }

  getNextConcept() {
    let sortedConcepts = this.getSortedConcepts();

    let currentConceptIndex = sortedConcepts.findIndex((concept)=>{
      return concept.id == this.model.concept.id;
    });

    if(this.model.concepts != null && currentConceptIndex < this.model.concepts.length-1) {
      return sortedConcepts[currentConceptIndex+1];
    }

    return null;
  }

  prev() {
    const prevConcept = this.getPrevConcept();

    if(prevConcept != null) {
      this.navigateToConcept(prevConcept);
    }
  }

  next() {
    const nextConcept = this.getNextConcept();

    if(nextConcept != null) {
      this.navigateToConcept(nextConcept);
    }
  }

  getSortedConcepts() {
    let sortedConcepts = [];
    if(this.model.project != null && this.model.concepts != null) {
      this.model.project.sections.forEach((section) => {
        let sectionConceptsSorted = this.model.concepts.filter((concept) => {
          return concept.section_id == section.id;
        }).sort((c1, c2) => {
          return c1.name.localeCompare(c2.name);
        });

        sortedConcepts.push(...sectionConceptsSorted);
      });

      let nonSectionStories = this.model.concepts.filter((concept) => {
        return concept.section_id == null;
      }).sort((c1, c2) => {
        return c1.name.localeCompare(c2.name);
      })

      sortedConcepts.push(...nonSectionStories);
    }
    return sortedConcepts;
  }

  navigateToConcept(concept : Concept) {
   // document.querySelector(".scrollBlock").scrollTop = 0;
    this.router.navigate(["/"+this.env.links.viewStory+"/"+concept.id]);
  }

  openDialog() {
    StoryNavigatorDialogComponent.openDialog().subscribe((concept)=>{
      if(concept != null) {
        this.navigateToConcept(concept);
      }
    })
  }
}
