import htmlTemplate from './story-rating-map-block.html'
import {StorySelectDialogComponent} from '../../story-select-dialog/story-select-dialog.component';
import {QuestionnaireService} from "../../questionnaire.service";
import {Catalog, CatalogService} from "../../catalog.service";
import { ModelService } from 'src/app/service/model.service';
import { Project } from 'src/app/model/project.model';
import {EditorJSBlockTranslator} from "../EditorJSBlockTranslator";

export class StoryRatingMapBlock {
  static get toolbox() {
    return {
      title: "Story Rating Map",
      icon: "<span class='material-icons'>scatter_plot</span>"
    }
  }

  private readonly editorHtml: HTMLElement;
  private template;
  //private catalogService: CatalogService;
  private modelService : ModelService;
  private catalog : Project;

  constructor({api, config, data}) {
    this.template = document.createElement('template');
    this.template.innerHTML = EditorJSBlockTranslator.translateHtmlTemplate(htmlTemplate);

    this.editorHtml = this.template.content.querySelector(".story-rating-map-block");

    console.log(config);

   // this.catalogService = injector.get(CatalogService);
    this.modelService = injector.get(ModelService);

  /*  this.catalogService.getCatalogFromId(config.projectId).subscribe((catalog)=>{
      this.catalog = catalog;
      console.log("DATA BACK PRE" + config.projectId);

      if (this.catalog)
        console.log("DATA BACK" + this.catalog.name);
      this.updateRatings();

      this.loadRatingIndex(data);
    });*/
    this.updateRatings();

    this.loadRatingIndex(data);

    if(data != null) {
      this.load(data);
    }

    this.editorHtml.querySelector(".addStory").addEventListener("click", () => {
      StorySelectDialogComponent.showDialog(config.projectId).subscribe((story)=>{
        if(story != null) {
          const storyElm = this.addStory();
          this.loadStory(storyElm, {
            title: story.name,
            id: story.id
          });
        }
      });
    });

    this.editorHtml.querySelector(".addLine").addEventListener("click", () => {
      this.addLine();
    });
  }

  updateRatings() {
    console.log("Update Ratings")
    if (this.modelService.project)
      this.catalog = this.modelService.project;
    if(this.catalog != null) {
      console.log(this.catalog);
      console.log(this.catalog.storyRatings);
      if(this.catalog.storyRatings == null) {
        //No story rating on this catalog, cant select ratings!
       // alert("No catalog wide rating enabled for the catalog this story is inside!");
      } else {
        console.log("Add ratings");
        console.log (this.catalog.storyRatings.questions.length)
        let questionIndex = 0;

        let xAxisRatings = this.editorHtml.querySelector(".xAxisRating");
        let yAxisRatings = this.editorHtml.querySelector(".yAxisRating");

        this.catalog.storyRatings.questions.forEach((question) => {
          let questionOption = document.createElement("option");
          questionOption.setAttribute("value", ""+questionIndex);
          questionOption.textContent = question;

          xAxisRatings.appendChild(questionOption);
          yAxisRatings.appendChild(questionOption.cloneNode(true));

          questionIndex++;
        });
      }
    }
  }

  addStory() {
    const storyElm = this.template.content.querySelector(".story-rating").cloneNode(true);

    this.editorHtml.querySelector(".stories").appendChild(storyElm);

    storyElm.querySelector(".delete").addEventListener("click", ()=>{
      storyElm.remove();
    });

    return storyElm;
  }

  addLine() {
    const lineElm = this.template.content.querySelector(".intersect-line").cloneNode(true);

    this.editorHtml.querySelector(".lines").appendChild(lineElm);

    lineElm.querySelector(".delete").addEventListener("click", ()=>{
      lineElm.remove();
    });

    return lineElm;
  }

  load(data) {
    if(data.title != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.graphTitle").value = data.title;
    }
    if(data.caption != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.graphCaption").value = data.caption;
    }

    if(data.xAxis != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.xAxisTitle").value = data.xAxis;
    }

    if(data.yAxis != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.yAxisTitle").value = data.yAxis;
    }

    if(data.stories != null) {
      data.stories.forEach((story)=>{
        let storyElm = this.addStory();
        this.loadStory(storyElm, story);
      })
    }

    if(data.intersectLines != null) {
      data.intersectLines.forEach((intersectLine)=>{
        let lineElm = this.addLine();
        this.loadLine(lineElm, intersectLine);
      })
    }
  }

  loadRatingIndex(data) {
    let xAxisRating = parseInt(data.xAxisRatingIndex);
    let yAxisRating = parseInt(data.yAxisRatingIndex);

    this.editorHtml.querySelector<HTMLSelectElement>(".xAxisRating").selectedIndex = xAxisRating;
    this.editorHtml.querySelector<HTMLSelectElement>(".yAxisRating").selectedIndex = yAxisRating;
  }

  loadStory(storyElm, data) {
    storyElm.querySelector(".storyTitle").textContent = data.title;
    storyElm.setAttribute("data-storyId", data.id);
  }

  loadLine(lineElm, data) {
    if(data.name != null) {
      lineElm.querySelector("input.title").value = data.name;
    }
    if(data.color != null) {
      lineElm.querySelector("input.lineColor").value = data.color;
    }
    if(data.x1 != null) {
      lineElm.querySelector("input.x1").value = data.x1;
    }
    if(data.y1 != null) {
      lineElm.querySelector("input.y1").value = data.y1;
    }
    if(data.x2 != null) {
      lineElm.querySelector("input.x2").value = data.x2;
    }
    if(data.y2 != null) {
      lineElm.querySelector("input.y2").value = data.y2;
    }
  }

  render() {
    return this.editorHtml;
  }

  save(blockContent) {
    const stories = [];

    blockContent.querySelectorAll(".stories .story-rating").forEach((storyElm)=>{
      stories.push({
        title: storyElm.querySelector(".storyTitle").textContent,
        id: storyElm.getAttribute("data-storyId")
      });
    });

    const intersectLines = [];

    blockContent.querySelectorAll(".lines .intersect-line").forEach((lineElm)=>{
      intersectLines.push({
        name: lineElm.querySelector("input.title").value,
        color: lineElm.querySelector("input.lineColor").value,
        x1: parseFloat(lineElm.querySelector("input.x1").value),
        y1: parseFloat(lineElm.querySelector("input.y1").value),
        x2: parseFloat(lineElm.querySelector("input.x2").value),
        y2: parseFloat(lineElm.querySelector("input.y2").value)
      });
    });

    return {
      title: blockContent.querySelector("input.graphTitle").value,
      caption: blockContent.querySelector("input.graphCaption").value,
      xAxis: blockContent.querySelector("input.xAxisTitle").value,
      yAxis: blockContent.querySelector("input.yAxisTitle").value,
      xAxisRatingIndex: parseInt(blockContent.querySelector(".xAxisRating").value),
      yAxisRatingIndex: parseInt(blockContent.querySelector(".yAxisRating").value),
      stories: stories,
      intersectLines: intersectLines
    }
  }
}
