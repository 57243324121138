import {Injectable} from '@angular/core';
import {Project, ProjectSection} from '../model/project.model';
import {AuthService} from './auth.service';
import {Subscription, Observable, Subject} from 'rxjs';
import {User} from '../model/user.model';
import {ProjectService} from './project.service';
import {Concept} from '../model/concept.model';
import {ConceptService} from './concept.service';
import {Kblock, kBlockType} from '../model/kblock.model';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {KblockService, kQueryType} from './kblock.service';
import {first, take} from 'rxjs/operators';
import {FavorService} from './favor.service';
import {Favor, StoryRating, FavorType} from '../model/favor.model';
import {RatingsService, Rating} from './ratings.service';
import {JoinKeysService} from './join-keys.service';
import {JoinKeys} from '../model/join-keys.model';
import {Catalog} from '../frontend/catalog.service';
import {ProcessService} from './process.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Workshop} from '../model/process.model';
import {NotificationService} from './notification.service';
import {IdeateNotificationType} from '../model/ideate-notification.model';
import {environment} from "../../environments/environment";
import {OverlaySpinnerService} from "./overlay-spinner.service";
import {HttpClient} from "@angular/common/http";


export enum CurrentPage {
  dashboard,
  user_profile,
  catalog_overview,
  catalog_setting,
  all_story,
  story_view,
  member_info,
  sharing,
  workshop
}

export class kBlockDateCollection {
  date: Date;
  kblocks: Kblock[];
}


@Injectable({
  providedIn: 'root'
})
export class ModelService {


  current_page: CurrentPage;

  browse_as_user: boolean = false; // See the catalog as a normal user
  user: User;
  active_user: User;

  projects: Project[];
  edit_view: boolean = true;

  project: Project;
  project_id: string;
  concepts: Concept[];
  favors: Favor[] = [];
  user_story_ratings: Rating[] = [];

  concept: Concept;
  concept_id: string;
  create_kblock_temp: Kblock;

  private conceptLoadedSource = new Subject<Concept>();
  public conceptLoaded$ = this.conceptLoadedSource.asObservable();

  private favorsLoadedSource = new Subject<Favor[]>();
  public favorLoaded$ = this.favorsLoadedSource.asObservable();


  private projectLoadedSource = new Subject<Project>();
  public projectLoaded$ = this.projectLoadedSource.asObservable();

  kblocks: Kblock[];
  kblockdates: kBlockDateCollection[] = [];
  kblock_type: kBlockType;


  workshop_id: string;

  public set displayView(value: boolean)
  {
    this.edit_view=!value;
  }


  public get displayView()
  {
    return !this.editView;
  }

  public get editView() {
    if (this.trueAdminRole())
    {
      return this.edit_view;
    }
    else
      false;
  }

  public get process_service(): ProcessService {
    return this.processService;
  }


  public get workshop(): Workshop {
    if (this.workshop_id && this.process_service.process && this.process_service.process.workshops) {
      return this.process_service.process.workshops.find(workshop => {
        if (workshop.id === this.workshop_id) return workshop;
      });
    }
    return null;
  }


  link_catalog: Catalog = null;
  join_key: JoinKeys = null;


  projects_subscription: Subscription;
  project_subscription: Subscription;
  concepts_subscription: Subscription;
  favors_subscription: Subscription;
  concept_subscription: Subscription;
  kblock_subscription: Subscription;
  new_kblock_subscription: Subscription;
  user_story_ratings_subscription: Subscription;


  constructor(private authService: AuthService, public projectService: ProjectService,
              public kBlockService: KblockService, public conceptService: ConceptService, public favorService: FavorService,
              private ratingService: RatingsService,
              private joinKeysService: JoinKeysService,
              private processService: ProcessService,
              private _snackBar: MatSnackBar,
              private notificationService: NotificationService,
              private http: HttpClient
  ) {
    this.project = new Project();
    this.concept = new Concept();
    this.project_id = "";
    this.user = this.authService.getUser();
    if (this.user == null) {
      authService.userLoggedIn$.subscribe(user => {
          //this.user = authService.getUser();
          if (user == null) {
            this.user = null;
            return;
          }

          this.authService.loadUser(user.uid).subscribe(e => {
            this.user = e as User;
            this.user.uid = user.uid;
          });

        }
      )
    }
  }

  isOwner(kblock: Kblock) {
    if (this.user == null) {
      return false;
    }

    return this.user.uid === kblock.owner.id;
  }

  isProjectPublicJoin(): boolean {
    if (this.project != null) {
      return this.project.joinMethod === "public_join";
    } else {
      return false;
    }
  }

  joinProject(project: Project) {
    return new Promise((resolve) => {
      const user = this.authService.getUser();
      const user_ref = this.authService.getUserReferenceById(user.uid);

      this.projectService.addParticipant(user, user_ref, project, "participant").then(result => {
        //resolve();
      });
    });
  }

  getUserRoleInProject(project): string {
    if (project != null && project.participants_role != null && this.user != null) {
      const user_project = project.participants_role.find(x => x.email == this.user.email);
      if (user_project) {
        return user_project.role;
      }
    }

    return "";
  }

  getProjectFavored(project): boolean {
    if (project != null && project.participants_role != null && this.user != null) {
      const user_project = project.participants_role.find(x => x.email == this.user.email);
      if (user_project && user_project.favored) {
        return user_project.favored;
      }
    }
    return false;
  }

  setProjectFavored(project: Project, value: boolean) {
    if (project != null && project.participants_role != null && this.user != null) {
      var user_project = project.participants_role.find(x => x.email == this.user.email);
      if (user_project) {
        user_project.favored = value;
      }
    }
    this.projectService.updateProject(project);
  }


  isProjectMember(): boolean {
    return this.trueAdminRole() || this.getUserRoleInProject(this.project) == "participant";
  }

  adminRole(): boolean {
    return this.trueAdminRole() && !this.browse_as_user;
  }

  trueAdminRole(): boolean {
    return (this.getUserRoleInProject(this.project) == "admin" || this.getUserRoleInProject(this.project) == "owner");
  }

/// Projects ////////////////
  subscribeProjects() {
    if (!this.authService.getUser()) {
      return;
    }

    if (this.projects_subscription && this.authService.getUser() && this.active_user && this.authService.getUser().email == this.active_user.email)
      return;

    this.active_user = this.authService.getUser();

    if (this.projects_subscription)
      this.projects_subscription.unsubscribe();

    this.projects_subscription = this.projectService.getProjects(this.authService.getUserReference()).subscribe(data => {
      if (data.length > 0 && data[0].payload.doc.metadata.fromCache == true)
        return;

      let projects = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {})
        } as Project;
      })

      if (this.projects != projects) {
        this.projects = projects;
      }

      if (this.projects) {
        this.projects.sort(function (a, b) {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    });
  }


  setSelectedProject(id: string) {
    if (id == this.project_id && this.project && this.project.name) {
        // already there
    } else {
      this.resetProject();
      this.project_id = id;
      this.subscribeProject();
    }

  }

  resetConcept() {
    this.concept_id = "";
    this.concept = new Concept();

    if (this.concept_subscription) this.concept_subscription.unsubscribe();

    this.concept_subscription = null;
  }

  resetProject(resetConcept=false) {
    this.browse_as_user = false;
    this.project = new Project();
    this.concepts = [];
    this.favors = [];
    this.user_story_ratings = [];
    this.project_id = "";

    if (this.project_subscription) this.project_subscription.unsubscribe();

    this.project_subscription = null;

    if(resetConcept) {
      this.resetConcept();
    }
  }


  subscribeProject() {
    if (this.project_subscription)
      this.project_subscription.unsubscribe();

    this.project_subscription = this.projectService.getProject(this.project_id).subscribe(e => {
      this.project = e as Project;
      this.project.id = this.project_id;

      this.subscribeConcepts();
      this.subscribeFavors();
      this.subscribeUserStoryRatings();
      this.processService.changeProcess(this.project.id);
      this.projectLoadedSource.complete();

    });

  }

////////////// Concepts ////////////////
  subscribeConcepts() {

    if (this.concepts_subscription)
      this.concepts_subscription.unsubscribe();
    if (!this.project)
      return;
    this.concepts_subscription = this.conceptService.getConcepts(this.projectService.getProjectReference(this.project)).subscribe(data => {
      this.concepts = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {})
        } as Concept;
      })

      if (this.concepts) {
        this.concepts.sort(function (a, b) {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }


    });

  }


  setSelectedConcept(id: string) {
    if (id == this.concept_id && this.concept.name) return false;

    this.concept_id = id;
    this.subscribeConcept();
  }

  subscribeConcept() {
    this.unsubscribeConcept();
    this.concept_subscription = this.conceptService.getConcept(this.concept_id).subscribe(e => {
      this.concept = e as Concept;
      this.concept.id = this.concept_id;
      this.conceptLoadedSource.next(this.concept);
      if (this.concept.project.id != this.project_id) {
        this.setSelectedProject(this.concept.project.id);
      }

    });
  }

  unsubscribeConcept() {
    if (this.concept_subscription) this.concept_subscription.unsubscribe();

  }




////////////////// Favors Services ////////////////////////////

  subscribeFavors() {
    if (this.favors_subscription) this.favors_subscription.unsubscribe();

    if (!this.project)
      return;

    this.favors_subscription = this.favorService.getFavors(this.projectService.getProjectReference(this.project)).subscribe(data => {
      this.favors = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {})
        } as Favor;
      })

      if (this.favors) {
        this.favors.sort(function (a, b) {
          if (a.title == null || b.title == null) {
            return 0;
          }

          const nameA = a.title.toUpperCase(); // ignore upper and lowercase
          const nameB = b.title.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
      this.favorsLoadedSource.complete();

    });

  }

  subscribeUserStoryRatings() {
    if (this.user_story_ratings_subscription) this.user_story_ratings_subscription.unsubscribe();
    this.user_story_ratings_subscription =
      this.ratingService.getRatingsCatalog(
        this.projectService.getProjectReference(this.project),
        this.authService.getUserReference()).subscribe(data => {
        this.user_story_ratings = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...(e.payload.doc.data() as {})
          } as Rating;
        })
      });

  }

////////////////// KBLOCK Services ////////////////////////////

  createKBlock(k_block: Kblock): Promise<DocumentReference> {

    if (!this.user)
      this.user = this.authService.getUser();

    k_block.time_stamp = new Date();
    k_block.owner = this.authService.getUserReference();

    if (!this.user.displayName)
      this.user.displayName = "";
    if (!this.user.photoURL)
      this.user.photoURL = "";
    if (this.user.image)
      k_block.owner_data = {
        'displayName': this.user.displayName,
        'photoURL': this.user.photoURL,
        'image': this.user.image
      };
    else
      k_block.owner_data = {'displayName': this.user.displayName, 'photoURL': this.user.photoURL, 'image': null};

    if (k_block.references == null) {
      k_block.references = [];
    }
    if (k_block.projects == null) {
      k_block.projects = [];
    }
    k_block.followers = [k_block.owner];

    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    const links = k_block.text.match(urlRegex);
    console.log("Total block:", k_block.text);
    if (links) {
      console.log("Found link:", links[0]);

      //Check that the link was not found inside an src attribute
      let linkIndex = k_block.text.indexOf(links[0]);

      let srcAttributeLink = k_block.text.indexOf("src=\"" + links[0] + "\"");

      if(srcAttributeLink === -1) {
        srcAttributeLink = k_block.text.indexOf("src='" + links[0] + "'");
      }
      if(srcAttributeLink === -1) {
        srcAttributeLink = k_block.text.indexOf("href=\"" + links[0] + "\"");
      }
      if(srcAttributeLink === -1) {
        srcAttributeLink = k_block.text.indexOf("href='" + links[0] + "'");
      }

      if (srcAttributeLink !== -1) {
        //The found link was inside a src/href attribute, dont use it...
        console.log("Found link inside src attribute, skipping...");
        k_block.link_url = links[0];
      } else {
        k_block.link_url = links[0];
        k_block.text = k_block.text.replace(links[0], '');
      }
    }
    this.create_kblock_temp = k_block;

    return new Promise(async (resolve) => {

      if(k_block.link_url != null) {
        try {
            await this.http.get("https://europe-west3-ideate-project.cloudfunctions.net/fetchOpengraph?url=" + encodeURIComponent(k_block.link_url)).pipe(first()).toPromise().then((ogsResult:any) => {
              console.log("Opengraph result:", ogsResult);
              k_block.open_graph = ogsResult;
            });
        } catch (ex){
            console.log("Unable to fetch OpenGraph info", ex);
        }
      }

      this.kBlockService.createkBlock(k_block).then(ref => {
        this.newKblockUnsubscripe();

        this.new_kblock_subscription = this.kBlockService.getkBlock(ref.id).subscribe(block => {
          block.id = ref.id;

          if (block.open_graph && block.open_graph.ogImage && Array.isArray(block.open_graph.ogImage)) {
            block.open_graph.ogImage = block.open_graph.ogImage[0];
          }
          if (block.open_graph && block.open_graph.ogImage && block.open_graph.ogImage.url.startsWith("//")) {
            let url = new URL(block.link_url);
            block.open_graph.ogImage.url = url.protocol + block.open_graph.ogImage.url;
          } else if (block.open_graph && block.open_graph.ogImage && block.open_graph.ogImage.url.startsWith("/")) {
            let url = new URL(block.link_url);
            block.open_graph.ogImage.url = url.origin + block.open_graph.ogImage.url;
          }

          this.kblocks.forEach((item, index) => {
            if (item.id === block.id) {
              this.kblocks.splice(index, 1);
            }

          });
          this.kblocks.unshift(block);
        });

        // Add tags and followers by connecting the block based on where we are
        if (this.project_id){
            // Add the project tag
            this.connectProjectKblock(k_block, this.project);
        }
        if (this.concept_id){
            // Add story tag
            this.connectConceptKblock(k_block, this.concept);
        }

        this.updateKblockdates(k_block);
        this.notificationService.createNotification(this.user, IdeateNotificationType.kBlockCreated, ref.id, k_block.followers);
        resolve(ref);
      });
    });
  }

  newKblockUnsubscripe() {
    if (this.new_kblock_subscription) {
      this.new_kblock_subscription.unsubscribe();
    }
  }

  showAllorMine(all: boolean) {
    this.kblocks = [];
    this.newKblockUnsubscripe();
    this.kBlockService.updatekBlockFilter(all);
    this.subscribeKblockUpdates();
  }

  createkBlock(kblock: Kblock) {
    this.kBlockService.triggerPaginationUpdate(kblock);
    this.kBlockService.updatekBlock(kblock);
  }

  deleteKBlock(block: Kblock) {
    this.newKblockUnsubscripe();
    this.kblocks.forEach((item, index) => {
      if (item.id === block.id) this.kblocks.splice(index, 1);
    });

    this.kBlockRemoveFromDateCollections(block);

    this.kBlockService.deletekBlock(block.id);
  }

  kBlockRemoveFromDateCollections(block:Kblock) {
    this.kblockdates.forEach((dates) => {
      let foundIndex = dates.kblocks.findIndex((kblock) => {
        return kblock.id === block.id;
      });

      if (foundIndex !== -1) {
        dates.kblocks.splice(foundIndex, 1);
      }
    });
  }

  kBlockLoading(): Observable<boolean> {
    return this.kBlockService.loading;
  }

  kBlockDone(): Observable<boolean> {
    return this.kBlockService.done;
  }

  kBlockData(): Observable<Kblock[]> {
    return this.kBlockService.data;
  }

  kBlocksMore() {
    this.kblocks = [];
    window.scroll(0, 0);
    this.kBlockService.more();
  }


  kBlocksBack() {
    this.kblocks = [];
    window.scroll(0, 0);
    this.kBlockService.back();
  }


  sameDay(d1: Date, d2: Date): boolean {
    if (d1 && d2) {
      return (d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear())
    }
    return false;
  }

  updateKblockdates(c: Kblock) {
    let date = c.timestamp.toDate();

    let collection = this.kblockdates.find((collection) => {
      return this.sameDay(collection.date, date);
    });

    if (collection == null) {
      collection = new kBlockDateCollection();
      collection.date = date;
      collection.kblocks = [];

      let insertIndex = this.kblockdates.findIndex((currentCollection) => {
        return collection.date.getTime() > currentCollection.date.getTime();
      });

      if (insertIndex === -1) {
        insertIndex = this.kblockdates.length;
      }

      this.kblockdates.splice(insertIndex, 0, collection);
    }

    // Remove if already present
    let oldIndex = collection.kblocks.findIndex((kblock) => {
      return kblock.id === c.id;
    });
    if (oldIndex !== -1) {
      collection.kblocks.splice(oldIndex, 1);
    }

    // Find new sorted place for the item (sometimes this is loading older and sometimes adding newer items)
    let newIndex = collection.kblocks.length;
    while (newIndex>0 && collection.kblocks[newIndex-1].timestamp<c.timestamp){
        newIndex --;
    }    
    collection.kblocks.splice(newIndex, 0, c);
  }

  private transformTokBlockMap() {
    this.kblocks.forEach(c => {
      this.updateKblockdates(c);
    });
  }

  private subscribeKblockUpdates() {
    this.kBlockService.data.subscribe(val => {
      /*   if (this.kblocks) {
           this.kblocks = this.kblocks.concat(val);
         } else*/
      this.kblocks = val;

      //Dont reset, we are updating results
      //this.kblockdates = [];
      this.transformTokBlockMap();

      // This is for updating old blocks, should be deleted soon.
      this.kblocks.forEach(block => {
        if (!block.followers) {
          block.followers = [block.owner];
          this.kBlockService.updatekBlock(block);
        }
      });

    });
  }

  // Deprecated
  subscribekBlocks(reference: DocumentReference, type: kBlockType) {
    this.kblock_type = type;
    if (type == kBlockType.User) {
      const userref = this.authService.getUserReference();
      if (!userref)
        return;
      this.kblocks = [];
      this.newKblockUnsubscripe();
      this.kBlockService.initGetkBlocks(null, userref, kQueryType.user, 10, null);
      this.subscribeKblockUpdates();


    } else {

      if (!reference)
        return;
      this.kblocks = [];
      //this.newKblockUnsubscripe();

      this.kBlockService.initGetkBlocks(reference, this.authService.getUserReference(), kQueryType.project, 10, null);
      this.subscribeKblockUpdates();

    }
  }

  loadkBlocks(type: kBlockType, blocks: number, id?: string, name?: string) {
    this.kblocks = [];
    this.kblockdates = [];
    
    let ref: DocumentReference;
    let kquery: kQueryType = kQueryType.project;
    switch (type) {
      case kBlockType.User:
        ref = this.authService.getUserReference();
        kquery = kQueryType.user;
        break;
      case kBlockType.Project:
        if (id) {
          ref = this.projectService.getProjectReferenceById(id);
        } else {
          ref = this.projectService.getProjectReferenceById(this.project_id);
        }
        break;

      case kBlockType.Concept:
        if (id) {
          ref = this.conceptService.getReferenceById(id);
        } else {
          ref = this.conceptService.getReference(this.concept);
        }

        break;
    }

    if (!ref)
      return;


    this.newKblockUnsubscripe();
    this.kBlockService.initGetkBlocks(ref, this.authService.getUserReference(), kquery, blocks, null);
    this.subscribeKblockUpdates();
  }

  connectProjectKblock(block: Kblock, project: Project) {

    if (block.references == null) {
      block.references = [];
    }
    if (block.projects == null) {
      block.projects = [];
    }
    const ref = this.projectService.getProjectReference(project);

    if (block.projects.find(x => x.id == ref.id)) {
      return;
    }
    let color = project.color;
    if (color == null) {
      color = "#fff";
    }

    block.references.push({'name': project.kid, 'ref': ref, color: color});
    block.projects.push(ref);

    block.followers = block.followers.concat(project.participants);

    this.kBlockService.updatekBlock(block);

    this.updateKblockdates(block);
  }

  connectConceptKblock(block: Kblock, concept: Concept) {

    if (block.references == null) {
      block.references = [];
    }
    if (block.projects == null) {
      block.projects = [];
    }
    const ref = this.conceptService.getReference(concept);

    if (block.projects.some(x => x.id == ref.id)) {
      return;
    }

    let color = concept.color;
    if (color == null) {
      color = "#fff";
    }

    block.references.push({'name': concept.name, 'ref': ref, color: color, project: concept.project});
    block.projects.push(ref);

    this.projectService.getProject(concept.project.id).pipe(take(1)).subscribe(e => {
      const project = e as Project;
      project.id = ref.id;
      block.followers = block.followers.concat(project.participants);
      this.kBlockService.updatekBlock(block);

      this.updateKblockdates(block);
    });

  }

  async deleteReference(kblock: Kblock, ref: DocumentReference) {
    let isProjectRef = ref.path.startsWith(environment.firebaseVariable.project);

    kblock.projects = kblock.projects.filter((projectRef) => {
      return projectRef.id !== ref.id;
    });

    kblock.references = kblock.references.filter((reference) => {
      return reference.ref.id !== ref.id;
    });

    let projectRef: DocumentReference = null;

    if (isProjectRef) {
      projectRef = ref;
    } else {
      //Find project from concept
      let concept = await this.conceptService.getConcept(ref.id).pipe(first()).toPromise();
      projectRef = concept.project;
    }
    let project = await this.projectService.getProject(projectRef.id).pipe(first()).toPromise();
    project.participants.forEach((participant) => {
      let index = kblock.followers.findIndex((followerRef) => {
        return followerRef.id === participant.id;
      });
      kblock.followers.splice(index, 1);
    });

    //Update kblockdates, if currently inside a project or story?
    if(isProjectRef) {
      if(this.project.id === ref.id && this.current_page === CurrentPage.catalog_overview) {
        this.kBlockRemoveFromDateCollections(kblock);
      }
    } else {
      if(this.concept.id === ref.id && this.current_page === CurrentPage.story_view) {
        this.kBlockRemoveFromDateCollections(kblock);
      }
    }

    this.kBlockService.updatekBlock(kblock);
  }

  /// User

  updateUser() {
    return this.authService.updateUser(this.user);
  }

  userLoggedIn(): boolean {
    return this.user != null;
  }

  getLocalkBlockVersion(id: string) {
    return this.kblocks.find(e => e.id === id)
  }


  /// Join Keys


  lookupJoinKey(key_id: string) {
    this.join_key = null;
    this.joinKeysService.getKeybyId(key_id).subscribe(arr => {
      this.join_key = arr as JoinKeys;

      this.projectService.getProject(this.join_key.catalog.id).subscribe(result => {
          this.link_catalog = result as Project;
          this.link_catalog.id = this.join_key.catalog.id;
        }
      );
    });

  }


  generateJoinCode() {
    const key: JoinKeys = new JoinKeys();
    key.catalog = this.projectService.getProjectReference(this.project);
    key.name = (this.project.name) ? this.project.name : "";
    key.tagline = (this.project.tagline) ? this.project.tagline : "";
    if (this.project) {
      this.joinKeysService.createJoinKey(key).then(result => {
        if (result) {
          this.project.join_code = result.id;
          this.projectService.updateProject(this.project);
        }
      });
    }
  }

  /// Favor


  getStoryRating(input_story: Concept): StoryRating {

    for (const favor of this.favors) {

      if (favor.type != FavorType.StoryRatings) {
        return null;
      }
      if (favor.stories.some(story => {
        return story === input_story.id;
      })) {
        return favor.storyRatings;

      }

    }

    return null;
  }

  inFavors(favor: Favor, id: string): boolean {
    if (favor.type != FavorType.StoryRatings) {
      return false;
    }

    return favor.stories.some(story => {
      return story === id
    });

  }

  countFavors(favor: Favor): number {
    if (favor.type != FavorType.StoryRatings)
      return;
    let count: number = 0;
    for (const rating of this.user_story_ratings) {
      if (favor.stories.some(story => {
        return story === rating.story.id
      })) {
        count++;
      }
    }
    return count;

  }

  deleteSection(section: ProjectSection) {
    let counter = 0;
    this.concepts.forEach((concept) => {
      if (concept.section_id == section.id) counter++;
    });
    if (counter == 0) {
      this.projectService.deleteSection(this.project, section);
    } else {
      this._snackBar.open("Please remove all stories from this theme before deleting it!", "", {
        duration: 2000,
      })
    }

  }


  isLastAdmin(project:Project) : boolean {
    let user = this.authService.getUser();

    let otherAdmins = 0;

    project.participants_role.forEach((role)=>{
      //Skip self, there needs to be another admin/owner
      if(role.id.id === user.uid) {
        return;
      }

      if(role.role === "owner" || role.role === "admin") {
        otherAdmins++;
      }
    });

    return otherAdmins === 0;
  }

  async leaveProject(project:Project) {
    let user = this.authService.getUser();

    console.group("Leaving project:", project.name);

    //Remove from role
    project.participants_role = project.participants_role.filter((role)=>{
      return role.id.id !== user.uid;
    });

    //Remove from participants
    project.participants = project.participants.filter((participantRef)=>{
      return participantRef.id !== user.uid;
    });

    //Remove ourselves as follower
    if(false) {
      let projectRef = this.projectService.getProjectReference(project);
      let concepts = await this.conceptService.getConceptsValueChanges(projectRef).pipe(first()).toPromise();

      for (let concept of concepts) {
        let conceptRef = this.conceptService.getReference(concept);
        let conceptKblocks = await this.kBlockService.getAllKblocksFromProject(conceptRef).pipe(first()).toPromise();
        for (let kblock of conceptKblocks) {
          let index = kblock.followers.findIndex((follower) => {
            return follower.id === user.uid;
          });

          if (index !== -1) {
            kblock.followers.splice(index, 1);
            console.log("Removed user from:", kblock);
            //await this.kblockService.updatekBlock(kblock);
          }
        }
      }

      let projectKblocks = await this.kBlockService.getAllKblocksFromProject(projectRef).pipe(first()).toPromise();
      for (let kblock of projectKblocks) {
        let index = kblock.followers.findIndex((follower) => {
          return follower.id === user.uid;
        });

        if (index !== -1) {
          kblock.followers.splice(index, 1);
          console.log("Removed user from:", kblock);
          //await this.kblockService.updatekBlock(kblock);
        }
      }
    }

    console.log("Cleaned project:", project);

    console.groupEnd();
    this.projectService.updateProject(this.project);
    //this.projectService.updateProject(project);
  }

  async hideProjectForUser(project:Project) {
    let user = this.authService.getUser();

    if(user.hiddenProjects == null) {
      user.hiddenProjects = [];
    }

    user.hiddenProjects.push(this.projectService.getProjectReference(project));

    await this.authService.updateUser(user);
  }

  async unhideProjectForUser(project:Project) {
    let user = this.authService.getUser();

    if(user.hiddenProjects == null) {
      user.hiddenProjects = [];
    }

    let index = user.hiddenProjects.findIndex((projectRef)=>{
      return projectRef.id === project.id;
    });

    if(index !== -1) {
      user.hiddenProjects.splice(index, 1);
      await this.authService.updateUser(user);
    }
  }

  isHiddenForUser(project:Project) {
    let user = this.authService.getUser();

    if(user.hiddenProjects != null) {
      return user.hiddenProjects.find((projectRef)=>{
        return projectRef.id === project.id;
      }) != null;
    }

    return false;
  }
}


