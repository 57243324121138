import {Component, OnInit, ViewChild} from '@angular/core';
import { Project, publish_modes } from 'src/app/model/project.model';
import { ProjectService } from 'src/app/service/project.service';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ModelService } from 'src/app/service/model.service';
import {ColorEvent} from 'ngx-color';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {Image} from '../../frontend/image-upload-dialog/image-upload-dialog.component';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  host: {'class': 'scrollblock'}
})
export class NewProjectComponent implements OnInit {
  @ViewChild("currentColor") currentColor;

  project: Project = new Project();
  constructor(public projectService : ProjectService, public authService : AuthService, private router: Router, private model : ModelService, public dialogRef: MatDialogRef<NewProjectComponent>) { }

  ngOnInit(): void {
  }

  createProject() {
     if (this.project.name==null) return;
     const user_ref = this.authService.getUserReference();
      this.project.participants.push(user_ref);
      this.project.published= "private";
      this.project.participants_role.push({'id': user_ref, 'name' : this.model.user.displayName , 'email': this.model.user.email, 'role' : "owner", 'favored':false});
      if (this.project.kid==null) this.project.kid = this.project.name;
      this.projectService.createProject(this.project).then(res => {
        this.project.id = res.id;
        this.router.navigate(['/'+environment.links.viewProject+'/'+res.id]);
        this.dialogRef.close(this.project);
      }, err => {});
  }

  colorChangeComplete(event: ColorEvent) {
    this.project.color = event.color.hex;

    if(this.currentColor?.nativeElement != null) {
      this.currentColor.nativeElement.style.backgroundColor = this.project.color;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  static showDialog() : Observable<Project> {
    let subject = new Subject<Project>();

    const dialog = injector.get(MatDialog);

    const dialogRef = dialog.open(NewProjectComponent, {
      panelClass: ['reinvent-popup'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((project)=>{
      subject.next(project);
    });

    return subject.asObservable();
  }
}
