import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import {environment} from 'src/environments/environment';
import {TranslateService} from "@ngx-translate/core";
import {marker as TRANSLATE} from '@biesbjerg/ngx-translate-extract-marker';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../../onboarding-flow.scss', './register.component.scss'],
  host: {'class': 'centerblock'}
})
export class RegisterComponent implements OnInit {
  env = environment;
  policyAgreed : boolean = false;
  constructor(public authService: AuthService, private _snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
  }

  agreeToTerms() {
    this._snackBar.open(TRANSLATE("You need to agree to terms and condition before continuing"), "", {
      duration: 1500,
    }).afterDismissed().subscribe(() => {
    });
  }

}
