import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  QResponse,
  Question,
  QuestionAnswerCounter,
  Questionnaire,
  QuestionnaireService,
  QuestionOption
} from "../questionnaire.service";
import {DocumentReference} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {AuthService} from "../../service/auth.service";
import {Subscription} from "rxjs";
import {UserService} from "../user.service";
import {ModelService} from "../../service/model.service";
import {TranslateService} from "@ngx-translate/core";

// @ts-ignore
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-questionnaire-question',
  templateUrl: './questionnaire-question.component.html',
  styleUrls: ['./questionnaire-question.component.scss']
})
export class QuestionnaireQuestionComponent implements OnInit, OnDestroy {

  @Input() questionnaireRef : DocumentReference;
  @Input() questionnaire : Questionnaire;
  @Input() question : Question;

  ready : boolean = false;

  commentAnswer : string;

  currentUserQResponses : QResponse[];

  questionAnswerCounter : QuestionAnswerCounter;

  questionAnswerCounterSubscription : Subscription;
  currentUserQResponsesSubscription : Subscription;

  showStats : boolean;
  commentEditMode: boolean = false;

  currentlyAnswering : boolean = false;
  latestAnswer : string;
  latestValue : string;
  answerTimer : Timeout = null;

  constructor(private questionnaireService:QuestionnaireService, public model : ModelService, public authService: AuthService, private userService: UserService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.commentAnswer = localStorage.getItem(""+this.questionnaireRef+this.question.id);

    if(this.commentAnswer == null) {
      this.commentAnswer = "";
    }

    let user : string | DocumentReference = this.authService.getUserReference();

    if(user == null) {
      user = this.userService.getAnonymousCookieValue();
    }

    this.questionAnswerCounterSubscription = this.questionnaireService.getQuestionAnswerCounter(this.question.id, this.questionnaireRef).subscribe((answerCounter)=>{
      this.questionAnswerCounter = answerCounter;
    });

    this.currentUserQResponsesSubscription = this.questionnaireService.getQResponses(this.question.id, this.questionnaireRef, user).subscribe((qresponses)=>{
      this.currentUserQResponses = qresponses;
      this.ready = true;
      this.showStats = this.showStats || (this.questionnaire.show_result && this.isAnswered());
    });

    window.addEventListener("beforeunload", ()=>{
      this.updateCommentAnswer({
        target: {
          value: ""
        }
      })
    });
  }

  ngOnDestroy() {
    if(this.currentUserQResponsesSubscription != null) {
      this.currentUserQResponsesSubscription.unsubscribe();
    }

    if(this.questionAnswerCounterSubscription != null) {
      this.questionAnswerCounterSubscription.unsubscribe();
    }
  }

  switchToEditMode() {
    const oldResponse = this.getOldResponse();
    this.updateCommentAnswer({
      target: {
        value: oldResponse.response_name
      }
    });

    this.commentEditMode = true;
  }

  cancelEditMode() {
    this.commentEditMode = false;
  }

  toggleStats() {
    if(this.model.adminRole() || this.questionnaire.show_result) {
      this.showStats = !this.showStats;
    }
  }

  updateCommentAnswer(evt) {
    this.commentAnswer = evt.target.value;

    localStorage.setItem(""+this.questionnaireRef+this.question.id, this.commentAnswer);
  }

  answerMultipleChoice( option:QuestionOption) {
    //Dont allow answering until we are ready
    if(!this.ready) {
      return;
    }

    this.answer(option.name, option.value);

    this.showStats = this.questionnaire.show_result && true;
  }

  answerComment() {
    //Dont allow answering until we are ready
    if(!this.ready) {
      return;
    }

    if(this.commentAnswer == null || this.commentAnswer.trim().length === 0) {
      return;
    }

    this.commentEditMode = false;

    this.answer(this.commentAnswer, -1);
  }

  answerRating(option) {
    this.answer(null, option);
  }

  private getOldResponse() : QResponse {
    return this.currentUserQResponses.find((qresponse)=>{
      return qresponse.question_id === this.question.id;
    });
  }

  private async deleteOldResponse() {
    let oldResponse = this.getOldResponse();

    if(oldResponse != null) {
      if(this.questionAnswerCounter == null) {
        console.warn("Weird state, trying to delete QResponse, but no questionAnswerCounter exists...", oldResponse);
      } else {
        let option = this.questionAnswerCounter.options.find((option)=>{
          return oldResponse.response_value === option.value;
        });

        if(option == null) {
          console.warn("Weird state: Trying to decrease option that was never increased...");
        } else {
          option.responses--;

          if(option.responses < 0) {
            console.warn("Weird state: Counter reached below zero...");
            option.responses = 0;
          }

          await this.questionnaireService.updateQuestionAnswerCounter(this.questionAnswerCounter);
        }
      }

      await this.questionnaireService.deleteQResponse(oldResponse);
    }
  }

  private answer(name, value) {
    if(this.currentlyAnswering) {
      this.latestAnswer = name;
      this.latestValue = value;

      if(this.answerTimer != null) {
        clearTimeout(this.answerTimer);
      }

      this.answerTimer = setTimeout(()=>{
        this.answer(this.latestAnswer, this.latestValue);
        this.answerTimer = null;
      }, 100);
      return;
    }

    this.currentlyAnswering = true;

    //Delete old answer if present (Decreases option count by 1)
    this.deleteOldResponse().then(()=>{

      let anonymousUser = null;
      const userReference =  this.authService.getUserReference();
      if(userReference == null) {
        anonymousUser = this.userService.getAnonymousCookieValue();
      }

      //Create qresponse object
      const qresponse : QResponse = {
        anonymous_user: anonymousUser,
        question_id: this.question.id,
        questionnaire: this.questionnaireRef,
        questionnaire_version: this.questionnaire.version,
        response_name: name,
        response_value: value,
        time: firebase.firestore.Timestamp.fromDate(new Date()),
        user: userReference
      };

      //Update question answer counter
      if(this.questionAnswerCounter == null) {
        this.questionAnswerCounter = {
          options: [
            {
              value: value,
              responses: 1
            }
          ],
          questionnaire: this.questionnaireRef,
          question: this.question.id
        };
      } else {
        let option = this.questionAnswerCounter.options.find((option)=>{
          return value === option.value;
        });

        if(option == null) {
          option = {
            value: value,
            responses: 0
          }

          this.questionAnswerCounter.options.push(option);
        }

        option.responses++;
      }

      //Save in database
      this.questionnaireService.saveQResponse(qresponse, this.questionAnswerCounter).then(()=>{
        this.currentlyAnswering = false;
      });
    });
  }

  canSeeStats() {
    return (this.isAnswered() && this.questionnaire.show_result) || this.model.adminRole();
  }

  isAnswered() {
    if(this.currentUserQResponses != null) {
      return this.currentUserQResponses.find((qresponse)=>{
        return qresponse.question_id === this.question.id;
      }) != null;
    }

    return false;
  }

  getAnswer() : QResponse {
    if(this.currentUserQResponses != null) {
      return this.currentUserQResponses.find((qresponse)=>{
        return qresponse.question_id === this.question.id;
      });
    }

    return null;
  }

  isSelected(option: QuestionOption) {
    if(this.currentUserQResponses != null) {
      return this.currentUserQResponses.find((qresponse)=>{
        return qresponse.question_id === this.question.id && qresponse.response_name === option.name && qresponse.response_value === option.value;
      }) != null;
    }

    return false;
  }

  isRatingSelected(option) {
    if(this.currentUserQResponses != null) {
      return this.currentUserQResponses.find((qresponse)=>{
        return qresponse.question_id === this.question.id && qresponse.response_value === option;
      }) != null;
    }

    return false;
  }

  ratingValue() : number {
    if(this.questionAnswerCounter != null) {
      let totalAnswers = 0;
      let totalValue = 0;

      this.questionAnswerCounter.options.forEach((option)=>{
        totalAnswers += option.responses;
        totalValue += option.responses * option.value;
      });

      if(totalAnswers > 0) {
        return (totalValue / totalAnswers);
      }
    }

    return 0;
  }

  selectedPercentage(selectedOption: QuestionOption) {
    let totalAnswers = 0;
    let selectedOptionAnswers = 0;

    if(this.questionAnswerCounter != null) {
      this.questionAnswerCounter.options.forEach((option)=>{
        totalAnswers += option.responses;

        if(option.value === selectedOption.value) {
          selectedOptionAnswers = option.responses;
        }
      });

      let percentage = (selectedOptionAnswers / totalAnswers * 100.0);

      if(isNaN(percentage)) {
        percentage = 0;
      }

      return percentage.toFixed(2);
    }

    return 0;
  }

  shouldShow() {
    switch(this.question.type) {
      case "comments":
        return this.model.isProjectMember();

      default:
        return true;
    }
  }

  deleteAnswer() {
    this.translate.get("Really delete your comment?").subscribe((translation)=>{
      if(confirm(translation)) {
        this.deleteOldResponse();
        this.commentAnswer = "";
      }
    });
  }

}
