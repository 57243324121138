<ng-container *ngIf="userReference">
    <app-commenter-editor [userReference]="userReference" [editingComment]="null" [owningReference]="getOwningReference()"></app-commenter-editor>
</ng-container>

<ul class="comments">
    <li *ngFor="let comment of comments" class="comment {{isSelfAuthored(comment)?'self-authored':''}}">
        <ng-container *ngIf="!comment.currentlyEditing">
            <div class="postheader">
                <div class="userinfo"><app-user-inline-view [userReference]="comment.user"></app-user-inline-view></div>
                <div class="time">
                    <div class="commentPostedDate">{{getDateFromTimestamp(comment.posted)}}</div>
                    <div class="commentEditedDate" *ngIf="comment.edited">{{'edited {date}' | translate:{date: getDateFromTimestamp(comment.edited)} }}</div>
                </div>
            </div>
            <div class="comment-content">{{comment.content}}</div>
            <div class="toolbox"><button mat-icon-button class="button-style" *ngIf="canEdit(comment)" (click)="editComment(comment)"><mat-icon>create</mat-icon></button><button class="button-style" mat-icon-button *ngIf="canEdit(comment)" (click)="deleteComment(comment)"><mat-icon>delete</mat-icon></button></div>
        </ng-container>
        <ng-container *ngIf="comment.currentlyEditing">
            <app-commenter-editor [userReference]="userReference" [editingComment]="comment"></app-commenter-editor>
        </ng-container>
    </li>
</ul>
