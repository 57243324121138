import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ElementRef,
  Pipe, PipeTransform, AfterViewChecked
} from '@angular/core';
import { Kblock, kBlockType } from 'src/app/model/kblock.model';
import { KblockService } from 'src/app/service/kblock.service';
import { AuthService } from 'src/app/service/auth.service';
import { Project } from 'src/app/model/project.model';
import { ProjectService } from 'src/app/service/project.service';
import { Concept } from 'src/app/model/concept.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentReference} from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ModelService, CurrentPage } from 'src/app/service/model.service';
import {CaviDraggableHTML5} from "../../frontend/CaviDraggable";
import { EditInspirationBlockComponent } from '../edit-inspiration-block/edit-inspiration-block.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewChild } from '@angular/core';
import {CommentsService} from "../../frontend/comments.service";
import {ThumbnailService} from "../../frontend/thumbnail.service";

export enum allmine {
  all,
  mine
}

@Component({
  selector: 'app-inspiration-block',
  templateUrl: './inspiration-block.component.html',
  styleUrls: ['./inspiration-block.component.scss']
})
export class InspirationBlockComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild("inspirationText") inspirationText: ElementRef;
  @ViewChild("kblockDiv") kblockDiv: ElementRef;
  @Input() kblock : Kblock;
  @Input() type : kBlockType;

  //concepts : Concept[];
  new_kblock: Kblock = new Kblock();
  env = environment;
  ktype = kBlockType;


  mine_allblocks : string = "all";
  edit_block_nr: number = -1; // used to set the block selected

  @Output() item_selected = new EventEmitter<Kblock>();

  numberOfComments: number = 0;

  constructor(public model: ModelService,private route: ActivatedRoute,
    private kblockService: KblockService,
    public authService: AuthService,
    public projectService: ProjectService,
    private router: Router,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private elementRef: ElementRef) {

    authService.userLoggedIn$.subscribe(user => {
      this.model.subscribeProjects();});
    this.route.url.subscribe(url =>{

          });

   }


  ngOnInit(): void {



 /*   let observer = new MutationObserver((mutations)=>{
      mutations.forEach((mutation)=>{
        if(mutation.target instanceof Element && mutation.target.matches(".draggable")) {
          console.log("Setup drag");

          this.setupDragging(mutation.target);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement, {
      subtree: true,
      childList: true
    });*/
  }


  ngAfterViewInit() {

    if (this.authService.getUser())
    {
      this.model.subscribeProjects();
    }
      this.commentService.getCommentsFromOwningEntity(this.kblock).subscribe((comments)=>{
          this.numberOfComments = comments.length;
      });
    this.edit_block_nr = -1;
    this.setupDragging(this.kblockDiv.nativeElement);

  }

  ngAfterViewChecked(): void {
    let photos = this.inspirationText.nativeElement.querySelector(".photos");

    this.inspirationText.nativeElement.querySelectorAll(".inspiration-text-inside img").forEach((img)=>{
      let oldImg = photos.querySelector("[src='"+img.src+"']");
      if(oldImg != null) {
        oldImg.remove();
      }
      photos.appendChild(img);
    });
  }

  hasPhoto(): boolean {
        if(this.kblock.owner_data != null) {
            return ThumbnailService.getPhotoFromUser(this.kblock.owner_data) != null;
        }
        return false;
    }

    getPhoto():string {
        return ThumbnailService.getPhotoFromUser(this.kblock.owner_data);
    }

  setupDragging(kblock) {
    if(kblock.caviDraggable == null && this.model.isOwner(this.kblock)) {
      new CaviDraggableHTML5(kblock, {
        onDragStart: (evt)=>{
          evt.dataTransfer.setData('application/x-editor-js', JSON.stringify([{
            tool: "kblock",
            data: {
              id: kblock.getAttribute("data-kblock-id")
            }
          }]));
          evt.dataTransfer.setData("ideate/kblock", kblock.getAttribute("data-kblock-id"));
          evt.dataTransfer.dropEffect = "copy";
        }
      });
    }
  }



  ngOnDestroy() {

  }

  datetime(kblock : Kblock) : string
  {
      let d: Date = kblock.timestamp.toDate();
      return d.toLocaleDateString();
  }

  createkBlock()
  {
    this.model.createKBlock(this.new_kblock);
    this.new_kblock = new Kblock();
  }

  openEdit(nr : number)
  {
    this.edit_block_nr = nr;
  }

  submitEdit(block : Kblock) {

    //block.owner_data = { 'displayName': this.model.user.displayName, 'photoURL' : this.model.user.photoURL};;
    console.log("kblock length:" + this.model.kblocks.length);
    this.kblockService.updatekBlock(block);
    this.edit_block_nr = -1;
  }

  cancelEdit() {
    this.edit_block_nr = -1;
  }

  deletekBlock(block: Kblock){
    this.edit_block_nr = -1;
    this.model.deleteKBlock(block);
  }

  selectBlock(block : Kblock) {
    this.item_selected.emit(block);
  }

  deselectBlock() {
    this.item_selected.emit(null);
  }

  addHashtag(block : Kblock){

  }

   openReference(ref : DocumentReference) {
    if (ref.path.startsWith(environment.firebaseVariable.project))
      this.router.navigate(['/'+environment.links.viewProject+'/'+ref.id]);
    if (ref.path.startsWith(environment.firebaseVariable.concept))
      this.router.navigate(['/'+environment.links.viewStory+'/'+ref.id]);

   }


   addProjectReference(project : Project, kblock : Kblock) {
     this.model.connectProjectKblock(kblock, project);
   }

   addStoryReference(concept : Concept, kblock : Kblock) {
    this.model.connectConceptKblock(kblock, concept);
   }

   scrollHandler(e)
   {
  /*   console.log(e);
     if (e== 'bottom') {
       this.model.kBlocksMore();
     }*/
   }

shortLink(link : string)
{
  return (new URL(link)).origin;

}

   public getkBlockDateTime(kblock : Kblock) : string
   {

       let d: Date = kblock.timestamp.toDate();
       return d.toLocaleDateString();
   }


public showReferenceMenu()
{
  return  (this.model.current_page==CurrentPage.catalog_overview || this.model.current_page==CurrentPage.story_view);
}


   changeAllMine() {
     console.log(this.mine_allblocks);
     this.model.showAllorMine(this.mine_allblocks==="all");
   }



   openKBlock() {
    const dialogRef = this.dialog.open(EditInspirationBlockComponent, {
      data: this.kblock,
      panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
      autoFocus: false
    });
  }

     public hasMenuPermission() {
      return this.model.user != null;
   }


  }

