import { Pipe, PipeTransform } from '@angular/core';
import firebase from "firebase/compat";
import DocumentReference = firebase.firestore.DocumentReference;
import {Project} from "../../model/project.model";
import {User} from "../../model/user.model";
import {environment} from "../../../environments/environment";

/**
 * Determine whether a tag should be shown right now or not
 */
@Pipe({
  name: 'shouldShowTag'
})
export class ShouldShowTagPipe implements PipeTransform {
  env = environment;

  transform(reference, currentProjectId: string, user:User): boolean {
    let shouldShowProjectTags = !currentProjectId;
    let shouldShowConceptTags = !shouldShowProjectTags;

    const isProject = reference.ref.path.startsWith(this.env.firebaseVariable.project);
    const isConcept = reference.ref.path.startsWith(this.env.firebaseVariable.concept);

    // Show only project tags when no project is selected and concept tags while navigating any project
    if (isProject && !shouldShowProjectTags){
        return false;
    }
    if (isConcept && !shouldShowConceptTags){
        return false;
    }

    // But if we are in a project only show tags for that project
    if (currentProjectId && isConcept){
        if (reference.project != null) {
            if (reference.project.id!==currentProjectId){
                return false;
            }
        } else {
          console.log("Concept reference with missing project:", reference);
        }        
    }

    // And if a user is logged in and has hidden a project, then hide those tags too
    if(user !== null) {
        let hiddenProjects = user.hiddenProjects;
        if(hiddenProjects == null) {
            hiddenProjects = [];
        }

        hiddenProjects = hiddenProjects.filter((projectRef)=>{
            return projectRef.id !== currentProjectId;
        });

        if(isProject) {
            let isProjectHidden = false;
            if (user.hiddenProjects != null) {
                isProjectHidden = hiddenProjects.some((projectRef) => {
                    return projectRef.id === reference.ref.id;
                });
                if (isProjectHidden){
                    return false;
                }
            }
        } else if(isConcept) {
            //Concept tags

            let isConceptProjectHidden = false;
            if (user.hiddenProjects != null) {
                if (reference.project != null) {
                    isConceptProjectHidden = hiddenProjects.some((projectRef) => {
                        return projectRef.id === reference.project.id;
                    });
                } else {
                    console.log("Concept reference with missing project:", reference);
                }
                if (isConceptProjectHidden){
                    return false;
                }
            }
        }
    }

    // It passed all the checks, show it
    return true;
  }
}
