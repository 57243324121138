<ng-container *ngIf="user">
    <div class="userBlock">
        <img class="background" [src]="getPhoto()">
        <img class="avatar" [src]="getPhoto()">
        <div class="credentials">
            <div class="name">{{user.displayName}}</div>
            <p>{{user.description}}</p>
        </div>
    </div>
</ng-container>
