import {Injectable, OnDestroy} from '@angular/core';
import {AngularFirestore, DocumentReference} from "@angular/fire/compat/firestore";
import {Observable, Subject} from "rxjs";
import firebase from "firebase/compat";
import {AuthService} from "../service/auth.service";
import {map} from "rxjs/operators";
import {UserService} from "./user.service";

export interface Questionnaire {
  id?: string;
  questions: Question[];
  title: string;
  version: number;
  show_result: boolean;
}

export interface Question {
  id: string;
  options?: QuestionOption[];
  question: string;
  type: string;
}

export interface QuestionOption {
  name: string;
  value: number;
}

export interface QResponse {
  id?: string;
  anonymous_user: string;
  question_id: string;
  questionnaire: DocumentReference;
  questionnaire_version: number;
  response_name: string;
  response_value: number;
  time: firebase.firestore.Timestamp;
  user: DocumentReference;
}

export interface QuestionAnswerCounterOptions {
  responses: number;
  value: number;
}

export interface QuestionAnswerCounter {
  id?: string;
  options: QuestionAnswerCounterOptions[];
  question: string;
  questionnaire: DocumentReference;
}

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  private questionnaires: Map<string, Questionnaire> = new Map();
  private qresponses: Map<string, QResponse> = new Map();
  private questionAnswerCounters : Map<string, QuestionAnswerCounter> = new Map();

  private questionnairesSubject : Subject<Questionnaire[]>;
  private qresponsesSubjects : Map<string, Subject<QResponse[]>>;
  private questionAnswerCounterSubjects : Map<string, Subject<QuestionAnswerCounter[]>>;

  qresponseFetchers: Set<string> = new Set();
  questionAnswerCounterFetchers : Set<string> = new Set();

  qresponsesSeenResult: Map<string, boolean> = new Map();

  constructor(private firestore : AngularFirestore, private authService : AuthService, private userService: UserService) {
    this.questionnairesSubject = new Subject();
    this.qresponsesSubjects = new Map();
    this.questionAnswerCounterSubjects = new Map();
  }

  private sendQuestionnaireUpdates() {
    let questionnaires = Array.from(this.questionnaires.values());

    this.questionnairesSubject.next(questionnaires);
  }

  private sendQResponseUpdates(key) {
    let qresponses = Array.from(this.qresponses.values());

    if(this.qresponsesSeenResult.get(key)) {

      let subject = this.qresponsesSubjects.get(key);
      if (subject != null) {
        subject.next(qresponses);
      }
    }
  }

  private sendQuestionAnswerCounterUpdates(key) {
    let questionAnswerCounters = Array.from(this.questionAnswerCounters.values());

    let subject = this.questionAnswerCounterSubjects.get(key);

    if(subject != null) {
      subject.next(questionAnswerCounters);
    }
  }

  getQuestionnaireRef(path): DocumentReference {
    return this.firestore.doc<Questionnaire>(path).ref;
  }

  getQuestionnaire(ref:DocumentReference) : Observable<Questionnaire> {
    if(!this.questionnaires.has(ref.id)) {
      //Start fetcher
      this.firestore.collection("questionnaire").doc<Questionnaire>(ref.id).valueChanges().subscribe((questionnaire)=>{
        questionnaire.id = ref.id;
        this.questionnaires.set(questionnaire.id, questionnaire);
        this.sendQuestionnaireUpdates();
      });
    } else {
      setTimeout(()=>{
        this.sendQuestionnaireUpdates();
      }, 0);
    }

    return this.questionnairesSubject.asObservable().pipe(map((questionnaires)=>{
      return questionnaires.find((questionnaire)=>{
        return questionnaire.id === ref.id;
      })
    }));
  }

  async deleteQResponse(qresponse: QResponse) {
    this.qresponses.delete(qresponse.id);
    await this.firestore.collection("qresponse").doc<QResponse>(qresponse.id).delete();
  }

  async saveQResponse(qresponse: QResponse, questionAnswerCounter: QuestionAnswerCounter) {
    await this.firestore.collection<QResponse>("qresponse").add(qresponse);

    let questionAnswerRef = null;

    if(questionAnswerCounter.id != null) {
      questionAnswerRef = this.firestore.collection("questionAnswerCounter").doc(questionAnswerCounter.id);
    }

    if(questionAnswerRef != null) {
      questionAnswerRef.update(questionAnswerCounter);
    } else {
      await this.firestore.collection("questionAnswerCounter").add(questionAnswerCounter);
    }
  }

  async updateQuestionAnswerCounter(questionAnswerCounter) {
    let questionAnswerRef = null;

    if(questionAnswerCounter.id != null) {
      questionAnswerRef = this.firestore.collection("questionAnswerCounter").doc(questionAnswerCounter.id);
    }

    if(questionAnswerRef != null) {
      await questionAnswerRef.update(questionAnswerCounter);
    }
  }

  saveQuestionnaire(questionnaire: Questionnaire): Promise<DocumentReference> {
    return this.firestore.collection("questionnaire").add(questionnaire);
  }

  getQResponses(questionId : string, questionnaireRef : DocumentReference, user? : string | DocumentReference) : Observable<QResponse[]>{
    let qresponseFetcherKey = questionId+"-"+questionnaireRef.id;

    if(user != null) {
      if(typeof user === "string") {
        qresponseFetcherKey += user;
      } else {
        qresponseFetcherKey += user.id;
      }
    }

    let qresponseSubject = this.qresponsesSubjects.get(qresponseFetcherKey);
    if(qresponseSubject == null) {
      qresponseSubject = new Subject();
      this.qresponsesSubjects.set(qresponseFetcherKey, qresponseSubject);
    }

    if(!this.qresponseFetchers.has(qresponseFetcherKey)) {
      //Start fetching
      this.qresponseFetchers.add(qresponseFetcherKey);

      this.firestore.collection<QResponse>("qresponse", (ref)=>{
        return ref.where("question_id", "==", questionId).where("questionnaire", "==", questionnaireRef);
      }).valueChanges({
        idField: "id"
      }).subscribe((qresponses)=>{

        let oldResponseIds = new Set<string>();
        qresponses.forEach((qresponse)=>{
          //Only delete qresponses of the kind we asked for, we wont see the others
          if(qresponse.question_id == questionId) {
            oldResponseIds.add(qresponse.id);
          }
        });

        qresponses.forEach((qresponse)=>{
          this.qresponses.set(qresponse.id, qresponse);
          oldResponseIds.delete(qresponse.id);
        });

        oldResponseIds.forEach((deltedResponseId)=>{
          this.qresponses.delete(deltedResponseId);
        });

        this.qresponsesSeenResult.set(qresponseFetcherKey, true);

        this.sendQResponseUpdates(qresponseFetcherKey);
      });
    } else {
      setTimeout(()=>{
        this.sendQResponseUpdates(qresponseFetcherKey);
      }, 0);
    }

    return qresponseSubject.asObservable().pipe(map((qresponses)=>{
      return qresponses.filter((qresponse)=>{
        if(qresponse.question_id !== questionId) {
          return false;
        }

        if(qresponse.questionnaire.id !== questionnaireRef.id) {
          return false;
        }

        if(user != null) {
          if(typeof user === "string") {
            if(qresponse.anonymous_user !== user) {
              return false;
            }
          } else {
            if(qresponse.user == null || qresponse.user.id !== user.id) {
              return false;
            }
          }
        }

        return true;

      });
    }));
  }

  getQuestionAnswerCounter(questionId : string, questionnaireRef : DocumentReference) : Observable<QuestionAnswerCounter> {
    const questionAnswerCounterFetcherKey = questionId + "-" + questionnaireRef.id;

    let questionAnswerCounterSubject = this.questionAnswerCounterSubjects.get(questionAnswerCounterFetcherKey);
    if(questionAnswerCounterSubject == null) {
      questionAnswerCounterSubject = new Subject();
      this.questionAnswerCounterSubjects.set(questionAnswerCounterFetcherKey, questionAnswerCounterSubject);
    }

    if(!this.questionAnswerCounterFetchers.has(questionAnswerCounterFetcherKey)) {
      //Start fetching

      this.questionAnswerCounterFetchers.add(questionAnswerCounterFetcherKey);

      this.firestore.collection<QuestionAnswerCounter>("questionAnswerCounter", (ref)=>{
        return ref.where("questionnaire", "==", questionnaireRef).where("question", "==", questionId);
      }).valueChanges({
        idField: "id"
      }).subscribe((questionAnswerCounters)=>{
        questionAnswerCounters.forEach((questionAnswerCounter)=>{
          this.questionAnswerCounters.set(questionAnswerCounter.id, questionAnswerCounter);
        });

        this.sendQuestionAnswerCounterUpdates(questionAnswerCounterFetcherKey);
      });
    } else {
      setTimeout(()=>{
        this.sendQuestionAnswerCounterUpdates(questionAnswerCounterFetcherKey);
      }, 0);
    }

    return questionAnswerCounterSubject.asObservable().pipe(map((questionAnswerCounters)=>{
      return questionAnswerCounters.find((questionAnswerCounter)=>{
        return questionAnswerCounter.question === questionId && questionAnswerCounter.questionnaire.id === questionnaireRef.id;
      });
    }));
  }

  filterQresponses(qresponses: QResponse[], includeCurrentUser : boolean, includeOthers : boolean) {
    let anonymousUser = null;
    const userReference =  this.authService.getUserReference();
    if(userReference == null) {
      anonymousUser = this.userService.getAnonymousCookieValue();
    }

    return qresponses.filter((qresponse)=>{
      let currentUser = false;

      if(anonymousUser != null) {
        currentUser = qresponse.anonymous_user === anonymousUser;
      } else {
        currentUser = qresponse.user!=null?qresponse.user.id === userReference.id:false;
      }

      let shouldInclude = false;

      if(currentUser) {
        shouldInclude = includeCurrentUser;
      } else {
        shouldInclude = includeOthers;
      }

      return shouldInclude;
    });
  }

  savePuzzleAnswer(puzzleAnswer) {
    this.firestore.collection("puzzleAnswers").add(puzzleAnswer);
  }
}
