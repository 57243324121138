<mat-form-field class="input_form_field_small">
  <mat-label class="input_description">{{"Title"|translate}}</mat-label>
  <input class="input_field" matInput type="text" required [(ngModel)]="title" matTooltip="{{'Title'|translate}}">
</mat-form-field>
<div class="quill-edit">
  <quill-editor [(ngModel)]="content" (onEditorCreated)="onEditorOpen($event)">
    <div quill-editor-toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
        </span>
      <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
      <select class="ql-header">
        <option value="1">{{'Heading 1'|translate}}</option>
        <option value="2">{{'Heading 2'|translate}}</option>
        <option value="3">{{'Heading 3'|translate}}</option>
        <option value="">{{'Normal'|translate}}</option>
      </select>
      <span class="ql-formats">
          <button class="ql-link"></button>
          <button (click)="openUploadDialog($event)" class="ql-image"></button>
        </span>
    </div>
  </quill-editor>
  <br/>
</div>
<br/>
<br/>
<button mat-raised-button color="primary" (click)="addStep()">{{"Save step"|translate}}</button>
