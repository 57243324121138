import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { ModelService, CurrentPage } from 'src/app/service/model.service';
import { environment } from 'src/environments/environment';
import {CookieService} from "ngx-cookie-service";


@Component({
  selector: 'app-story-sidebar',
  templateUrl: './story-sidebar.component.html',
  styleUrls: ['./story-sidebar.component.scss', '../sidebar.scss']
})
export class StorySidebarComponent implements OnInit {
    @Input() favor_id: string;
    @Input() story_id: string;
    @HostBinding('class.minimized') minimized: boolean = false;
    public env = environment;

    constructor(public model: ModelService, private cookieService: CookieService) {

    }

    ngOnInit(): void {
      this.minimized = this.cookieService.get("menuMinimized") === "true";
    }

    toggle(){
      this.minimized = !this.minimized;
      this.cookieService.set("menuMinimized", ""+this.minimized);
    }

    getImage(obj, getSmall = false) {
      if (obj != null) {
        if (obj.image != null) {
          return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
        } else {
          if (obj.image_name) {
            return obj.image_name;
          }
          if (obj.img_url) {
            return obj.img_url;
          }
          return environment.story_background_url;
        }
      }
    }
}
