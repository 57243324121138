import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {QResponse, Question, Questionnaire, QuestionnaireService, QuestionOption} from "../questionnaire.service";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {Subscription} from "rxjs";
import {MemberInfoService} from "../member-info.service";
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  @Input() questionnaireRef: DocumentReference;
  @Input() admin: boolean = false;
  questionnaire: Questionnaire;

  alreadyClearing: boolean = false;
  clearingTimer: number = null;

  questionnaireSubscription: Subscription;

  constructor(private authService: AuthService, private questionnaireService: QuestionnaireService, private elementRef: ElementRef, private memberInfoService: MemberInfoService) {
  }

  ngOnInit(): void {
    this.questionnaireSubscription = this.questionnaireService.getQuestionnaire(this.questionnaireRef).subscribe((questionnaire) => {
      this.questionnaire = questionnaire;
    });
  }

  ngOnDestroy() {
    if (this.questionnaireSubscription != null) {
      this.questionnaireSubscription.unsubscribe();
    }
  }

  trackByFn(index, question: Question) {
    return question.id;
  }

  clearAnswers() {
    if (this.alreadyClearing) {
      //Dont allow clearing if already asked to clear
      return;
    }

    this.alreadyClearing = true;

    let user = this.authService.getUser();

    let promises = [];

    this.questionnaire.questions.forEach((question) => {
      promises.push(new Promise<void>((resolve) => {
        let extendedQuestion = Object.assign({questionnaireRef: this.questionnaireRef}, question);
        const answerCounterSubscription = this.questionnaireService.getQuestionAnswerCounter(question.id, this.questionnaireRef).subscribe((answerCounter) => {
          answerCounterSubscription.unsubscribe();
          const qresponseSubscription = this.memberInfoService.getQuestionResponses(extendedQuestion, user).subscribe((qresponses) => {
            qresponseSubscription.unsubscribe();

            let innerPromises = [];

            qresponses.forEach((qresponse) => {
              innerPromises.push(new Promise<void>((innerResolve) => {
                this.questionnaireService.deleteQResponse(qresponse).then(() => {

                  //Also decrease answer counter
                  let option = answerCounter.options.find((option) => {
                    return qresponse.response_value === option.value;
                  });

                  option.responses--;

                  if (option.responses < 0) {
                    option.responses = 0;
                  }

                  this.questionnaireService.updateQuestionAnswerCounter(answerCounter).then(() => {
                    innerResolve();
                  });
                });
              }));
            });

            Promise.all(innerPromises).then(()=>{
              resolve();
            });
          });
        });
      }));
    });

    Promise.all(promises).then(() => {
      this.alreadyClearing = false;
    });
  }
}
