<div class="frontpage-container">


  <!-- Page Content -->
  <div class="frontpage-column">
    <!-- We'll fill this with dummy content -->
    <div class="frontpage-inner-container">

      <div class="frontpage-column1">
        <h3 class="top_heading_label">Thank You for Registering</h3>

        <div class="formGroup" *ngIf="authService.userData as user">
          <p>You need to verify your email before continuing.</p>
          <p>We have sent a confirmation email to <strong>{{user.email}}</strong>.</p>
          <p>Please check your email and click on the link to verify your email address.</p>
          <p>If you have not received an email, please check your spam folder to see if the verification mail has been
            marked as spam.</p>
        </div>

        <br/>
        <button mat-flat-button color="primary" (click)="authService.SendVerificationMail()">Resend Verification Email
        </button>

        <!-- Calling SendVerificationMail() method using authService Api -->
        <br/><br/><br/>

        <div class="redirectToLogin">
          Go back to?
          <button mat-button color="primary" routerLink="{{'/'+env.links.login}}">Login</button>
        </div>
      </div>


    </div>
  </div>
</div>
