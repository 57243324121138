import htmlTemplate from './concept-puzzle-block.html'
import {Image, ImageUploadDialogComponent} from '../../image-upload-dialog/image-upload-dialog.component';
import {CatalogService} from '../../catalog.service';
import {StorySelectDialogComponent} from '../../story-select-dialog/story-select-dialog.component';
import * as uuid from 'uuid';
import {TranslateService} from "@ngx-translate/core";
import {EditorJSBlockTranslator} from "../EditorJSBlockTranslator";

export class ConceptPuzzleBlock {
  static get toolbox() {
    return {
      title: "Concept Puzzle",
      icon: "<span class='material-icons'>extension</span>"
    }
  }

  private readonly editorHtml: HTMLElement;
  private template;
  private catalogService: CatalogService;
  private translate: TranslateService;

  private id: string;

  constructor({api, config, data}) {
    this.catalogService = injector.get(CatalogService);

    this.template = document.createElement('template');
    this.template.innerHTML = EditorJSBlockTranslator.translateHtmlTemplate(htmlTemplate);

    this.editorHtml = this.template.content.querySelector(".concept-puzzle-block");

    this.id = uuid.v4();

    try {
      if (data != null) {
        this.load(data);
      }

      this.editorHtml.querySelector(".addEmpty").addEventListener("click", () => {
        this.addPiece();
      });
      this.editorHtml.querySelector(".addStory").addEventListener("click", () => {
        StorySelectDialogComponent.showDialog(config.projectId).subscribe((story)=>{
          if(story != null) {
            let pieceElm = this.addPiece();

            this.loadPiece(pieceElm, {
              title: story.name,
              description: story.tagline,
              image: story.image,
              color: "#FFFFFF",
              weight: 0
            });
          }
        });
      });
    } catch(e) {
      console.error(e);
    }
  }

  load(data) {
    if(data.title != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.puzzleTitle").value = data.title;
    }
    if(data.description != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.puzzleHelp").value = data.description;
    }
    if(data.caption != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.puzzleCaption").value = data.caption;
    }
    if(data.maxWeight != null) {
      this.editorHtml.querySelector<HTMLInputElement>("input.maxWeight").value = data.maxWeight;
    }

    if(data.pieces != null) {
      data.pieces.forEach((piece)=>{
        let pieceElm = this.addPiece();

        this.loadPiece(pieceElm, piece);
      });
    }

    if(data.id != null) {
      this.id = data.id;
    }
  }

  loadPiece(pieceElm, piece) {
    if(piece.title != null) {
      pieceElm.querySelector("input.pieceTitle").value = piece.title;
    }
    if(piece.description != null) {
      pieceElm.querySelector("input.pieceDescription").value = piece.description;
    }
    if(piece.weight != null) {
      pieceElm.querySelector("input.pieceWeight").value = piece.weight;
    }
    if(piece.color != null) {
      pieceElm.querySelector("input.pieceColor").value = piece.color;
    }
    if(piece.image != null && piece.image.url != null) {
      this.setPieceImage(pieceElm, piece.image);
    }
  }

  addPiece() {
    const pieceElm = this.template.content.querySelector(".puzzle-piece").cloneNode(true);

    this.editorHtml.querySelector(".pieces").appendChild(pieceElm);

    pieceElm.querySelector(".delete").addEventListener("click", ()=>{
      pieceElm.remove();
    });

    pieceElm.querySelector(".addImage").addEventListener("click", ()=>{
      ImageUploadDialogComponent.showUploadDialog().subscribe((image)=>{
        if(image != null) {
          this.setPieceImage(pieceElm, image);
        }
      });
    });

    return pieceElm;
  }

  setPieceImage(pieceElm: HTMLElement, image: Image) {
    let img = document.createElement("img");

    img.src = image.url.small.replace(/&amp;/g, "&");

    // @ts-ignore
    img.imgData = image;

    let imageDiv = pieceElm.querySelector(".image");
    let oldImg = imageDiv.querySelector("img");
    if (oldImg){
        oldImg.remove();
    }
    imageDiv.appendChild(img);
  }

  render() {
    return this.editorHtml;
  }

  save(blockContent) {
    const title = blockContent.querySelector("input.puzzleTitle").value;
    const description = blockContent.querySelector("input.puzzleHelp").value;
    const caption = blockContent.querySelector("input.puzzleCaption").value;
    const maxWeight = parseInt(blockContent.querySelector("input.maxWeight").value);

    const pieces = [];

    blockContent.querySelectorAll(".pieces .puzzle-piece").forEach((pieceElm)=>{
      const img = pieceElm.querySelector(".image img");
      let image = null;

      if(img != null) {
        image = img.imgData;
      }

      pieces.push({
        title: pieceElm.querySelector("input.pieceTitle").value,
        description: pieceElm.querySelector("input.pieceDescription").value,
        weight: parseInt(pieceElm.querySelector("input.pieceWeight").value),
        color: pieceElm.querySelector("input.pieceColor").value,
        image: image
      });
    });

    return {
      title: title,
      description: description,
      caption: caption,
      maxWeight: maxWeight,
      pieces: pieces,
      id: this.id
    }
  }
}
