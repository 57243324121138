<div class="container-fluid page-max-width">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="format-one-container">
                <div class="format-one-column">
                    <app-catalog-menu></app-catalog-menu>
                    <div class="format-column1-content">
                        <div *ngIf="model.process_service.process && model.process_service.process.workshops">
                            <div class="heading2" translate>Promoted workshops</div>
                            <ul class="workshop-list">
                                <span class="workshop" *ngFor="let workshop of model.process_service.process.workshops; let i = index">

                                    <li *ngIf="workshop.status==wsType.promoted">
                                        <a [routerLink]="'/'+env.links.workshop+'/'+model.project.id+'/'+workshop.id">
                                            <div class="workshop-content">
                                                <div class="workshop-title">{{workshop.title}}</div>
                                                <div class="workshop-tagline"> {{workshop.summary}}</div>
                                            </div>
                                            <div class="workshop-join">{{'Visit'|translate}}</div>
                                        </a>
                                        <div class="workshop-menu">
                                            <button *ngIf="this.model.adminRole()" mat-icon-button [matMenuTriggerFor]="workshop_menu"
                                                    [matMenuTriggerData]="{id : workshop.id, nr: i}" aria-label="Workshop menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                        </div>
                                    </li>
                                </span>
                            </ul>

                            <div class="heading2" translate>Previous workshops</div>
                            <ul class="workshop-list">
                                <span class="workshop" *ngFor="let workshop of model.process_service.process.workshops; let i = index">

                                    <li *ngIf="workshop.status==wsType.active">
                                        <a [routerLink]="'/'+env.links.workshop+'/'+model.project.id+'/'+workshop.id">
                                            <div class="workshop-content">
                                                <div class="workshop-title">{{workshop.title}}</div>
                                                <div class="workshop-tagline"> {{workshop.summary}}</div>
                                            </div>
                                            <div class="workshop-join">{{'Visit'|translate}}</div>
                                        </a>
                                        <div class="workshop-menu">
                                            <button *ngIf="this.model.adminRole()" mat-icon-button [matMenuTriggerFor]="workshop_menu"
                                                    [matMenuTriggerData]="{id : workshop.id, nr: i}" aria-label="Workshop menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                        </div>
                                    </li>
                                </span>
                            </ul>

                            <div class="heading2" *ngIf="this.model.adminRole()"translate>Planned or hidden workshops</div>
                            <ul class="workshop-list" *ngIf="this.model.adminRole()">
                                <span class="workshop" *ngFor="let workshop of model.process_service.process.workshops; let i = index">

                                    <li *ngIf="workshop.status==wsType.hidden">
                                        <a [routerLink]="'/'+env.links.workshop+'/'+model.project.id+'/'+workshop.id">
                                            <div class="workshop-content">
                                                <div class="workshop-title">{{workshop.title}}</div>
                                                <div class="workshop-tagline"> {{workshop.summary}}</div>
                                            </div>
                                            <div class="workshop-join">{{'Visit'|translate}}</div>
                                        </a>
                                        <div class="workshop-menu">
                                            <button *ngIf="this.model.adminRole()" mat-icon-button [matMenuTriggerFor]="workshop_menu"
                                                    [matMenuTriggerData]="{id : workshop.id, nr: i}" aria-label="Workshop menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                        </div>
                                    </li>
                                </span>
                            </ul>
                        </div>

                        <div>
                            <button *ngIf="this.model.adminRole() && !this.model.process_service.process" mat-button color="primary" (click)="createProcess()">{{'Create Process'|translate}}</button>
                        </div>
                        <div>
                            <button *ngIf="this.model.adminRole() && this.model.process_service.process" mat-button color="primary" (click)="createWorkshop()">{{'New Workshop'|translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #image_header>
    <div *ngIf="model.project" class="outer-image-top-small" (click)="model.adminRole() && showUploadImageDialog()">
        <div *ngIf="getImage(model.project)" class="top-image-cover-div inner-image">
            <img [src]="getImage(model.project)" class="top-image-cover">
            <div class="image-inline-text">
                <div class="title" translate>Workshops</div>
            </div>
        </div>
        <div *ngIf="!getImage(model.project)" class="top-image-cover-div inner-image">
            <img src="./assets/img/picback.png" class="top-image-cover">
            <div class="image-inline-text">
                <div class="title" translate>Workshops</div>
            </div>
        </div>
    </div>
</ng-template>

<mat-menu #workshop_menu="matMenu">
    <ng-template matMenuContent let-id="id" let-nr="nr">
        <button mat-menu-item [matMenuTriggerFor]="changeVisibilityMenu" [matMenuTriggerData]="{nr : nr}">
            <mat-icon>visibility</mat-icon>
            <span translate>Change visibility</span>
        </button>

        <button *ngIf="nr>0" mat-menu-item (click)="moveWorkshopUp(nr)">
            <mat-icon>north</mat-icon>
            <span>Move up</span>
        </button>
        <button *ngIf="model.process_service.process.workshops.length-1>nr" mat-menu-item (click)="moveWorkshopDown(nr)">
            <mat-icon>south</mat-icon>
            <span translate>Move down</span>
        </button>

        <button mat-menu-item (click)="deleteWorkshop(id)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #changeVisibilityMenu="matMenu">
    <ng-template matMenuContent let-nr="nr">
        <button mat-menu-item (click)="changeVisibility(nr, wsType.promoted)">
            <mat-icon>thumb_up</mat-icon>
            <span translate>Promoted</span>
        </button>

        <button mat-menu-item (click)="changeVisibility(nr, wsType.active)">
            <mat-icon>visibility</mat-icon>
            <span translate>Active</span>
        </button>

        <button mat-menu-item (click)="changeVisibility(nr, wsType.hidden)">
            <mat-icon>visibility_off</mat-icon>
            <span translate>Hidden</span>
        </button>
    </ng-template>
</mat-menu>