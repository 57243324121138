<div class="splashblock">
    <div class="splashblock-splash">
        <b>ideate</b>.nu
    </div>
    <form class="splashblock-content">
        <h1 translate>Login</h1>

        <mat-form-field>
            <mat-label>{{'Email'|translate}}</mat-label>
            <input matInput type="email" matTooltip="{{'Email'|translate}}" #userEmail required placeholder="{{'Enter email'|translate}}">
        </mat-form-field>        
        
        <mat-form-field>
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput type="password" matTooltip="{{'Password'|translate}}" required placeholder="{{'Password'|translate}}" #userPwd required>
        </mat-form-field>
        
        <div class="onboarding-options">
            <button type="submit" mat-flat-button color="primary" (click)="authService.SignIn(userEmail.value, userPwd.value)">{{'Login'|translate}}</button>            <button mat-raised-button (click)="authService.GoogleAuth()">{{'Log in with Google'|translate}}</button>
            <!-- Disabled as facebook app is not completely set up yet.
            <button mat-raised-button (click)="authService.FacebookAuth()">{{'Log in with Facebook'|translate}}</button>-->
            <a href routerLink="/forgot-password">{{'Forgot Password?'|translate}}</a>
            <span>{{'Don\'t have an account?'|translate}}<a class="redirect" routerLink="/register"> {{'Sign Up'|translate}}</a></span>
        </div>
    </form>
</div>