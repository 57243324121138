import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../service/auth.service";
import {Comment} from "./commenter/commenter.component";
import {Observable} from "rxjs";
import {Concept} from "../model/concept.model";
import {ConceptService} from "../service/concept.service";
import {KblockService} from "../service/kblock.service";
import {Kblock} from "../model/kblock.model";

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private firestore : AngularFirestore, private authService : AuthService, private conceptService : ConceptService, private kblockService : KblockService) {
  }

  getCommentsFromOwningEntity(entity : Concept | Kblock) : Observable<Comment[]>{
    let ref = null;

    if((entity as Concept).story_rating != null) {
      ref = this.conceptService.getReference(entity as Concept);
    } else {
      ref = this.kblockService.getkBlockReference(entity as Kblock);
    }

    return this.firestore.collection<Comment>("comments", (query)=>{
      return query.where("owningReference", "==", ref)
    }).valueChanges({
      idField: "id"
    });
  }

  saveComment(comment : Comment) {
    let commentClone = Object.assign({}, comment);
    delete commentClone.id;
    delete commentClone.currentlyEditing;

    return this.firestore.collection<Comment>("comments").add(commentClone);
  }

  updateComment(comment : Comment) {
    let commentClone = Object.assign({}, comment);
    delete commentClone.id;
    delete commentClone.currentlyEditing;

    this.firestore.collection("comments").doc<Comment>(comment.id).update(commentClone);
  }

  deleteComment(comment : Comment) {
    this.firestore.collection("comments").doc<Comment>(comment.id).delete();
  }
}
