<ng-container *ngIf="model.project && shouldShow()">
    <div class="center catalog-menu">

        <div class="menu-top" (click)="model.adminRole() && showUploadImageDialog()">
            <div *ngIf="getImage()" class="top-image-cover-div inner-image">
                <img [src]="getImage()" class="top-image-cover">
                <div class="top-image-cover-overlay" *ngIf="!isMobile"></div>
                <div class="image-inline-text">


                    <div class="menu-content" (click)="$event.stopPropagation()" *ngIf="!isMobile">
                        <div class="menu-left">
                            
                            <a *ngIf="model.project && model.isProjectMember()" class="catalog-title" href="javascript:void(null);"
                                                  [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id">{{model.project.name}}
                            </a>
                            <a *ngIf="model.project && !model.isProjectMember()" class="catalog-title" href="javascript:void(null);"
                                                  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id">{{model.project.name}}
                            </a>




                        </div>
                        <div class="menu-right">




                            <div *ngIf="env.links.viewProject != active_page" class="catalog-item">
                                
                                <a class="catalog-link" href="javascript:void(null);"
                                [routerLink]="'/'+env.links.viewProject+'/'+model.project.id"
                                (click)="$event.stopPropagation()">
                          
                                <mat-icon>panorama</mat-icon>  
                                {{'catalog-menu.overview' | translate }}</a>
                            </div>


                            <div *ngIf="env.links.viewProject == active_page "
                                class="catalog-item catalog-item-selected">
                                <mat-icon>panorama</mat-icon>
                                {{'catalog-menu.overview' | translate }}
                            </div>


                            <div class="catalog-item" *ngIf="env.links.viewCatalog != active_page">
                                <a href="javascript:void(null);" class="catalog-link" 
                                   [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id">
                                   <mat-icon>view_module</mat-icon>  
                                   {{'catalog-menu.feed' | translate }}</a>
                              <!--     <a href="javascript:void(null);" class="catalog-link" *ngIf="model.displayView"
                                   [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id" translate>Dashboard</a>-->
                            </div>



                            <div *ngIf="env.links.viewCatalog == active_page" class="catalog-item catalog-item-selected"
                                 translate>
                                 <mat-icon>view_module</mat-icon>  
                                 {{'catalog-menu.feed' | translate }}
                            </div>


                            <div *ngIf="env.links.sharing != active_page && model.adminRole()" class="catalog-item">
                                <a class="catalog-link" href="javascript:void(null);"
                                   [routerLink]="'/'+env.links.sharing+'/'+model.project.id"
                                   (click)="$event.stopPropagation()">
                                    <mat-icon>share</mat-icon>  
                                    {{'catalog-menu.sharing' | translate }}</a>
                            </div>


                            <div *ngIf="env.links.sharing == active_page && model.adminRole()"
                                 class="catalog-item catalog-item-selected" translate>
                                 <mat-icon>share</mat-icon>  
                                 {{'catalog-menu.sharing' | translate }}
                            </div>


                            <!--       <div *ngIf="env.links.inspirationGridView != active_page && model.displayView" class="catalog-item">
                                <a class="catalog-link" href="javascript:void(null);"
                                   [routerLink]="'/'+env.links.inspirationGridView+'/'+model.project.id"
                                   (click)="$event.stopPropagation()" translate>Inspiration</a>
                            </div>


                            <div *ngIf="env.links.inspirationGridView == active_page && model.displayView"
                                 class="catalog-item catalog-item-selected" translate>
                                 Inspiration
                            </div>-->



                         


                            <div class="catalog-item" *ngIf="env.links.viewProjectSetting != active_page && model.adminRole()">
                                <a class="catalog-link" href="javascript:void(null);"
                                [routerLink]="'/'+env.links.viewProjectSetting+'/'+model.project.id"
                                   (click)="$event.stopPropagation()">  
                                   <mat-icon>settings</mat-icon>  
                                    {{'catalog-menu.settings' | translate }}</a>
                            </div>

                            <div *ngIf="env.links.viewProjectSetting == active_page && model.adminRole()"
                                 class="catalog-item  catalog-item-selected">
                                 <mat-icon>settings</mat-icon>  
                                 {{'catalog-menu.settings' | translate }}
                            </div>


                          <!--    <div class="catalog-item catalog-link">
                                <mat-slide-toggle [(ngModel)]="this.model.displayView" *ngIf="model.adminRole()" (change)="onEditModeToggle($event)" translate>Presentation</mat-slide-toggle>
                            </div> -->



                        <div class="avatar-div"  *ngIf="user">
                            <a href="javascript:void(null);" [matMenuTriggerFor]="profile_menu" >
                                <ng-template #noProfileImage>
                                    <span class="avatar-div"><mat-icon class="avatar person">person</mat-icon> </span>
                                </ng-template>
                                  
                             <span *ngIf="hasPhoto(); else noProfileImage">
                                    <img class="avatar" [src]='getPhoto()'/>
                                </span>
                             
                            </a>
                        </div>

                        <div class="catalog-item" *ngIf="!user">
                            <a class="catalog-link" href="javascript:void(null);"
                            [routerLink]="'/'+env.links.login"
                               (click)="$event.stopPropagation()" translate>Register / Log-in</a>
                        </div>

                            <!--     <a  *ngIf="model.project" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id">Participant</a>  -->
                            <div><a *ngIf="model.project" href="javascript:void(null);" class="menu-icon"
                                    [matMenuTriggerFor]="header_menu" aria-label="Header menu"
                                    (click)="$event.stopPropagation()">
                                <mat-icon>more_horiz</mat-icon>
                            </a></div>

                        </div>
                    </div>




                    <div class="title">
                        <div (click)="model.adminRole() && editTitle(); $event.stopPropagation()">{{getTitle()}}</div>
                        <div *ngIf="this.model.adminRole() && showEditTitleIcon()" class="edit-title-mouse">
                            <mat-icon>edit</mat-icon>
                        </div>
                    </div>
                    <div class="tagline">
                        <div (click)="model.adminRole() && editTagline(); $event.stopPropagation()">{{getTagline()}}</div>
                        <div *ngIf="this.model.adminRole() && showEditTaglineIcon()" class="edit-tagline-mouse">
                            <mat-icon>edit</mat-icon>
                        </div>
                    </div>


                </div>


            </div>


            <!--<div *ngIf="!getImage(model.project)" class="top-image-cover-div inner-image">
              <img src="./assets/img/picback.png" class="top-image-cover">
              <div class="image-inline-text">
                <div *ngIf="model.project.catalog_subtitle" class="title">{{model.project.catalog_subtitle}} </div>
                <div *ngIf="!model.project.catalog_subtitle || model.project.catalog_subtitle==''" class="title" translate>Stories</div>
              </div>
            </div>-->
        </div>


    </div>
</ng-container>


<!--
<ng-container *ngIf="model.project">
<div class="center catalog-menu">
    <div class="menu-content" >
       <div class="menu-left"><a  *ngIf="model.project" class="catalog-title" href="javascript:void(null);"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id">{{model.project.name}}</a>


        
        
        </div>
       <div class="menu-right">  
               <a  *ngIf="env.links.viewProject != active_page" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id" translate>Home</a>  
               <span  *ngIf="env.links.viewProject == active_page" class="catalog-item catalog-item-selected"  translate>Home</span>  



        <a  *ngIf="env.links.viewCatalog != active_page" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id">
        
          <span *ngIf="model.project.catalog_subtitle">{{model.project.catalog_subtitle}} </span>
          <span *ngIf="!model.project.catalog_subtitle || model.project.catalog_subtitle==''" translate>Stories</span>
  </a>  


        
    <span *ngIf="env.links.viewCatalog == active_page && model.project.catalog_subtitle && model.project.catalog_subtitle!=''" class="catalog-item catalog-item-selected" >{{model.project.catalog_subtitle}} </span>
    <span *ngIf="env.links.viewCatalog == active_page && (!model.project.catalog_subtitle || model.project.catalog_subtitle=='')" class="catalog-item catalog-item-selected"  translate>Stories</span>



        <a  *ngIf="env.links.processOverview != active_page" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.processOverview+'/'+model.project.id" translate>Workshops</a>  
        <span  *ngIf="env.links.processOverview == active_page" class="catalog-item catalog-item-selected"  translate>Workshops</span>  


        <a  *ngIf="env.links.inspirationGridView != active_page" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.inspirationGridView+'/'+model.project.id" translate>Inspiration</a>  
        <span  *ngIf="env.links.inspirationGridView == active_page" class="catalog-item catalog-item-selected"  translate>Inspiration</span>  

         <a *ngIf="model.project" href="javascript:void(null);" class="menu-icon" [matMenuTriggerFor]="header_menu" aria-label="Header menu"><mat-icon>menu</mat-icon> </a>  </div>
</div>

</div>
</ng-container>-->


<mat-menu #header_menu="matMenu">
    <ng-template matMenuContent>

        <button mat-menu-item [routerLink]="'/'+env.links.participants+'/'+model.project.id">
            <mat-icon>person</mat-icon>
            <span translate>{{"Participants"|translate}}</span>
        </button>

        <button *ngIf="model.isProjectMember()" mat-menu-item
                [routerLink]="'/'+env.links.projectMemberInfo+'/'+model.project.id">
            <mat-icon>person</mat-icon>
            <span translate>{{"Member Info"|translate}}</span>
        </button>

        <button *ngIf="this.model.adminRole()" mat-menu-item [routerLink]="'/'+env.links.processOverview+'/'+model.project.id">
            <mat-icon>share</mat-icon>
            <span translate>Workshops (beta)</span>
        </button>  



     
                          <!--       <button *ngIf="this.model.adminRole()" mat-menu-item
        [routerLink]="'/'+env.links.inspirationGridView+'/'+model.project.id">
    <mat-icon>settings</mat-icon>
    <span translate>Inspiration</span>
</button> -->

<button mat-menu-item [matMenuTriggerFor]="language_menu" aria-label="Language Menu" translate>
    <mat-icon>language</mat-icon> <span translate>Switch language</span>
</button>


        <button *ngIf="this.model.trueAdminRole()" mat-menu-item (click)="model.browse_as_user=!model.browse_as_user">
            <mat-icon>preview</mat-icon>
            <span *ngIf="!model.browse_as_user" translate>Browse as participants</span><span
                *ngIf="model.browse_as_user " translate>Switch to admin</span>
        </button>


    </ng-template>
</mat-menu>


<mat-menu #language_menu="matMenu">
    <button mat-menu-item (click)="languageService.useLanguage('en')" translate>English</button>
    <button mat-menu-item (click)="languageService.useLanguage('da')" translate>Dansk</button>
</mat-menu>
<mat-menu #profile_menu="matMenu">
    <button mat-menu-item routerLink="/user-data">
        <mat-icon>edit</mat-icon> <span translate>Edit user</span>
    </button>



    <button mat-menu-item (click)="authService.SignOut()">
        <mat-icon>logout</mat-icon> <span translate>Log out</span>
    </button>
</mat-menu>  