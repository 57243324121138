import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../../model/user.model";
import {Kblock} from "../../model/kblock.model";
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'filterHiddenKblock'
})
export class FilterHiddenKblockPipe implements PipeTransform {

  env = environment;

  transform(kblock: Kblock, user:User, currentProjectId: string): boolean {
    //If no user, anonym access, they have not choosen to hide anything
    if(user == null) {
      return true;
    }

    //Check every project reference, if they are all hidden projects for this user, skip this block
    let projectReferences = kblock.references?.length;

    if (projectReferences == null) {
      projectReferences = 0;
    }

    let hiddenProjects = user.hiddenProjects;
    if(hiddenProjects == null) {
      hiddenProjects = [];
    }

    hiddenProjects = hiddenProjects.filter((projectRef)=>{
      return projectRef.id !== currentProjectId;
    });

    let hiddenProjectReferences = kblock.references?.filter((reference) => {
      return hiddenProjects?.find((hiddenProjectRef) => {
        if(reference.project != null) {
          return hiddenProjectRef.id === reference.project.id;
        } else {
          return hiddenProjectRef.id === reference.ref.id;
        }
      }) != null;
    }).length;

    if (hiddenProjectReferences == null) {
      hiddenProjectReferences = 0;
    }

    if (hiddenProjectReferences > 0 && hiddenProjectReferences === projectReferences) {
      return false;
    }

    return true;
  }
}
