import { Component, OnInit } from '@angular/core';
import {CurrentPage, ModelService} from "../../service/model.service";
import {AuthService} from "../../service/auth.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import { Favor } from 'src/app/model/favor.model';
import {ActivePageServiceService} from "../../frontend/active-page-service.service";
import {MemberInfoService} from "../../frontend/member-info.service";

@Component({
  selector: 'app-top-menu-mobile',
  templateUrl: './top-menu-mobile.component.html',
  styleUrls: ['./top-menu-mobile.component.scss']
})
export class TopMenuMobileComponent implements OnInit {
  env = environment;
  currentPage = CurrentPage;

  hasMemberInfo:boolean = false;

  constructor(public model : ModelService, public authService : AuthService, public activePageService : ActivePageServiceService, private memberInfoService:MemberInfoService) {
  }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
    this.memberInfoService.getQuestions(this.model.project_id).subscribe((questions)=> {
      this.hasMemberInfo = questions.length > 0;
    });
  }

}
