import {Component, OnInit, Output, EventEmitter, ElementRef} from '@angular/core';
import {Project} from 'src/app/model/project.model';
import {ProjectService} from 'src/app/service/project.service';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/service/auth.service';
import {User} from 'src/app/model/user.model';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Concept} from 'src/app/model/concept.model';
import {ConceptService} from 'src/app/service/concept.service';
import {CurrentPage, ModelService} from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';
import {CaviDroppableHTML5} from '../../frontend/CaviDraggable';
import {Catalog, CatalogService} from "../../frontend/catalog.service";
import {NewProjectComponent} from '../new-project/new-project.component';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  host: {'class': 'flexblock'}

})
export class ProjectOverviewComponent implements OnInit {
  env = environment;

  constructor(public catalogService: CatalogService, public model: ModelService, private projectService: ProjectService, private concept_service: ConceptService, public authService: AuthService, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.model.current_page = CurrentPage.dashboard;

    if (this.authService.getUser()) {
      this.model.subscribeProjects();
    }

    let observer = new MutationObserver((mutations)=>{
      mutations.forEach((mutation)=>{
        if(mutation.target instanceof Element && mutation.target.matches(".droppable")) {
          this.setupDrop(mutation.target);
        } else if(mutation.target instanceof Element) {
          mutation.target.querySelectorAll(".droppable").forEach((droppable)=>{
            this.setupDrop(droppable);
          });
        }
      });
    });

    observer.observe(this.elementRef.nativeElement, {
      subtree: true,
      childList: true
    });

    this.elementRef.nativeElement.querySelectorAll(".droppable").forEach((project)=>{
      this.setupDrop(project);
    });

  }

  getFavoredProjects(favored:boolean) {
    if(this.model.projects != null) {
      if (favored) {
        return this.model.projects.filter(element => (this.model.getProjectFavored(element)==true)&&!this.model.isHiddenForUser(element));
      } else {
        return this.model.projects.filter(element => (this.model.getProjectFavored(element)==false)&&!this.model.isHiddenForUser(element));
      }
    }
  }
  
  getArchivedProjects() {
    if(this.model.projects != null) {
        return this.model.projects.filter(element => this.model.isHiddenForUser(element)==true)
    } else {
      return null;
    } 

  }  
 
  setProjectFavored(catalog : Project, value : boolean) : boolean {
    this.model.setProjectFavored(catalog,value);
    return false;
  }

  getImage(catalog:Project): string {
    if(catalog.image != null) {
      return catalog.image.url.small;
    } else {
      if (catalog.img_url)
         return catalog.img_url;
      else
        return this.env.story_background_url;
    }
  }

  setupDrop(projectElm): void {
    if(projectElm.caviDroppable == null) {
      new CaviDroppableHTML5(projectElm, {
        onDrop: (evt)=>{
          if(evt.dataTransfer.types.includes('application/x-editor-js')) {
            let kblockId = JSON.parse(evt.dataTransfer.getData('application/x-editor-js'))[0].data.id;
            let projectId = projectElm.getAttribute("data-catalog-id");
            const kblock = this.model.getLocalkBlockVersion(kblockId);
            if (kblock==null) return;

            let storySubscription = this.projectService.getProject(projectId).subscribe((project)=>{
              project.id = projectId;
              this.model.connectProjectKblock(kblock, project);
              storySubscription.unsubscribe();
            });
          }
        },
        onDragOver: (evt)=>{
          evt.dataTransfer.dropEffect = "copy";
        }
      });
    }
  }

  createProject(favourite=false) {
    NewProjectComponent.showDialog().pipe(first()).toPromise().then((project)=>{
      if(project != null) {
        this.setProjectFavored(project, favourite);
      }
    });
  }
}
