import {Component, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-puzzle-comment-dialog',
  templateUrl: './puzzle-comment-dialog.component.html',
  styleUrls: ['./puzzle-comment-dialog.component.css']
})
export class PuzzleCommentDialogComponent {

  @ViewChild("commentArea") commentArea;

  constructor(public dialogRef: MatDialogRef<PuzzleCommentDialogComponent>) { }
}
