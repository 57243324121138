

<div class="editorWrapper">
    <div #editorjs></div>
    <div class="floaty-detector"></div>
    <div class="floaty">
        <div class="minimal">
            <button title="{{saveText}}" mat-icon-button color="primary" (click)="save()">
                <span class="mat-button-wrapper">
                    <mat-icon>save</mat-icon>
                </span>          
            </button>
            <button title="{{cancelText}}" mat-icon-button color="primary" (click)="cancel()">
                <span class="mat-button-wrapper">
                    <mat-icon>cancel</mat-icon>
                </span>          
            </button>      
        </div>
        <div class="normal">
              <button mat-raised-button color="primary" (click)="save()">{{saveText}}</button>
              <button mat-button color="primary" (click)="cancel()">{{cancelText}}</button>      
        </div>
    </div>
  </div>