
import {Component, OnInit, Inject, ElementRef, AfterViewInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from 'src/app/service/project.service';
import {ConceptService} from 'src/app/service/concept.service';
import {environment} from 'src/environments/environment';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModelService, CurrentPage} from 'src/app/service/model.service';
import {Kblock, kBlockType} from 'src/app/model/kblock.model';
import {AuthService} from 'src/app/service/auth.service';
import {ImageUploadDialogComponent} from '../../frontend/image-upload-dialog/image-upload-dialog.component';
import {ThumbnailService} from '../../frontend/thumbnail.service';
import {CaviDroppableHTML5} from "../../frontend/CaviDraggable";
import {KblockService} from "../../service/kblock.service";
import { EditorJsComponent } from 'src/app/frontend/editor-js/editor-js.component';
import { Favor, FavorType } from 'src/app/model/favor.model';
import { Concept } from 'src/app/model/concept.model';
import { EditFavorComponent } from 'src/app/favors/edit-favor/edit-favor.component';
import { ProjectSection } from 'src/app/model/project.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Process, WorkshopStatusType } from 'src/app/model/process.model';
import { CreateKblocksComponent } from 'src/app/kblocks/create-kblocks/create-kblocks.component';
import { CreateConceptComponent } from 'src/app/concepts/create-concept/create-concept.component';

@Component({
  selector: 'app-catalog-dashboard',
  templateUrl: './catalog-dashboard.component.html',
  styleUrls: ['./catalog-dashboard.component.scss'],
  host: {'class': 'flexblock'}
})
export class CatalogDashboardComponent implements  OnInit, AfterViewInit {

  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;

  edit_project_heading: boolean = false;
  edit_catalog_subheading : boolean = false;

  current_id: string;
  env = environment;
  ws_type = WorkshopStatusType;
  isMobile : boolean = false;
  ktype = kBlockType;

  constructor(public authService: AuthService, public model: ModelService, private route: ActivatedRoute, private projectService: ProjectService, private conceptService: ConceptService, private router: Router, public dialog: MatDialog, private thumbnailService: ThumbnailService, private elementRef: ElementRef, private kblockService: KblockService,
     private _snackBar: MatSnackBar) {

    //Setup mobile check
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 991;
    }
    this.isMobile = window.innerWidth <= 991;

    router.events.subscribe((val) => {
        if (this.route.snapshot.paramMap.get('id') != this.current_id && authService.getUser()) {
          this.setProject();
        }
      }
    )

    this.model.subscribeProjects();
    if (this.model && model.displayView) 
    {
      console.group("Navigate to catalog");
      //router.navigate(['/' + environment.links.viewCatalog + '/' + this.model.project.id]);
    }
    console.group("MissedNavigate to catalog: " + model.displayView);

  }
  
  setProject() {
    this.current_id = this.route.snapshot.paramMap.get('id');
    this.model.setSelectedProject(this.current_id);
    this.model.subscribekBlocks(this.projectService.getProjectReferenceById(this.current_id), kBlockType.Project);
    this.model.current_page = CurrentPage.catalog_overview;
  }

  ngAfterViewInit() {
    let observer = new MutationObserver((mutations)=>{
      mutations.forEach((mutation)=>{
        if(mutation.target instanceof Element && mutation.target.matches(".droppable")) {
          this.setupDrop(mutation.target);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement, {
      subtree: true,
      childList: true
    });

    this.elementRef.nativeElement.querySelectorAll(".droppable").forEach((story)=>{
      this.setupDrop(story);
    });
  }

  setupDrop(story): void {
    if(story.caviDroppable == null) {
      new CaviDroppableHTML5(story, {
        onDrop: (evt)=>{
          console.log("EVT: "+ evt);
          if(evt.dataTransfer.types.includes('application/x-editor-js')) {
            let kblockId = JSON.parse(evt.dataTransfer.getData('application/x-editor-js'))[0].data.id;
            let storyId = story.getAttribute("data-story-id");

            // Get the local version of the kblock since we are not listening on updates due to pagination.
            const kblock = this.model.getLocalkBlockVersion(kblockId);
            if (kblock==null) return;

            //let kblockSubscription = this.kblockService.getkBlock(kblockId).subscribe((kblock)=>{
              let storySubscription = this.conceptService.getConcept(storyId).subscribe((story)=>{
                //kblock.id = kblockId;
                story.id = storyId;
                this.model.connectConceptKblock(kblock, story);
              //  kblockSubscription.unsubscribe();
                storySubscription.unsubscribe();
              });
            //});
          }
        },
        onDragOver: (evt)=>{
          evt.dataTransfer.dropEffect = "copy";
        }
      });
    }
  }

  ngOnInit(): void {
    this.setProject();
    this.model.resetConcept();
  }




  enterEditmode() {
    this.edit_project_heading=true;
    this.edit_catalog_subheading = false;

    setTimeout(()=>{
      this.editorjs.saved.subscribe((result)=>{
        this.model.project.content = result;
        this.updateProject();

      });
      this.editorjs.canceled.subscribe(()=>{
        this.edit_project_heading=false;
      });
    }, 0);

  }

  enterCatalogEditMode() {
    this.edit_project_heading=false;
    this.edit_catalog_subheading = true;
    setTimeout(()=>{
      this.editorjs.saved.subscribe((result)=>{
        this.model.project.introduction={};
        this.model.project.introduction.content = result;
        this.updateProject();

      });
      this.editorjs.canceled.subscribe(()=>{
        this.edit_catalog_subheading=false;
      });
    }, 0);
  }




  updateProject() {
    this.projectService.updateProject(this.model.project);
    this.edit_project_heading = false;
    this.edit_catalog_subheading = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.projectService.updateProject(this.model.project);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  unassignedConcepts() {
    return this.model.concepts && ((this.model.concepts.length > 0 && this.model.concepts.some(x => x.section_id == null)) || this.model.concepts.length == 0);
  }

  openNewSectionDialog(): void {
    const dialogRef = this.dialog.open(NewSectionDialog, {
      width: '250px',
      panelClass: ['reinvent-popup'],
      data:  {name:"", edit:false},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.projectService.addSection(this.model.project, result.name);
    });

  };

  favorString(favor : Favor)
  {

    if (this.model.user_story_ratings && this.model.concepts && FavorType.StoryRatings==favor.type && favor.stories)
       return "("+ this.model.countFavors(favor) +" / "+favor.stories.length + ")";
    else return "";
  }

  ratingCompleted(story:Concept) {
    return this.model.user_story_ratings.some(x => x.story.id == story.id);
  }

  showUploadImageDialog() {
    ImageUploadDialogComponent.showUploadDialog().subscribe((image) => {
      if (image == null) {
        return;
      }

      //Delete old file
      if (this.model.project.img_url) {
        this.model.project.img_url = "";
        try {
          this.thumbnailService.delete(this.model.project.img_url);
        } catch (e) {
          //Ignore silently
        }
      }

      if (this.model.project.image) {
        this.thumbnailService.delete(this.model.project.image);
      }

      //Attach and save
      this.model.project.image = image;
      this.projectService.updateProject(this.model.project);
    });
  }

  getImage(obj, getSmall = false) {
    if (obj != null) {
      if (obj.image != null) {
        return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
      } else {
        if (obj.image_name) {
          return obj.image_name;
        }
        if (obj.img_url) {
          return obj.img_url;
        }
        return environment.story_background_url;
      }
    }
  }

  /** Open the project setting page */
  openSettingsPage() {
    this.router.navigate(['/' + environment.links.viewProjectSetting + '/' + this.model.project.id]);
  }

  /** Handles setting a scroll event when you reach the buttom of the page. */
  scrollHandler(e) {
 /*   if (e == 'bottom') {
      this.model.kBlocksMore();
    }*/
  }


    /** Handles setting a scroll event when you reach the buttom of the page. */
    showTab(name : string) {
      switch (name)
      {
        case 'News':
          return this.model.adminRole();

        case 'Contributors':
          return this.model.getUserRoleInProject(this.model.project) != "participant";
        case 'LongRead':
          return this.model.getUserRoleInProject(this.model.project) != "participant";
        case 'Analytics':
          return this.model.getUserRoleInProject(this.model.project) != "participant";

        case 'Settings':
          return true;
      }
    }

    createProcess() {
      let process = new Process();
      process.catalog = this.model.project.id;
      process.title = "";
      process.content = "";
      this.model.process_service.create(process);
    }


    editSection(section : ProjectSection) {
      const dialogRef = this.dialog.open(NewSectionDialog, {
        width: '250px',
        panelClass: ['reinvent-popup'],
        data: {name:section.name, edit:true},
      });

      dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        section.name = result.name;
         this.projectService.editSection(this.model.project);
        }
      });
    }

    deleteSection(section : ProjectSection) {
      let counter = 0;
      this.model.concepts.forEach((concept)=> {if (concept.section_id==section.id) counter++;});
      if (counter ==0)
      {
        this.projectService.deleteSection(this.model.project, section);
      } else {
        this._snackBar.open("Please remove all stories from this theme before deleting it!", "", {
          duration: 2000,
        })
      }

    }


     swapSection(fromIndex : number, toIndex : number) {
        this.model.project.sections = this.model.project.sections.map((val, idx) => {
        if (idx === fromIndex) return this.model.project.sections[toIndex];
        if (idx === toIndex) return this.model.project.sections[fromIndex];
        return val;
      });
  }

    moveSectionUp(id : number) {
      this.swapSection(id, id-1);
      this.updateProject();
    }

    moveSectionDown(id : number) {
      this.swapSection(id, id+1);
      this.updateProject();
    }



    createInspirationBlock() {
      const dialogRef = this.dialog.open(CreateKblocksComponent, {
        panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
        autoFocus: false
      });
    }


    openCreateStory(): void {
      CreateConceptComponent.showDialog();
    }


}

export interface DialogData {
  name: string;
  edit : boolean;
}

@Component({
  selector: 'new-section-dialog',
  templateUrl: './new-section-dialog.html'
})

export class NewSectionDialog {

  constructor(
    public dialogRef: MatDialogRef<CatalogDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
