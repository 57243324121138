import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentReference} from "@angular/fire/compat/firestore";
import {AuthService} from "./auth.service";
import {UserService} from "../frontend/user.service";
import {Observable, of} from "rxjs";

interface RatingRating {
  index: number;
  value: number;
}

export interface Rating {
  id: string;
  story: DocumentReference;
  catalog: DocumentReference;
  user: DocumentReference;
  anonymous_user: string;
  ratings: RatingRating[];
}

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(private firestore : AngularFirestore, private authService : AuthService, private userService: UserService) {

  }

  getRating(storyRef: DocumentReference, user: DocumentReference|string) : Observable<Rating[]> {
    if(typeof user === "string") {
      return this.firestore.collection<Rating>("storyRatings", (ref)=>{
        return ref.where("story", "==", storyRef).where("anonymous_user", "==", user);
      }).valueChanges({
        idField: "id"
      });
    } else {
      return this.firestore.collection<Rating>("storyRatings", (ref)=>{
        return ref.where("story", "==", storyRef).where("user", "==", user);
      }).valueChanges({
        idField: "id"
      });
    }
  }

  getRatingsCatalog(catalogRef: DocumentReference, user: DocumentReference|string)  {
    if(typeof user === "string") {
      return this.firestore.collection<Rating>("storyRatings", (ref)=>{
        return ref.where("catalog", "==", catalogRef).where("anonymous_user", "==", user);
      }).snapshotChanges();
    } else {
      return this.firestore.collection<Rating>("storyRatings", (ref)=>{
        return ref.where("catalog", "==", catalogRef).where("user", "==", user);
      }).snapshotChanges();
    }
  }

  saveRating(rating: Rating) {
    let id = rating.id;

    delete rating.id;
    if(id != null) {
      this.firestore.doc("/storyRatings/"+id).update(rating);
    } else {
      this.firestore.collection("storyRatings").add(rating);
    }
  }

  getRatingsForStory(storyId: string) : Observable<Rating[]> {
    let storyRef = this.firestore.doc("/concepts/"+storyId).ref;

    return this.firestore.collection<Rating>("storyRatings", (ref)=>{
      return ref.where("story", "==", storyRef);
    }).valueChanges({
      idField: "id"
    });
  }



}
