<ul [class.tree]="showAsTree" *ngIf="!topView">
    <li [class.concept]="isConcept(ref)" *ngFor="let ref of sortedReferences()">
        <span class="tag" [attr.tag-color]="ref.color" *ngIf="showAll || (ref | shouldShowTag:model.project_id:authService.userData)">
            <span class="kblock_ref" (click)="openReference(ref); $event.stopPropagation()">#{{ref.name}}</span>
            <ng-container *ngIf="showDelete">
                <button mat-button (click)="model.deleteReference(kblock, ref.ref); $event.stopPropagation()"><mat-icon>cancel</mat-icon></button>
            </ng-container>
        </span>
    </li>
</ul>

<div [class.tree]="showAsTree" *ngIf="topView">
    <div [class.concept]="isConcept(ref)" *ngFor="let ref of sortedReferences()">
        <div class="tag-top" [attr.tag-color]="ref.color" *ngIf="showAll || (ref | shouldShowTag:model.project_id:authService.userData)">
            <span class="kblock_ref" (click)="openReference(ref); $event.stopPropagation()">{{ref.name}}</span>
        </div>
    </div>
</div>