import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModelService} from "../../service/model.service";
import {Concept} from "../../model/concept.model";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {Image} from "../image-upload-dialog/image-upload-dialog.component";

@Component({
  selector: 'app-story-navigator-dialog',
  templateUrl: './story-navigator-dialog.component.html',
  styleUrls: ['./story-navigator-dialog.component.scss']
})
export class StoryNavigatorDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StoryNavigatorDialogComponent>, public model:ModelService) { }

  ngOnInit(): void {
  }

  getStoriesForSection(section) {
    return this.model.concepts.filter((concept)=>{
      return concept.section_id == section.id;
    }).sort((c1, c2)=>{
      return c1.name.localeCompare(c2.name);
    });
  }

  getNonSectionStories() {
    return this.model.concepts.filter((concept)=> {
      return concept.section_id == null;
    }).sort((c1, c2)=> {
      return c1.name.localeCompare(c2.name);
    })
  }

  select(concept: Concept) {
    this.dialogRef.close(concept);
  }

  static openDialog() {
    let subject = new Subject<Concept>();

    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(StoryNavigatorDialogComponent, {
      panelClass: ['reinvent-popup']
    });

    dialogRef.afterClosed().subscribe((concept)=>{
      subject.next(concept);
    });

    return subject.asObservable();
  }
}
