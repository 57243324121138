import { Component, OnInit } from '@angular/core';
import {OnboardingService} from "../../../service/onboarding.service";
import {first} from "rxjs/internal/operators/first";
import {StepInfo} from "../site-popup/StepInfo";
import {ImageUploadDialogComponent} from "../../image-upload-dialog/image-upload-dialog.component";
import {OnboardingStepEditorComponent} from "../onboarding-step-editor/onboarding-step-editor.component";

@Component({
  selector: 'app-onboarding-admin',
  templateUrl: './onboarding-admin.component.html',
  styleUrls: ['./onboarding-admin.component.scss']
})
export class OnboardingAdminComponent implements OnInit {

  steps: StepInfo[] = [];

  constructor(private onboardingService: OnboardingService) { }

  ngOnInit(): void {
    this.onboardingService.findSteps(-1).subscribe((steps)=>{
      this.steps = steps.sort((step1, step2)=>{
        return step1.timestamp - step2.timestamp;
      });
    })
  }

  editStep(step) {
    console.log("TODO: Editing step:", step);
    OnboardingStepEditorComponent.showDialog(step);
  }

  addStep() {
    OnboardingStepEditorComponent.showDialog(null);
  }

  deleteStep(step) {
    console.log("Deleting: ", step);
    this.onboardingService.deleteStep(step.id);
  }
}
