import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentPage, ModelService} from "../../service/model.service";
import {ProjectService} from "../../service/project.service";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {ConceptService} from "../../service/concept.service";
import {KblockService} from "../../service/kblock.service";
import {first} from "rxjs/internal/operators/first";
import {OverlaySpinnerService} from "../../service/overlay-spinner.service";
import {Project} from "../../model/project.model";

@Component({
  selector: 'app-member-info-page',
  templateUrl: './member-info-page.component.html',
  styleUrls: ['./member-info-page.component.css'],
  host: {'class': 'scrollblock'}
})
export class MemberInfoPageComponent implements OnInit {

  current_id : string;
  currentPage = CurrentPage;
  favored : boolean;

  constructor(public authService: AuthService, private route: ActivatedRoute, private router:Router, public model:ModelService, private projectService:ProjectService, private conceptService: ConceptService, private kblockService: KblockService, private spinnerService: OverlaySpinnerService) {
    if (this.route.snapshot.paramMap.get('id') != this.current_id) {
      this.current_id = this.route.snapshot.paramMap.get('id');
      this.model.setSelectedProject(this.current_id);
      this.model.current_page = this.currentPage.member_info;
    }

    router.events.subscribe((val) => {
      if (this.route.snapshot.paramMap.get('id') != this.current_id) {
        this.current_id = this.route.snapshot.paramMap.get('id');
        this.model.setSelectedProject(this.current_id);
        this.model.current_page = this.currentPage.member_info;
      }
    });
  }

  ngOnInit(): void {
    this.model.subscribeProjects();
    this.favored = this.model.getProjectFavored(this.model.project);

    this.authService.userLoggedIn$.subscribe(user => {
      this.current_id = this.route.snapshot.paramMap.get('id');
      this.model.setSelectedProject(this.current_id);
    });
  }

  favoredChanged(event : MatSlideToggleChange) {
    console.log(event + 'Changed: '+this.favored)
    this.model.setProjectFavored(this.model.project, this.favored);
  }

  async leaveProject() {
    this.spinnerService.show("Leaving project...");

    const project = this.model.project;

    this.model.leaveProject(project);

    this.spinnerService.hide();

    //this.router.navigate(["/"]);
  }

  isHiddenForUser() {
    return this.model.isHiddenForUser(this.model.project);
  }

  unhideProjectForUser() {
    this.model.unhideProjectForUser(this.model.project).then(()=>{
      console.log("Project un-hidden!");
    });
  }

  hideProjectForUser() {
    this.model.hideProjectForUser(this.model.project).then(()=>{
      console.log("Project hidden!");
      this.router.navigate(["/"]);
    });
  }

  isLastAdmin() {
    return this.model.isLastAdmin(this.model.project);
  }
}
