import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MemberInfoService} from "../member-info.service";
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-member-info-service-test',
  templateUrl: './member-info-service-test.component.html',
  styleUrls: ['./member-info-service-test.component.css']
})
export class MemberInfoServiceTestComponent implements OnInit {

  constructor(private route:ActivatedRoute, private memberInfoService:MemberInfoService, private authService: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params)=>{
      const id = params.get("id");

      console.log("Got project: ", id);

      this.memberInfoService.getQuestions(id).subscribe((questions)=>{
        questions.forEach((question)=>{
          console.log("Found question: ", question);
          this.memberInfoService.getQuestionResponses(question, this.authService.getUser()).subscribe((qresponses)=>{
            console.log("QResponses for user ["+question.question+"]: ", qresponses);
          });

          this.memberInfoService.getQuestionResponses(question).subscribe((qresponses)=>{
            console.log("QResponses for all ["+question.question+"]: ", qresponses);
          });

          if(question.options != null && question.options.length > 0) {
            this.memberInfoService.getUsersWhoAnswered(question, question.options[0].name).subscribe((users) => {
              console.log("Users who answered ["+question.question+"] [" + question.options[0].name + "]: ", users);
            });
          }
        })
      });
    })
  }

}
