import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../model/user.model";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {Subscription} from "rxjs";
import {UserService} from "../user.service";
import {AuthService} from 'src/app/service/auth.service';

@Component({
  selector: 'app-user-inline-view',
  templateUrl: './user-inline-view.component.html',
  styleUrls: ['./user-inline-view.component.scss']
})
export class UserInlineViewComponent implements OnInit, OnDestroy {

  @Input() userReference : DocumentReference;

  user : User;
  userSubscription: Subscription;

  constructor(private userService : UserService, private authService : AuthService) { }

  ngOnInit(): void {
    if(this.userReference != null) {
      this.userSubscription = this.userService.getUser(this.userReference).subscribe((user)=>{
        this.user = user;
      });
    }
  }

  ngOnDestroy() {
    if(this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    }
  }

  isSelf(){
    if(this.user != null && this.authService.getUser() != null) {
      return this.user.uid == this.authService.getUser().uid;
    }

    return false;
  }

}
