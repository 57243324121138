<div *ngIf="this.model.project" class="container-fluid page-max-width">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="format-one-container">
                <div class="format-one-column">
                    <app-catalog-menu></app-catalog-menu>
                    <div class="format-column1-content">
                      <!--  <div class="top-inspiration">
                            <span class="top-text" translate>Filter</span>
                            <mat-form-field>
                                <mat-label>Stories</mat-label>
                                <mat-select [(ngModel)]="selected_story">
                                    <mat-option value="all" translate>All</mat-option>
                                    <mat-option *ngFor="let concept of model.concepts" [value]="concept">{{concept.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="top-right">
                                <button mat-fab class="add-button" (click)="createInspirationBlock()">  <mat-icon>add</mat-icon></button>
                            </div>
                        </div>-->


                        <div class="heading-div" translate>
                            <div class="heading-title" translate></div>
<div class="filter-text">

                            <mat-form-field>
                                <mat-label>Stories</mat-label>
                                <mat-select [(ngModel)]="selected_story">
                                    <mat-option value="all" translate>All</mat-option>
                                    <mat-option *ngFor="let concept of model.concepts" [value]="concept">{{concept.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                            <div class="heading-menu">                           <button (click)="createInspirationBlock()"  mat-icon-button title="{{'Create new inspiration block'|translate}}">
                                    <mat-icon>add_circle</mat-icon>
                                </button>    
                            </div>
                        </div>

                       <!-- <app-kblocks-overview *ngIf="selected_story=='all'" [type]="ktype.Project" [blocks]="18"></app-kblocks-overview>-->
                        <app-kblocks-date-overview *ngIf="selected_story=='all'"  [type]="ktype.Project" [blocks]="30"></app-kblocks-date-overview>                           
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


