import { Injectable } from '@angular/core';
import {QResponse, Question, QuestionnaireService} from "./questionnaire.service";
import {CatalogService} from "./catalog.service";
import {Observable, Subject} from "rxjs";
import {User} from "../model/user.model";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {AuthService} from "../service/auth.service";
import {UserService} from "./user.service";

export interface ExtendedQuestion extends Question {
  questionnaireRef: DocumentReference;
}

@Injectable({
  providedIn: 'root'
})
export class MemberInfoService {

  constructor(private questionnaireService: QuestionnaireService, private catalogService:CatalogService, private authService: AuthService, private userService: UserService) {}

  public getQuestions(projectId: string) : Observable<ExtendedQuestion[]> {
    console.log("Looking up questions: ", projectId);

    const subject = new Subject<ExtendedQuestion[]>();

    let questions = [];

    const catalogSubscription = this.catalogService.getCatalogFromId(projectId).subscribe((catalog)=>{
      console.log("Got catalog...");

      if(catalog != null) {
        console.log("Got NOT NULL catalog: ", catalog);
        catalogSubscription.unsubscribe();

        let promises = [];
        if(catalog.infoQuestionnaire != null && catalog.infoQuestionnaire.content != null && catalog.infoQuestionnaire.content.blocks != null) {
          catalog.infoQuestionnaire.content.blocks.forEach((block)=>{
            if(block.type === "questionnaire") {
              promises.push(new Promise<void>((resolve, reject)=>{
                const questionnaireReference = this.questionnaireService.getQuestionnaireRef(block.data.reference);
                const questionnaireSubscription = this.questionnaireService.getQuestionnaire(questionnaireReference).subscribe((questionnaire)=>{
                  if(questionnaire != null) {
                    questionnaireSubscription.unsubscribe();
                    questionnaire.questions.forEach((question)=>{
                      let extendedQuestion = Object.assign({questionnaireRef: questionnaireReference}, question);
                      questions.push(extendedQuestion);
                    });
                  }
                  resolve();
                });
              }));
            }
          });
        }
        Promise.all(promises).then(()=>{
          subject.next(questions);
        });
      }
    });

    return subject.asObservable();
  }

  public getQuestionResponses(question: ExtendedQuestion, user?: User) : Observable<QResponse[]>{
    let subject = new Subject<QResponse[]>();

    let observable : Observable<QResponse[]> = null;

    if(user != null) {
      observable = this.questionnaireService.getQResponses(question.id, question.questionnaireRef, this.authService.getUserReferenceById(user.uid));
    } else {
      observable = this.questionnaireService.getQResponses(question.id, question.questionnaireRef);
    }

    const subscription = observable.subscribe((qresponses)=>{
      subject.next(qresponses);
      subscription.unsubscribe();
    });

    return subject.asObservable();
  }

  public getUsersWhoAnswered(question: ExtendedQuestion, questionAnswer: string) : Observable<User[]>{
    let subject = new Subject<User[]>();

    this.getQuestionResponses(question).subscribe((qresponses)=>{

      let foundUsers = [];

      let promises = [];

      qresponses.forEach((qresponse)=>{
          if(qresponse.user != null && qresponse.response_name === questionAnswer) {
            promises.push(new Promise<void>((resolve, reject)=>{
              const userSubscription = this.userService.getUser(qresponse.user).subscribe((user)=>{
                userSubscription.unsubscribe();
                foundUsers.push(user);
                resolve();
              });
            }));
          }
      });

      Promise.all(promises).then(()=>{
        subject.next(foundUsers);
      });
    });

    return subject.asObservable();
  }
}
