import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {CatalogService, Story} from '../catalog.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-story-select-dialog',
  templateUrl: './story-select-dialog.component.html',
  styleUrls: ['./story-select-dialog.component.scss']
})
export class StorySelectDialogComponent implements OnInit, OnDestroy {

  selectDisabled: boolean = true;

  stories: Story[];

  projectId: string;

  subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<StorySelectDialogComponent>, private catalogService: CatalogService) { }

  ngOnInit() {
    this.subscription = this.catalogService.getStories(this.projectId).subscribe((stories)=>{
      console.log("Got stories: ", stories);
      this.stories = stories;
    });
  }

  ngOnDestroy() {
    if(this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  selectStory(story) {
    this.dialogRef.close(story);
  }

  static showDialog(projectId) :Observable<Story> {
    let subject = new Subject<Story>();

    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(StorySelectDialogComponent, {
      panelClass: 'reinvent-popup'
    });
    dialogRef.componentInstance.projectId = projectId;

    dialogRef.afterClosed().subscribe((story)=>{
      subject.next(story);
    });

    return subject.asObservable();
  }
}
