import { Component } from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import { environment } from 'src/environments/environment';

import {marker as TRANSLATE} from '@biesbjerg/ngx-translate-extract-marker';

import {LanguageService} from "./frontend/language.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ideate';

  isMobile: boolean;

  constructor(private router : Router, private languageService: LanguageService) {
    this.languageService.init();

    //Setup mobile check
    window.addEventListener("resize", ()=>{
      this.isMobile = window.innerWidth <= 991;
    });

    this.isMobile = window.innerWidth <= 991;

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          switch(event.url) {
            case "/"+environment.links.login:
            case "/"+environment.links.register:
            case "/"+environment.links.verifyEmail:
            case "/"+environment.links.forgotPassword:
              //Skip these, dont save previous url
              break;

            default:
              window.localStorage.setItem('previousUrl', event.url);
          }
        }
      });
  }
}
