
<ng-container>


    <ng-container *ngIf="!isMobile">
        <!-- <app-story-menu class="scrollblock"></app-story-menu>
          <app-left-start-menu class="scrollblock"></app-left-start-menu>
          <app-left-start-menu class="scrollblock"></app-left-start-menu>-->
          <app-story-sidebar class="scrollblock" [story_id]="model.concept.id" *ngIf="!isMobile"></app-story-sidebar>


         <div class="scrollblock">
            <app-catalog-menu class="scrollblock"  *ngIf="!isMobile"></app-catalog-menu>
     
           
             <div class="normal-page-content">
                 <ng-container *ngTemplateOutlet="intro_column"></ng-container>
     
             </div>
         </div>
     </ng-container>
     
     
     <div class="scrollblock row no-gutters" *ngIf="isMobile">
         <div class="format-one-container">
             <div class="format-one-column">
     
                 <div>
                     <ng-container *ngTemplateOutlet="story_column">
                     </ng-container>
                 </div>
             </div>
         </div>
     </div>
     
     <ng-template #intro_column>
     
     
     
             <!-- <div class="admin-section"  *ngIf="this.model.adminRole()">
                  <button mat-button color="primary" (click)="enterEditmode()">{{'Edit community introduction'|translate}}</button>
              </div>-->
     
              <div class="heading2-no" translate>Introduction                       <button mat-button color="primary" *ngIf="this.model.adminRole()"
                  (click)="enterEditmode()">{{'edit'|translate}}</button> </div>
              <div>
                  <div [ngSwitch]="edit_project_heading" id="storyview">
                      <div *ngSwitchCase="false">
                          <div *ngIf="model.project?.content" class="catalog-description">
                              <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="model.project?.content?.blocks"></app-editor-js-viewer>
                          </div>
                          <div *ngIf="!model.project?.content" class="no-subheading" translate>Add a catalog description ...</div>
                      </div>
                  </div>
              </div>
     
              <div *ngIf="edit_project_heading==true" class="catalog-description-edit">
                  <app-editor-js [content]="model.project" [projectId]="model.project?.id"></app-editor-js>
                  <br/>
              </div>
     
     
     
          <div *ngIf="model.project && model.project.catalog_subtitle && model.project.catalog_subtitle!=''"
          class="heading2-no">{{model.project.catalog_subtitle}}
         <button mat-button color="primary" *ngIf="this.model.adminRole()"
                 (click)="enterCatalogEditMode()">{{'edit'|translate}}</button>
     </div>
     <div *ngIf="!model.project || !model.project.catalog_subtitle || model.project.catalog_subtitle==''" class="heading2"
          translate>Catalog stories
         <button mat-button color="primary" *ngIf="this.model.adminRole()"
                 (click)="enterCatalogEditMode()">{{'edit'|translate}}</button>
     
     </div>
     
     <div *ngIf="edit_catalog_subheading">
         <mat-form-field class="edit_project_heading2" appearance="standard">
             <mat-label translate>Catalog subtitle</mat-label>
     
             <input matInput type="text" [(ngModel)]="model.project.catalog_subtitle"
                    matTooltip="{{'Catalog subtitle'|translate}}">
     
         </mat-form-field>
     
     </div>
     
     
     
     
     <!--                      <div class="admin-section" *ngIf="!edit_catalog_subheading && this.model.adminRole()">
              <button mat-button color="primary" *ngIf="this.model.adminRole()"
                      (click)="enterCatalogEditMode()">{{'Edit catalog description'|translate}}</button>
          </div>-->
     
              <ng-container *ngTemplateOutlet="catalog_column">
              </ng-container>
              <ng-container *ngTemplateOutlet="story_column">
              </ng-container>
     
     
     </ng-template>
     
     
     <!--  Should probably be its own component -->
     
     
     <ng-template #catalog_column>
      <!--      <div class="block1" *ngIf="model.project">
             <div *ngIf="authService.isLoggedIn && !model.isProjectMember() && model.isProjectPublicJoin()">{{'Your not a member of this catalog:'|translate}} <button mat-flat-button (click)="model.joinProject(model.project)">{{'Join!'|translate}}</button></div>
         </div> -->
     </ng-template>
     
     <ng-template #story_column>
         <div [ngSwitch]="edit_catalog_subheading" id="storyview">
             <div *ngSwitchCase="true" class="catalog-description-edit">
                 <app-editor-js [content]="model.project.introduction" [projectId]="model.project.id"></app-editor-js>
             </div>
             <div *ngSwitchCase="false" class="catalog-description">
                 <div *ngIf="model.project && model.project.introduction && model.project.introduction.content">
                     <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="model.project.introduction.content.blocks"></app-editor-js-viewer>
                 </div>
             </div>
         </div>
     
         <div *ngIf="model.project && model.project.sections && model.project.sections.length>0">
             <div *ngFor="let section of model.project.sections; let id = index">
                 <div class="heading-div" >
                     <div class="heading-title" (click)="editSection(section)">{{section.name}}       <div class="edit-mouse" *ngIf="this.model.adminRole()"><mat-icon>edit</mat-icon></div></div>
                     <div class="heading-menu">
                         <ng-container *ngIf="!isMobile">
                             <button *ngIf="this.model.adminRole() && model.project.sections.length-1>id" class="grey-icon" (click)="moveSectionDown(id)"  mat-icon-button title="{{'Move theme down'|translate}}">
                                 <mat-icon>keyboard_arrow_down</mat-icon>
                             </button>
     
                             <button *ngIf="this.model.adminRole() && id>0" (click)="moveSectionUp(id)"  class="grey-icon" mat-icon-button title="{{'Move theme up'|translate}}">
                                 <mat-icon>keyboard_arrow_up</mat-icon>
                             </button>
     
                             <button *ngIf="this.model.adminRole()" (click)="model.deleteSection(section)" class="grey-icon" mat-icon-button title="{{'Delete theme'|translate}}">
                                 <mat-icon>delete_forever</mat-icon>
                             </button>
     
                             <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-icon-button title="{{'Create new story'|translate}}">
                                 <mat-icon>add_circle</mat-icon>
                             </button>
                         </ng-container>
     
                         <ng-container *ngIf="isMobile">
                             <mat-menu #section_menu="matMenu">
                                 <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-menu-item>
                                     <mat-icon>add_circle</mat-icon>
                                     {{'Create new story'|translate}}
                                 </button>
     
                                 <button *ngIf="id>0" mat-menu-item (click)="moveSectionUp(id)">
                                     <mat-icon>north</mat-icon>
                                     <span>Move up</span>
                                 </button>
                                 <button *ngIf="model.project.sections.length-1>id" mat-menu-item (click)="moveSectionDown(id)">
                                     <mat-icon>south</mat-icon>
                                     <span translate>Move down</span>
                                 </button>
     
                                 <button mat-menu-item (click)="editSection(section)">
                                     <mat-icon>edit</mat-icon>
                                     <span translate>Edit</span>
                                 </button>
     
                                 <button mat-menu-item (click)="model.deleteSection(section)">
                                     <mat-icon>delete</mat-icon>
                                     <span translate>Delete</span>
                                 </button>
                             </mat-menu>
     
                             <button *ngIf="this.model.adminRole()" mat-icon-button>
                                 <mat-icon [matMenuTriggerFor]="section_menu">more_horiz</mat-icon>
                             </button>
                         </ng-container>
                     </div>
                 </div>
     
                 <ul class="image-list-new">
                     <span *ngFor="let concept of model.concepts">
                         <li *ngIf="concept.section_id==section.id">
                             <a routerLink="/{{env.links.viewStory}}/{{concept.id}}">
                                 <img *ngIf="getImage(concept, true)" [src]="getImage(concept, true)" width="150px" height="100px">
                                 <div class="title">{{concept.name}}</div>
                                 <div class="img-tagline">{{concept.tagline}}</div>
                             </a>
                         </li>
                     </span>
                 </ul>
             </div>
         </div>
     
         <div *ngIf="unassignedConcepts()">
             <div class="heading-div" >
                 <div class="heading-title" translate>Stories</div>
                 <div class="heading-menu">
                     <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-icon-button title="{{'Create new story'|translate}}">
                         <mat-icon>add_circle</mat-icon>
                     </button>
                 </div>
             </div>
             <ul class="image-list-new">
                 <span *ngFor="let concept of model.concepts">
                     <li *ngIf="concept.section_id==null">
                         <a routerLink="/{{env.links.viewStory}}/{{concept.id}}">
                             <img *ngIf="getImage(concept, true)" [src]="getImage(concept, true)" width="150px" height="100px">
                             <div class="title">{{concept.name}}</div>
                             <div class="img-tagline">{{concept.tagline}}</div>
                         </a>
                     </li>
                 </span>
             </ul>
         </div>
     
         <div *ngIf="this.model.adminRole()">
             <button mat-button color="primary" (click)="openNewSectionDialog()">{{'Create theme' | translate}}</button>
             <br/><br/><br/>
         </div>
     </ng-template>
     

</ng-container>

<!--  <ng-container *ngIf="!isMobile">


     <div class="scrollblock">
       <app-catalog-menu class="scrollblock"  *ngIf="!isMobile"></app-catalog-menu>

      
        <div class="normal-page-content">
            <ng-container *ngTemplateOutlet="intro_column"></ng-container>

        </div>
    </div>
</ng-container>


<div class="scrollblock row no-gutters" *ngIf="isMobile">
    <div class="format-one-container">
        <div class="format-one-column">

            <div>
                <ng-container *ngTemplateOutlet="story_column">
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #intro_column>





         <div class="heading2-no" translate>Introduction                       <button mat-button color="primary" *ngIf="this.model.adminRole()"
             (click)="enterEditmode()">{{'edit'|translate}}</button> </div>
         <div>
             <div [ngSwitch]="edit_project_heading" id="storyview">
                 <div *ngSwitchCase="false">
                     <div *ngIf="model.project?.content" class="catalog-description">
                         <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="model.project?.content?.blocks"></app-editor-js-viewer>
                     </div>
                     <div *ngIf="!model.project?.content" class="no-subheading" translate>Add a catalog description ...</div>
                 </div>
             </div>
         </div>

         <div *ngIf="edit_project_heading==true" class="catalog-description-edit">
             <app-editor-js [content]="model.project" [projectId]="model.project?.id"></app-editor-js>
             <br/>
         </div>



     <div *ngIf="model.project && model.project.catalog_subtitle && model.project.catalog_subtitle!=''"
     class="heading2-no">{{model.project.catalog_subtitle}}
    <button mat-button color="primary" *ngIf="this.model.adminRole()"
            (click)="enterCatalogEditMode()">{{'edit'|translate}}</button>
</div>
<div *ngIf="!model.project || !model.project.catalog_subtitle || model.project.catalog_subtitle==''" class="heading2"
     translate>Catalog stories
    <button mat-button color="primary" *ngIf="this.model.adminRole()"
            (click)="enterCatalogEditMode()">{{'edit'|translate}}</button>

</div>

<div *ngIf="edit_catalog_subheading">
    <mat-form-field class="edit_project_heading2" appearance="standard">
        <mat-label translate>Catalog subtitle</mat-label>

        <input matInput type="text" [(ngModel)]="model.project.catalog_subtitle"
               matTooltip="{{'Catalog subtitle'|translate}}">

    </mat-form-field>

</div>




         

         <ng-container *ngTemplateOutlet="catalog_column">
         </ng-container>
         <ng-container *ngTemplateOutlet="story_column">
         </ng-container>


</ng-template>





<ng-template #catalog_column>
    <div class="block1" *ngIf="model.project">
        <div *ngIf="authService.isLoggedIn && !model.isProjectMember() && model.isProjectPublicJoin()">{{'Your not a member of this catalog:'|translate}} <button mat-flat-button (click)="model.joinProject(model.project)">{{'Join!'|translate}}</button></div>
    </div>
</ng-template>

<ng-template #story_column>
    <div [ngSwitch]="edit_catalog_subheading" id="storyview">
        <div *ngSwitchCase="true" class="catalog-description-edit">
            <app-editor-js [content]="model.project.introduction" [projectId]="model.project.id"></app-editor-js>
        </div>
        <div *ngSwitchCase="false" class="catalog-description">
            <div *ngIf="model.project && model.project.introduction && model.project.introduction.content">
                <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="model.project.introduction.content.blocks"></app-editor-js-viewer>
            </div>
        </div>
    </div>

    <div *ngIf="model.project && model.project.sections && model.project.sections.length>0">
        <div *ngFor="let section of model.project.sections; let id = index">
            <div class="heading-div" >
                <div class="heading-title" (click)="editSection(section)">{{section.name}}       <div class="edit-mouse" *ngIf="this.model.adminRole()"><mat-icon>edit</mat-icon></div></div>
                <div class="heading-menu">
                    <ng-container *ngIf="!isMobile">
                        <button *ngIf="this.model.adminRole() && model.project.sections.length-1>id" class="grey-icon" (click)="moveSectionDown(id)"  mat-icon-button title="{{'Move theme down'|translate}}">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>

                        <button *ngIf="this.model.adminRole() && id>0" (click)="moveSectionUp(id)"  class="grey-icon" mat-icon-button title="{{'Move theme up'|translate}}">
                            <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>

                        <button *ngIf="this.model.adminRole()" (click)="model.deleteSection(section)" class="grey-icon" mat-icon-button title="{{'Delete theme'|translate}}">
                            <mat-icon>delete_forever</mat-icon>
                        </button>

                        <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-icon-button title="{{'Create new story'|translate}}">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="isMobile">
                        <mat-menu #section_menu="matMenu">
                            <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-menu-item>
                                <mat-icon>add_circle</mat-icon>
                                {{'Create new story'|translate}}
                            </button>

                            <button *ngIf="id>0" mat-menu-item (click)="moveSectionUp(id)">
                                <mat-icon>north</mat-icon>
                                <span>Move up</span>
                            </button>
                            <button *ngIf="model.project.sections.length-1>id" mat-menu-item (click)="moveSectionDown(id)">
                                <mat-icon>south</mat-icon>
                                <span translate>Move down</span>
                            </button>

                            <button mat-menu-item (click)="editSection(section)">
                                <mat-icon>edit</mat-icon>
                                <span translate>Edit</span>
                            </button>

                            <button mat-menu-item (click)="model.deleteSection(section)">
                                <mat-icon>delete</mat-icon>
                                <span translate>Delete</span>
                            </button>
                        </mat-menu>

                        <button *ngIf="this.model.adminRole()" mat-icon-button>
                            <mat-icon [matMenuTriggerFor]="section_menu">more_horiz</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>

            <ul class="image-list-new">
                <span *ngFor="let concept of model.concepts">
                    <li *ngIf="concept.section_id==section.id">
                        <a routerLink="/{{env.links.viewStory}}/{{concept.id}}">
                            <img *ngIf="getImage(concept, true)" [src]="getImage(concept, true)" width="150px" height="100px">
                            <div class="title">{{concept.name}}</div>
                            <div class="img-tagline">{{concept.tagline}}</div>
                        </a>
                    </li>
                </span>
            </ul>
        </div>
    </div>

    <div *ngIf="unassignedConcepts()">
        <div class="heading-div" >
            <div class="heading-title" translate>Stories</div>
            <div class="heading-menu">
                <button *ngIf="this.model.adminRole()" (click)="openCreateStory()"  mat-icon-button title="{{'Create new story'|translate}}">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
        <ul class="image-list-new">
            <span *ngFor="let concept of model.concepts">
                <li *ngIf="concept.section_id==null">
                    <a routerLink="/{{env.links.viewStory}}/{{concept.id}}">
                        <img *ngIf="getImage(concept, true)" [src]="getImage(concept, true)" width="150px" height="100px">
                        <div class="title">{{concept.name}}</div>
                        <div class="img-tagline">{{concept.tagline}}</div>
                    </a>
                </li>
            </span>
        </ul>
    </div>

    <div *ngIf="this.model.adminRole()">
        <button mat-button color="primary" (click)="openNewSectionDialog()">{{'Create theme' | translate}}</button>
        <br/><br/><br/>
    </div>
</ng-template>

       -->