
import { DocumentReference} from '@angular/fire/compat/firestore';
import { Favor } from './favor.model';


export enum FavorType {
    StoryRatings = "StoryRatings"
}

export enum WorkshopStatusType {
    hidden = "hidden",
    active = "active",
    promoted = "promoted"
}


export interface WorkshopFinsihed {
    content : any; 

}



export interface Workshop {
    id: string; 
    title: string; 
    summary: string; 
    content : any; 
    visible: boolean;
    status : WorkshopStatusType;
    icon: string;
    finished_text: WorkshopFinsihed;
    favors : Favor[];
}


export class Process {
    title : string;
    content : any; 
    id : string;
    catalog: string;
    workshops: Workshop[];

}
