import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadDialogComponent } from 'src/app/frontend/image-upload-dialog/image-upload-dialog.component';
import { ThumbnailService } from 'src/app/frontend/thumbnail.service';
import { Concept } from 'src/app/model/concept.model';
import {Kblock, kBlockType} from 'src/app/model/kblock.model';
import { AuthService } from 'src/app/service/auth.service';
import { kQueryType } from 'src/app/service/kblock.service';
import { ModelService } from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';
import { CreateKblocksComponent } from '../create-kblocks/create-kblocks.component';


@Component({
  selector: 'app-inspiration-grid-view',
  templateUrl: './inspiration-grid-view.component.html',
  styleUrls: ['./inspiration-grid-view.component.scss'],
  host: {'class': 'scrollblock'}
})
export class InspirationGridViewComponent implements OnInit {
  current_id: string;
  env = environment;
  ktype = kBlockType;

  selected_story : any = 'all';

  constructor(public model: ModelService,public authService: AuthService, private thumbnailService: ThumbnailService, private route: ActivatedRoute,    public dialog: MatDialog, private router: Router) { 

    if (this.route.snapshot.paramMap.get('id') != this.current_id) {
      this.setProject();
    }

    router.events.subscribe((val) => {
        if (this.route.snapshot.paramMap.get('id') != this.current_id && authService.getUser()) {
          this.setProject();
        }

      }
    )

    this.model.subscribeProjects();

  }

  setProject()
  {
    this.current_id = this.route.snapshot.paramMap.get('id');
    this.model.setSelectedProject(this.current_id);
  //  this.model.subscribekBlocks(this.model.projectService.getProjectReferenceById(this.current_id), kBlockType.Project);
  }


  ngOnInit(): void {
  }

  showUploadImageDialog() {
    ImageUploadDialogComponent.showUploadDialog().subscribe((image) => {
      if (image == null) {
        return;
      }

      //Delete old file
      if (this.model.project.img_url) {
        this.model.project.img_url = "";
        try {
          this.thumbnailService.delete(this.model.project.img_url);
        } catch (e) {
          //Ignore silently
        }
      }

      if (this.model.project.image) {
        this.thumbnailService.delete(this.model.project.image);
      }

      //Attach and save
      this.model.project.image = image;
      this.model.projectService.updateProject(this.model.project);
    });
  }
  
  getImage(obj, getSmall = false) {
    if (obj != null) {
      if (obj.image != null) {
        return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
      } else {
        if (obj.image_name) {
          return obj.image_name;
        }
        if (obj.img_url) {
          return obj.img_url;
        }
        return environment.story_background_url;
      }
    }
  }


  createInspirationBlock() {
    const dialogRef = this.dialog.open(CreateKblocksComponent, {
      panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result)=>{
      if(result != null) {
        //Setup correct project on kblock
        const kblock = result as Kblock;
      //  kblock.projects = [];
     //   kblock.projects.push(this.model.projectService.getProjectReferenceById(this.current_id));
        this.model.kBlockService.updatekBlock(Object.assign({}, kblock));
      }
    });
  }

  selectStory(event: Event) {
    const change: string = ((event.target as HTMLSelectElement).value) as string;
    if (this.selected_story!=change) 
    {

    }
  }
}
