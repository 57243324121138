import { DocumentReference} from '@angular/fire/compat/firestore';

export enum FavorType {
    StoryRatings = "StoryRatings",
    DivergeFavor = "DivergeFavor"
}

export class Favor {
    title : string;
    type: string;
    //story: DocumentReference;
    catalog: string;
    workshop_id: string;
    story: string;
    stories: string[];
    id: string;
    content : any; 
    description: string = ""; 
    storyRatings: StoryRating;
}


export class StoryRating {
published: boolean;
title: string;
intro: string;
highRatingText: string;
lowRatingText: string;

questions: string[];
}
