import { Injectable } from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {AngularFirestore, DocumentReference} from "@angular/fire/compat/firestore";
import {Project} from "../model/project.model";
import {map, tap, take} from "rxjs/operators";
import {Concept} from "../model/concept.model";
import {AuthService} from "../service/auth.service";
import {Kblock} from '../model/kblock.model';

export class Catalog extends Project {

}

export class Story extends Concept {
  questionnaires: DocumentReference[];
}

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private catalogs : Map<string, Catalog> = new Map();
  private stories : Map<string, Story> = new Map();
  private kblocks : Map<string, Kblock> = new Map();

  private catalogsSubject : Subject<Catalog[]>;
  private storiesSubject : Subject<Story[]>;
  private projectStoryFetchers : Set<string> = new Set();

  private kblockSubject : Subject<Kblock[]>;

  constructor(private firestore : AngularFirestore, private authService : AuthService) {
    this.catalogsSubject = new Subject<Catalog[]>();
    this.storiesSubject = new Subject<Story[]>();
    this.kblockSubject = new Subject<Kblock[]>();

    this.firestore.collection<Catalog>("projects", (ref)=>{
      if(this.authService.isLoggedIn) {
        return ref.where("published", "in", ["public", "link", "private"]);
      } else {
        return ref.where("published", "in", ["public", "link"]);
      }
    }).valueChanges({
      idField: "id"
    }).subscribe((catalogs) => {
      catalogs.forEach((catalog) => {
        this.catalogs.set(catalog.id, catalog);
      });

      this.sendCatalogUpdates();
    });
  }

  private sendCatalogUpdates() {
    let catalogs = Array.from(this.catalogs.values());

    this.catalogsSubject.next(catalogs);
  }

  getCatalogs() : Observable<Catalog[]> {
    setTimeout(()=>{
      this.sendCatalogUpdates();
    }, 0);

    return this.catalogsSubject.asObservable();
  }

  getCatalogRef(catalog: Catalog) : DocumentReference {
    return this.getCatalogRefFromId(catalog.id);
  }

  getCatalogRefFromId(id) : DocumentReference {
    return this.firestore.doc("/projects/"+id).ref
  }

  getCatalogFromId(id) : Observable<Catalog> {
    setTimeout(()=>{
      this.sendCatalogUpdates();
    }, 0);

    return this.catalogsSubject.asObservable().pipe(map((catalogs)=>{
      return catalogs.find((catalog)=>{
        return catalog.id === id;
      });
    }));
  }

  getCatalogFromUrl(url) : Observable<Catalog> {
    setTimeout(()=>{
      this.sendCatalogUpdates();
    }, 0);

    return this.catalogsSubject.asObservable().pipe(map((catalogs)=>{
      return catalogs.find((catalog)=>{
        return catalog.url === url;
      });
    }));
  }

  private sendKblockUpdates() {
    let kblocks = Array.from(this.kblocks.values());
    this.kblockSubject.next(kblocks);
  }

  private sendStoryUpdates() {
    let stories = Array.from(this.stories.values());

    this.storiesSubject.next(stories.sort((s1, s2)=>{
      return s1.id.localeCompare(s2.id);
    }));
  }

  getStories(projectId : string) : Observable<Story[]> {
    if(!this.projectStoryFetchers.has(projectId)) {
      const projectReference = this.firestore.collection("projects").doc(projectId).ref;

      this.projectStoryFetchers.add(projectId);

      //Start stories fetch
      this.firestore.collection<Story>("concepts", (ref)=>{
        return ref.where("project", "==", projectReference);
      }).valueChanges({
        idField: "id"
      }).subscribe((stories)=>{
        stories.forEach((story)=>{
          this.stories.set(story.id, story);
        });

        this.sendStoryUpdates();
      });
    } else {
      setTimeout(()=>{
        this.sendStoryUpdates();
      }, 0);
    }

    return this.storiesSubject.asObservable().pipe(map((stories)=>{
      return stories.filter((story)=>{
        return story.project.id === projectId;
      });
    }));
  }

  getStory(storyId: string) : Observable<Story> {
    if(!this.stories.has(storyId)) {
      //Fetch story from DB
      this.firestore.collection("concepts").doc<Story>(storyId).valueChanges().subscribe((story)=>{
        //Save id
        story.id = storyId;
        this.stories.set(storyId, story);
        this.sendStoryUpdates();
      })
    } else {
      setTimeout(()=>{
        this.sendStoryUpdates();
      }, 0);
    }

    return this.storiesSubject.asObservable().pipe(map((stories)=>{
      return stories.find((story)=>{
        return story.id === storyId;
      });
    }));
  }

  getStoryRef(story: Story) : DocumentReference {
    return this.getStoryRefFromId(story.id);
  }

  getStoryRefFromId(id: string) : DocumentReference {
    return this.firestore.doc("/concepts/"+id).ref;
  }

  getKblock(kblockId: string): Observable<Kblock> {
    if(!this.kblocks.has(kblockId)) {
      //Fetch kblock
      this.firestore.collection("kblocks").doc<Kblock>(kblockId).valueChanges().pipe(take(1)).subscribe((kblock)=>{
        kblock.id = kblockId;
        this.kblocks.set(kblockId, kblock);
        this.sendKblockUpdates();
      });
    } else {
      setTimeout(()=>{
        this.sendKblockUpdates();
      }, 0);
    }

    return this.kblockSubject.asObservable().pipe(map((kblocks)=>{
      return kblocks.find((kblock)=>{
        return kblock.id === kblockId;
      });
    }));
  }

  getKblocks(projectReference: DocumentReference) : Observable<Kblock[]> {
    return this.firestore.collection<Kblock>("kblocks", (ref)=>{
      return ref.where("projects", "array-contains", projectReference);
    }).valueChanges({
      idField: "id"
    });
  }

  getKblocksFromOwner(ownerReference: DocumentReference) : Observable<Kblock[]> {
    return this.firestore.collection<Kblock>("kblocks", (ref)=>{
      return ref.where("owner", "==", ownerReference);
    }).valueChanges({
      idField: "id"
    });
  }

  canEdit(catalog : Catalog) {
    let userReference = this.authService.getUserReference();

    if(userReference != null) {
      return catalog.participants.find((participant)=>{
        return participant.id === userReference.id;
      }) != null;
    }

    return false;
  }
}
