<div class=" mb-5 story-rating ">

    <div class="participate">
    <div class="participate-heading" translate>{{'Contribute'|translate}}</div>
    <div class="participate-text">
    <!--<h2>{{this.storyRatings.title}}</h2>-->
    <p>{{this.storyRatings.intro}}</p>


    <div class="flippable-question" class="{{showStats?'flipped':''}}">
        <div class="options">
            <table>
                <tr>
                    <th></th>
                    <th>{{this.storyRatings.lowRatingText}}</th>
                    <th colspan='8'></th>
                    <th>{{this.storyRatings.highRatingText}}</th>
                </tr>
                <tr>
                    <th></th>
                    <th *ngFor="let option of [1,2,3,4,5,6,7,8,9,10]" class="fixed-width">{{option}}</th>
                </tr>
                <tr class="critline" *ngFor="let question of this.storyRatings.questions; let i = index; trackBy: trackIndex" [attr.data-criteriaIndex]="i">
                    <th>{{question}}</th>
                    <td *ngFor="let option of [1,2,3,4,5,6,7,8,9,10]" class="fixed-width">
                <mat-radio-button color="primary" name="criteriaGroup{{i}}" [value]="option" (change)="saveRatings(i,option)" [checked]="ratings_checked[i][option-1]"></mat-radio-button>
                </td>
                </tr>
            </table>
            <div *ngIf="canSeeStats()" (click)="toggleStats()" class="flipper">{{'See Results'|translate}} &gt;</div>
        </div>
        <ul class="statistics">
            <li class="stats-item" *ngFor="let ratingStat of ratingStats">
                <div class="graph-area" *ngIf="ratingStat.name">
                    <div class="option-title">{{ratingStat.name}}</div>
                    <div class="graph" style="width:{{ratingStat.percentage}}%"></div>
                    <div class="value-view avgRating">{{(ratingStat.avgRating)  | number:'1.1-1'}}</div>
                </div>
            </li>
            <div *ngIf="getRatingCount()>1" class='totalStats'>{{'{count} people answered'|translate:{count: getRatingCount()} }}</div>
            <li class="flipper" (click)="toggleStats()">&lt; {{'Your answers'|translate}}</li>
        </ul>
    </div>
</div>
</div>
</div>
