import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from 'src/app/service/model.service';
import { Kblock, kBlockType } from 'src/app/model/kblock.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DocumentReference } from '@angular/fire/compat/firestore';
import {AuthService} from "../../service/auth.service";


@Component({
    selector: 'app-taglist',
    templateUrl: './taglist.component.html',
    styleUrls: ['./taglist.component.scss']
})
export class TaglistComponent implements OnInit {
    @Input() kblock : Kblock;
    @Input() showDelete : boolean = false;
    @Input() showLinks : boolean = true;
    @Input() showAsTree : boolean = false;
    @Input() showAll : boolean = false;
    @Input() topView : boolean = false;

    env = environment;
    ktype = kBlockType;

    constructor(private router: Router, public model: ModelService, public authService: AuthService) {
        
    }

    ngOnInit(): void {
    }

    /**
     * Sort the references in order of project id, with project tags first then concepts
     */
    sortedReferences(){
        // Add a fallback since there may be legacy tags that are not in a project
        let projectTags = new Map();
        let conceptTags = new Map();
        projectTags.set("legacy", []);
        conceptTags.set("legacy", []);

        // First add all project ids and project tags, either from project tags or inferred from project reference on story tags
        this.kblock.references.forEach((ref)=>{
            if (this.isProject(ref)){
                if (!projectTags.has(ref.ref.id)){
                    projectTags.set(ref.ref.id, []);
                    conceptTags.set(ref.ref.id, []);
                }
                projectTags.get(ref.ref.id).push(ref);
            } else if (this.isConcept(ref) && ref.project) {
                if (!projectTags.has(ref.project.id)){
                    projectTags.set(ref.project.id, []);
                    conceptTags.set(ref.project.id, []);
                }
                conceptTags.get(ref.project.id).push(ref);
            } else if (this.isConcept(ref)){
                conceptTags.get("legacy").push(ref); // legacy, no project on tag
            }
        });

        // Then push all tags in sorted order to the result list
        let result = [];
        for (const [id, tags] of projectTags) {
            tags.forEach((tag)=>{
                result.push(tag);
            });
            
            // STUB: sort alphabetically?
            conceptTags.get(id).forEach((tag)=>{
                result.push(tag);
            });                        
        }

        return result;
    }   

    isProject(ref){
        return ref.ref.path.startsWith(environment.firebaseVariable.project);
    }
    isConcept(ref){
        return ref.ref.path.startsWith(environment.firebaseVariable.concept);
    }

    openReference(ref) {
        if (this.isProject(ref)) {
            this.router.navigate(['/'+environment.links.viewProject+'/'+ref.ref.id]);
        } else if (this.isConcept(ref)){
            this.router.navigate(['/'+environment.links.viewStory+'/'+ref.ref.id]);
        } else {
            // Unknown reference type? Ignore it for now
        }
   }
}
