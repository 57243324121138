
<div *ngIf="catalog">
    <div class="heading3">{{'Participants'|translate}}</div>
    <div class="participants">
        <div *ngFor="let participant of getParticipants()">
            <app-user-block-view [userReference]="participant"></app-user-block-view>
        </div>
    </div>
    <ng-container *ngIf="!fullpage">
        <a (click)="viewAll = true" *ngIf="!viewAll">View All</a>
        <a (click)="viewAll = false" *ngIf="viewAll">Hide All</a>
    </ng-container>
</div>