import {Component, Input, OnInit} from '@angular/core';
import {Catalog, CatalogService} from "../catalog.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {Image} from "../image-upload-dialog/image-upload-dialog.component";
import {StoryRating} from "../../model/project.model";
import {Concept} from "../../model/concept.model";
import {ConceptService} from "../../service/concept.service";
import {ProjectService} from "../../service/project.service";

@Component({
  selector: 'app-story-rating-setting',
  templateUrl: './story-rating-setting.component.html',
  styleUrls: ['./story-rating-setting.component.scss']
})
export class StoryRatingSettingComponent implements OnInit {

  catalog : Catalog;
  concept : Concept;
  storyRatings : StoryRating;

  constructor(public dialogRef: MatDialogRef<StoryRatingSettingComponent>, private conceptService : ConceptService, private projectService : ProjectService) { }

  ngOnInit(): void {
    //copy questions array and storyRatings object
    let questions = [];
    questions.push(...this.catalog.storyRatings.questions);
    this.storyRatings = Object.assign({}, this.catalog.storyRatings, {questions: questions});

    this.dialogRef.afterClosed().subscribe(()=>{
      //Save new story ratings on catalog
      this.catalog.storyRatings = this.storyRatings;
      this.projectService.updateProject(Object.assign({}, this.catalog));
    });
  }

  isRatingEnabled() {
    return this.concept.story_rating != null && this.concept.story_rating > 0;
  }

  public static showDialog(catalog: Catalog, concept: Concept) {
    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(StoryRatingSettingComponent, {
      panelClass: 'reinvent-popup'
    });

    dialogRef.componentInstance.catalog = catalog;
    dialogRef.componentInstance.concept = concept;
  }

  public saveConcept() {
    this.conceptService.updateConcept(Object.assign({}, this.concept));
  }

}
