import {Component, Input, OnInit} from '@angular/core';
import {QuestionnaireService} from "../questionnaire.service";
import {Image} from "../image-upload-dialog/image-upload-dialog.component";
import {ThumbnailService} from "../thumbnail.service";
import { Kblock } from 'src/app/model/kblock.model';


@Component({
  selector: 'app-editor-js-viewer',
  templateUrl: './editor-js-viewer.component.html',
  styleUrls: ['./editor-js-viewer.component.scss']
})
export class EditorJsViewerComponent implements OnInit {
  @Input() admin : boolean = false;
  @Input() blocks : any;

  constructor(public questionnaireService: QuestionnaireService) { }

  ngOnInit(): void {
    console.log("Blocks: ", this.blocks);
  }

  getJsonParsed(json) {
    //Fix editorjs cancel bug?
    if(json instanceof Object) {
      return json;
    }

    return JSON.parse(json);
  }

  unescape(text: string) {
    return text.replace(/&amp;/g, "&");
  }

  getCaption(image: Image) {
    return ThumbnailService.getCaption(image);
  }
}
