
<h1 mat-dialog-title *ngIf="addFavor">{{'Add a new favor'|translate}}</h1>
<h1 mat-dialog-title *ngIf="!addFavor">{{'Edit favor'|translate}}</h1>

<!--<div class="heading3" translate>Heading</div>-->

<div class="info-description">

{{'Name and description of your favor'|translate}}

</div>
<div>
<mat-form-field appearance="standard" class="form-size">

  <mat-label>{{'Provide a name for your favor'|translate}}</mat-label>

<input class="input_field" matInput type="text" [(ngModel)]="favor.title" matTooltip="{{'Favor title'|translate}}" placeholder="{{'Please type in the title'|translate}}" required>
</mat-form-field><br/><br/>


<div class="heading4" translate>Description</div>

<div class="info-description">{{'Provide a short introduction text to your favor. What would you like the user to contribute with? '|translate}}</div><br/>

<div class="favor-editor">
    <!--<app-editor-js  *ngIf="favor" [content]="favor" [projectId]="favor.id" [dialogView]="true"></app-editor-js>-->
    <quill-editor [(ngModel)]="favor.description" >
  
      <div quill-editor-toolbar>
          <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>

            </span>
        <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>

            </span>

        <select class="ql-header">
          <option value="1">{{'Heading 1'|translate}}</option>
          <option value="2">{{'Heading 2'|translate}}</option>
          <option value="3">{{'Heading 3'|translate}}</option>
          <option value="">{{'Normal'|translate}}</option>
        </select>
        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>
      </div>
    </quill-editor>

  </div>
<br/>
<br/>

</div>

<br/>          <br/> 

<div class="heading4" translate>Select story</div>
<div class="info-description">{{'Select the story where the input will be associated to'|translate}}</div>

<mat-form-field appearance="fill">
    <mat-label>{{'Select a story'|translate}}</mat-label>
    <mat-select [(value)]="favor.story">
        <mat-option *ngFor="let story of model.concepts" [value]="story.id">
            {{story.name}}
          </mat-option>
    </mat-select>
  </mat-form-field>

<div>



</div>


<div mat-dialog-actions class="dialog-action-buttons">
  <button mat-button mat-dialog-close>Close</button>

  <button mat-raised-button mat-dialog-close color="primary" *ngIf="!addFavor" (click)="updateFavor()">{{'Update Favor'|translate}}</button>
  <button mat-raised-button mat-dialog-close color="primary" *ngIf="addFavor" (click)="createFavor()">{{'Create Favor'|translate}}</button>

</div>





