import {NgZone} from "@angular/core";

export class CaviDraggableHTML5 {
  private readonly html: Element;
  private readonly options: any;

  private readonly dragStartHandler:  (evt)=>void;
  private readonly dragEndHandler:  (evt)=>void;

  private zone: NgZone;

  constructor(html, options) {
    this.html = html;

    this.zone = injector.get(NgZone);

    // @ts-ignore
    this.html.caviDraggable = this;

    let defaultOptions =  {
      onDragStart: ()=>{console.warn("Missing onDragStart method...")},
      onDragComplete: ()=>{},
      draggingClass: "dragging"
    };
    this.options = Object.assign({}, defaultOptions, options);
    let self = this;

    //Setup handlers for adding removing event handlers
    this.dragStartHandler = function (evt) {
      self.dragStart(evt);
    };
    this.dragEndHandler = function (evt) {
      self.dragEnd(evt);
    };
    this.html.setAttribute("draggable", "true");
    this.zone.runOutsideAngular(()=>{
      this.html.addEventListener("dragstart", this.dragStartHandler);
      this.html.addEventListener("dragend", this.dragEndHandler);
    });
  }
  destroy() {
    this.html.removeEventListener("dragstart", this.dragStartHandler);
    this.html.removeEventListener("dragend", this.dragEndHandler);
    this.html.removeAttribute("draggable");
  }
  dragStart(evt) {
    let self = this;
    evt.dataTransfer.effectAllowed = "copyMove";
    this.html.classList.add(this.options.draggingClass);
    if(typeof this.options.onDragStart === "function") {
      this.options.onDragStart(evt);
    }
  }
  dragEnd(evt) {
    this.html.classList.remove(this.options.draggingClass);
    if(typeof this.options.onDragComplete === "function") {
      this.options.onDragComplete(evt);
    }
  }
}

export class CaviDroppableHTML5 {
  private readonly html: Element;
  private readonly options: any;
  private currentDropEffect: string;

  private readonly dragOverHandler: (evt)=>void;
  private readonly dragLeaveHandler: (evt)=>void;
  private readonly dropHandler: (evt)=>void;
  private readonly dragEnterHandler: (evt)=>void;

  private zone : NgZone;

  private highlightCounter: Set<HTMLElement>;

  constructor(html, options) {
    this.html = html;

    this.zone = injector.get(NgZone);
    this.highlightCounter = new Set<HTMLElement>();

    // @ts-ignore
    this.html.caviDroppable = this;

    let defaultOptions =  {
      onDrop: (evt, currentDropEffect)=>{console.warn("No onDrop event supplied...")},
      highlightClass: "dragOver",
      highlightTypes: null
    };
    this.options = Object.assign({}, defaultOptions, options);
    let self = this;
    this.currentDropEffect = "none";
    this.dragOverHandler = function (evt) {
      self.dragOver(evt);
      self.currentDropEffect = evt.dataTransfer.dropEffect;
    };
    this.dropHandler = function (evt) {
      self.drop(evt);
    };
    this.dragLeaveHandler = function(evt){
      self.dragLeave(evt);
    };
    this.dragEnterHandler = function(evt){
      self.dragEnter(evt);
    };
    this.zone.runOutsideAngular(()=>{
      this.html.addEventListener("dragover", this.dragOverHandler);
      this.html.addEventListener("dragenter", this.dragEnterHandler);
      this.html.addEventListener("dragleave", this.dragLeaveHandler);
      this.html.addEventListener("drop", this.dropHandler);
    });
  }

  private setHighlight() {
    if(this.highlightCounter.size > 0) {
      if(!this.html.classList.contains(this.options.highlightClass)) {
        this.html.classList.add(this.options.highlightClass);
      }
    } else {
      if(this.html.classList.contains(this.options.highlightClass)) {
        this.html.classList.remove(this.options.highlightClass);
      }
    }
  }

  highlight(target) {
    this.highlightCounter.add(target);
    this.setHighlight();
  }

  unhighlight(target) {
    this.highlightCounter.delete(target);
    this.setHighlight();
  }

  destroy() {
    this.html.removeEventListener("dragover", this.dragOverHandler);
    this.html.removeEventListener("dragleave", this.dragLeaveHandler);
    this.html.removeEventListener("drop", this.dropHandler);
  }
  dragOver(evt) {
    evt.preventDefault();
    if(typeof this.options.onDragOver === "function") {
      this.options.onDragOver(evt, this);
    } else {
      if(evt.ctrlKey) {
        evt.dataTransfer.dropEffect = "copy";
      } else {
        evt.dataTransfer.dropEffect = "move";
      }
    }
  }
  dragEnter(evt) {
    let shouldHighlight = true;
    if(this.options.highlightTypes != null) {
      shouldHighlight = false;
      this.options.highlightTypes.forEach((highlightType)=>{
        if(evt.dataTransfer.types.includes(highlightType)){
          shouldHighlight = true;
        }
      })
    }
    if(shouldHighlight) {
      this.highlight(evt.target);
    }

    if(typeof this.options.onEnterOver === "function") {
      this.options.onDragEnter(evt, this);
    }
  }
  dragLeave(evt) {
    let shouldHighlight = true;
    if(this.options.highlightTypes != null) {
      shouldHighlight = false;
      this.options.highlightTypes.forEach((highlightType)=>{
        if(evt.dataTransfer.types.includes(highlightType)){
          shouldHighlight = true;
        }
      })
    }
    if(shouldHighlight) {
      this.unhighlight(evt.target);
    }

    this.currentDropEffect = "none";
    if(typeof this.options.onDragLeave === "function") {
      this.options.onDragLeave(evt, this);
    }
  }
  drop(evt) {
    evt.preventDefault();
    this.highlightCounter.clear();
    this.setHighlight();
    if(typeof this.options.onDrop === "function") {
      this.options.onDrop(evt, this);
    }
  }
}
