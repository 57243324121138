<div *ngFor="let block of blocks" class="unbreakable">
    <ng-container [ngSwitch]="block.type">
        <ng-container *ngSwitchCase="'header'" [ngSwitch]="block.data.level">
            <h1 *ngSwitchCase="1" [innerHTML]="block.data.text"></h1>
            <h2 *ngSwitchCase="2" [innerHTML]="block.data.text"></h2>
            <h3 *ngSwitchCase="3" [innerHTML]="block.data.text"></h3>
            <h4 *ngSwitchCase="4" [innerHTML]="block.data.text"></h4>
            <h5 *ngSwitchCase="5" [innerHTML]="block.data.text"></h5>
            <h6 *ngSwitchCase="6" [innerHTML]="block.data.text"></h6>
        </ng-container>

        <ng-container *ngSwitchCase="'list'" [ngSwitch]="block.data.style">
          <ul *ngSwitchCase="'unordered'">
            <li *ngFor="let item of block.data.items" [innerHTML]="item"></li>
          </ul>
          <ol *ngSwitchCase="'ordered'">
            <li *ngFor="let item of block.data.items" [innerHTML]="item"></li>
          </ol>
        </ng-container>

        <div *ngSwitchCase="'table'" class="editorjstable">
            <table>
                <tr *ngFor="let row of getJsonParsed(block.data.content)">
                  <td *ngFor="let col of row">{{col}}</td>
                </tr>
            </table>
        </div>

        <p class="main-paragraph" *ngSwitchCase="'paragraph'" [innerHTML]="block.data.text"></p>

        <figure *ngSwitchCase="'image'">
          <ng-container  *ngIf="block.data.image && block.data.image.url">
            <img src='{{unescape(block.data.image.url.normal)}}'/>
            <figcaption [innerHTML]="getCaption(block.data.image)"></figcaption>
          </ng-container>
        </figure>

        <app-editor-jsviewer-kblock *ngSwitchCase="'kblock'"  [kblockId]="block.data.id"></app-editor-jsviewer-kblock>

        <app-questionnaire *ngSwitchCase="'questionnaire'" [admin]="admin" [questionnaireRef]="questionnaireService.getQuestionnaireRef(block.data.reference)"></app-questionnaire>

        <app-concept-puzzle *ngSwitchCase="'conceptPuzzle'"
          [header]="block.data.title"
          [helptext]="block.data.description"
          resettext="Nulstil"
          savetext="Indsend"
          [caption]="block.data.caption"
          [puzzleId]="block.data.id"

          [maxWeight]="block.data.maxWeight"

          [storySet]="block.data.pieces"></app-concept-puzzle>

        <app-story-rating-map *ngSwitchCase="'storyRatingMap'"
          [stories]="block.data.stories"
          [header]="block.data.title"
          [caption]="block.data.caption"
          [xAxis]="block.data.xAxis"
          [yAxis]="block.data.yAxis"
          [xAxisRatingIndex]="block.data.xAxisRatingIndex"
          [yAxisRatingIndex]="block.data.yAxisRatingIndex"
          [intersectLines]="block.data.intersectLines"
        ></app-story-rating-map>
    </ng-container>
</div>
