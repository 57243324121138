import {Component, ElementRef, Input, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';

import * as Chartist from 'chartist';

import 'chartist-plugin-axistitle';
import {ViewIntersector} from "../ViewIntersector";
import {AnimationObserver} from "../AnimationObserver";
import {CatalogService} from "../catalog.service";
import {Observable, Subject, Subscriber} from "rxjs";
import {RatingsService} from "../../service/ratings.service";

export interface Story {
  title: string,
  id: string
}

export interface Rating {
  param1: number,
  param2: number
}

export interface IntersectLine {
  name: string,
  x1: number,
  y1: number,
  x2: number,
  y2: number
}

@Component({
  selector: 'app-story-rating-map',
  templateUrl: './story-rating-map.component.html',
  styleUrls: ['./story-rating-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryRatingMapComponent implements OnInit {
  @Input() stories: Story[];
  @Input() header: string;
  @Input() caption: string;
  @Input() xAxis: string;
  @Input() yAxis: string;
  @Input() intersectLines: IntersectLine[];

  @Input() xAxisRatingIndex: number;
  @Input() yAxisRatingIndex: number;

  ngOnChanges(changes: SimpleChanges) {
    this.generateGraph();
  };

  constructor(private elementRef : ElementRef, private ratingsService: RatingsService) { }

  ngOnInit(): void {
    let chartElm = this.elementRef.nativeElement.querySelector(".ct-chart");
    ViewIntersector.whenInViewAddClass(chartElm, ()=>{
      chartElm.classList.add("first-view");
      AnimationObserver.whenAnimationStable(chartElm, ()=>{
        chartElm.classList.add("stable-view");
        chartElm.classList.remove("first-view");
      });
    });

    this.generateGraph();
  }

  private ctPointLabels(options) {
    return function ctPointLabels(chart) {
      const defaultOptions = {
        "textAnchor": "middle",
        "labelOffset": {
          x: 0,
          y: -7
        }
      };

      options = Chartist.extend({}, defaultOptions, options);

      if(chart instanceof Chartist.Line) {
        chart.on('draw', function(data) {
          if(data.type === 'point') {
            data.group.elem('text', {
              x: data.x + options.labelOffset.x,
              y: data.y + options.labelOffset.y,
              style: 'text-anchor: ' + options.textAnchor
            }, options.labelClass).text(data.series.name.replace("_complete", ""));
          }
        });
      }
    }
  }

  private ctIntersectLine(options) {
    return function ctIntersectLine(chart) {
      const defaultOptions = {
        "textAnchor": "middle",
        "labelOffset": {
          x: 0,
          y: 5
        },
        class: "ct-intersect-line",
        lines: []
      };

      options = Chartist.extend({}, defaultOptions, options);

      if(chart instanceof Chartist.Line) {
        chart.on('created', function(context) {
          let width = context.chartRect.width();
          let height = context.chartRect.height();

          let axisXWidth = context.axisX.range.max - context.axisX.range.min;
          let axisYHeight = context.axisY.range.max - context.axisY.range.min;

          function toPixels(value, axisSize, pixelSize, offset, sign = 1) {
            return offset + sign * ((value / axisSize) * pixelSize);
          }

          options.lines.forEach((line)=>{
            let x1 = toPixels(line.x1, axisXWidth, width, context.chartRect.x1);
            let y1 = toPixels(line.y1, axisYHeight, height, context.chartRect.y1, -1);
            let x2 = toPixels(line.x2, axisXWidth, width, context.chartRect.x1);
            let y2 = toPixels(line.y2, axisYHeight, height, context.chartRect.y1, -1);

            context.svg.elem("line", {
              x1: x1,
              y1: y1,
              x2: x2,
              y2: y2,
              style: "stroke: "+line.color
            }, options.class);

            let centerX = (line.x2 + line.x1) / 2.0;
            let centerY = (line.y2 + line.y1) / 2.0;

            let lineHeight = Math.abs(y1 - y2);
            let lineWidth = Math.abs(x1 - x2);

            let slope = lineHeight / lineWidth;

            let angle = Math.atan(slope) * (180.0 / Math.PI);

            let originX = toPixels(centerX, axisXWidth, width, context.chartRect.x1);
            let originY = toPixels(centerY, axisYHeight, height, context.chartRect.y1, -1);

            context.svg.elem("text", {
              x: originX + options.labelOffset.x,
              y: originY - options.labelOffset.y,
              style: 'text-anchor: ' + options.textAnchor+"; transform: rotate(-"+angle+"deg); transform-origin: "+originX+"px "+originY+"px; fill: "+line.color
            }, options.class).text(line.name);

          });
        });
      }
    }
  }

  private generateGraph() : void {
    console.log("Generating graph...");

    let options = {
      chartPadding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 0
      },
      showLine: false,
      fullWidth: false,
      showPoint: true,
      height: "25em",
      width: "100%",
      axisX: {
        type: Chartist.FixedScaleAxis,
        ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        low: 0,
        high: 10
      },
      axisY: {
        type: Chartist.FixedScaleAxis,
        ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        low: 0,
        high: 10
      },
      plugins: [
        this.ctPointLabels({
        }),
        this.ctIntersectLine({
          lines: this.intersectLines
        }),
        Chartist.plugins.ctAxisTitle({
          axisX: {
            axisTitle: this.xAxis,
            axisClass: "ct-axis-title",
            textAnchor: "middle",
            offset: {
              x: 0,
              y: 30
            }
          },
          axisY: {
            axisTitle: this.yAxis,
            axisClass: "ct-axis-title",
            offset: {
              x: 0,
              y: 12
            },
            flipTitle: true
          }
        })
      ]
    };

    let chartElm = this.elementRef.nativeElement.querySelector(".ct-chart");

    this.generateDataFromStories().subscribe((data)=>{

      let chart = new Chartist.Line(chartElm, data, options);

      chart.on('created', ()=>{
        chartElm.querySelectorAll("g.ct-series[*|series-name*='_complete']").forEach((series)=> {
          series.classList.add("ct-hidden-series");
        });

        chartElm.querySelectorAll(".ct-point").forEach((point)=>{
          let series = point.closest("g.ct-series");
          let isHidden = series.classList.contains("ct-hidden-series");
          let seriesName = series.getAttribute("ct:series-name").replace("_complete", "");
          let hiddenSeries : Element = chartElm.querySelector("g.ct-series[*|series-name*='"+seriesName+"_complete']");

          if(!isHidden) {
            point.addEventListener("mouseenter", ()=>{
              hiddenSeries.classList.add("ct-show-hidden-series");
            });
            document.addEventListener("mousemove", (evt : MouseEvent)=>{
              if(hiddenSeries.classList.contains("ct-show-hidden-series")) {
                let pointBounds = point.getBoundingClientRect();
                if (evt.clientX >= pointBounds.left-10 && evt.clientX <= pointBounds.right+10 &&
                  evt.clientY >= pointBounds.top-10 && evt.clientY <= pointBounds.bottom+10) {
                } else {
                  hiddenSeries.classList.remove("ct-show-hidden-series");
                }
              }
            });
          }
        });
      });
    });
  }

  private generateDataFromStories() : Observable<Chartist.IChartistData> {
    let subject = new Subject<Chartist.IChartistData>();

    let data: Chartist.IChartistData = {
      series: []
    };

    let ratingPromises = [];

    this.stories.forEach((storyData)=>{
      let param1Avg = 0;
      let param2Avg = 0;

      let seriesCompleteData : Chartist.IChartistSeriesData = {
        name: storyData.title+"_complete",
        data: []
      };

      ratingPromises.push(new Promise<void>((resolve, reject)=>{
        this.ratingsService.getRatingsForStory(storyData.id).subscribe((storyRatings)=>{
          if(storyRatings != null && storyRatings.length > 0) {
            let numRatings = 0;

            storyRatings.forEach((storyRating)=>{
              let param1 = null;
              let param2 = null;

              storyRating.ratings.forEach((rating)=>{
                if(rating.index === this.xAxisRatingIndex) {
                  param1 = rating.value;
                } else if(rating.index === this.yAxisRatingIndex) {
                  param2 = rating.value;
                }
              });

              param1Avg += param1;
              param2Avg += param2;

              numRatings++;

              seriesCompleteData.data[seriesCompleteData.data.length] = {
                x: param1,
                y: param2
              };
            });

            if(numRatings > 0) {
              param1Avg /= numRatings;
              param2Avg /= numRatings;
            }

            let seriesData : Chartist.IChartistSeriesData = {
              name: storyData.title.substr(0,35) + " (" + numRatings + ")",
              data: [{x: param1Avg, y:param2Avg}]
            };
            seriesCompleteData.name = storyData.title.substr(0,35) + " (" + numRatings + ")_complete";

            data.series[data.series.length] = seriesData;
            data.series[data.series.length] = seriesCompleteData;
          }
          resolve();
        });
      }));

    });

    Promise.all(ratingPromises).then(()=>{
      subject.next(data);
    })

    return subject.asObservable();
  }
}
