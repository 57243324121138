import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewChecked
} from '@angular/core';
import {Concept} from 'src/app/model/concept.model';
import {ConceptService} from 'src/app/service/concept.service';
import {Project, ProjectSection} from 'src/app/model/project.model';
import {environment} from 'src/environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import {ModelService, CurrentPage} from 'src/app/service/model.service';
import {AuthService} from 'src/app/service/auth.service';
import {KblockService} from "../../service/kblock.service";
import {CaviDraggableHTML5, CaviDroppableHTML5} from "../../frontend/CaviDraggable";
import {first} from "rxjs/operators";
import {ProjectService} from "../../service/project.service";
import { CreateConceptComponent } from 'src/app/concepts/create-concept/create-concept.component';
import { NewSectionDialog2Component } from 'src/app/catalogs/new-section-dialog2/new-section-dialog2.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-stories-list',
  templateUrl: './stories-list.component.html',
  styleUrls: ['./stories-list.component.scss']
})
export class StoriesListComponent implements OnInit, AfterViewChecked {
  @Input() story: string;

  concepts: Concept[];
  selected_project: Project;
  public env = environment;
  public currentPage = CurrentPage;
  route_id: string;

  constructor(private dialog:MatDialog, private elementRef: ElementRef, private projectService: ProjectService, private conceptService: ConceptService, public model: ModelService, private router: Router, private route: ActivatedRoute, public authService: AuthService, private kblockService: KblockService) {
    router.events.subscribe((val) => {
      this.route_id = this.route.snapshot.paramMap.get('id');
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    //Enable drag of stories
    this.elementRef.nativeElement.querySelectorAll(".dragStory").forEach((storyElm) => {
        if(storyElm.caviDraggable == null) {
          new CaviDraggableHTML5(storyElm, {
            onDragStart: (evt)=>{
              console.log("Starting story drag...");
              evt.stopPropagation();
              evt.stopImmediatePropagation();
              evt.dataTransfer.setData("ideate/concept", storyElm.getAttribute("data-conceptId"));
            }
          });
        }
    });

    //Enable drag of sections
    this.elementRef.nativeElement.querySelectorAll(".dragSection").forEach((sectionElm) => {
        if (sectionElm.caviDraggable == null) {
          new CaviDraggableHTML5(sectionElm, {
            onDragStart: (evt) => {
              console.log("Starting section drag...");
              evt.stopPropagation();
              evt.stopImmediatePropagation();
              evt.dataTransfer.setData("ideate/section", sectionElm.getAttribute("data-sectionId"));
            }
          });
        }
    });

    function moveToSection(concept, targetSection) : boolean {
      if(concept.section_id !== targetSection) {
        //Moved to new section
        if(targetSection === -1) {
          concept.section_id = null;
        } else {
          concept.section_id = targetSection;
        }
        return true;
      }

      return false;
    }

    this.elementRef.nativeElement.querySelectorAll(".dropStory").forEach((storyElm) => {
      if (storyElm.caviDroppable == null) {
        new CaviDroppableHTML5(storyElm, {
          highlightTypes: ["ideate/kblock"],
          onDrop: async (evt, droppable) => {
            if (evt.dataTransfer.types.includes("ideate/kblock")) {
              let kblockId = evt.dataTransfer.getData("ideate/kblock");
              let conceptId = droppable.html.getAttribute("data-conceptId");

              evt.stopPropagation();
              evt.stopImmediatePropagation();

              let concept = await this.conceptService.getConcept(conceptId).pipe(first()).toPromise();

              if (kblockId != null && kblockId.trim().length > 0) {

                console.log("kblock drop on story:", kblockId, concept);

                let kblock = await this.kblockService.getkBlock(kblockId).pipe(first()).toPromise();
                kblock.id = kblockId;
                this.model.connectConceptKblock(kblock, concept);
              }
            }
          }
        });
      }
    });

    //Drop on section
    this.elementRef.nativeElement.querySelectorAll(".dropSection, .dropSectionUnassigned").forEach((sectionElm) => {
      if (sectionElm.caviDroppable == null) {
          new CaviDroppableHTML5(sectionElm, {
            highlightTypes: ["ideate/section", "ideate/concept"],
            onDragOver: (evt, droppable) => {
              if (evt.dataTransfer.types.includes("ideate/section")) {
                let targetSectionId = parseInt(droppable.html.getAttribute("data-sectionId"));

                if (targetSectionId == -1) {
                  //Dont support sorting below unassigned
                  evt.dataTransfer.dropEffect = "none";
                  return;
                }
              }

              evt.dataTransfer.dropEffect = "move";
            },
            onDrop: async (evt, droppable) => {
              droppable.html.classList.remove("dragOver");

              if (evt.dataTransfer.types.includes("ideate/section")) {
                evt.stopPropagation();
                evt.stopImmediatePropagation();

                let sourceSectionId = parseInt(evt.dataTransfer.getData("ideate/section"));
                let targetSectionId = parseInt(droppable.html.getAttribute("data-sectionId"));

                console.log("Section drop on section:", sourceSectionId, targetSectionId);

                if (targetSectionId == -1) {
                  //Dont support sorting below unassigned
                  return;
                }

                if (sourceSectionId === targetSectionId) {
                  //Dont drop on self
                  return;
                }

                let fromIndex = this.model.project.sections.findIndex((section) => {
                  return section.id === sourceSectionId
                });
                let toIndex = this.model.project.sections.findIndex((section) => {
                  return section.id === targetSectionId
                });

                let deleted = this.model.project.sections.splice(fromIndex, 1);

                this.model.project.sections.splice(toIndex, 0, deleted[0]);

                this.projectService.updateProject(this.model.project);
              } else if (evt.dataTransfer.types.includes("ideate/concept")) {
                evt.stopPropagation();
                evt.stopImmediatePropagation();

                let sourceConceptId = evt.dataTransfer.getData("ideate/concept");
                let sectionId = parseInt(droppable.html.getAttribute("data-sectionId"));

                let sourceConcept = await this.conceptService.getConcept(sourceConceptId).pipe(first()).toPromise();

                console.log("Story drop on section:", sourceConcept, sectionId);

                if (moveToSection(sourceConcept, sectionId)) {
                  this.conceptService.updateConcept(sourceConcept);
                }
              }
            }
          });
      }
    });
  }

  getImage(obj, getSmall = false) {
    if (obj != null) {
      if (obj.image != null) {
        return (getSmall ? obj.image.url.small : obj.image.url.normal).replace("&amp;", "&");
      } else {
        if (obj.image_name) {
          return obj.image_name;
        }
        if (obj.img_url) {
          return obj.img_url;
        }
        return environment.story_background_url;
      }
    }
  }

  unassignedConcepts() {
    return this.model.concepts && ((this.model.concepts.length > 0 && this.model.concepts.some(x => x.section_id == null)) || this.model.concepts.length == 0);
  }

  deleteSection(section) {
    this.model.deleteSection(section);
  }

  createStory(section) {
    if (section)
    {
      CreateConceptComponent.showDialog(section.id);
    } else 
    {
      CreateConceptComponent.showDialog(-1);
    }
  }

  createTheme() {
    const dialogRef = this.dialog.open(NewSectionDialog2Component, {
      width: '250px',
      panelClass: ['reinvent-popup'],
      data:  {name:"", edit:false},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.projectService.addSection(this.model.project, result.name);
    });
  }
}

