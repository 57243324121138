<h1 mat-dialog-title>{{'Choose Inspiration'|translate}}</h1>
<div mat-dialog-content #dialogContent>
    <div class="kblocks">
        <div *ngFor="let kblock of kblocks">
            <div class="kblock-outer" (click)="select(kblock, $event)">
                 <app-inspiration-block [kblock]="kblock" style="pointer-events: none"></app-inspiration-block>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">{{'Cancel'|translate}}</button>
</div>
