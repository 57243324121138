import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/service/auth.service';
import {CurrentPage, ModelService} from 'src/app/service/model.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from 'src/app/service/project.service';
import {environment} from 'src/environments/environment';
import {publish_modes, admin_modes, StoryRating, join_modes} from 'src/app/model/project.model';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from 'src/app/model/user.model';


@Component({
  selector: 'app-sharing-catalog',
  templateUrl: './sharing-catalog.component.html',
  styleUrls: ['./sharing-catalog.component.scss'],
  host: {'class': 'flexblock'}
})
export class SharingCatalogComponent implements OnInit  {
  currentPage = CurrentPage;
  current_id: string;
  env = environment;
  publish_types = publish_modes;
  join_methods = join_modes;
  adm_modes = admin_modes;
  email: string;
  displayedColumns: string[] = ['name', 'email', 'role', 'action'];

  isMobile : boolean = false;

  constructor(private authService: AuthService, public model: ModelService, private route: ActivatedRoute, private projectService: ProjectService, private router: Router, private _snackBar: MatSnackBar) {
  
      //Setup mobile check
      window.onresize = () => {
        this.isMobile = window.innerWidth <= 991;
      }
      this.isMobile = window.innerWidth <= 991;
    router.events.subscribe((val) => {
      if (this.route.snapshot.paramMap.get('id') != this.current_id) {
        this.current_id = this.route.snapshot.paramMap.get('id');
        this.model.setSelectedProject(this.current_id);
        this.model.current_page = this.currentPage.sharing;
      }
    });
  }

  ngOnInit(): void {
    this.model.subscribeProjects();

    this.authService.userLoggedIn$.subscribe(user => {
      this.current_id = this.route.snapshot.paramMap.get('id');
      this.model.setSelectedProject(this.current_id);
      this.model.current_page = this.currentPage.sharing;
    });
  }

  getPublicUrl() {
    return location.origin+"/project/"+this.current_id;
  }

  updateProject() {
    this.projectService.updateProject(this.model.project).then(result => {
      this._snackBar.open("Saved", "", {
        duration: 2000,
      });
    });

  }





  generateJoinCode() {
    this.model.project.published="link";
    this.projectService.updateProject(this.model.project);
    this.model.generateJoinCode();
  }

  copyLink() {

  }
}
