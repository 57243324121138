import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewConceptComponent } from './concepts/view-concept/view-concept.component';
import { LoginComponent } from './login/login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import { environment } from 'src/environments/environment';
import { UserDataComponent } from './users/user-data/user-data.component';
import {CatalogDashboardComponent} from './catalogs/catalog-dashboard/catalog-dashboard.component';
import { CatalogSettingsComponent } from './catalogs/catalog-settings/catalog-settings.component';
import { JoinCatalogComponent } from './catalogs/join-catalog/join-catalog.component';
import { MainOverviewComponent } from './dashboard/main-overview/main-overview.component';
import { ProcessDashboardComponent } from './process/process-dashboard/process-dashboard.component';
import { DivergeFavorComponent } from './favors/diverge-favor/diverge-favor.component';
import {PublicAuthGuard} from "./login/guard/public-auth.guard";
import { ViewWorkshopComponent } from './process/view-workshop/view-workshop.component';
import {MemberInfoServiceTestComponent} from "./frontend/member-info-service-test/member-info-service-test.component";
import {MemberInfoPageComponent} from "./frontend/member-info-page/member-info-page.component";
import { SharingCatalogComponent } from './catalogs/sharing-catalog/sharing-catalog.component';
import { ViewCatalogComponent } from './catalogs/view-catalog/view-catalog.component';
import { InspirationGridViewComponent } from './kblocks/inspiration-grid-view/inspiration-grid-view.component';
import {ParticipantViewPageComponent} from "./frontend/participant-view-page/participant-view-page.component";
import { ProjectOverviewComponent } from './projects/project-overview/project-overview.component';
import {OnboardingAdminComponent} from "./frontend/onboarding/onboarding-admin/onboarding-admin.component";

const routes: Routes = [
  { path: '', component: MainOverviewComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.viewCatalog + '/:id', component: CatalogDashboardComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.viewProject + '/:id', component: ViewCatalogComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.inspirationGridView + '/:id', component: InspirationGridViewComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.viewProjectSetting + '/:id', component: CatalogSettingsComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.sharing + '/:id', component: SharingCatalogComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.projectMemberInfo +"/:id", component: MemberInfoPageComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.viewProjects, component:  ProjectOverviewComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.joinCatalog + '/:id', component: JoinCatalogComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.viewStory + '/:id', component: ViewConceptComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.viewStory + '/:id/:fid', component: ViewConceptComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.userData, component: UserDataComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.login, component:  LoginComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.register, component:  RegisterComponent, canActivate: [PublicAuthGuard]  },
  { path: environment.links.forgotPassword, component:  ForgotPasswordComponent, canActivate: [PublicAuthGuard]  },
  { path: environment.links.verifyEmail, component:  VerifyEmailComponent, canActivate: [PublicAuthGuard]  },

  { path: "project-member-info-test/:id", component: MemberInfoServiceTestComponent, canActivate: [PublicAuthGuard]},
  { path: environment.links.participants+'/:id', component: ParticipantViewPageComponent, canActivate: [PublicAuthGuard]},

  { path: environment.links.processOverview + '/:id', component: ProcessDashboardComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.processOverview + '/:id/:wid', component: ProcessDashboardComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.workshop + '/:id/:wid', component: ViewWorkshopComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.workshop + '/:id/:wid/:finished', component: ViewWorkshopComponent, canActivate: [PublicAuthGuard] },

  { path: environment.links.divergeFavor + '/:cid/:fid', component: DivergeFavorComponent, canActivate: [PublicAuthGuard] },
  { path: environment.links.onboardingAdmin, component: OnboardingAdminComponent, canActivate: [PublicAuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

