<div class="container-fluid  page-max-width">
  <div class="justify-content-md-center">
    <div class="format-one-container">
      <div class="format-one-column">
        <app-catalog-menu></app-catalog-menu>
        <div class="format-column1-content">


          <ng-container *ngIf="this.model.project">
            <div class="heading-div" translate>
              <div class="heading-title" translate>Catalog specific actions</div>
            </div>

            <mat-slide-toggle [(ngModel)]="favored"
                              (change)="favoredChanged($event)">{{'Favored' | translate}} </mat-slide-toggle>

            <br/>
            <br/>

            <ng-container *ngIf="isHiddenForUser(); else notHiddenForUser">
              <button mat-button color="primary" [matMenuTriggerFor]="menu">{{"Unhide community"|translate}}</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="unhideProjectForUser()">{{"Unhide community"|translate}}</button>
              </mat-menu>
            </ng-container>
            <ng-template #notHiddenForUser>
              <button mat-button color="primary" [matMenuTriggerFor]="menu">{{"Hide community"|translate}}</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="hideProjectForUser()">{{"Hide community"|translate}}</button>
              </mat-menu>
            </ng-template>

            <ng-container *ngIf="!isLastAdmin()">
              <br/>
              <br/>
              <button mat-button color="primary" [matMenuTriggerFor]="menu">{{"Leave community"|translate}}</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="leaveProject()">{{"Leave community"|translate}}</button>
              </mat-menu>
            </ng-container>

            <div class="heading-div" translate>
              <div class="heading-title" translate>Catalog specific information</div>
            </div>
            <app-member-info [catalog]="this.model.project" [isAdmin]="this.model.adminRole()"
                             [includeEditor]="true"></app-member-info>

          </ng-container>
        </div>
        <br/><br/>
      </div>
    </div>
  </div>
</div>

<div class="hidden">
  <mat-form-field>
    <input matInput type="text"/>
  </mat-form-field>
</div>
