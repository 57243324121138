import {Component, OnInit, HostBinding, AfterViewInit, ElementRef, AfterViewChecked} from '@angular/core';
import {Project} from 'src/app/model/project.model';
import {ProjectService} from 'src/app/service/project.service';
import {AuthService} from 'src/app/service/auth.service';
import {environment} from 'src/environments/environment';
import {ModelService} from 'src/app/service/model.service';
import {KblockService} from '../../service/kblock.service';
import {CookieService} from 'ngx-cookie-service';
import {CaviDroppableHTML5} from '../../frontend/CaviDraggable';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {NewProjectComponent} from '../../projects/new-project/new-project.component';
import {User} from '../../model/user.model';

@Component({
  selector: 'app-left-start-menu',
  templateUrl: './left-start-menu.component.html',
  styleUrls: ['./left-start-menu.component.scss', '../sidebar.scss'],
})
export class LeftStartMenuComponent implements OnInit, AfterViewChecked {
  @HostBinding('class.minimized') minimized: boolean = false;
  projects: Project[];
  public env = environment;
  showMoreProjectOption:boolean = false;
  public showAllProjects:boolean = false;

  constructor(public model: ModelService, private dialog: MatDialog, private authService: AuthService, private projectService: ProjectService, private kblockService: KblockService, private cookieService: CookieService, private elementRef: ElementRef) {
    authService.userLoggedIn$.subscribe(user => {
      this.model.subscribeProjects();
    });
  }

  ngOnInit(): void {
    this.model.subscribeProjects();
    this.minimized = this.cookieService.get('menuMinimized') === 'true';
  }

  ngAfterViewChecked() {
    this.elementRef.nativeElement.querySelectorAll('.dropProject').forEach((projectElm) => {
      if (projectElm.caviDroppable == null) {
        new CaviDroppableHTML5(projectElm, {
          onDragOver: (evt, droppable) => {
            if (evt.dataTransfer.types.includes('ideate/kblock')) {
              droppable.html.classList.add('dragOver');
            }
            evt.dataTransfer.dropEffect = 'move';
          },
          onDragLeave: (evt, droppable) => {
            droppable.html.classList.remove('dragOver');
          },
          onDrop: async (evt, droppable) => {
            droppable.html.classList.remove('dragOver');

            if (evt.dataTransfer.types.includes('ideate/kblock')) {
              evt.stopPropagation();
              evt.stopImmediatePropagation();

              let kblockId = evt.dataTransfer.getData('ideate/kblock');
              let projectId = droppable.html.getAttribute('data-projectId');

              let project = await this.projectService.getProject(projectId).pipe(first()).toPromise();

              if (kblockId != null && kblockId.trim().length > 0) {

                console.log('kblock drop on project:', kblockId, project);

                let kblock = await this.kblockService.getkBlock(kblockId).pipe(first()).toPromise();
                kblock.id = kblockId;
                this.model.connectProjectKblock(kblock, project);
              }
            }
          }
        });
      }
    });
  }

  getImage(catalog: Project): string {
    if (catalog.image != null) {
      return catalog.image.url.small;
    } else {
      if (catalog.img_url) {
        return catalog.img_url;
      } else {
        return environment.story_background_url;
      }
    }
  }

  toggleShowAll(){
     this.showAllProjects = !this.showAllProjects;
  }

  /**
   * Fetches a compact list of the user's projects. Shows the favourite ones or just the 5 latest
   * ones if none are marked as favourite.
   */
  getProjects(showAll) {
    if (this.model.projects == null) return [];

    // Hide projects that are hidden
    let user = this.authService.getUser();
    let notHidden = this.model.projects.filter((project)=>{
      //Only include projects that are not hidden by this user.
      return user.hiddenProjects?.find((projectRef)=>{
        return projectRef.id === project.id;
      }) == null;
    });

    var total_projects = (notHidden)?notHidden.length:0; 

    if (showAll){
      this.showMoreProjectOption = true;
      return notHidden;
    } else {
      // By default show favoured projects
      let shownProjects = notHidden.filter((project)=>{return this.model.getProjectFavored(project)});

      if (shownProjects.length == total_projects) {
        this.showMoreProjectOption = false;
      } else {
        this.showMoreProjectOption = true;
      }
      
      // If none, then show any up to 5
      if(shownProjects.length === 0) {
          shownProjects = notHidden.slice(0, 5);
          if (shownProjects.length == total_projects) {
            this.showMoreProjectOption = false;
          } else {
            this.showMoreProjectOption = true;
          }
      }
      return shownProjects;
    }



  }



  toggle() {
    this.minimized = !this.minimized;
    this.cookieService.set('menuMinimized', '' + this.minimized);
  }

  createProject(favoured = false) {
    NewProjectComponent.showDialog().pipe(first()).toPromise().then((project) => {
      this.model.setProjectFavored(project, favoured);
    });
  }
}
