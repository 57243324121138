import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "../../service/auth.service";
import {AuthGuardHelper} from "./AuthGuardHelper";
import {CatalogDashboardComponent} from "../../catalogs/catalog-dashboard/catalog-dashboard.component";
import { environment } from 'src/environments/environment';
import {ViewConceptComponent} from "../../concepts/view-concept/view-concept.component";
import {MainOverviewComponent} from "../../dashboard/main-overview/main-overview.component";
import {CatalogSettingsComponent} from "../../catalogs/catalog-settings/catalog-settings.component";
import {JoinCatalogComponent} from "../../catalogs/join-catalog/join-catalog.component";
import {JoinKeysService} from "../../service/join-keys.service";

import {marker as TRANSLATE} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from "@ngx-translate/core";
import {CreateConceptComponent} from "../../concepts/create-concept/create-concept.component";
import {MemberInfoPageComponent} from "../../frontend/member-info-page/member-info-page.component";
import {NewProjectComponent} from "../../projects/new-project/new-project.component";
import {UserDataComponent} from "../../users/user-data/user-data.component";
import {LoginComponent} from "../login/login.component";
import {RegisterComponent} from "../register/register.component";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {VerifyEmailComponent} from "../verify-email/verify-email.component";
import {MemberInfoServiceTestComponent} from "../../frontend/member-info-service-test/member-info-service-test.component";
import {ProcessDashboardComponent} from "../../process/process-dashboard/process-dashboard.component";
import {ViewWorkshopComponent} from "../../process/view-workshop/view-workshop.component";
import {DivergeFavorComponent} from "../../favors/diverge-favor/diverge-favor.component";
import { ViewCatalogComponent } from 'src/app/catalogs/view-catalog/view-catalog.component';
import {ParticipantViewPageComponent} from "../../frontend/participant-view-page/participant-view-page.component";
import {SharingCatalogComponent} from "../../catalogs/sharing-catalog/sharing-catalog.component";
import {ProjectOverviewComponent} from "../../projects/project-overview/project-overview.component";

@Injectable({
  providedIn: 'root'
})
export class PublicAuthGuard implements CanActivate {

  env = environment;

  constructor(
      private authService: AuthService,
      private authHelper: AuthGuardHelper,
      private joinKeysService: JoinKeysService,
      private translate: TranslateService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {

    const user = this.authService.getUser();

    //console.log("Checking publicAuthGuard: ", next.component, user);

    if (user != null && !user.emailVerified && next.component!=RegisterComponent  && next.component!=VerifyEmailComponent && next.component!=LoginComponent && next.component!=ForgotPasswordComponent)
    {
        this.translate.get(TRANSLATE("You need to verify your email account before continuing")).subscribe((translation)=>{
          this.authHelper.showSnackbarThenNavigate(translation, this.env.links.verifyEmail);
        });
    }

    switch(next.component) {

      case ViewWorkshopComponent:
      case ViewCatalogComponent:
      case ParticipantViewPageComponent:
      case CatalogDashboardComponent: {
        return this.authHelper.checkCatalogAccess(next.paramMap.get("id"), user).then((shouldNavigate) => {
          if (!shouldNavigate) {
            this.translate.get(TRANSLATE("You do not have permission to access this catalog")).subscribe((translation)=>{
              this.authHelper.showSnackbarThenNavigate(translation);
            });
          }

          //Pass the shouldNavigate decision on
          return shouldNavigate;
        });
      }

      case ViewConceptComponent: {
        return this.authHelper.checkStoryAccess(next.paramMap.get("id"), user).then((shouldNavigate) => {
          if (!shouldNavigate) {
            this.translate.get(TRANSLATE("You do not have permission to access this story")).subscribe((translation)=>{
              this.authHelper.showSnackbarThenNavigate(translation);
            });
          }

          //Pass the shouldNavigate decision on
          return shouldNavigate;
        });
      }

      case SharingCatalogComponent:
      case CatalogSettingsComponent: {
        return this.authHelper.checkCatalogRole(next.paramMap.get("id"), user, ["admin", "owner"]).then((shouldNavigate) => {
          if (!shouldNavigate) {
            this.translate.get(TRANSLATE("You are not an administrator of this catalog")).subscribe((translation)=>{
              this.authHelper.showSnackbarThenNavigate(translation);
            });
          }

          //Pass the shouldNavigate decision on
          return shouldNavigate;
        });
      }

      case JoinCatalogComponent: {
        //Check that join kode is correct
        return new Promise<boolean>((resolve, reject)=>{
          this.joinKeysService.getKeybyId(next.paramMap.get("id")).subscribe((key)=>{
            resolve(key != null);
          });
        }).then((keyExists)=>{
          if(!keyExists) {
            this.translate.get(TRANSLATE("The join key used is invalid")).subscribe((translation) => {
              this.authHelper.showSnackbarThenNavigate(translation);
            });
          }

          return keyExists;
        });
      }

      case CreateConceptComponent: {
        return this.authHelper.checkCatalogRole(next.paramMap.get("project"), user, ["admin", "owner"]).then((shouldNavigate) => {
          if (!shouldNavigate) {
            this.translate.get(TRANSLATE("You cannot create stories on this catalog")).subscribe((translation)=>{
              this.authHelper.showSnackbarThenNavigate(translation, this.env.links.viewProject+"/"+next.paramMap.get("project"));
            });
          }

          //Pass the shouldNavigate decision on
          return shouldNavigate;
        });
      }

      case ProcessDashboardComponent:
      case MemberInfoPageComponent: {
        return this.authHelper.checkCatalogRole(next.paramMap.get("id"), user, ["admin", "owner", "participant"]).then((shouldNavigate) => {
          if (!shouldNavigate) {
            this.translate.get(TRANSLATE("You are not a member of this catalog")).subscribe((translation)=>{
              this.authHelper.showSnackbarThenNavigate(translation, this.env.links.viewProject+"/"+next.paramMap.get("id"));
            });
          }

          //Pass the shouldNavigate decision on
          return shouldNavigate;
        });
      }

      case NewProjectComponent: {
        if(user == null) {
          this.translate.get(TRANSLATE("You need to be logged in to create a catalog")).subscribe((translation)=>{
            this.authHelper.showSnackbarThenNavigate(translation);
          });
          return false;
        }

        return true;
      }

      case UserDataComponent: {
        if(user == null) {
          this.translate.get(TRANSLATE("You need to be logged in to edit your user")).subscribe((translation)=>{
            this.authHelper.showSnackbarThenNavigate(translation);
          });
          return false;
        }

        return true;
      }

      //All these components, require that you are NOT logged in
      case RegisterComponent:
      case ForgotPasswordComponent:
      case VerifyEmailComponent:
      case LoginComponent: {
        if(user != null && user.emailVerified) {
          this.authHelper.navigateTo("/");
          /*this.translate.get(TRANSLATE("You are already logged in")).subscribe((translation)=>{
            this.authHelper.showSnackbarThenNavigate(translation);
          });*/
          return false;
        }

        return true;
      }

      case DivergeFavorComponent: {
        //TODO: What is required to view this favor component? Membership, or just public catalog for the workshop?
        break;
      }

      case MemberInfoServiceTestComponent: {
        //Test page, just allow
        break;
      }

      case MainOverviewComponent: {
        // Forward to login
        if(user == null) {
            this.authHelper.navigateTo(this.env.links.login);
        }
        if (!user.emailVerified){
            this.authHelper.navigateTo(this.env.links.verifyEmail);
        }

        break;
      }

      case ProjectOverviewComponent: {
        console.log("Project overview");
        //TODO: Who can view the project overview component?
        break;
      }

      default:
        console.warn("Unknown next component: ", next.component);
    }

    return true;
  }
}
