import firebase from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { DocumentReference} from '@angular/fire/compat/firestore';
import {Image} from "../frontend/image-upload-dialog/image-upload-dialog.component";



export enum IdeateNotificationType {
    kBlockCreated,
    Project,
    Concept
}


export interface user_data {
    displayName : string;
    photoURL : string;
    image: Image;
    uid: string;
}


export class IdeateNotification {
    timestamp : Timestamp;
    observers : DocumentReference[];
    actor : user_data;
    references_id : string;
    type : IdeateNotificationType;

    public get datetime() : string
    {

        let d: Date = this.timestamp.toDate();
        return d.toLocaleDateString();
    }

    public set time_stamp(d:Date)
    {
        this.timestamp= Timestamp.fromDate(d);
    }


}






