import {Component, OnInit, Input, ElementRef, OnDestroy, OnChanges, ViewChild, SimpleChanges} from '@angular/core';
import {StoryRating} from "../../model/project.model";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {AuthService} from "../../service/auth.service";
import {UserService} from "../user.service";
import {Rating, RatingsService} from "../../service/ratings.service";
import {ModelService} from "../../service/model.service";


import {Subscription} from 'rxjs';

@Component({
  selector: 'app-story-rating-view',
  templateUrl: './story-rating-view.component.html',
  styleUrls: ['./story-rating-view.component.scss']
})
export class StoryRatingViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() storyRatings: StoryRating;
  @Input() storyRef: DocumentReference;
  @Input() catalogRef: DocumentReference;

  oldStoryRef: string;

  rating: Rating;
  allRatings: Rating[] = [];
  ratingStats: any[] = [];
  rating_subscription: Subscription;
  ratingStatsSubscription: Subscription;

  showStats: boolean = false;
  answered: boolean = false;

  ratings_checked : boolean[][];

  ratingCount : number;

  constructor(private ratingsService: RatingsService, public model : ModelService, private authService: AuthService, private userService: UserService) {
  }

  ngOnChanges(changes:SimpleChanges) {
    if (this.oldStoryRef != this.storyRef.id || changes.hasOwnProperty("storyRatings")) {
      console.log("Updating story ratings view");
      this.oldStoryRef = this.storyRef.id;

      this.answered = false;
      this.showStats = false;

      this.ratings_checked = new Array(this.storyRatings.questions.length);
      for(let i = 0; i < this.ratings_checked.length; i++) {
        this.ratings_checked[i] = new Array(10).fill(false);
      }

      this.subscribeRatingService();
    }
  }

  subscribeRatingService() {
    let user: string | DocumentReference = this.authService.getUserReference();

    if (user == null) {
      user = this.userService.getAnonymousCookieValue();
    }

    if (this.rating_subscription) {
      this.rating = null;
      this.rating_subscription.unsubscribe();
    }

    if (this.ratingStatsSubscription) {
      this.allRatings = [];
      this.ratingStatsSubscription.unsubscribe();
    }

    this.rating_subscription = this.ratingsService.getRating(this.storyRef, user).subscribe((ratings) => {

      if (ratings.length > 0) {

        this.rating = ratings[0];

        if(!this.answered) {
          this.showStats = this.isAnswered();
          this.answered = this.showStats;
        }

        this.updateSelections();
      }
    });

    this.ratingStatsSubscription = this.ratingsService.getRatingsForStory(this.storyRef.id).subscribe((storyRatings)=> {
      this.allRatings = storyRatings;

      this.ratingCount = this.allRatings.filter((rating)=>{
        return rating.ratings.length >= this.storyRatings.questions.length;
      }).length;

      this.createRatingStats();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.rating_subscription) {
      this.rating_subscription.unsubscribe();
    }
  }

  trackIndex(index, item) {
    return index;
  }

  toggleStats() {
    this.showStats = !this.showStats;
    if(this.showStats) {
      this.updateSelections();
    }
  }

  canSeeStats() {
    return this.isAnswered() || this.model.adminRole();
  }

  isAnswered() {
    if(this.rating != null) {
      let unrated = this.rating.ratings.find((rating)=>{
        return rating.value === 0;
      });

      return unrated == null;
    }

    return false;
  }

  saveRatings(question: number, value: number) {
    //Check if same result as last?
    if(this.rating != null) {
      if (this.rating.ratings.find((entry) => {
        return entry.index === question && entry.value === value;
      }) != null) {
        //Already saved answer, skip
        return;
      }
    }

    let user: DocumentReference = this.authService.getUserReference();
    let anonymous_user = null;

    if (user == null) {
      anonymous_user = this.userService.getAnonymousCookieValue();
    }

    //Set newly selected value
    this.ratings_checked[question].fill(false);
    this.ratings_checked[question][value-1] = true;

    let rating: Rating = {
      id: this.rating != null ? this.rating.id : null,
      user: user,
      catalog: this.catalogRef,
      anonymous_user: anonymous_user,
      story: this.storyRef,
      ratings: this.ratings_checked.map((entry, index)=>{
        return {
          index: index,
          value: entry.indexOf(true)+1
        };
      })
    };

    this.rating = rating;

    this.ratingsService.saveRating(rating);
  }

  updateSelections() {
    this.rating.ratings.forEach((rating)=>{
      //Set new value
      this.ratings_checked[rating.index].fill(false);
      this.ratings_checked[rating.index][rating.value-1] = true;
    });
  }

  /**
   * Returns the number of users that has rated this story
   */
  getRatingCount() {
    return this.ratingCount;
  }

  private createRatingStats() {
    this.ratingStats = [];

    let ratingStatsMap = new Map();

    this.allRatings.forEach((userRatings)=>{
      userRatings.ratings.forEach((rating)=>{
        let ratingMapItem = ratingStatsMap.get(rating.index);
        if(ratingMapItem == null) {
          ratingMapItem = {
            name: this.storyRatings.questions[rating.index],
            totalValue: 0,
            count: 0
          };
          ratingStatsMap.set(rating.index, ratingMapItem);
        }

        ratingMapItem.totalValue += rating.value;
        ratingMapItem.count ++;
      })
    });

    ratingStatsMap.forEach((entry)=>{
      let ratingStat = {
        name: entry.name,
        percentage: (entry.totalValue / entry.count) / 10.0 * 100.0,
        avgRating: (entry.totalValue / entry.count)
      }

      this.ratingStats.push(ratingStat);
    });
  }
}
