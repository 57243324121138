<div class="top-image-cover-overlay-frontpage "></div>
<div class="margin-top-menu container-fluid">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="row no-gutters">

                        <div class="format-column1-content format-one-column">

                            <div class="heading3" translate>Edit your user data</div>

                            <ng-container *ngIf="model.user">
                                <mat-form-field class="input_form_field_small">
                                    <mat-label class="input_description">{{'The name to be displayed'|translate}}</mat-label><br/>
                                    <input class="input_field" matInput type="text" [(ngModel)]="model.user.displayName"  matTooltip="{{'Display Name'|translate}}">
                                </mat-form-field><br/><br/>

                                <mat-form-field class="input_form_field_small">
                                    <mat-label class="input_description">{{'Email'|translate}}</mat-label><br/>
                                    <input disabled class="input_field" matInput type="text" [(ngModel)]="model.user.email"  matTooltip="{{'Email'|translate}}" >
                                </mat-form-field><br/><br/>

                                <mat-form-field class="input_form_field_small">
                                    <mat-label>{{'Description'|translate}}</mat-label>
                                    <textarea class="input_about_me" matInput [(ngModel)]="model.user.description"></textarea>
                                </mat-form-field>
                                <br />

                                <img width="256px" [src]="getUserImage()" />
                                <br />
                                <br />
                                <button mat-raised-button color="primary" (click)="changePhoto()">{{'Select photo'|translate}}</button>
                                <br />
                                <br />
                            </ng-container>
                            <button mat-raised-button color="primary" (click)="saveUserData()">{{'Save'|translate}}</button>
                            <br />
                            <br />
                        </div>
                    </div>


        </div>
    </div>
