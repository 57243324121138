import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Project, ProjectSection } from 'src/app/model/project.model';
import { Subject, Subscription } from 'rxjs';
import { User } from '../model/user.model';
import { AuthService } from './auth.service';
import { Process } from '../model/process.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessService implements OnDestroy {
  process_subscription : Subscription;
  current_project_id : string = "";
  public process : Process;
  private processLoadedSource = new Subject<Process[]>();
  public processLoaded$ = this.processLoadedSource.asObservable();

  constructor(private firestore: AngularFirestore, private authService : AuthService) { }

  ngOnDestroy() {
    if (this.process_subscription)
      this.process_subscription.unsubscribe();
  }

  deleteWorkshop(id : string) {
    this.process.workshops.forEach(ws => {console.log(ws.id)});
    this.process.workshops = this.process.workshops.filter(item => item.id != id);

    this.update();
  }


  create(process: Process){
    return this.firestore.collection("processes").add({...process});

  }

  getbyProcessById(reference : string) {
    return this.firestore.collection('processes', ref => ref.where("catalog","==",reference)).snapshotChanges();
  }


  getbyId(processId: string) {
    return this.firestore.doc<Process>('processes/' + processId).valueChanges();
  }



  update(){
    let id = this.process.id;
    delete this.process.id;
    this.firestore.doc('processes/' + id).update(this.process);
    this.process.id = id;
    return
  }

  delete(processId: string){
      this.firestore.doc('processes/' + processId).delete();
  }


  changeProcess(id : string)
  {
    if (id==this.current_project_id)
    {
      this.processLoadedSource.complete();
      return;
    }
    this.current_project_id = id;
    this.process = null;

    if (this.process_subscription)
      this.process_subscription.unsubscribe();



    this.process_subscription = this.getbyProcessById(id).subscribe(data => {
        const process_array = data.map(e => {
            return {
                id: e.payload.doc.id,
                ...(e.payload.doc.data() as {})
            } as Process;
        });

      if (process_array!=null && process_array.length>0)
      {
        this.process = process_array[0];
      } else {
        this.process = null;
      }

      this.processLoadedSource.complete();

    });

  }

}
