<div class="backdrop" *ngIf="visible" [class.completed]="completed">
    <div class="popup">
        <div id="onboarding-content" [innerHTML]="steps[currentStep].content"></div>
        <ul class="progress-indicator" *ngIf="steps.length>1">
            <li *ngFor="let step of steps; index as stepNumber" [class.current]="stepNumber==currentStep" class="step"></li>
        </ul>
        <div class="navigation">
            <button mat-raised-button color="primary" (click)="next()">
                Next →
            </button>    
        </div>
    </div>    
</div>
