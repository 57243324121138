import {Component, Input, OnInit} from '@angular/core';
import {KblockService} from "../../service/kblock.service";
import {AuthService} from "../../service/auth.service";
import {DocumentReference} from "@angular/fire/compat/firestore";
import {UserService} from "../user.service";
import {Kblock} from "../../model/kblock.model";
import {User} from "../../model/user.model";
import {TranslateService} from "@ngx-translate/core";

export class Like {
  userReference: DocumentReference
  userName: string
}

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss']
})
export class LikeComponent implements OnInit {

  @Input() kblock : Kblock;

  isLiked : boolean;

  userReference: DocumentReference;
  user: User;
  tooltip: string;
  num_likes: number;

  constructor(public authService : AuthService, private kblockService: KblockService, private userService : UserService, private translate: TranslateService) { }

  ngOnInit(): void {
    //Save user reference to kblock
    this.userReference = this.authService.getUserReference();
    this.user = this.authService.getUser();

    if(this.kblock.likes != null) {
      this.updateTooltip();

      this.isLiked = this.kblock.likes.find((like)=>{
        return this.userReference != null && like.userReference.id === this.userReference.id;
      }) != null;

      this.num_likes = this.kblock.likes.length;
    }
  }

  toggleLike() {
    this.isLiked = !this.isLiked;

    if(this.kblock.likes == null) {
      this.kblock.likes = [];
    }

    if(this.isLiked) {
      //Add ourself to like array
      this.kblock.likes.push({
        userName: this.user.displayName,
        userReference: this.userReference
      })
    } else {
      //Remove ourself from like array
      this.kblock.likes = this.kblock.likes.filter((like)=>{
        return like.userReference.id !== this.userReference.id;
      });
    }

    this.kblockService.updatekBlock(this.kblock);

    this.updateTooltip();

    this.num_likes = this.kblock.likes.length;
  }

  async updateTooltip() {
    let tooltip = "";

    if(this.kblock.likes != null && this.kblock.likes.length > 0) {
      this.translate.stream("Liked by:").subscribe((translation)=>{
        tooltip = translation;

        let first = true;

        tooltip += " " + this.kblock.likes.map((like)=>{return like.userName}).join(", ");

        this.tooltip = tooltip;
      });
    } else {
      this.tooltip = tooltip;
    }
  }

}
