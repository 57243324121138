import {TranslateService} from "@ngx-translate/core";
import htmlTemplate from "./concept-puzzle-block/concept-puzzle-block.html";

export class EditorJSBlockTranslator {
  public static translateHtmlTemplate(template: string) : string {
    let translate = injector.get(TranslateService);

    let regexp = new RegExp("{{'(.*?)'\\|translate}}", "g");

    let match = regexp.exec(template);
    while(match != null) {
      let translation = translate.instant(match[1]);

      template = template.replace(match[0], translation);

      regexp.lastIndex = 0;

      match = regexp.exec(template);
    }

    return template;
  }
}
