<figure class="infographics">
  <h1>{{header}}</h1>
  <div class="builder">
    <div class="concept-toolbox">
        <h2>{{helptext}}</h2>
        <ul>
          <li *ngFor="let story of storySet; let i = index"
              attr.data-concept-index="{{i}}"
              style="--concept-colour: {{story.color}}"
              draggable="true">
                <img [src]="getImage(story)" />
                <div class="weightindicator">{{story.weight}}</div>
                <h3>{{story.title}}</h3>
                <p>{{story.description}}</p>
            </li>
        </ul>
    </div>
    <div class="result">
      <div class="ct-chart"></div>
      <div class="options">
        <button mat-raised-button color="primary" (click)="reset()">{{resettext}}</button>
        <div class="limit">{{usedWeight}} / {{maxWeight}}</div>
        <button mat-raised-button color="primary" (click)="save()">{{savetext}}</button>
      </div>
      
    </div>
  </div>
  <figcaption>{{caption}}</figcaption>
</figure>
