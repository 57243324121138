import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Concept } from 'src/app/model/concept.model';
import { ConceptService } from 'src/app/service/concept.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/service/project.service';
import { environment } from 'src/environments/environment';
import { ModelService } from 'src/app/service/model.service';
import {EditorJsComponent} from "../../frontend/editor-js/editor-js.component";
import {Color, ColorEvent} from 'ngx-color';
import {Observable, Subject} from 'rxjs';
import {Project} from '../../model/project.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-concept',
  templateUrl: './create-concept.component.html',
  styleUrls: ['./create-concept.component.scss'],
  host: {'class': 'scrollblock'}
})
export class CreateConceptComponent implements AfterViewInit {

  @ViewChild("currentColor") currentColor;

  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;
  concept : Concept;
  edit_concept: boolean = false;
  constructor(private activated_route: ActivatedRoute, private conceptService: ConceptService, private projectService : ProjectService, private router: Router, public model: ModelService, public dialogRef: MatDialogRef<CreateConceptComponent>) {
    this.concept = new Concept();
  }

  ngAfterViewInit(): void {
  /*  this.editorjs.saved.subscribe((result)=>{
      this.concept.content = result;
      this.createConcept();
    });

    this.editorjs.canceled.subscribe(()=>{
      this.cancel();
    });*/
    if(this.currentColor?.nativeElement != null) {
      this.currentColor.nativeElement.style.backgroundColor = this.concept.color;
    }
  }

  createConcept() {
    if (!this.edit_concept)
    {
    const id = this.model.project.id;
    if (!this.model.project)
      return;
    this.concept.project=this.projectService.getProjectReference(this.model.project);
    this.conceptService.createConcept(this.concept).then(concept => {

        this.dialogRef.close(concept);
        this.router.navigate(['/'+environment.links.viewStory+'/'+concept.id]);
    }, err => { console.log("Error")});
    }
    else {
      this.conceptService.updateConcept(this.concept);
      this.dialogRef.close(this.concept);
    }

  }

  cancel() {
    this.dialogRef.close();
  }

  colorChangeComplete(event: ColorEvent) {
    this.concept.color = event.color.hex;

    if(this.currentColor?.nativeElement != null) {
      this.currentColor.nativeElement.style.backgroundColor = this.concept.color;
    }
  }

  static showDialog(section_id: number = -1,  concept:Concept=null) : Observable<Concept> {
    let subject = new Subject<Concept>();
    const dialog = injector.get(MatDialog);

    const dialogRef = dialog.open(CreateConceptComponent, {
      panelClass: ['reinvent-popup'],
      autoFocus: false
    });
    console.log(section_id);
    if (section_id!=-1)
      dialogRef.componentInstance.concept.section_id = section_id;
    if (concept) {
      dialogRef.componentInstance.concept = concept;
      dialogRef.componentInstance.edit_concept=true;
    }



    dialogRef.afterClosed().subscribe((concept)=>{
      subject.next(concept);
    });

    return subject.asObservable();
  }
}
