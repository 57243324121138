<div class="splashblock">
    <div class="splashblock-splash">
        <b>ideate</b>.nu
    </div>
    <form class="splashblock-content">
        <h1 translate>Register</h1>

        <mat-form-field>
            <mat-label>{{'Your name'|translate}}</mat-label>
            <input matInput type="text" matTooltip="{{'Display name'|translate}}" #displayName required autofocus placeholder="{{'Your name'|translate}}">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Email'|translate}}</mat-label>
            <input matInput type="email" matTooltip="{{'Email'|translate}}" #userEmail required placeholder="{{'Enter email'|translate}}">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput type="password" matTooltip="{{'Password'|translate}}" required placeholder="{{'Password'|translate}}" #userPwd required>
        </mat-form-field>



        <div class="onboarding-options">
            <mat-checkbox class="wrapped" [(ngModel)]="policyAgreed" name="agree">
                {{'I agree with the'|translate}} 
                <a [href]="env.terms_conditions" target="_blank" translate>Terms</a>
                {{'and the'|translate}}
                <a [href]="env.privacy_policy" target="_blank" translate>Privacy Policy</a>                
            </mat-checkbox>    


            <button type="submit" mat-flat-button color="primary" (click)="agreeToTerms()"  *ngIf="!policyAgreed">{{'Sign up'|translate}} </button>
            <button *ngIf="!policyAgreed" mat-raised-button (click)="agreeToTerms()">
                {{'Continue with Google'|translate}}
            </button>



            <button *ngIf="policyAgreed" type="submit" mat-flat-button color="primary" (click)="authService.SignUp(displayName.value, userEmail.value, userPwd.value)"  [disabled]="!policyAgreed">{{'Sign up'|translate}} </button>
            <button *ngIf="policyAgreed" mat-raised-button (click)="authService.GoogleAuth()">
                {{'Continue with Google'|translate}}
            </button>



            <span>{{'Already have an account?'|translate}} <a class="redirect" routerLink="/login">{{'Log In'|translate}}</a></span>
        </div>
    </form>
</div>
