import { DocumentReference} from '@angular/fire/compat/firestore';
import {Image} from "../frontend/image-upload-dialog/image-upload-dialog.component";
import {Color} from 'ngx-color';

export interface TextBlockData
{
    text : string;
}

export interface TextBlock {
    type : string;
    data : TextBlockData;
}

export class Concept {
    name: string;
    tagline : string;
    id: string;
    description: string;
    project: DocumentReference;
    image_name : string;
    image: Image;
    section_id : number;
    text : TextBlock[];
    content: any;
    story_rating: number = 0;
    showComments: boolean;
    color: string;
}
