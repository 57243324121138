<div class="main-view" >
    <div class="top">
        <!-- <app-top-menu *ngIf="!isMobile"></app-top-menu>-->
        <app-top-menu-mobile *ngIf="isMobile"></app-top-menu-mobile>
        <app-top-menu-catalog-mobile *ngIf="isMobile"></app-top-menu-catalog-mobile>
        <app-top-menu-story-mobile *ngIf="isMobile"></app-top-menu-story-mobile>
        <app-top-menu-workshop-mobile *ngIf="isMobile"></app-top-menu-workshop-mobile>
    </div>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

    <app-site-popup></app-site-popup>
</div>