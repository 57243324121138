
import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from 'src/app/service/auth.service';
import {CurrentPage, ModelService} from 'src/app/service/model.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from 'src/app/service/project.service';
import {environment} from 'src/environments/environment';
import {publish_modes, admin_modes, StoryRating, join_modes} from 'src/app/model/project.model';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {marker as TRANSLATE} from '@biesbjerg/ngx-translate-extract-marker';

import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from 'src/app/model/user.model';
import {ColorEvent} from "ngx-color";
import {KblockService} from "../../service/kblock.service";
import {OverlaySpinnerService} from '../../service/overlay-spinner.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-catalog-settings',
  templateUrl: './catalog-settings.component.html',
  styleUrls: ['./catalog-settings.component.css'],
  host: {'class': 'flexblock'}
})
export class CatalogSettingsComponent implements OnInit {

  @ViewChild("currentColor") currentColor;

  currentPage = CurrentPage;

  current_id: string;
  env = environment;
  publish_types = publish_modes;
  join_methods = join_modes;
  adm_modes = admin_modes;
  email: string;
  test = [];

  displayedColumns = ['name', 'email', 'role', 'action'];
  isMobile : boolean = false;  

  constructor(private authService: AuthService, private translateService: TranslateService, public model: ModelService, private kblockService: KblockService, private route: ActivatedRoute, private projectService: ProjectService, private router: Router, private _snackBar: MatSnackBar, private overlaySpinnerService: OverlaySpinnerService) {
    //Setup mobile check
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 991;
    }
    this.isMobile = window.innerWidth <= 991;
    router.events.subscribe((val) => {
      if (this.route.snapshot.paramMap.get('id') != this.current_id) {
        this.current_id = this.route.snapshot.paramMap.get('id');
        this.model.setSelectedProject(this.current_id);
        this.model.current_page = this.currentPage.catalog_setting;
      }
    });
  }

  ngOnInit(): void {
    this.model.subscribeProjects();

    this.authService.userLoggedIn$.subscribe(user => {
      this.current_id = this.route.snapshot.paramMap.get('id');
      this.model.setSelectedProject(this.current_id);
      this.model.current_page = this.currentPage.catalog_setting;
    });
  }

  getPublicUrl() {
    return location.origin+"/project/"+this.current_id;
  }

  updateProject() {
    this.projectService.updateProject(this.model.project).then(result => {
      this.translateService.get(TRANSLATE("Saving project...")).subscribe((translation)=>{
        this.overlaySpinnerService.show(translation);

        //Now update all kblocks to new color...
        let projectRef = this.projectService.getProjectReference(this.model.project);
        this.kblockService.updateColorFromReference(projectRef, this.model.project.kid, this.model.project.color).then(()=>{
          this.overlaySpinnerService.hide();
          this.router.navigate(["/"+environment.links.viewProject+"/"+this.model.project.id]);
        });
      });
    });

  }

  addUser() {
    if (this.email) {
      this.authService.loadUserByEmail(this.email).subscribe(result => {
        if (result.length > 0) {
          const user = result[0] as User;
          const user_ref = this.authService.getUserReferenceById(user.uid);
          this.projectService.addParticipant(user, user_ref, this.model.project, "participant").then(result => {
            this._snackBar.open("Participant added", "", {
              duration: 2000,
            });
            this.email = "";
          });

        } else {
          this._snackBar.open("No user registered with that email", "", {
            duration: 2000,
          });
        }
      });
    }
  }

  removeUser(ref: DocumentReference) {
    console.log("remove user + " + ref.id);
    this.projectService.removeParticipant(ref.id, this.model.project).then(result => {
        this._snackBar.open("Participant removed", "", {
          duration: 2000,
        });
        this.email = "";
      }
    );
  }

  deleteProject() {
    this.projectService.deleteProject(this.model.project.id);
    this.router.navigate(['/']);
  }

  enableStoryRatings() {
    this.model.project.storyRatings = {
      published: false,
      title: "",
      intro: "",
      highRatingText: "",
      lowRatingText: "",
      questions: []
    };

    this.model.project.storyRatings.published = false;
  }

  disableStoryRatings() {
    this.model.project.storyRatings = null;
  }

  generateJoinCode() {
    this.model.generateJoinCode();
  }

  copyLink() {

  }

  colorChangeComplete(event: ColorEvent) {
    this.model.project.color = event.color.hex;
  }

  getCurrentColor() {
    if(this.currentColor?.nativeElement != null) {
      this.currentColor.nativeElement.style.backgroundColor = this.model.project.color;
    }

    return this.model.project.color;
  }
}
