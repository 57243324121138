<ng-container *ngIf="!isMobile">
<app-left-start-menu class="scrollblock"></app-left-start-menu>

<div class="scrollblock" *ngIf="model.project" >


<app-top-menu [current_page]="env.links.home"></app-top-menu>


    
    <div class="normal-page-content top-space">


 




    

    <div class="inspiration-adder">
        <div class="inspiration-floater">
            <input onFocus="this.blur()" (blur)="createInspirationBlock();" placeholder="{{'Contribute...'|translate}}">
        </div>
        
    </div>

    <div class="div-space">
    
    </div>
    <!--<app-kblocks-search></app-kblocks-search>-->

    <app-kblocks-date-overview [type]="ktype.User" [blocks]="10"></app-kblocks-date-overview>
</div>
</div>
</ng-container>



<div class="scrollblock row no-gutters" *ngIf="isMobile">
    <div class="format-one-container" *ngIf="model.project" >
        <div class="format-one-column mobile-padding-left">
           <!-- <app-catalog-menu></app-catalog-menu>
            <app-story-menu></app-story-menu>-->

            <div class="heading-div-top">
                <div class="heading-title" translate>Home</div>
            </div>

            <div class="inspiration-adder">
                <div class="inspiration-floater">
                    <input onFocus="this.blur()" (blur)="createInspirationBlock();" placeholder="{{'Create inspiration...'|translate}}">
                </div>
            </div>

            <div class="div-space">

            </div>


            <app-kblocks-date-overview [type]="ktype.User" [blocks]="10"></app-kblocks-date-overview>

        </div>
    </div>
</div>


