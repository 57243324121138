import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css', '../../frontpage/frontpage/frontpage.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  env = environment;
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
