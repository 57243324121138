import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ModelService} from 'src/app/service/model.service';
import {JoinKeysService} from "../../service/join-keys.service";
import {Catalog, CatalogService} from "../../frontend/catalog.service";
import {MemberInfoService} from "../../frontend/member-info.service";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../service/auth.service";

enum PageMode {
    About,
    JoinCatalog,
    ThankYou,
    MemberInfo,
    Spam
}

@Component({
    selector: 'app-join-catalog',
    templateUrl: './join-catalog.component.html',
    styleUrls: ['../../onboarding-flow.scss', './join-catalog.component.scss'],
    host: {'class': 'centerblock'}
})
export class JoinCatalogComponent implements OnInit {
  PageMode = PageMode;
  currentPage: PageMode;
  joinKeyId: string;
  catalog: Catalog;
  env = environment;

    constructor(private catalogService: CatalogService, private model: ModelService, private authService : AuthService, private router: Router, public route: ActivatedRoute,private joinKeysService: JoinKeysService, private memberInfoService: MemberInfoService) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.paramMap.get('id')) {
            this.joinKeyId = this.route.snapshot.paramMap.get('id');

            console.log("Join key:", this.joinKeyId);

            const preAccept = this.route.snapshot.queryParamMap.get("preAccept");

            this.joinKeysService.getKeybyId(this.joinKeyId).subscribe((joinKey) => {
                const catalogSubscription = this.catalogService.getCatalogFromId(joinKey.catalog.id).subscribe((catalog) => {
                    console.log("Catalog:", catalog, joinKey.catalog.id);

                    if(catalog != null) {
                        catalogSubscription.unsubscribe();
                        this.catalog = catalog;

                        //Check if already a member
                        const userRole = this.model.getUserRoleInProject(catalog);

                        if(userRole !== "") {
                            //Already a member, just redirect
                            this.redirectToCatalog();
                        }

                        if(preAccept != null) {
                            //We came from a previous accept, probabely just back from LoginComponent, go directly to join
                            this.joinCatalog();
                        } else {
                            this.currentPage = PageMode.JoinCatalog;
                        }
                    }
                });
            })
        }
    }

    getImageUrl(): string {

        switch(this.currentPage) {
            case PageMode.About:
                return null;

            case PageMode.JoinCatalog: {
                if (this.catalog == null) {
                    return null;
                }
                if (this.catalog.image != null) {
                    return "url("+this.catalog.image.url.small+")";
                } else {
                    return "url("+this.catalog.img_url+")";
                }
            }
        }
    }

    joinCatalog() {
        //Check if user is logged in, redirect to login if not
        if(this.authService.getUser() == null) {
            //HiJack previousUrl to include preAccept, so we can see that accept was already given opun returning.
            const oldPrevious = window.localStorage.getItem('previousUrl');
            window.localStorage.setItem('previousUrl', oldPrevious+"?preAccept=true");

            this.router.navigate([this.env.links.login]);
        } else {
            const catalogClone = Object.assign({}, this.catalog);

            this.model.joinProject(catalogClone).then(() => {
                this.memberInfoService.getQuestions(this.catalog.id).subscribe((questions) => {
                    if (questions.length > 0) {
                        this.currentPage = PageMode.MemberInfo;
                    } else {
                        this.thankYou();
                    }
                });
            });
        }
    }

    redirectToCatalog() {
        this.router.navigate([this.env.links.viewProject+"/"+this.catalog.id]);
    }

    thankYou() {
        this.currentPage = PageMode.ThankYou;

        setTimeout(()=>{
            //Redirect to newly joined catalog?
            this.redirectToCatalog();
        }, 1000);
    }
}
