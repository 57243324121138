import { Component, OnInit } from '@angular/core';
import { ModelService } from 'src/app/service/model.service';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/frontend/language.service';
import { User } from 'src/app/model/user.model';
import { ThumbnailService } from 'src/app/frontend/thumbnail.service';


@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss', '../sidebar.scss'],
  host: {'class': 'sidebar-header'}

})
export class SidebarHeaderComponent implements OnInit {
    user : User;
    public env = environment;

    constructor(public model: ModelService, public authService: AuthService, public languageService: LanguageService) {
        authService.userLoggedIn$.subscribe(user => {
            this.user = user;
        });
    }

    ngOnInit(): void {
        if (this.authService.getUser())
        this.user = this.authService.getUser();
    }

    hasPhoto(): boolean {
        return ThumbnailService.getPhotoFromUser(this.model.user) != null;
    }

    getPhoto():string {
        return ThumbnailService.getPhotoFromUser(this.model.user);
    }

}
