import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Kblock } from 'src/app/model/kblock.model';
import { AuthService } from 'src/app/service/auth.service';
import { ModelService } from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';
import {ImageUploadDialogComponent} from "../../frontend/image-upload-dialog/image-upload-dialog.component";
import {OverlaySpinnerService} from "../../service/overlay-spinner.service";

@Component({
  selector: 'app-create-kblocks',
  templateUrl: './create-kblocks.component.html',
  styleUrls: ['./create-kblocks.component.scss']
})
export class CreateKblocksComponent implements OnInit {
  new_kblock: Kblock = new Kblock();
  env = environment;
  // @ts-ignore
  editor: Quill;

  constructor(public model: ModelService, public authService : AuthService,
    public dialogRef: MatDialogRef<CreateKblocksComponent>, private overlaySpinnerService: OverlaySpinnerService) { }

  ngOnInit(): void {
  }

  createkBlock()
  {
    this.overlaySpinnerService.show("Creating kblock...");
    this.closeDialog(this.new_kblock);
    this.model.createKBlock(this.new_kblock).then((ref)=>{
      this.new_kblock.id = ref.id;
      this.overlaySpinnerService.hide();
    });
  }


  closeDialog(result: Kblock=null) {
    this.dialogRef.close(result);
  }

  setFocus(editor) {
    console.log("Test:", typeof editor, editor.constructor.name);
    this.editor = editor;
    editor.focus()
  }

  openUploadDialog(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    ImageUploadDialogComponent.showUploadDialog().subscribe((img)=>{
      if (img == null) {
        return;
      }

      this.editor.insertEmbed(this.editor.getSelection(true).index, 'customImage', {
        src: img.url.small,
        original: img.url.original,
        width: 200
      }, 'user');

      //Make sure text was saved? Seems updateContents does not trigger [(ngmodel)]
      this.new_kblock.text = this.editor.root.innerHTML;

    });
  }

}
