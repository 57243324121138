<div class="top-inspiration hidden-on-mobile">
    <div class="participate-heading">{{'Contribute with inspiration2' | translate }}</div>
    <div class="kblock-menu ">
        <span class="pointer grey" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<div  *ngIf="authService.isLoggedIn else notLoggedIn">
    <div class="white-background">
        <div class="kblock-edit-inner">
            <div class="kblock-edit-main ">
                <quill-editor [(ngModel)]="new_kblock.text" (onEditorCreated)="setFocus($event)" placeholder="{{'Contribute with links, texts and other ideas ...'|translate}}" cdkFocusInitial>
                    <div quill-editor-toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>

                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>

                        </span>
                        <span class="ql-formats">
                            <button class="ql-link"></button>
                            <button class="ql-image" (click)="openUploadDialog($event)"></button>
                        </span>
                    </div>

                </quill-editor>

            </div>


        </div>
        <div class="kblock-edit-action">
            <button mat-stroked-button color="primary" class="button-full-width"  (click)="createkBlock()">{{'Post' | translate}}</button>
        </div>
    </div>

</div>

<ng-template #notLoggedIn>
    <div class="favor">
        {{"To create Inspirations you need to be logged in: "|translate}}
        <a  href="javascript:void(null);"
        [routerLink]="'/'+env.links.login"
           (click)="closeDialog(); $event.stopPropagation()" translate>Register / Log-in</a>
    </div>
</ng-template>
