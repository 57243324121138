import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Concept } from 'src/app/model/concept.model';
import { Project } from '../model/project.model';
import { finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConceptService {
  uploadPercent: Observable<number>;
  downloadURLObserver: Observable<string>;
  private downloadURLObserverSource = new Subject<string>();
  imageURLFinished$ = this.downloadURLObserverSource.asObservable();


  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {

  }


  getConcepts(reference : DocumentReference) {
    return this.firestore.collection('concepts', ref => ref.where("project","==",reference)).snapshotChanges();
}

  getConceptsValueChanges(reference : DocumentReference) {
    return this.firestore.collection<Concept>('concepts', ref => ref.where("project","==",reference)).valueChanges({
      idField: "id"
    });
  }

createConcept(concept: Concept) : Promise<DocumentReference>{
  return this.firestore.collection("concepts").add({...concept});
}




getConcept(conceptId: string) {
  return this.firestore.doc<Concept>('concepts/' + conceptId).valueChanges({
    idField: "id"
  });
}

getReference(concept: Concept) : DocumentReference {
  return this.firestore.doc<Concept>('concepts/' + concept.id).ref;
}

getReferenceById(id: string) {
  return this.firestore.doc<Concept>('concepts/' + id).ref;
}

updateConcept(concept: Concept){
    const cloneConcept = Object.assign({}, concept);
   delete cloneConcept.id;
   return this.firestore.doc('concepts/' + concept.id).update(cloneConcept);
}

deleteConcept(conceptId: string){
    this.firestore.doc('concepts/' + conceptId).delete();
}

uploadFile(file: File) {
    const filePath = `images/${new Date().getTime()}_${file.name}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(  finalize(() =>
    { this.downloadURLObserver = fileRef.getDownloadURL();
      this.downloadURLObserver.subscribe(data => {
        // to create an id for the document.
        console.log("reference: "+data)
        this.downloadURLObserverSource.next(data);
      });

    }))
    .subscribe();

}
}
