import {enableProdMode, Injector} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Quill from 'quill';
import {LiveElement} from "./app/frontend/LiveElement";
import {KblockService} from "./app/service/kblock.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Kblock} from "./app/model/kblock.model";
import {first} from "rxjs/internal/operators/first";
import {ConceptService} from "./app/service/concept.service";

if (environment.production) {
  enableProdMode();
}

declare global {
  var injector: Injector;
}

//Setup custom image on QUILL

// @ts-ignore
const ImageBlot = Quill.import('formats/image');

class CustomImageBlot extends ImageBlot {
  static blotName = 'customImage';
  static tagName = 'img';

  static create(data) {
    let node = super.create();

    node.setAttribute('src', data.src);
    node.setAttribute('data-original', data.original);
    node.setAttribute('width', data.width);

    return node;
  }
  static value(domNode) {
    let blot = {};

    // @ts-ignore
    blot.src = domNode.getAttribute('src');
    // @ts-ignore
    blot.original = domNode.getAttribute('data-original');
    // @ts-ignore
    blot.width = domNode.getAttribute('width');

    return blot;
  }
}

Quill.register(CustomImageBlot);

let live = new LiveElement(".inspiration-adder");
live.forEach((inspirationAdder)=>{
  if(!inspirationAdder.alreadyHandled) {
    inspirationAdder.alreadyHandled = true;

    let observer = new IntersectionObserver((intersections)=>{
      intersections.forEach((intersection)=>{
        let scrollView = inspirationAdder.closest(".scrollblock");
        let bounds = scrollView.getBoundingClientRect();

        let floater = inspirationAdder.querySelector(".inspiration-floater");
        
        if(intersection.boundingClientRect.top < 0 && !intersection.isIntersecting) {
          // Rect is above 0 and some of it not visible anymore, add floating
          floater.classList.add("floating");
          floater.style.top = bounds.top + "px";
        } else {
          // Remove floating
          floater.classList.remove("floating");
          floater.style.top = null;
        }
      });
    }, {
        threshold: 1.0
    });

    observer.observe(inspirationAdder);
  }
});

platformBrowserDynamic().bootstrapModule(AppModule).then((appRef)=>{
  window.injector = appRef.injector;

  //Fix all kblocks? (Disable after run)
  if(false) {
    let kblockService = window.injector.get(KblockService);
    let conceptService = window.injector.get(ConceptService);
    let firestore = window.injector.get(AngularFirestore);

    let env = environment;

    firestore.collection<Kblock>("kblocks").valueChanges({"idField": "id"}).pipe(first()).toPromise().then(async (kblocks)=> {
      console.groupCollapsed("Fixing kblocks....", kblocks.length);
      for (let kblock of kblocks) {
        try {
          let updated = false;
          if (kblock.references != null) {
            for (let reference of kblock.references) {
              if (reference.ref.path.startsWith(env.firebaseVariable.concept)) {
                if (reference.project == null) {
                  if(!updated) {
                    console.groupCollapsed("Kblock needs reference update:", kblock);
                  }
                  let concept = await conceptService.getConcept(reference.ref.id).pipe(first()).toPromise();
                  console.log("Concept reference missing project:", Object.assign({}, reference));
                  console.log("Concept:", concept);
                  reference.project = concept.project;
                  updated = true;
                }
              }
            }
          }
          if (updated) {
            console.log("Saving new kblock:", kblock);
            await kblockService.updatekBlock(kblock);
            console.groupEnd();
          }
        } catch(e) {
          console.warn("Something happened updating kblock:", e);
          console.groupEnd();
        }
      }
      console.groupEnd();
      console.log("Done!");
    });
  }

}).catch(err => console.error(err));
