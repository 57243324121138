import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentReference} from "@angular/fire/compat/firestore";
import {User} from "../../model/user.model";
import {Subscription} from "rxjs";
import {UserService} from "../user.service";
import {ThumbnailService} from "../thumbnail.service";

@Component({
  selector: 'app-user-block-view',
  templateUrl: './user-block-view.component.html',
  styleUrls: ['./user-block-view.component.scss']
})
export class UserBlockViewComponent implements OnInit, OnDestroy {

  @Input() userReference : DocumentReference;

  user: User;
  userSubscription: Subscription;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    if(this.userReference != null) {
      this.userSubscription = this.userService.getUser(this.userReference).subscribe((user)=>{
        this.user = user;
      });
    }
  }

  ngOnDestroy(): void {
    if(this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    }
  }

  getPhoto() : string {
    let photoUrl = ThumbnailService.getPhotoFromUser(this.user);

    if(photoUrl == null) {
      photoUrl = "/assets/img/empty_avatar.png";
    }

    return photoUrl;
  }

}
