import {Image} from "../frontend/image-upload-dialog/image-upload-dialog.component";
import {Project} from './project.model';
import {DocumentReference} from '@angular/fire/compat/firestore';

export class User {
    uid: string;
    email: string;
    displayName?: string = "";
    photoURL?: string = "";
    image? : Image;
    emailVerified: boolean;
    savedLanguage?: string;
    description?: string = "";
    hiddenProjects?: DocumentReference<Project>[];
    onboardingLastStep?: number;
}
