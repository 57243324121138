import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Favor } from 'src/app/model/favor.model';
import { kBlockType } from 'src/app/model/kblock.model';
import { AuthService } from 'src/app/service/auth.service';
import {environment} from 'src/environments/environment';

import { ModelService } from 'src/app/service/model.service';
import { Concept } from 'src/app/model/concept.model';
import { CreateKblocksComponent } from 'src/app/kblocks/create-kblocks/create-kblocks.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-diverge-favor',
  templateUrl: './diverge-favor.component.html',
  styleUrls: ['./diverge-favor.component.scss'],
  host: {'class': 'scrollblock'}
})
export class DivergeFavorComponent implements OnInit, OnDestroy {

  current_id : string; 
  favor_id : string; 
  env = environment;
  favor : Favor;
  story : Concept;
  workshop_id : string; 
  user_subscription: Subscription;
  router_subscription: Subscription;
  catalog_loaded: boolean = false;
  ktype = kBlockType;
  isMobile : boolean = false;




  kblock_reference : string;
  kblock_reference_name : string;

  constructor(private authService: AuthService, public model: ModelService,   public dialog: MatDialog, private route: ActivatedRoute, private router: Router) 
  {

    console.log('Constructor');
    this.catalog_loaded = false;
    //Setup mobile check
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 991;
    }
    this.isMobile = window.innerWidth <= 991;


    this.user_subscription = authService.userLoggedIn$.subscribe(user => {
     if (this.route.snapshot.paramMap.get('cid') != this.current_id) {
       this.loadCatalog();
     }

   });

   this.router_subscription = router.events.subscribe((val) => {
     this.catalog_loaded = false;
  //   this.kblock_reference = null;
 //    this.kblock_reference_name = null;

       if ((this.route.snapshot.paramMap.get('cid') != this.current_id || this.route.snapshot.paramMap.get('fid') != this.favor_id) && authService.getUser()) {
         this.loadCatalog();
       }

     }
   )

   this.model.subscribeProjects();
   this.loadCatalog();

 }


 loadCatalog() {

  if (this.catalog_loaded) return;
  this.catalog_loaded = true;
 // this.model.subscribekBlocks(this.model.kblock_reference, kBlockType.Concept);

  if (this.current_id == this.route.snapshot.paramMap.get('cid') && this.favor_id == this.route.snapshot.paramMap.get('fid') ) return;

  this.current_id = this.route.snapshot.paramMap.get('cid');
  this.favor_id = this.route.snapshot.paramMap.get('fid');

  if (this.model.favors) {
    this.favor = this.model.favors.find((fav) => { return fav.id == this.favor_id} )

   if (this.favor)
    {
      this.story = this.model.concepts.find((st) => { return st.id == this.favor.story} )
      this.workshop_id = this.favor.workshop_id;
      this.kblock_reference = this.favor.story;
      this.kblock_reference_name = this.story.name;
    }
  }


  this.model.favorLoaded$.subscribe({
    complete: () => {
      this.favor = this.model.favors.find((fav) => { return fav.id == this.favor_id} )

      if (this.favor)
      {
        this.story = this.model.concepts.find((st) => { return st.id == this.favor.story} )
        this.workshop_id = this.favor.workshop_id;
        this.kblock_reference = this.favor.story;
        this.kblock_reference_name = this.story.name;
       // this.model.kblock_reference = this.model.conceptService.getReferenceById(this.favor.story);
       // this.model.kblock_ref_name = this.story.name;
      }
    }
    });

  this.model.setSelectedProject(this.current_id);


    
  

//  this.model.subscribekBlocks(this.projectService.getProjectReferenceById(this.current_id), kBlockType.Project);
//  this.model.current_page = CurrentPage.catalog_overview;

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.user_subscription)
      this.user_subscription.unsubscribe();
    if (this.router_subscription)
      this.router_subscription.unsubscribe();  
  }

    /** Handles setting a scroll event when you reach the buttom of the page. */
    scrollHandler(e) {
    /*  if (e == 'bottom') {
        this.model.kBlocksMore();
      }*/
    }

    createInspirationBlock() {
      const dialogRef = this.dialog.open(CreateKblocksComponent, {
        panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
        autoFocus: false
      });
  
    }

}
