import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModelService} from "../../service/model.service";
import {ActivePageServiceService} from "../active-page-service.service";

@Component({
  selector: 'app-favor-navigator-dialog',
  templateUrl: './favor-navigator-dialog.component.html',
  styleUrls: ['./favor-navigator-dialog.component.scss']
})
export class FavorNavigatorDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FavorNavigatorDialogComponent>, public model:ModelService, public activePageService:ActivePageServiceService) {

  }

  ngOnInit(): void {
  }

  static openDialog() {
    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(FavorNavigatorDialogComponent, {
      panelClass: ['reinvent-popup']
    });
  }

  close() {
    this.dialogRef.close();
  }

}
