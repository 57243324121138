import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { Process } from '../model/process.model';
import { IdeateNotification, IdeateNotificationType } from '../model/ideate-notification.model';
import { User } from '../model/user.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
 // notification_subscription : Subscription;
//  current_project_id : string = "";
//  public process : Process;
//  private processLoadedSource = new Subject<Process[]>();
 // public processLoaded$ = this.processLoadedSource.asObservable();

  constructor(private firestore: AngularFirestore, private authService : AuthService) { }

  ngOnDestroy() {
  //  if (this.notification_subscription) 
   //   this.notification_subscription.unsubscribe();
  }

  create(notification: IdeateNotification){
    return this.firestore.collection("notifications").add({...notification});

  }


  createNotification(user:User, type:IdeateNotificationType, ref_id : string, observers: DocumentReference[]) 
  {
    var notification: IdeateNotification = new IdeateNotification();
    notification.time_stamp =  new Date();

    notification.actor = {'displayName': user.displayName, 'photoURL': user.photoURL, 'image': user.image, 'uid': user.uid}
    notification.references_id = ref_id;
    notification.observers = observers;
    notification.type = type;
    this.create(notification);
  }


  getById(id : string) {
    return this.firestore.doc<Process>('notifications/' + id).valueChanges();
  }

  delete(id: string){
      this.firestore.doc('notifications/' + id).delete();
  }


}