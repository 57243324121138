import {Component, Input, OnInit} from '@angular/core';
import {Catalog} from "../catalog.service";

@Component({
  selector: 'app-participant-view',
  templateUrl: './participant-view.component.html',
  styleUrls: ['./participant-view.component.scss'],
})
export class ParticipantViewComponent implements OnInit {

  @Input() catalog: Catalog;

  @Input() fullpage : boolean = false;

  public viewAll: boolean;


  constructor() {


   }

  ngOnInit(): void {
  }

  getParticipants() {
    if(this.catalog != null && this.catalog.participants_role != null) {
      return this.catalog.participants_role.map((role) => {
        if (this.viewAll || this.fullpage) {
          return role.id;
        } else if (role.role == "owner" || role.role == "admin") {
          return role.id;
        }
      });
    }
    return [];
  }

}
