<div *ngIf="model.process_service.process && model.process_service.process.workshops">
    <div *ngIf="selected_favor">
        <div   *ngFor="let favor of model.favors; let fid = index">
            <div *ngIf="workshop_id && workshop_id && favor.workshop_id == workshop_id" >
                <div *ngIf="selected_favor && favor.id == selected_favor.id">

                    <div class="heading3">{{favor.title}}  {{favorString(favor)}}</div>
                    <div *ngIf="favor.description!=''" [innerHTML]="favor.description"> </div>

                    <div [ngSwitch]="favor.type">
                        <div *ngSwitchCase="FavorType.StoryRatings" class="catalog-description">
                            <span *ngFor="let concept of model.concepts">
                                <span *ngIf="model.inFavors(favor, concept.id)">
                                    <div [ngSwitch]="(model.concept.id == concept.id) && selected_favor!=null && selected_favor.id==favor.id" >
                                        <div *ngSwitchCase="true">
                                            <div class="story-selected">
                                                <mat-icon *ngIf="ratingCompleted(concept)" class="favor-icon favor-icon-selected">check_circle</mat-icon>   <mat-icon class="favor-icon" *ngIf="!ratingCompleted(concept)">play_circle_outline</mat-icon>
                                                {{concept.name}}  <br/>
                                            </div>
                                        </div>
                                        <div *ngSwitchCase="false">
                                            <mat-icon *ngIf="ratingCompleted(concept)" class="favor-icon favor-icon-selected">check_circle</mat-icon>   <mat-icon class="favor-icon" *ngIf="!ratingCompleted(concept)">play_circle_outline</mat-icon>
                                            <a (click)="navigateTo('/'+env.links.viewStory+'/'+concept.id+'/'+favor.id)">{{concept.name}}</a>  <br/>
                                        </div>
                                    </div>
                                </span>
                            </span>
                            <br/>  
                        </div>
                    </div>

                    <div *ngIf="showBackButton">
                        <br/>
                        <button  mat-flat-button  color="primary" class="workshop-done" routerLink="{{'/'+env.links.workshop+'/'+model.project.id+'/'+workshop_id}}">{{'Back to workshop'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ul *ngIf="!selected_favor" class="task-list">
        <div   *ngFor="let favor of model.favors; let fid = index">
            <div *ngIf="workshop_id && workshop_id && favor.workshop_id == workshop_id" >
                <li>
                    <div class="task-content">
                        <div class="task-title"> {{favor.title}}  {{favorString(favor)}}  <mat-icon *ngIf="favorCompleted(favor)" class="favor-icon favor-icon-selected">check_circle</mat-icon></div>
                        <div class="task-tagline">
                            <div *ngIf="favor.description" class="favor-description">
                                <div *ngIf="favor.description!=''" [innerHTML]="favor.description"> </div>
                            </div>

                            <div [ngSwitch]="favor.type">
                                <div *ngSwitchCase="FavorType.StoryRatings" class="catalog-description">
                                    <span *ngFor="let concept of model.concepts">
                                        <span *ngIf="model.inFavors(favor, concept.id)">
                                            <div [ngSwitch]="(model.concept.id == concept.id) && selected_favor!=null && selected_favor.id==favor.id" >
                                                <div *ngSwitchCase="true">
                                                    <div class="story-selected">
                                                        <mat-icon *ngIf="ratingCompleted(concept)" class="favor-icon favor-icon-selected">check_circle</mat-icon>   <mat-icon class="favor-icon" *ngIf="!ratingCompleted(concept)">play_circle_outline</mat-icon>
                                                        {{concept.name}}  <br/>

                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="false">
                                                    <mat-icon *ngIf="ratingCompleted(concept)" class="favor-icon favor-icon-selected">check_circle</mat-icon>   <mat-icon class="favor-icon" *ngIf="!ratingCompleted(concept)">play_circle_outline</mat-icon>
                                                    <a routerLink="/{{env.links.viewStory}}/{{concept.id}}/{{favor.id}}">{{concept.name}}</a>  <br/>
                                                </div>

                                            </div>

                                        </span>
                                    </span><br/><br/>
                                </div>
                                <div *ngSwitchCase="FavorType.DivergeFavor" class="start-button-div">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="favor.type==FavorType.DivergeFavor" class="task-action">      
                        <span [ngSwitch]="favor.type">
                            <span *ngSwitchCase="FavorType.DivergeFavor">
                                <a href="#" routerLink="{{'/'+env.links.divergeFavor+'/'+model.project.id+'/'+favor.id}}">{{'Start' | translate}}</a>
                            </span>  
                        </span>       
                    </div>

                    <div class="task-menu">
                        <button *ngIf="this.model.adminRole()" mat-icon-button [matMenuTriggerFor]="edit_favor_menu"
                                [matMenuTriggerData]="{id : favor.id, favor: favor}" aria-label="Workshop menu">
                            <mat-icon>more_vert</mat-icon>
                        </button> 
                    </div>
                </li>
            </div>
        </div>
        <li>
            <div class="task-final">
                <a href="#" routerLink="{{'/'+env.links.workshop+'/'+model.project.id+'/'+workshop_id+'/'+true}}">
                    <div class="task-final-icon">          <mat-icon>check_circle</mat-icon>
                    </div>
                    <div class="task-final-text">
                        {{'End workshop' | translate}}
                    </div>  
                </a>
            </div>
        </li>
    </ul>
</div>

<mat-menu #edit_favor_menu="matMenu">
    <ng-template matMenuContent let-id ="id" let-favor="favor">


        <button mat-menu-item (click)="editFavor(favor)">
            <mat-icon>edit</mat-icon>
            <span translate>Edit</span>
        </button>       


        <button mat-menu-item (click)="deleteFavor(id)">
            <mat-icon>delete</mat-icon>
            <span translate>Delete</span>
        </button>

    </ng-template>
</mat-menu>