import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';

import {ConceptService} from 'src/app/service/concept.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ProjectService} from 'src/app/service/project.service';
import {environment} from 'src/environments/environment';
import {StoryMenuComponent} from 'src/app/menu/story-menu/story-menu.component';
import {ModelService, CurrentPage} from 'src/app/service/model.service';
import {kBlockType} from 'src/app/model/kblock.model';
import {AuthService} from 'src/app/service/auth.service';
import {ImageUploadDialogComponent} from '../../frontend/image-upload-dialog/image-upload-dialog.component';
import {ThumbnailService} from '../../frontend/thumbnail.service';
import {EditorJsComponent, EditorJSContent} from "../../frontend/editor-js/editor-js.component";
import { StoryRating } from 'src/app/model/project.model';
import { Subscription } from 'rxjs';
import { Favor } from 'src/app/model/favor.model';
import { MatDialog } from '@angular/material/dialog';
import { CreateKblocksComponent } from 'src/app/kblocks/create-kblocks/create-kblocks.component';
import { EditorDialogComponent } from 'src/app/dialogs/editor-dialog/editor-dialog.component';
import {StoryRatingSettingComponent} from "../../frontend/story-rating-setting/story-rating-setting.component";
import {ColorEvent} from "ngx-color";
import {KblockService} from "../../service/kblock.service";
import { CreateConceptComponent } from '../create-concept/create-concept.component';

@Component({
  selector: 'app-view-concept',
  templateUrl: './view-concept.component.html',
  styleUrls: ['./view-concept.component.scss'],
  host: {'class': 'flexblock'}
})
export class ViewConceptComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("currentColor") currentColor;

  ktype = kBlockType;

  isLoaded: boolean = false;
  editmode: boolean = false;
  storyRating : StoryRating = null;
  storyRatingLoaded : boolean = false;
  env = environment;
  column_view = true;
  favor_id: string;
  current_favor: Favor;
  save_subscription : Subscription;
  edit_subscription : Subscription;
  current_id : string;

  @ViewChild(EditorJsComponent) editorjs: EditorJsComponent;

  isMobile : boolean = false;

  constructor(public authService: AuthService, public model: ModelService, public route: ActivatedRoute, private kblockService:KblockService, public projectService: ProjectService,    public dialog: MatDialog, public conceptService: ConceptService, private router: Router, private thumbnailService: ThumbnailService) {
    //Setup mobile check
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 991;
    }
    this.isMobile = window.innerWidth <= 991;

    this.favor_id = this.route.snapshot.paramMap.get('fid');
    if (this.favor_id)
    {
      this.current_favor = model.favors.find( value => value.id == this.favor_id)

    }

    this.route.url.subscribe(url => {
      this.current_id = this.route.snapshot.paramMap.get('id');
      if ((model.setSelectedConcept(this.route.snapshot.paramMap.get('id')))==false)
      {
        this.isLoaded = true;
      }
      this.storyRatingLoaded = false;

      this.favor_id = this.route.snapshot.paramMap.get('fid');
      if (this.favor_id)
      {
        this.current_favor = model.favors.find( value => value.id == this.favor_id);
      }

      console.log(this.favor_id);
      this.model.subscribekBlocks(this.conceptService.getReferenceById(this.route.snapshot.paramMap.get('id')), kBlockType.Concept);
      this.model.current_page = CurrentPage.story_view;
    });
  }



  ngOnInit(): void {
    this.getStoryRatings();
    this.model.current_page = CurrentPage.story_view;


   /* this.authService.userLoggedIn$.subscribe(user => {
      this.model.setSelectedConcept(this.route.snapshot.paramMap.get('id'));
      this.model.subscribekBlocks(this.conceptService.getReferenceById(this.route.snapshot.paramMap.get('id')), kBlockType.Concept);
    });*/

    this.model.conceptLoaded$.subscribe(concept => {
      this.getStoryRatings();

      this.isLoaded = true;
      this.editmode = false;
      this.favor_id = this.route.snapshot.paramMap.get('fid');
      if (this.favor_id)
      {
        this.current_favor = this.model.favors.find( value => value.id == this.favor_id)
      }

      if (this.save_subscription)
        this.save_subscription.unsubscribe();

      if (this.edit_subscription)
        this.edit_subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {

    if (this.save_subscription)
      this.save_subscription.unsubscribe();

    if (this.edit_subscription)
      this.edit_subscription.unsubscribe();

  }

  getImage(): string {
    if(this.model.concept.image != null) {
      return this.model.concept.image.url.normal;
    } else {
      return this.model.concept.image_name;
    }
  }

  deleteConcept() {
    this.model.unsubscribeConcept();
    this.conceptService.deleteConcept(this.model.concept.id);
    this.router.navigate(['/' + environment.links.viewProject + '/' + this.model.concept.project.id]);
  }

  showImageUploadDialog() {
    ImageUploadDialogComponent.showUploadDialog().subscribe((image) => {
      if (image == null) {
        return;
      }

      //Delete old file
      if (this.model.concept.image_name) {
        this.model.concept.image_name = "";
        try {
          this.thumbnailService.delete(this.model.concept.image_name);
        } catch(e) {
          //Ignore silently
        }
      }

      if (this.model.concept.image) {
        this.thumbnailService.delete(this.model.concept.image);
      }

      //Attach new image
      this.model.concept.image = image;

      //Save concept
      this.updateConcept();
    });
  }

  enterEditmode() {
    this.editmode = true;

    if (this.save_subscription)
      this.save_subscription.unsubscribe();

    if (this.edit_subscription)
      this.edit_subscription.unsubscribe();

    setTimeout(()=>{
      this.save_subscription=this.editorjs.saved.subscribe((result)=>{
        this.model.concept.content = result;
        this.updateConcept();
        this.editmode = false;
      });
      this.edit_subscription = this.editorjs.canceled.subscribe(()=>{
        this.editmode = false;
      });
    }, 0);
  }

  updateConcept() {
    this.conceptService.updateConcept(Object.assign({}, this.model.concept));
  }

  scrollHandler(e) {
 /*   if (e == 'bottom') {
      this.model.kBlocksMore();
    }*/
  }

  getStoryRatings(){
    // if (this.storyRating || this.storyRatingLoaded) return this.storyRating;
    if (this.model.concept.story_rating && this.model.concept.story_rating>0)
    {
      this.storyRating = this.model.project.storyRatings; //.getStoryRating(this.model.concept);
    } else {
      this.storyRating = null;
    }
    console.log("GET STORY RATING: " + this.storyRating);
    this.storyRatingLoaded = true;
  }

  showStoryRatingDialog() {
    StoryRatingSettingComponent.showDialog(this.model.project, this.model.concept);
  }

  setStoryRating(value : number) {
    this.model.concept.story_rating = value;
    this.updateConcept();
    this.getStoryRatings();
  }

  toggleShowComments() {
    if(this.model.concept.showComments == null) {
      this.model.concept.showComments = false;
    }

    this.model.concept.showComments = !this.model.concept.showComments;

    this.updateConcept();
  }


  openEditDialog(content : EditorJSContent, projectId: string) {
      const dialogRef = this.dialog.open(EditorDialogComponent, {
        data: {projectId : projectId, content: content, ktype : kBlockType.Concept, conceptId: this.model.concept.id},
        width: "1350px",
        height: "1350px",
        panelClass: ['reinvent-popup'],
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.model.concept.content = result;
          this.updateConcept();
        }
      });
  }


  createInspirationBlock() {
    const dialogRef = this.dialog.open(CreateKblocksComponent, {
      panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
      autoFocus: false
    });

  }


  editStory()
  {
    CreateConceptComponent.showDialog(-1,this.model.concept);
  }


  colorChangeComplete(event: ColorEvent) {
    this.model.concept.color = event.color.hex;
    this.conceptService.updateConcept(this.model.concept).then(()=>{
      let conceptRef = this.conceptService.getReference(this.model.concept);
      this.kblockService.updateColorFromReference(conceptRef, this.model.concept.name, this.model.concept.color);
    });
  }



  getCurrentColor() {
    if(this.currentColor?.nativeElement != null) {
      if(this.model?.concept?.color) {
        this.currentColor.nativeElement.style.backgroundColor = this.model.concept.color;
      } else {
        this.currentColor.nativeElement.style.backgroundColor = "#fff";
      }
    }

    if(this.model?.concept?.color != null) {
      return this.model.concept.color;
    } else {
      return "#fff";
    }
  }
}
