import { Component, OnInit } from '@angular/core';
import { ModelService, CurrentPage } from 'src/app/service/model.service';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { kBlockType } from 'src/app/model/kblock.model';
import { CreateKblocksComponent } from 'src/app/kblocks/create-kblocks/create-kblocks.component';
import { MatDialog } from '@angular/material/dialog';
import { ThumbnailService } from 'src/app/frontend/thumbnail.service';
import { User } from 'src/app/model/user.model';
import { LanguageService } from 'src/app/frontend/language.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-main-overview',
  templateUrl: './main-overview.component.html',
  styleUrls: ['./main-overview.component.scss'],
  host: {'class': 'flexblock'}
})
export class MainOverviewComponent implements OnInit {
  ktype = kBlockType;
  user : User;
  isMobile : boolean = false;
  env = environment;


  constructor(public model :ModelService, public dialog: MatDialog, public languageService: LanguageService, private thumbnailService : ThumbnailService, public authService : AuthService, private route: ActivatedRoute ) {
    authService.userLoggedIn$.subscribe(user => {
      this.model.current_page = CurrentPage.dashboard;
      this.model.subscribeProjects();
   //   this.model.subscribekBlocks(null, kBlockType.User);
      //Setup mobile check
      window.onresize = () => {
        this.isMobile = window.innerWidth <= 991;
      }
      this.isMobile = window.innerWidth <= 991;
    });

    authService.userLoggedIn$.subscribe(user => {
      this.user = user;
  });
    this.route.url.subscribe(url =>{
      this.model.current_page = CurrentPage.dashboard;
    });


  }

  ngOnInit(): void {
    this.model.resetProject(true);
    this.model.subscribeProjects();
    this.model.current_page = CurrentPage.dashboard;
    if (this.authService.getUser())
    this.user = this.authService.getUser();

  //  this.model.subscribekBlocks(null, kBlockType.User);
  }

  createInspirationBlock() {
    const dialogRef = this.dialog.open(CreateKblocksComponent, {
      panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
      autoFocus: false
    });

  }



  scrollHandler(e)
  {
 /*  if (e== 'bottom') {
      this.model.kBlocksMore();
    }*/
  }

  hasPhoto(): boolean {
    return ThumbnailService.getPhotoFromUser(this.model.user) != null;
  }
  
  getPhoto():string {
    return ThumbnailService.getPhotoFromUser(this.model.user);
  }
  

}
