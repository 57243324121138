<!-- User menu -->
<div class="bb-image">
    <!-- Home Link -->
<a routerLink="/" class="homelink" title="Home">
    <!--   <mat-icon class="ideate-icon">home</mat-icon>--> <span class="hide-when-minimized logo-text1">ideate.nu</span>
  </a>  <!-- 
<a href="javascript:void(null);" [matMenuTriggerFor]="profile_menu" *ngIf="user" class="sidebar-entry user-menu">
    <ng-template #noProfileImage>
        <span class="avatar-div"><img class="avatar" width="36" height="36" [src]='env.no_person_url'/></span>
    </ng-template>

      
 <span class="avatar-div" *ngIf="hasPhoto(); else noProfileImage">
        <img class="avatar small" [src]='getPhoto()'/>
    </span>

    <span class="title" *ngIf="user.displayName">{{user.displayName}} </span><mat-icon class="icon-size-small">expand_more</mat-icon>

</a>-->



<!-- Mini-breadcrumb -->
 <!-- <div class="mini-breadcrumb hide-when-minimized">
    <a class="sidebar-entry " routerLink="/project/{{model.project.id}}" [attr.title]="model.project.name" *ngIf="this.model.project_id">
        {{model.project.name}}
    </a>
   <a class="sidebar-entry" routerLink="/view-concept/{{model.concept.id}}" [attr.title]="model.concept.name" *ngIf="this.model.concept_id">
        {{model.concept.name}}
    </a> 
</div>-->
</div>



<mat-menu #language_menu="matMenu">
    <button mat-menu-item (click)="languageService.useLanguage('en')" translate>English</button>
    <button mat-menu-item (click)="languageService.useLanguage('da')" translate>Dansk</button>
</mat-menu>
<mat-menu #profile_menu="matMenu">
    <button mat-menu-item routerLink="/user-data">
        <mat-icon>edit</mat-icon> <span translate>Edit user</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="language_menu" aria-label="Language Menu" translate>
        <mat-icon>language</mat-icon> <span translate>Switch language</span>
    </button>

    <button mat-menu-item (click)="authService.SignOut()">
        <mat-icon>logout</mat-icon> <span translate>Log out</span>
    </button>
</mat-menu>  