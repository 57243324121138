import { Component, OnInit } from '@angular/core';
import { ModelService, CurrentPage } from 'src/app/service/model.service';
import { User } from 'src/app/model/user.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {ImageUploadDialogComponent} from "../../frontend/image-upload-dialog/image-upload-dialog.component";
import {CatalogService} from "../../frontend/catalog.service";
import {AuthService} from "../../service/auth.service";
import {KblockService} from "../../service/kblock.service";

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css'],
  host: {class:"scrollBlock"}
})
export class UserDataComponent implements OnInit {

  constructor(public model : ModelService, private router : Router, private catalogService: CatalogService, private authService: AuthService, private kblockService: KblockService) {
  }

  ngOnInit(): void {
    this.model.current_page = CurrentPage.user_profile;
  }

  changePhoto() {
    ImageUploadDialogComponent.showUploadDialog(true).subscribe((img)=>{
      if (img == null) {
        return;
      }

      this.model.user.image = img;
    });
  }

  getUserImage():string {
    if(this.model.user.image != null) {
      return this.model.user.image.url.small;
    } else {
      return this.model.user.photoURL;
    }
  }

  saveUserData() : void {
    console.log("Saving user: ", this.model.user);

    this.model.updateUser().then(()=>{
      //Update all owner data of all kblocks
      let subscription = this.catalogService.getKblocksFromOwner(this.authService.getUserReference()).subscribe((kblocks)=>{
        subscription.unsubscribe();
        kblocks.forEach((kblock)=>{
          kblock.owner_data.displayName = this.model.user.displayName;
          kblock.owner_data.image = this.model.user.image;
          this.kblockService.updatekBlock(Object.assign({}, kblock));
        });

        //Navigate away
        this.router.navigate(['/']);
      });
    });
  }
}
