import {Component, OnInit, ViewChild, ViewContainerRef, TemplateRef, Input} from '@angular/core';
import {StoryRating} from "../../model/project.model";

@Component({
  selector: 'app-story-rating-editor',
  templateUrl: './story-rating-editor.component.html',
  styleUrls: ['./story-rating-editor.component.scss']
})
export class StoryRatingEditorComponent implements OnInit {

  @Input() storyRatings: StoryRating;

  constructor() {
  }

  ngOnInit(): void {
  }

  questionIndex(index, item) {
    return index;
  }
}
