import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import EditorJS, {OutputData} from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';

import {QuestionnaireBlock} from './questionnaire-block/questionnaire-block';
import {ImageBlock} from './image-block/image-block';

import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatDialog} from '@angular/material/dialog';
import {KblockBlock} from "./kblock-block/kblock-block";
import {ConceptPuzzleBlock} from './concept-puzzle-block/concept-puzzle-block';
import {StoryRatingMapBlock} from './story-rating-map-block/story-rating-map-block';
import {TranslateService} from "@ngx-translate/core";

export interface EditorJSContent {
  content : any
}

class IdeateTable extends Table {
  constructor(args) {
    if(args.data.content != null) {
      try {
        args.data.content = JSON.parse(args.data.content);
      } catch (e) {
        console.warn("Unable to parse: ", args.data.content);
      }
    }
    super(args);
  }

  save(toolsContent) {
    let superData = super.save(toolsContent);

    superData.content = JSON.stringify(superData.content, null, 2);

    return superData;
  }

  render() {
    return super.render();
  }
}

@Component({
  selector: 'app-editor-js',
  templateUrl: './editor-js.component.html',
  styleUrls: [
    './editor-js.component.scss',
    './questionnaire-block/questionnaire-block.scss',
    './image-block/image-block.scss',
    './kblock-block/kblock-block.scss',
    './concept-puzzle-block/concept-puzzle-block.scss',
    './story-rating-map-block/story-rating-map-block.scss'
  ]
})
export class EditorJsComponent implements OnInit, AfterViewInit {

  @ViewChild("editorjs") editorRef;

  @Output() saved: EventEmitter<OutputData> = new EventEmitter();
  @Output() canceled: EventEmitter<null> = new EventEmitter();

  @Input() content: EditorJSContent;
  @Input() cancelText: string = null;
  @Input() saveText: string = null;
  @Input() dialogView: boolean = false;

  @Input() projectId: string;

  editor: EditorJS;

  private observer = null;

  constructor(public firestore : AngularFirestore, private dialog : MatDialog, private translate: TranslateService) {
    if(this.cancelText == null) {
      this.translate.stream("Cancel").subscribe((translation)=>{
        this.cancelText = translation;
      });
    }
    if(this.saveText == null) {
      this.translate.stream("Save").subscribe((translation)=>{
        this.saveText = translation;
      });
    }
  }

  ngAfterViewInit(): void {
    let self = this;

    //Get translations
    this.translate.get("Text-EditorJS").subscribe(()=>{
      //Translation is loaded, use instant here

      //Create editor
      this.editor = new EditorJS({
        holder: this.editorRef.nativeElement,
        tools: {
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: this.translate.instant("Placeholder-EditorJS"),
            }
          },
          header: {
            class: Header,
            inlineToolbar: ['link']
          },
          list: {
            class: List
          },
          table: {
            class: IdeateTable
          },
          image: ImageBlock,
          questionnaire: QuestionnaireBlock,
          kblock: KblockBlock,
          conceptPuzzle: {
            class: ConceptPuzzleBlock,
            config: {
              projectId: this.projectId
            }
          },
          storyRatingMap: {
            class: StoryRatingMapBlock,
            config: {
              projectId: this.projectId
            }
          }
        },
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              "blockTunes": {
                "toggler": {
                  "Click to tune": this.translate.instant("Click to tune-EditorJS"),
                  "or drag to move": this.translate.instant("or drag to move-EditorJS")
                }
              },
              "inlineToolbar": {
                "converter": {
                  "Convert to": this.translate.instant("ConvertTo-EditorJS")
                }
              },
              "toolbar": {
                "toolbox": {
                  "Add": this.translate.instant("Add-EditorJS")
                }
              }
            },
            "blockTunes": {
              "delete": {
                "Delete": this.translate.instant("Delete-EditorJS")
              },
              "moveUp": {
                "Move up": this.translate.instant("Move up-EditorJS")
              },
              "moveDown": {
                "Move down": this.translate.instant("Move down-EditorJS")
              }
            },
            tools: {
              list: {
                "Ordered": this.translate.instant("Ordered-EditorJS"),
                "Unordered": this.translate.instant("Unordered-EditorJS")
              },
              link: {
                "Add a link": this.translate.instant("AddALink-EditorJS")
              }
            },
            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              "Text": this.translate.instant("Text-EditorJS"),
              "Bold": this.translate.instant("Bold-EditorJS"),
              "Italic": this.translate.instant("Italic-EditorJS"),
              "Link": this.translate.instant("Link-EditorJS"),
              "Heading": this.translate.instant("Heading-EditorJS"),
              "List": this.translate.instant("List-EditorJS"),
              "Table": this.translate.instant("Table-EditorJS"),
              "Image": this.translate.instant("Image-EditorJS"),
              "Concept Puzzle": this.translate.instant("ConceptPuzzle-EditorJS"),
              "Story Rating Map": this.translate.instant("StoryRatingMap-EditorJS"),
              "Questionnaire": this.translate.instant("Questionnaire-EditorJS")
            }
          }
        },
        data: (this.content!=null?this.content.content:null)
      });
    });
  }

  ngOnInit(): void {

    if (!this.dialogView)
    {
      this.observer = new IntersectionObserver(
        ([e]) => { e.target.toggleAttribute('floating', e.intersectionRatio < 1)},
        {threshold: [1]}
      );
      this.observer.observe(document.querySelector('.floaty-detector'));

    }
  }

  save(): void {
    this.editor.save().then((result)=>{
      this.saved.emit(result);
    });
  }

  cancel(): void {
    this.canceled.emit(null);
  }
}
