<div class="top-image-cover-overlay-frontpage " *ngIf="!isMobile"></div>

<div  class="home-top-menu">
        <div class="menu-content" (click)="$event.stopPropagation()" *ngIf="!isMobile">
            <div class="menu-left">
                
                <div class="home-title" translate>{{ getTitle() }}</div>


            </div>
      <div class="menu-right">
        <!--            <div *ngIf="env.links.home != current_page" class="catalog-item">
                <a class="catalog-link" href="javascript:void(null);"
                   [routerLink]="'/'"
                   (click)="$event.stopPropagation()" translate>Home</a>
            </div>


            <div *ngIf="env.links.home == current_page"
                 class="catalog-item catalog-item-selected" translate>
                 Home
            </div>


            <div *ngIf="env.links.viewProjects != current_page" class="catalog-item">
                <a class="catalog-link" href="javascript:void(null);"
                   [routerLink]="'/'+env.links.viewProjects"
                   (click)="$event.stopPropagation()">{{'top-menu.all-projects' | translate }}</a>
            </div>


            <div *ngIf="env.links.viewProjects == current_page"
                 class="catalog-item catalog-item-selected">
                 {{'top-menu.all-projects' | translate }}            </div>
-->
            <div class="avatar-div">
                <a href="javascript:void(null);" [matMenuTriggerFor]="profile_menu" *ngIf="user" >
                    <ng-template #noProfileImage>
                        <span class="avatar-div"><img class="avatar" width="36" height="36" [src]='env.no_person_url'/></span>
                    </ng-template>
                
                      
                 <span *ngIf="hasPhoto(); else noProfileImage">
                        <img class="avatar" [src]='getPhoto()'/>
                    </span>
                
                 
                </a>
            </div>

                <!--     <a  *ngIf="model.project" class="catalog-item" href="javascript:void(null);"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id">Participant</a>  -->
                <div><a *ngIf="model.project" href="javascript:void(null);" class="menu-icon"
                        [matMenuTriggerFor]="header_menu" aria-label="Header menu"
                        (click)="$event.stopPropagation()">
                    <mat-icon>more_horiz</mat-icon>
                </a></div>

            </div>
        </div>
    </div>
  

    <mat-menu #header_menu="matMenu">
      <ng-template matMenuContent>
  
  
  <button mat-menu-item [matMenuTriggerFor]="language_menu" aria-label="Language Menu" translate>
      <mat-icon>language</mat-icon> <span translate>Switch language</span>
  </button>
  
  
  
      </ng-template>
  </mat-menu>
  
  
  <mat-menu #language_menu="matMenu">
      <button mat-menu-item (click)="languageService.useLanguage('en')" translate>English</button>
      <button mat-menu-item (click)="languageService.useLanguage('da')" translate>Dansk</button>
  </mat-menu>
  
  <mat-menu #profile_menu="matMenu">
      <button mat-menu-item routerLink="/user-data">
          <mat-icon>edit</mat-icon> <span translate>Edit user</span>
      </button>
  
  
  
      <button mat-menu-item (click)="authService.SignOut()">
          <mat-icon>logout</mat-icon> <span translate>Log out</span>
      </button>
  </mat-menu>  
  
  
  <!--<div class="center">
  <div>

    <nav class="navbar navbar-light navbar-expand-md  row header_main">

      <a routerLink="/" class="no_link navbar-brand"><span class="logo-text1">ideate.nu</span>
      </a><br/><br/>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>



      <div class="collapse navbar-collapse top-menu-padding" id="collapsibleNavbar">
        <ul class="navbar-nav top-item   mr-auto">



          
          <li class="nav-item menu-text nav-link" [matMenuTriggerFor]="cat_menu" >
<span *ngIf="model && model.project &&  model.current_page != current_page.dashboard">
            {{model.project.name}}</span>
            <span *ngIf="!model || !model.project || model.project.name=='' || model.current_page == current_page.dashboard" translate>
            Projects</span>        
            <mat-icon >expand_more</mat-icon>

          </li>
          
        </ul>

        <ng-container *ngIf="authService.isLoggedIn else notLoggedIn">

        <ul class="navbar-nav top-item ">
          <li class="nav-item">
               <a class="nav-link menu-text" href="javascript:void(null);" [matMenuTriggerFor]="language_menu" aria-label="Language Menu" translate >Switch languages</a>
             </li>
             <li class="nav-item">

              <a class="nav-link menu-text" href="javascript:void(null);" [matMenuTriggerFor]="profile_menu"> 
                
                <span class="avatar-div" *ngIf="hasPhoto(); else noProfileImage">
                  <img class="avatar" width="36" height="36" [src]='getPhoto()'/></span>
                
                
                <ng-template #noProfileImage>
                    <span class="avatar-div"><img class="avatar" width="36" height="36" [src]='env.no_person_url'/></span>
                
                  </ng-template>
                
                
                   <span class="material-icons"><mat-icon>person</mat-icon> 
              
              
              
              
              </span>
                <span class="menu-text" *ngIf="user && user.displayName">{{user.displayName}}</span></a>
            </li>


           </ul>
             <li class="nav-item " *ngIf="authService.isLoggedIn">
            <a class="nav-link menu-text" [routerLink]="env.links.startDashboard" translate>Inspiration blocks</a>
          </li>

        <button mat-icon-button [matMenuTriggerFor]="language_menu" aria-label="Language Menu"
                class="second-last-menu-item ">
          <span class="menu-text" translate> Switch language</span>
        </button>



        <ng-container *ngIf="authService.isLoggedIn else notLoggedIn">
          <button mat-icon-button [matMenuTriggerFor]="profile_menu" aria-label="profile Menu"
                  class="last-menu-item menu-text">
            <mat-icon>person</mat-icon>
            <span *ngIf="user && user.displayName">{{user.displayName}}</span>
          </button>


        </ng-container>
        <ng-template #notLoggedIn>
          <ul class="navbar-nav ml-auto last-menu-item-login last-menu-item">

            <li class="nav-item">
              <a class="nav-link menu-text" routerLink="/login">Login</a>
            </li>
            <li class="nav-item">
              <button mat-flat-button color="primary"  routerLink="/register" translate>{{'REGISTER'|translate}}</button>
            </li>

          </ul>
        </ng-template>
      </div>
    </nav>
  </div>
</div>



<mat-menu #cat_menu="matMenu">
  <button mat-menu-item routerLink="{{env.links.viewProjects}}" translate>All projects</button>

    <button mat-menu-item routerLink="/project/{{proj.id}}" *ngFor="let proj of model.projects">{{proj.name}}</button>
</mat-menu>


<mat-menu #language_menu="matMenu">
  <button mat-menu-item (click)="languageService.useLanguage('en')" translate>English</button>
  <button mat-menu-item (click)="languageService.useLanguage('da')" translate>Dansk</button>
</mat-menu>


<mat-menu #profile_menu="matMenu">
  <button mat-menu-item routerLink="/user-data" translate>Edit user</button>
  <button mat-menu-item (click)="authService.SignOut()" translate>Log out</button>

</mat-menu>

-->