import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ModelService} from "../../service/model.service";
import {AuthService} from "../../service/auth.service";
import {ProjectService} from "../../service/project.service";
import {Catalog} from "../catalog.service";

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {

  @Input() catalog : Catalog;
  @Input() isAdmin : boolean = false;
  @Input() includeEditor: boolean = false;

  editing: boolean = false;

  constructor(private projectService:ProjectService) {
  }

  ngOnInit(): void {
  }

  enterEditMode() {
    this.editing = true;
  }

  private updateProject() {
    const projectClone = Object.assign({}, this.catalog);
    this.projectService.updateProject(projectClone);
  }

  updateInfoQuestionnaire(saveData) {
    this.catalog.infoQuestionnaire = {
      content: saveData
    };
    this.updateProject();
    this.exitEditMode();
  }

  exitEditMode() {
    this.editing = false;
  }
}
