
<h1 mat-dialog-title *ngIf="addFavor">{{'Add a new favor'|translate}}</h1>
<h1 mat-dialog-title *ngIf="!addFavor">{{'Edit favor'|translate}}</h1>

<div class="info-description">{{'A rating favor is a way of organizing what you ask your community to contribute with. This favor is a rating favor and asks the users to rate one or more stories.'|translate}}</div><br/>


    <div >



      <mat-form-field appearance="standard" class="form-size">
        <mat-label>{{'Provide a name for your favor'|translate}}</mat-label>

      <input class="input_field" matInput type="text" [(ngModel)]="favor.title" matTooltip="{{'Favor title'|translate}}" placeholder="{{'Please type in the title'|translate}}"  required>
      </mat-form-field><br/><br/>


      <div class="heading4" translate>Description</div>

      <div class="info-description">{{'Provide a short introduction text to your favor. What would you like the user to contribute with'|translate}}</div><br/>


    <!--          <app-editor-js  *ngIf="favor" [content]="favor" [projectId]="favor.id" [dialogView]="true"></app-editor-js>-->
    
    <div class="favor-editor">
         <quill-editor [(ngModel)]="favor.description" >
  
            <div quill-editor-toolbar>
                <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>

                  </span>
              <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>

                  </span>

              <select class="ql-header">
                <option value="1">{{'Heading 1'|translate}}</option>
                <option value="2">{{'Heading 2'|translate}}</option>
                <option value="3">{{'Heading 3'|translate}}</option>
                <option value="">{{'Normal'|translate}}</option>
              </select>
              <span class="ql-formats">
                <button class="ql-link"></button>
              </span>
            </div>
          </quill-editor>
        </div>

<br/>

     <!--  <div>
        <button mat-button matStepperNext>Next</button>
      </div>!-->
    </div>


 <!--


  <div class="input_header">Favor title</div>


  <div class="input_header">Favor description</div>
  <div [ngSwitch]="editmode" id="storyview">
    <div *ngSwitchCase="true">
      <app-editor-js [content]="favor" [projectId]="favor.id" [dialogView]="true"></app-editor-js>
    </div>
    <div *ngSwitchCase="false">
        <app-editor-js-viewer [blocks]="favor.content.blocks"></app-editor-js-viewer>
    </div>
  </div>










  !-->




    <div >
      <br/> 
      <br/> 
      <div class="heading4" translate>Stories to rate</div>

      <div class="info-description">{{'Drag the stories to the right box if you want to add them to this favor.'|translate}}</div><br/>

      <div class="example-container">
        <h2>{{'Not selected stories'|translate}}</h2>

        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="all_concepts"
          [cdkDropListConnectedTo]="[doneList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of all_concepts" cdkDrag>{{item.name}}</div>
        </div>
      </div>

      <div class="example-container">
        <h2>{{'Selected stories'|translate}}</h2>

        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="selected_concepts"
          [cdkDropListConnectedTo]="[todoList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of selected_concepts" cdkDrag>{{item.name}}</div>
        </div>
      </div>


          <br/> 


      <!--   <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>  !-->
    </div>







    <div class="info-description">{{'You are now ready to add/update your favor. Make sure that rating is enabled for the stories you have selected.'|translate}}</div>
    <div>
      <!--   <button mat-button (click)="stepper.reset()">Reset</button>  !-->

    </div>


<div mat-dialog-actions class="dialog-action-buttons">
  <button mat-button mat-dialog-close>Close</button>

  <button mat-raised-button mat-dialog-close color="primary" *ngIf="!addFavor" (click)="updateFavor()">{{'Update Favor'|translate}}</button>
  <button mat-raised-button mat-dialog-close color="primary" *ngIf="addFavor" (click)="createFavor()">{{'Create Favor'|translate}}</button>

</div>







