<div class="container-fluid page-max-width">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="format-one-container">
                <div class="format-one-column">


                    <div class="steps">
                        <h1>First-Time Info</h1>
                        <p>
                            Each step you create is shown in a popup for all users on the site - but only if they haven't seen it already.
                            You can edit existing popups to correct spelling etc., but if you delete one and create a new one it will pop up for everyone again.
                        </p>

                        <ul *ngFor="let step of steps">
                            <li class="step">
                                <div class="head">
                                <h2>{{step.helpTitle}}</h2>
                                <mat-icon class="button" (click)="editStep(step)">edit</mat-icon>
                                <mat-icon class="button" (click)="deleteStep(step)">delete</mat-icon>
                                </div>
                                <div>Shown since {{step.timestamp | date}}</div>
                                <div class="content" [innerHTML]="step.content"></div>
                            </li>
                        </ul>
                        <button mat-button color="primary" (click)="addStep()">{{"Add step"|translate}}</button>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>