<h1 translate>Contents</h1>
<div class="story-list">
    <ng-container *ngIf="model.concepts && model.project">
        <ng-container *ngFor="let section of model.project.sections">
            <div mat-menu-item class="section">{{section.name}}</div>
            <ng-container *ngFor="let concept of getStoriesForSection(section)">
                <button mat-menu-item class="{{concept.id == model.concept.id?'current':''}}" (click)="select(concept);">{{concept.name}}</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="getNonSectionStories().length > 0">
            <div mat-menu-item class="section">---- Non Section Stories ----</div>
            <ng-container *ngFor="let concept of getNonSectionStories()">
                <button mat-menu-item class="{{concept.id == model.concept.id?'current':''}}" (click)="select(concept);">{{concept.name}}</button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
