<div class="heading3" translate>Story Ratings</div>
<ng-container *ngIf="!isRatingEnabled()">
    <button (click)="concept.story_rating = 1; saveConcept()">Show rating for story</button>
</ng-container>
<ng-container *ngIf="isRatingEnabled()">
    <button (click)="concept.story_rating = 0; saveConcept()">Hide rating for story</button>
</ng-container>

<div class="heading3" translate>Setup story ratings</div>
<app-story-rating-editor [storyRatings]="storyRatings"></app-story-rating-editor>
