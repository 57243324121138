<!--<div class="primary" class='{{model.current_page==currentPage.dashboard?"flattened":""}}'>-->
    <div class="primary">
    <ng-container *ngIf="activePageService.currentFavor">
        <mat-icon routerLink="/{{env.links.workshop}}/{{model.project_id}}/{{activePageService.currentFavor.workshop_id}}">chevron_left</mat-icon>
    </ng-container>
    <ng-container *ngIf="activePageService.active_page==env.links.workshop">
        <mat-icon routerLink="/{{env.links.processOverview}}/{{model.project_id}}">chevron_left</mat-icon>
    </ng-container>

    <div class="headerText">
        <ng-container *ngIf="activePageService.currentFavor && model.workshop">
            {{model.workshop.title}}
        </ng-container>
        <ng-container *ngIf="!(activePageService.currentFavor && model.workshop)">
            <ng-container [ngSwitch]="model.current_page">
                <ng-container *ngSwitchCase="currentPage.catalog_overview">
                    <ng-container *ngIf="model.project && model.project.name">
                        <span [matMenuTriggerFor]="cat_menu">{{model.project.name}}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.member_info">
                    <ng-container *ngIf="model.project && model.project.name">
                        <span [matMenuTriggerFor]="cat_menu">{{model.project.name}}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.story_view">
                    <ng-container *ngIf="model.project && model.project.name">
                        <span [matMenuTriggerFor]="cat_menu">{{model.project.name}}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.all_story">
                    All story...
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.workshop">
                    Workshops
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.dashboard">
                    <span [matMenuTriggerFor]="cat_menu">Projects</span>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.catalog_setting">
                    <ng-container *ngIf="model.project && model.project.name">
                        <span [matMenuTriggerFor]="cat_menu">{{model.project.name}}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.sharing">
                    <ng-container *ngIf="model.project && model.project.name">
                        <span [matMenuTriggerFor]="cat_menu">{{model.project.name}}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="currentPage.user_profile">
                    User profile...
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!activePageService.currentFavor && !(activePageService.active_page==env.links.workshop)">
            <span  class="expand-icon"><mat-icon [matMenuTriggerFor]="cat_menu">expand_more</mat-icon></span>
        </ng-container>
    </div>



    <mat-icon [matMenuTriggerFor]="dot_menu">more_horiz</mat-icon>
</div>

<mat-menu #cat_menu="matMenu">
    <button mat-menu-item routerLink="/" translate>Home</button>
    <button mat-menu-item routerLink="/project/{{proj.id}}" *ngFor="let proj of model.projects">{{proj.name}}</button>
</mat-menu>

<mat-menu #dot_menu="matMenu">
    <button mat-menu-item routerLink="/">ideate.nu</button>
    <button mat-menu-item routerLink="/project-member-info/{{model.project.id}}" *ngIf="model.project && model.project.id && (hasMemberInfo || model.adminRole())" translate>Member Info</button>
    <button mat-menu-item routerLink="/{{env.links.sharing}}/{{model.project.id}}" *ngIf="model.project && model.project.id && model.adminRole()" translate>Sharing</button>
    <button mat-menu-item *ngIf="model.project && model.project.id && !model.browse_as_user && model.trueAdminRole()" (click)="model.browse_as_user=true" translate>Browse as participants</button>
    <button mat-menu-item *ngIf="model.project && model.project.id && model.browse_as_user && model.trueAdminRole()" (click)="model.browse_as_user=false" translate>Switch to admin</button>
    <button mat-menu-item routerLink="/{{env.links.viewProjectSetting}}/{{model.project.id}}" *ngIf="model.project && model.project.id && model.adminRole()" translate>Settings</button>
    <button mat-menu-item routerLink="/user-data" *ngIf="authService.getUser()" translate>Edit user</button>
    <button mat-menu-item (click)="authService.SignOut()" *ngIf="authService.getUser()" translate>Log out</button>
    <button mat-menu-item routerLink="/login" *ngIf="!authService.getUser()" translate>Login</button>
    <button mat-menu-item routerLink="/register" *ngIf="!authService.getUser()" translate>Register</button>
</mat-menu>
