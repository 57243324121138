<div>
    <mat-form-field>
        <mat-label>{{'Visibility'|translate}}</mat-label>
        <mat-select [(value)]="storyRatings.published">
          <mat-option [value]="false">{{'Hidden'|translate}}</mat-option>
          <mat-option [value]="true">{{'Published'|translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'Whether to show this form on all stories'|translate}}</mat-hint>
    </mat-form-field><br /><br />

   <!-- <mat-form-field appearance="fill">
        <mat-label>{{'Form Title'|translate}}</mat-label>
        <input matInput placeholder="{{'Title of the form'|translate}}" [(ngModel)]="storyRatings.title">
        <mat-hint>{{'A title describing the form'|translate}}</mat-hint>
    </mat-form-field>&nbsp;-->

    <mat-form-field appearance="fill">
        <mat-label>{{'Introduction'|translate}}</mat-label>
        <input matInput placeholder="{{'Introduction for the rating form'|translate}}" [(ngModel)]="storyRatings.intro">
        <mat-hint>{{'Text displayed before ratings'|translate}}</mat-hint>
    </mat-form-field><br /><br />

    <mat-form-field appearance="fill">
        <mat-label>{{'Low rating text'|translate}}</mat-label>
        <input matInput placeholder="{{'E.g. \'Dislike\''|translate}}" [(ngModel)]="storyRatings.lowRatingText">
        <mat-hint>{{'One or a few words shown at the low end of the rating spectrum'|translate}}</mat-hint>
    </mat-form-field>&nbsp;
    <mat-form-field appearance="fill">
        <mat-label>{{'High rating text'|translate}}</mat-label>
        <input matInput placeholder="{{'E.g. \'Like it!\''|translate}}" [(ngModel)]="storyRatings.highRatingText">
        <mat-hint>{{'One or a few words shown at the high end of the rating spectrum'|translate}}</mat-hint>
    </mat-form-field><br /><br />


    <div #criteriaList>
        <div class='criteria' *ngFor="let question of storyRatings.questions; let i = index; trackBy: questionIndex;">
            <mat-form-field appearance="fill">
                <input matInput placeholder="{{'Question or criteria'|translate}}" [(ngModel)]="storyRatings.questions[i]">
            </mat-form-field><br />
            <button mat-button color="primary" (click)="storyRatings.questions.splice(i, 1)"><mat-icon>delete</mat-icon></button>
        </div>
    </div>
    <button mat-button color="primary" (click)="storyRatings.questions.push('')">{{'Add Rating Criteria'|translate}}</button>
</div>
