import { DocumentReference} from '@angular/fire/compat/firestore';
import {Image} from "../frontend/image-upload-dialog/image-upload-dialog.component";

import {marker as TRANSLATE} from '@biesbjerg/ngx-translate-extract-marker';

export interface ProjectSection {
    id : number;
    name : string;
}

export const admin_modes = [TRANSLATE("owner"), TRANSLATE("admin"), TRANSLATE("participant")];


export interface Participant {
    id : DocumentReference;
    name : string;
    email : string;
    role: string;
    favored: boolean;
}

export const publish_modes = [TRANSLATE("public"), TRANSLATE("link"), TRANSLATE("private")];
export const join_modes = [TRANSLATE("public_join"), TRANSLATE("link_join")];

export class Project {

    name: string = "";
    tagline : string = "";
    content: any;

    catalog_subtitle : string;
    introduction: any = {};

    kblocks_title : string;
    kblocks_introduction: string;


    id: string;
    kid: string;
    img_url : string;
    image : Image;
    participants : DocumentReference[] = [];
    kblocks : DocumentReference[] = [];
    sections : ProjectSection[] = [];
    url: string;
    published : string;
    joinMethod: string;
    participants_role : Participant[] = [];
    storyRatings: StoryRating;

    color: string;

    join_code : string;

  nonMemberInspirations: boolean;

  infoQuestionnaire: any;
}

export interface StoryRating {
  published: boolean;
  title: string;
  intro: string;
  highRatingText: string;
  lowRatingText: string;

  questions: string[];
}

