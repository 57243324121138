import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadDialogComponent } from 'src/app/frontend/image-upload-dialog/image-upload-dialog.component';
import { ThumbnailService } from 'src/app/frontend/thumbnail.service';
import { ModelService } from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';
import {TranslateService} from "@ngx-translate/core";
import { HeaderDialogComponent } from 'src/app/dialogs/header-dialog/header-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from 'src/app/service/auth.service';
import { User } from 'src/app/model/user.model';
import { LanguageService } from 'src/app/frontend/language.service';


@Component({
  selector: 'app-catalog-menu',
  templateUrl: './catalog-menu.component.html',
  styleUrls: ['./catalog-menu.component.scss']
})
export class CatalogMenuComponent implements OnInit {
  active_page : string;
  env = environment;
  user : User;
  title : string;
  subtitle : string; 

  isMobile: boolean;

  constructor(public model : ModelService, public authService: AuthService, public languageService: LanguageService, public route : ActivatedRoute, private thumbnailService : ThumbnailService, private router: Router, private translateService : TranslateService, private dialog: MatDialog) {

    //Setup mobile check
    window.addEventListener("resize", ()=>{
      this.isMobile = window.innerWidth <= 991;
    });

    this.isMobile = window.innerWidth <= 991;

    authService.userLoggedIn$.subscribe(user => {
      this.user = user;
  });
    router.events.subscribe((val) => {
      this.active_page = this.route.snapshot.url[0].path;


      }
    )


    this.model.projectLoaded$.subscribe({
      complete:()  => {
        setTimeout(() => {
          this.active_page = this.route.snapshot.url[0].path;

        }, 100);

      }
      });
  


  }

  ngOnInit(): void {
    this.active_page = this.route.snapshot.url[0].path;
    if (this.authService.getUser())
    this.user = this.authService.getUser();

  }

  shouldShow() {
    if(this.isMobile) {
      switch(this.active_page) {
        case this.env.links.viewStory:
        case this.env.links.viewProject:
          return true;
      }

      return false;
    }

    return true;
  }


  showUploadImageDialog() {
    ImageUploadDialogComponent.showUploadDialog().subscribe((image) => {
      if (image == null) {
        return;
      }
      switch (this.active_page) 
      {
        case this.env.links.projectMemberInfo:

        case this.env.links.viewCatalog:
        case this.env.links.workshop:
        case this.env.links.divergeFavor:          
        case this.env.links.processOverview:
        case this.env.links.inspirationGridView:
          case this.env.links.viewProjectSetting:          
        case this.env.links.sharing:
        case this.env.links.viewProject:
            //Delete old file
            if (this.model.project.img_url) {
              this.model.project.img_url = "";
              try {
                this.thumbnailService.delete(this.model.project.img_url);
              } catch (e) {
                //Ignore silently
              }
            }

            if (this.model.project.image) {
              this.thumbnailService.delete(this.model.project.image);
            }

            //Attach and save
            this.model.project.image = image;
            this.model.projectService.updateProject(this.model.project);
        break;
        case this.env.links.viewStory:
                      //Delete old file
              if (this.model.concept.image_name) {
                this.model.concept.image_name = "";
                try {
                  this.thumbnailService.delete(this.model.concept.image_name);
                } catch(e) {
                  //Ignore silently
                }
              }

              if (this.model.concept.image) {
                this.thumbnailService.delete(this.model.concept.image);
              }

              //Attach new image
              this.model.concept.image = image;

              //Save concept
              this.model.conceptService.updateConcept(Object.assign({}, this.model.concept));
          break;


      }
    });
  }


  getTitle() : string {
    switch(this.active_page) {
      case this.env.links.viewProject:
        if (this.model.project.name && this.model.project.name!="")
        return this.model.project.name;
        else
          return this.translateService.instant("No title");
      case this.env.links.viewCatalog:
        if (this.model.project.name && this.model.project.name!="")
        return this.model.project.name;
        else
          return this.translateService.instant("No title");
      /*  if (this.model.project.catalog_subtitle && this.model.project.catalog_subtitle!='') 
        {
          return this.model.project.catalog_subtitle;
        } else {
          return this.translateService.instant("Stories");
        }*/
      case this.env.links.workshop:
        
      case this.env.links.divergeFavor:
        if (this.model.workshop)
          return this.model.workshop.title;

      case this.env.links.processOverview:
        return this.translateService.instant("Workshops");
      case this.env.links.inspirationGridView:
        return this.translateService.instant("Inspiration");
     case this.env.links.projectMemberInfo:
          return this.translateService.instant("Member Info");
     case this.env.links.sharing:
          return  this.translateService.instant("Sharing");
      case this.env.links.viewProjectSetting:
            return this.translateService.instant("Settings");
      case this.env.links.viewStory:

        if (this.model.concept.name && this.model.concept.name!="")
          return this.model.concept.name;
        else
          return this.translateService.instant("No title");
    }
  }

  getTagline() : string {
    switch(this.active_page) {
      case this.env.links.viewProject:
        case this.env.links.viewCatalog:
        if (this.model.project.tagline && this.model.project.tagline!="")
          return this.model.project.tagline;
        else {
          if (this.model.adminRole())
            return this.translateService.instant("No subtitle");
          else
            return ""
        }


      case this.env.links.workshop:

      case this.env.links.divergeFavor:
      case this.env.links.processOverview:
      case this.env.links.inspirationGridView:
      case this.env.links.projectMemberInfo:
      case this.env.links.sharing:
      case this.env.links.viewProjectSetting:
          return "";
      case this.env.links.viewStory:
        if (this.model.concept.tagline && this.model.concept.tagline!="")
        return this.model.concept.tagline;
      else {
        if (this.model.adminRole())
          return this.translateService.instant("No subtitle");
        else
          return ""
      }
    }
  }


  getImage(): string {
    switch (this.active_page) 
    {
      case this.env.links.projectMemberInfo:     
      case this.env.links.viewProjectSetting:      
      case this.env.links.sharing:
      case this.env.links.workshop:
      case this.env.links.participants:
      case this.env.links.divergeFavor:
      case this.env.links.viewCatalog:
      case this.env.links.processOverview:
      case this.env.links.inspirationGridView:
      case this.env.links.viewProject:
        if(this.model.project.image != null) {
          return this.model.project.image.url.normal;
        } else {
          if (this.model.project.img_url)
          {
            return this.model.project.img_url;
          } else {
              return environment.story_background_url;
          } 
        }
    case this.env.links.viewStory:
        if(this.model.concept.image != null) {
          return this.model.concept.image.url.normal;
        } else {
          if (this.model.concept.image_name)
          {
             return this.model.concept.image_name;
          } else {
              return environment.story_background_url;
          } 
        }
  }
  }



  showEditTitleIcon() : boolean {
    switch (this.active_page) {
      case this.env.links.viewProject:
      case this.env.links.workshop:
      case this.env.links.viewStory:
      case this.env.links.viewCatalog:
        return true;
    }
    return false;
  }

  showEditTaglineIcon() : boolean {
    switch (this.active_page) {
      case this.env.links.viewProject:
      case this.env.links.viewCatalog:
      case this.env.links.viewStory:
        return true;
    }
    return false;
  }

  editTitle() {
    if (!this.model.adminRole()) return; 
    let dialogRef;
    switch (this.active_page) 
    {
      case this.env.links.viewProject:
        dialogRef = this.dialog.open(HeaderDialogComponent, {
          position: {top:'20vh'},
          panelClass: 'reinvent-popup',
          data: {name:this.model.project.name},
        });
    
        dialogRef.afterClosed().subscribe(result => {
        if (result)
        {
          this.model.project.name = result.name;
           this.model.projectService.updateProject(this.model.project);
          }
        });
        break;


        case this.env.links.viewStory:
          dialogRef = this.dialog.open(HeaderDialogComponent, {
            position: {top:'20vh'},
            panelClass: 'reinvent-popup',
            data: {name:this.model.concept.name},
          });
      
          dialogRef.afterClosed().subscribe(result => {
          if (result)
          {
            this.model.concept.name = result.name;
             this.model.conceptService.updateConcept(this.model.concept);
            }
          });
          break;


          case this.env.links.viewCatalog:
            dialogRef = this.dialog.open(HeaderDialogComponent, {
              position: {top:'20vh'},
              panelClass: 'reinvent-popup',
              data: {name:this.model.project.catalog_subtitle},
            });
        
            dialogRef.afterClosed().subscribe(result => {
            if (result)
            {
              this.model.project.catalog_subtitle = result.name;
               this.model.projectService.updateProject(this.model.project);
              }
            });
            break;




            case this.env.links.workshop:
              if (!this.model.workshop) return;
              dialogRef = this.dialog.open(HeaderDialogComponent, {
                position: {top:'20vh'},
                panelClass: 'reinvent-popup',
                data: {name:this.model.workshop.title},
              });
          
              dialogRef.afterClosed().subscribe(result => {
              if (result)
              {
                this.model.workshop.title = result.name;
                 this.model.process_service.update();
                }
              });
              break;


    }




  }




  editTagline() {
    if (!this.model.adminRole()) return; 
    let dialogRef;
    switch (this.active_page) 
    {
      case this.env.links.viewProject:
        dialogRef = this.dialog.open(HeaderDialogComponent, {
          position: {top:'20vh'},
          panelClass: 'reinvent-popup',
          data: {name:this.model.project.tagline},
        });
    
        dialogRef.afterClosed().subscribe(result => {
        if (result)
        {
          this.model.project.tagline = result.name;
           this.model.projectService.updateProject(this.model.project);
          }
        });
        break;


        case this.env.links.viewStory:
          dialogRef = this.dialog.open(HeaderDialogComponent, {
            position: {top:'20vh'},
            panelClass: 'reinvent-popup',
            data: {name:this.model.concept.tagline},
          });
      
          dialogRef.afterClosed().subscribe(result => {
          if (result)
          {
            this.model.concept.tagline = result.name;
             this.model.conceptService.updateConcept(this.model.concept);
            }
          });
          break;

    }




  }


  onEditModeToggle(event: MatSlideToggleChange) {
    console.log('toggle', event.checked);

    if (this.env.links.viewCatalog == this.active_page && !event.checked)
    {
     // this.router.navigate(["/"+environment.links.viewProject+"/"+this.model.project.id]);
    }

    if (this.env.links.viewProject == this.active_page && event.checked)
    {
     // this.router.navigate(["/"+environment.links.viewCatalog+"/"+this.model.project.id]);
    }    
}

hasPhoto(): boolean {
  return ThumbnailService.getPhotoFromUser(this.model.user) != null;
}

getPhoto():string {
  return ThumbnailService.getPhotoFromUser(this.model.user);
}


}
