import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  ElementRef,
  ViewChild, AfterViewInit, NgZone
} from '@angular/core';
import {Kblock, kBlockType} from 'src/app/model/kblock.model';
import {KblockService} from 'src/app/service/kblock.service';
import {AuthService} from 'src/app/service/auth.service';

import {ProjectService} from 'src/app/service/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from 'src/environments/environment';

import {kBlockDateCollection, ModelService} from 'src/app/service/model.service';
import {CreateKblocksComponent} from '../create-kblocks/create-kblocks.component';
import {MatDialog} from '@angular/material/dialog';
import moment from "moment/moment";

@Component({
  selector: 'app-kblocks-date-overview',
  templateUrl: './kblocks-date-overview.component.html',
  styleUrls: ['./kblocks-date-overview.component.scss']
})

export class KblocksDateOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() blocks: number = 5;
  @Input() type: kBlockType;

  @ViewChild("loadMore") loadMoreDiv: ElementRef;
  @ViewChild("dateList") dateList: ElementRef;
  env = environment;
  private dateListWidth: number = null;
  private updateColumnStuffAnimationId: number = null;
  public kBlockDateSource = null;

  @Output() item_selected = new EventEmitter<Kblock>();

  private loadQueued : boolean;
  private loadQueueId : number;

  constructor(private ngZone: NgZone, public model: ModelService, private route: ActivatedRoute, private kblockService: KblockService, public authService: AuthService, public dialog: MatDialog, public projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.loadKblocks();

    window.addEventListener("resize", ()=>{
      //Trigger recalculate of width
      this.dateListWidth = null;
      this.updateColumnStuff();
    });

    this.kblockService.data.subscribe((data)=>{
      if(data.length > 0) {
        this.queueLoadMoreBlocks();
      }
    });
  }

  queueLoadMoreBlocks() {
    if(this.loadQueueId == null) {
      this.loadQueueId = window.setTimeout(() => {
        this.loadQueueId = null;
        setTimeout(()=>{
          this.loadMoreBlocks();
        }, 0);
      }, 100);
    }
  }

  loadMoreBlocks() {
    if(this.loadQueued && this.model.kblockdates.length > 0) {
      this.kblockService.more();
    }
  }

  ngAfterViewInit(): void {
    let observer = new IntersectionObserver((intersections) => {
      intersections.forEach((intersection) => {
        if (intersection.isIntersecting) {
          this.loadQueued = true;
          this.queueLoadMoreBlocks();
        } else {
          this.loadQueued = false;
        }
      });
    });

    observer.observe(this.loadMoreDiv.nativeElement);
  }

  loadKblocks() {
    if (this.type != null) {
        console.log(this.model.project);
        this.model.loadkBlocks(this.type, this.blocks);
    }
  }

  public getkBlockDateTime(kblock: Kblock): string {
    let d: Date = kblock.timestamp.toDate();
    return d.toLocaleDateString();
  }


  public newDate(kblock: Kblock, previous: Kblock): boolean {
    let d1: Date = kblock.timestamp.toDate();
    let d2: Date = previous.timestamp.toDate();
    return d1.getDate() != d2.getDate();

  }

  ngAfterViewChecked(): void {
    this.updateColumnStuff();
  }

  updateColumnStuff(): void {
    if(this.updateColumnStuffAnimationId == null) {
      this.updateColumnStuffAnimationId = -1;
      this.ngZone.runOutsideAngular(()=>{
        this.updateColumnStuffAnimationId = requestAnimationFrame(()=>{
          this.updateColumnStuffReal();
          this.updateColumnStuffAnimationId = null;
        })
      });
    }
  }

  updateColumnStuffReal(): void {
    let elementWidth = 360;

    if(this.dateListWidth == null) {
      this.dateListWidth = this.dateList.nativeElement.getBoundingClientRect().width;
    }

    let maxElements = Math.floor(this.dateListWidth / elementWidth);

    this.dateList.nativeElement.querySelectorAll(".same-date").forEach((sameDate)=>{
      let numKblocks = sameDate.querySelector(".kblock-flow").children.length;

      let newWidth = null;

      let hasAutoColumns = sameDate.classList.contains("autocolumns");

      if(numKblocks > maxElements) {
        if(!hasAutoColumns) {
          sameDate.classList.add("autocolumns");
        }
        newWidth = maxElements * 360;
      } else {
        if(hasAutoColumns) {
          sameDate.classList.remove("autocolumns");
        }
        newWidth = numKblocks * 360;
      }

      if(newWidth !== parseInt(sameDate.style.width)) {
        sameDate.style.width = newWidth + "px";
      }
    });
  }

  getDateFromTimestamp(timestamp) {
    // @ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;

    if (locale == null) {
      locale = "en";
    }

    moment.updateLocale('en', {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'dddd D. MMM YYYY'
      }
    });

    moment.updateLocale('da', {
      calendar: {
        sameDay: '[I dag]',
        nextDay: '[I morgen]',
        nextWeek: 'dddd',
        lastDay: '[I går]',
        lastWeek: '[Sidste] dddd',
        sameElse: 'dddd D. MMM YYYY'
      }
    });

    return moment(timestamp).locale(locale).calendar();
  }

  ngOnDestroy() {
  }
}


