import {Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FileInput} from "ngx-material-file-input";
import {Observable, Subject} from 'rxjs';
import {ThumbnailService, ThumbSize} from '../thumbnail.service';
import {HttpClient} from '@angular/common/http';
import Cropper from 'cropperjs';

enum CurrentTab {
  File,
  Link,
  Unslpash
}

export class Image {
  type: CurrentTab

  url: {
    original: string,
    small: string,
    normal: string
  }

  author?: {
    name: string
    link: string
  }

  provider?: {
    name: string
    link: string
  }
}

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent {
  readonly validUrlRegexp : RegExp = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i);

  fromFile: FileInput;
  fromUrl: string;
  fromUnsplash: any;

  unsplashQuery: string;
  unsplashLastQuery: string;
  unsplashSearching: boolean = false;

  uploadDisabled: boolean = true;
  unsplashPrev = false;
  unsplashNext = false;

  unsplashPage = 1;

  disableUnsplash: boolean = false;

  currentTab: CurrentTab = CurrentTab.File;

  cropper: Cropper = null;

  @ViewChild("photoResults") photoResultsRef: ElementRef;
  @ViewChild("dialogContent") dialogContent: ElementRef;
  @ViewChild("imgPreview") imagePreview: ElementRef;
  @ViewChild("cropperArea") cropperArea: ElementRef;

  constructor(public dialogRef: MatDialogRef<ImageUploadDialogComponent>, private thumbnailService: ThumbnailService, private http: HttpClient, private zone: NgZone) { }

  async uploadImage() {
    const self = this;

    let originalUrl = null;
    let smallUrl = null;
    let normalUrl = null;

    let author = null;
    let provider = null;

    if(!this.uploadDisabled) {
      this.dialogContent.nativeElement.classList.add("uploading");

      switch(this.currentTab) {
        case CurrentTab.File:

          async function upload(maxWidth, maxHeight, name) {
            const canvas = self.cropper.getCroppedCanvas({
              maxWidth: maxWidth,
              maxHeight: maxHeight,
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high'
            });

            return await new Promise((resolve)=>{
              canvas.toBlob((blob)=>{
                const file = new File([blob], name);
                self.thumbnailService.upload(file).subscribe((url)=>{
                  resolve(url);
                });
              }, "image/jpeg", 0.8);
            });
          }

          let filename = this.fromFile.files[0].name.substring(0, this.fromFile.files[0].name.lastIndexOf("."));
          let extension = ".jpg";

          originalUrl = await upload(4096, 4096, filename+extension);
          normalUrl = await upload(1920, 1920, filename+"_1920_"+extension);
          smallUrl = await upload(512, 512, filename+"_512_"+extension);
          break;
        case CurrentTab.Link:
          originalUrl = this.fromUrl;
          normalUrl = this.fromUrl;
          smallUrl = this.fromUrl;
          break;
        case CurrentTab.Unslpash:
          originalUrl = this.fromUnsplash.urls.full;
          normalUrl = this.fromUnsplash.urls.regular;
          smallUrl = this.fromUnsplash.urls.small;
          author = {
            name: this.fromUnsplash.user.name,
            link: this.fromUnsplash.user.links.html+"?utm_source=ReInvent&utm_medium=referral"
          };

          provider = {
            name: "Unsplash",
            link: "https://unsplash.com/?utm_source=ReInvent&utm_medium=referral"
          };

          //Send request to download location
          this.http.get("https://europe-west3-ideate-project.cloudfunctions.net/unsplashSearch?downloadId="+this.fromUnsplash.id).subscribe((result)=>{
            //Silently ignore
          }, ()=>{
            //Silently ignore
          });

          break;
      }

      this.dialogContent.nativeElement.classList.remove("uploading");
    }

    const img: Image = {
      type: this.currentTab,
      url: {
        original: originalUrl,
        normal: normalUrl,
        small: smallUrl
      }
    }

    if(author) {
      img.author = author;
    }

    if(provider) {
      img.provider = provider;
    }

    this.dialogRef.close(img);
  }

  tabIndexChanged(tabIndex) {
    this.currentTab = tabIndex;

    //Check if upload should be enabled for this tab
    this.checkUploadEnabled();
  }

  checkUploadEnabled(event=null) {
    const self = this;

    this.uploadDisabled = true;

    this.imagePreview.nativeElement.src = "";
    this.cropperArea.nativeElement.classList.add("hidden");

    if(this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }

    switch(this.currentTab) {
      case CurrentTab.File: {
        this.uploadDisabled = (this.fromFile == null);

        //Check if we got a file from event
        if(this.fromFile != null) {
          this.cropperArea.nativeElement.classList.remove("hidden");

          this.zone.runOutsideAngular(()=>{
            this.fromFile.files[0].arrayBuffer().then((arrayBuffer)=>{
              const bytes = new Uint8Array(arrayBuffer);
              const blob = new Blob([bytes.buffer]);

              self.imagePreview.nativeElement.src = URL.createObjectURL(blob);
              self.cropper = new Cropper(self.imagePreview.nativeElement,{
                viewMode: 1,
                background: false,
                autoCrop: false,
                autoCropArea: 1,
                movable: false,
                zoomable: false,
                scalable: false,
                toggleDragModeOnDblclick: false,
                ready(event: Cropper.ReadyEvent<HTMLImageElement>) {
                  self.cropper.crop();
                }
              });
            });
          });
        }

        break;
      }
      case CurrentTab.Link:
        this.uploadDisabled = !(this.fromUrl != null && this.validUrlRegexp.test(this.fromUrl));
        break;
      case CurrentTab.Unslpash:
        this.uploadDisabled = (this.photoResultsRef.nativeElement.querySelector("img.selected") == null);
        break;
    }
  }

  validateUrl(event) {
    let input = event.target as HTMLInputElement;

    if(this.validUrlRegexp.test(input.value)) {
      input.closest("mat-form-field").classList.remove("invalid");
    } else {
      input.closest("mat-form-field").classList.add("invalid");
    }
  }

  onNoClick() {
    //Cancel
    this.dialogRef.close();
  }

  unsplashSearchKey(event) {
    if(event.key === "Enter") {
      this.unsplashSearch();
      event.preventDefault();
      this.unsplashQuery = "";
    }
  }

  unsplashMore(): void {
    this.unsplashPage++;
    this.unsplashSearch();
  }

  unsplashBack(): void {
    this.unsplashPage--;
    this.unsplashSearch();
  }

  unsplashSearch() {
    this.checkUploadEnabled();

    if (this.unsplashQuery !== "") {
      this.unsplashLastQuery = this.unsplashQuery;
      this.unsplashQuery = "";
    }

    this.unsplashSearching = true;

    this.photoResultsRef.nativeElement.querySelectorAll("img").forEach((imgElm)=>{
      imgElm.remove();
    });

    this.dialogContent.nativeElement.classList.add("searching");

    console.log("Searching unsplash:", this.unsplashLastQuery, this.unsplashPage);

    this.http.get("https://europe-west3-ideate-project.cloudfunctions.net/unsplashSearch?query=" + this.unsplashLastQuery + "&page=" + this.unsplashPage).subscribe((unsplashResults)=>{
      this.unsplashSearching = false;

      // @ts-ignore
      unsplashResults.results.forEach((result)=>{
        let img = document.createElement("img");

        img.src = result.urls.thumb;

        img.addEventListener("click", ()=>{
          this.photoResultsRef.nativeElement.querySelectorAll("img").forEach((imgElm)=>{
            imgElm.classList.remove("selected");
          });
          img.classList.add("selected");
          this.fromUnsplash = result;
          this.uploadDisabled = false;
        });

        this.photoResultsRef.nativeElement.append(img);
      })

      // @ts-ignore
      if (this.unsplashPage >= unsplashResults.total_pages) {
        this.unsplashNext = false;
      } else {
        this.unsplashNext = true;
      }

      if (this.unsplashPage > 1) {
        this.unsplashPrev = true;
      } else {
        this.unsplashPrev = false;
      }

      this.dialogContent.nativeElement.classList.remove("searching");
    });
  }

  rotate(event, degrees) {
    event.preventDefault();
    if(this.cropper != null) {
      this.cropper.clear();
      this.cropper.rotate(degrees);
      let canvasData = this.cropper.getCanvasData();
      this.cropper.crop();
      this.cropper.setCropBoxData(canvasData);
    }
  }

  static showUploadDialog(disableUnsplash:boolean = false) : Observable<Image> {
    let subject = new Subject<Image>();

    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(ImageUploadDialogComponent, {
      panelClass: ['reinvent-popup']
    });
    dialogRef.componentInstance.disableUnsplash = disableUnsplash;

    dialogRef.afterClosed().subscribe((image)=>{
      if(dialogRef.componentInstance.imagePreview.nativeElement.src != null) {
        URL.revokeObjectURL(dialogRef.componentInstance.imagePreview.nativeElement.src);
      }

      subject.next(image);
    });

    return subject.asObservable();
  }
}
