export class ViewIntersector {
  private static observer: IntersectionObserver = new IntersectionObserver((entries)=>{
    entries.forEach((entry)=>{
      if(entry.isIntersecting) {
        console.log(entry);
        let c = ViewIntersector.lookupMap.get(entry.target);
        c();
        ViewIntersector.observer.unobserve(entry.target);
      }
    });
  });

  private static lookupMap: Map<Element, ()=> void> = new Map();

  static whenInViewAddClass(target:Element, c:()=>void) {
    ViewIntersector.lookupMap.set(target, c);
    ViewIntersector.observer.observe(target);
  }
}
