<ng-container *ngIf="authService.isLoggedIn else notLoggedIn">
  <button mat-icon-button (click)="toggleLike();$event.stopPropagation()" [matTooltip]="tooltip">
      <div class="like-view">
          <mat-icon *ngIf="isLiked">favorite</mat-icon><mat-icon *ngIf="!isLiked">favorite_border</mat-icon>
          <div class="likes" *ngIf="num_likes>0">
              {{num_likes}}          
          </div>          
      </div>
  </button>
</ng-container>
<ng-template #notLoggedIn>
  <span class="like-view">
    <mat-icon *ngIf="isLiked">favorite</mat-icon><mat-icon *ngIf="!isLiked">favorite_border</mat-icon>
    <div class="likes" *ngIf="num_likes>0">
        {{num_likes}}          
    </div>          
  </span>
</ng-template>
