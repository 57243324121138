import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/compat/firestore';
import {Project, ProjectSection} from 'src/app/model/project.model';
import {Subject} from 'rxjs';
import {User} from '../model/user.model';
import {AuthService} from './auth.service';
import {first} from "rxjs/internal/operators/first";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private firestore: AngularFirestore, private authService: AuthService) {
  }

  selectedProject: Project;
  private selectedProjectSource = new Subject<Project>();
  projectChanged$ = this.selectedProjectSource.asObservable();

  getProjects(user_ref: DocumentReference) {
    return this.firestore.collection('projects', ref => ref.where('participants', 'array-contains-any', [user_ref])).snapshotChanges();
  }

  getFeaturedProjects() {
    return this.firestore.collection('projects', ref => ref.where('published', '==', 'public').where('featured', '==', true)).valueChanges();

  }

  getTaggedProject(tag: string) {
    return this.firestore.collection('projects', ref => ref.where('published', '==', 'public').where('tags', 'array-contains-any', [tag])).valueChanges();

  }


  createProject(project: Project) {
    return this.firestore.collection("projects").add({...project});
    //.then(res => {}, err => reject(err));
//});
  }

  getProject(projectId: string) {
    return this.firestore.doc<Project>('projects/' + projectId).valueChanges({
      idField: "id"
    });
  }

  getProjectReference(project: Project): DocumentReference<Project> {
    return this.firestore.doc<Project>('projects/' + project.id).ref;
  }

  getProjectReferenceById(id: string): DocumentReference<Project> {
    return this.firestore.doc<Project>('projects/' + id).ref;
  }

  updateProject(project: Project) {

    const project2 = {...project};
    let id = project.id;
    delete project2.id;
    return this.firestore.doc('projects/' + id).update(project2);
  }

  deleteProject(projectId: string) {
    this.firestore.doc('projects/' + projectId).delete();
  }

  setProject(project: Project) {
    this.selectedProject = project;
    this.selectedProjectSource.next(project);
  }

  getSelectedProject(): Project {
    return this.selectedProject;
  }

  public addSection(project: Project, title: string) {
    let id = 0;
    if (project.sections)
      id = (Math.max(Math.max(...project.sections.map(section => section.id)), 0) + 1)
    console.log(id);
    if (!project.sections)
      project.sections = [];
    project.sections.push({id: id, name: title});
    this.updateProject(project);
  }

  public editSection(project: Project) {

    this.updateProject(project);
  }


  deleteSection(project: Project, section: ProjectSection) {
    project.sections = project.sections.filter(item => item.id !== section.id);
    console.log(project.sections.length);
    console.log(project.id);
    this.updateProject(project);

  }

  addParticipant(user: User, user_ref: DocumentReference, project: Project, role: string) {
    if (project.participants == null) {
      project.participants = [];
    }
    if (project.participants_role == null) {
      project.participants_role = [];
    }

    if (project.participants.some(x => x.id == user.uid)) {
      return new Promise((resolve, reject) => {
        resolve('Participant already added');
      });
    }
    project.participants_role.push({
      'id': user_ref,
      'name': user.displayName,
      'email': user.email,
      'role': role,
      'favored': false
    });
    project.participants.push(user_ref);


    return this.updateProject(project);
  }

  removeParticipant(id: string, project: Project) {
    project.participants.forEach((item, index) => {
      if (item.id == id) {
        console.log("match");
        project.participants.splice(index, 1);
      }
    });

    project.participants_role.forEach((item, index) => {
      if (item.id.id == id) project.participants_role.splice(index, 1);
    });
    return this.updateProject(project);
  }
}






