<ng-container *ngIf="catalog">
    <ng-container *ngIf="!editing else editor">
        <app-editor-js-viewer [admin]="isAdmin" *ngIf="catalog.infoQuestionnaire && catalog.infoQuestionnaire.content" [blocks]="catalog.infoQuestionnaire.content.blocks"></app-editor-js-viewer>
        <button mat-button (click)="enterEditMode()" *ngIf="isAdmin && includeEditor">
            <mat-icon>edit</mat-icon>
            {{"Edit"|translate}}</button>
    </ng-container>
    <ng-template #editor>
        <app-editor-js [content]="catalog.infoQuestionnaire" [projectId]="catalog.id" (saved)="updateInfoQuestionnaire($event)" (canceled)="exitEditMode()"></app-editor-js>
    </ng-template>
</ng-container>
<div class="hidden">
  <mat-form-field>
    <input matInput type="text" />
  </mat-form-field>
</div>
