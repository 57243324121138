<app-story-sidebar class="scrollblock" [favor_id]="favor_id" [story_id]="model.concept.id" *ngIf="!isMobile"></app-story-sidebar>
<div class="scrollblock">
    <app-catalog-menu></app-catalog-menu>

    <div class="normal-page-content">
        <ng-container *ngTemplateOutlet="story_header"></ng-container>
        <ng-container *ngTemplateOutlet="story_column"></ng-container>
    </div>

    <div class="storyNavigator" *ngIf="isMobile">
        <app-favor-navigator></app-favor-navigator>
        <app-story-navigator></app-story-navigator>
    </div>
</div>


<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="deleteConcept()">{{'Delete story'|translate}}</button>
</mat-menu>


<ng-template #image_header>
    <div class="outer-image-top" (click)="this.model.adminRole() && showImageUploadDialog()">
        <div *ngIf="getImage()" class="top-image-cover-div inner-image">
            <img [src]="getImage()" class="top-image-cover">
            <div class="image-inline-text">
                <div class="title">{{model.concept.name}} </div>
                <div class="tagline"> {{model.concept.tagline}}</div>
            </div>

        </div>
        <div *ngIf="!getImage()" class="top-image-cover-div inner-image">
            <img src="./assets/img/picback.png" class="top-image-cover">
            <div class="image-inline-text">
                <div class="title">{{model.concept.name}} </div>
                <div class="tagline"> {{model.concept.tagline}}</div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #story_header>
  <!--  <ng-container *ngIf="model.project">
        <div *ngIf="authService.isLoggedIn && !model.isProjectMember() && model.isProjectPublicJoin()">{{'Your not a member of this catalog:'|translate}} <button mat-flat-button (click)="model.joinProject(model.project)">{{'Join!'|translate}}</button></div>
    </ng-container>-->
</ng-template>


<ng-template #story_column>
    <div *ngIf="isLoaded" class="first-column-flow">
        <div [ngSwitch]="editmode">
            <div *ngSwitchCase="true">
                <mat-form-field class="edit_story_heading">
                    <mat-label>{{'Story header'|translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="model.concept.name" matTooltip="{{'Header'|translate}}">
                </mat-form-field>
                <br/>
                <mat-form-field class="edit_story_heading">
                    <mat-label>{{'Tagline'|translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="model.concept.tagline" matTooltip="{{'Tagline'|translate}}">
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!this.model.adminRole()"><br/></div>
        <div *ngIf="this.model.adminRole()">
            <div>
                <div class="admin-bar" *ngIf="!editmode">
                    <div class="admin-menu" *ngIf="!isMobile">

                        <button mat-button color="primary" (click)="openEditDialog(model.concept, model.concept.project.id)" matTooltip="{{'Edit story'|translate}}">
                            <mat-icon>edit_note</mat-icon>
                        </button>

                        <button mat-button color="primary" (click)="editStory()" matTooltip="{{'Edit story'|translate}}">
                            <mat-icon>create</mat-icon>
                        </button>



                        <span *ngIf="model.project.storyRatings">
                            <button mat-button color="primary" (click)="showStoryRatingDialog()" matTooltip="{{'Story ratings'|translate}}">
                                <mat-icon>tune</mat-icon>
                            </button>
                        </span>

                        <button mat-button color="primary" (click)="toggleShowComments()" matTooltip="{{'Toggle comments'|translate}}">
                            <mat-icon>comment</mat-icon>
                        </button>

                        <button mat-button color="primary" [matMenuTriggerFor]="menu" matTooltip="{{'Delete story'|translate}}">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div class="admin-menu" *ngIf="isMobile">
                        <button mat-button color="primary" [matMenuTriggerFor]="admin_menu">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #admin_menu="matMenu">
                            <button mat-menu-item (click)="editStory()" matTooltip="{{'Edit story'|translate}}">
                                <mat-icon>create</mat-icon>
                                {{'Edit story'|translate}}
                            </button>
                            <button mat-menu-item (click)="openEditDialog(model.concept, model.concept.project.id)" matTooltip="{{'Edit story'|translate}}">
                                <mat-icon>create</mat-icon>
                                {{'Edit story intro'|translate}}
                            </button>
                            <button *ngIf="model.project.storyRatings" mat-menu-item (click)="showStoryRatingDialog()" matTooltip="{{'Story ratings'|translate}}">
                                <mat-icon>tune</mat-icon>
                                {{'Story ratings'|translate}}
                            </button>

                            <button mat-menu-item (click)="toggleShowComments()" matTooltip="{{'Toggle comments'|translate}}">
                                <mat-icon>comment</mat-icon>
                                {{'Toggle comments'|translate}}
                            </button>

                            <button mat-menu-item [matMenuTriggerFor]="menu" matTooltip="{{'Delete story'|translate}}">
                                <mat-icon>delete</mat-icon>
                                {{'Delete story'|translate}}
                            </button>
                        </mat-menu>
                    </div>

                    <!--  <div> {{'Admin mode'|translate}}</div>

                  <mat-form-field *ngIf="model.project" appearance="standard">
                        <mat-label>{{'Theme'|translate}}</mat-label>
                        <mat-select [(value)]="model.concept.section_id" (selectionChange)="updateConcept()">
                            <mat-option *ngFor="let section of model.project.sections" [value]="section.id">
                                {{section.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                  <div *ngIf="model.project" appearance="standard">
                    <mat-label>{{'Pick a color'|translate}}</mat-label>
                    <div class="currentColor" #currentColor style="background-color: #fff"></div>
                    <color-github [colors]="['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB']" [color]="getCurrentColor()" (onChangeComplete)="colorChangeComplete($event)"></color-github>
                  </div>-->
                </div>
            </div>
        </div>

        <div [ngSwitch]="editmode" id="storyview" class="story-view">
            <div *ngSwitchCase="true">
                <mat-label>{{'Write your story below'|translate}}<br/></mat-label>
                <app-editor-js [content]="model.concept" [projectId]="model.concept.project.id"></app-editor-js>
            </div>
            <div *ngSwitchCase="false">
                <div *ngIf="model.concept.content">
                    <app-editor-js-viewer [admin]="model.adminRole()" [blocks]="model.concept.content.blocks"></app-editor-js-viewer>
                </div>
                <div *ngIf="!model.concept.content && model.adminRole()">
                    <div><i translate>No story added - press edit to start writing a story</i></div>
                </div>

                <div *ngIf="!model.concept.content">
                    {{model.concept.description}}
                </div>
            </div>
        </div>
        <br/>
        <br/>

        <div
            *ngIf="model.project && model.project.storyRatings && model.concept.story_rating && model.concept.story_rating!=0 && !editmode">
            <app-story-rating-view [storyRatings]="model.project.storyRatings"
                                   [storyRef]="conceptService.getReference(model.concept)"
                                   [catalogRef]="projectService.getProjectReference(model.project)"></app-story-rating-view>
        </div>


        <!--- Commenter -->
        <div *ngIf="model.concept.showComments && !editmode">
            <app-commenter [owningEntity]="model.concept"></app-commenter>
        </div>

        <br/>
        <br/>

        <div class="margin-inspiration">
            <div class="inspiration-adder">
                <div class="inspiration-floater">
                    <input onFocus="this.blur()" (blur)="createInspirationBlock();" placeholder="{{'Create inspiration...'|translate}}">
                </div>
            </div>
           <!-- <div class="heading-div" translate>
                <div class="heading-title" translate>Inspiration</div>
            </div>-->
            <div class="div-space">

            </div>

            <app-kblocks-overview *ngIf="current_id" [type]="ktype.Concept" [blocks]="16" [id]="current_id" [name]="model.concept.name"></app-kblocks-overview>
        </div>
    </div>
</ng-template>
