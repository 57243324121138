import {Component, ElementRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import * as Chartist from "chartist";
import {ViewIntersector} from "../ViewIntersector";
import {AnimationObserver} from "../AnimationObserver";
import {Image} from '../image-upload-dialog/image-upload-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import {PuzzleCommentDialogComponent} from "../puzzle-comment-dialog/puzzle-comment-dialog.component";
import {UserService} from "../user.service";
import {AuthService} from "../../service/auth.service";
import firebase from "firebase/compat/app";
import {QuestionnaireService} from "../questionnaire.service";

export interface Story {
  title: string,
  description: string,
  color: string,
  weight: number,
  image: Image
}

@Component({
  selector: 'app-concept-puzzle',
  templateUrl: './concept-puzzle.component.html',
  styleUrls: ['./concept-puzzle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConceptPuzzleComponent implements OnInit {

  @Input() storySet: Story[];
  @Input() maxWeight: number;

  @Input() header: string;
  @Input() caption: string;

  @Input() helptext: string;
  @Input() resettext: string;
  @Input() savetext: string;

  @Input() puzzleId: string;

  stories: Story[] = [];

  usedWeight: number = 0;

  constructor(private elementRef : ElementRef, private dialog : MatDialog, private userService: UserService, private authService: AuthService, private questionnaireService: QuestionnaireService) { }

  ngOnInit(): void {
    let self = this;

    let chartElm = this.elementRef.nativeElement.querySelector(".ct-chart");
    ViewIntersector.whenInViewAddClass(chartElm, ()=>{
      chartElm.classList.add("first-view");
      AnimationObserver.whenAnimationStable(chartElm, ()=>{
        chartElm.classList.add("stable-view");
        chartElm.classList.remove("first-view");
      });
    });

    this.generateGraph();

    let dropZone = this.elementRef.nativeElement.querySelector(".result");
    //Setup drop zone
    dropZone.addEventListener("dragover", (evt)=>{
      let conceptIndex = evt.dataTransfer.getData("reinvent/story");
      if(conceptIndex != null) {
        evt.dataTransfer.dropEffect = "move";
        evt.preventDefault();
      }
    });

    dropZone.addEventListener("drop", (evt)=>{
      let conceptIndex = evt.dataTransfer.getData("reinvent/story");
      if(conceptIndex != null) {
        let concept = self.storySet[conceptIndex];
        self.elementRef.nativeElement.querySelector(".concept-toolbox li[data-concept-index='"+conceptIndex+"']").classList.add("used");
        self.stories.push(concept);
        self.usedWeight += concept.weight;

        if(self.usedWeight > self.maxWeight) {
          self.elementRef.nativeElement.querySelector(".limit").classList.add("limit-reached");
        }

        self.generateGraph();
      }
    });
  }

  getImage(story) {
    if(story.image != null && story.image.url != null) {
      return story.image.url.small.replace(/&amp;/g, "&");
    }

    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";
  }

  reset() : void {
    this.stories = [];
    this.usedWeight = 0;

    this.generateGraph();

    this.elementRef.nativeElement.querySelector(".limit").classList.remove("limit-reached");

    this.elementRef.nativeElement.querySelectorAll(".concept-toolbox li").forEach((conceptLi)=>{
      conceptLi.classList.remove("used");
    });
  }

  ngAfterViewInit() : void {
    this.elementRef.nativeElement.querySelectorAll(".concept-toolbox li").forEach((conceptLi)=>{

      let index = parseInt(conceptLi.getAttribute("data-concept-index"));

      conceptLi.addEventListener("dragstart", (evt)=>{
        if(conceptLi.classList.contains("used")) {
          evt.preventDefault();
          return;
        }

        evt.dataTransfer.setData("reinvent/story", index);
        evt.dataTransfer.dropEffect = "copy";
      });
    });
  }

  private generateGraph() : void {
    let self = this;

    let options = {
      donut: true,
      donutWidth: 50,
      donutSolid: false,
      startAngle: 270,
      total: 200,
      showLabel: false,
      chartPadding: 5
    };

    let data = {
      series: []
    };

    let totalWeight = 0;

    this.storySet.forEach((story)=>{
      totalWeight += story.weight;
    })

    this.stories.forEach((story, index)=>{
      data.series.push({
        value: story.weight / totalWeight * 100,
        meta: "index-"+index
      });
    });

    let chartElm = this.elementRef.nativeElement.querySelector(".ct-chart");

    let chart = new Chartist.Pie(chartElm, data, options);

    chart.on("created", (context)=>{
      let halfLineWidth = context.options.donutWidth / 2.0;

      let maxRadius = Math.min(context.chartRect.width() / 2, context.chartRect.height() / 2) - halfLineWidth;

      let centerX = context.chartRect.width() / 2 + context.options.chartPadding;
      let centerY = context.chartRect.height() / 2 + context.options.chartPadding;

      let startX =  centerX + maxRadius;
      let startY = centerY ;

      let endX = centerX - maxRadius;
      let endY = startY;

      //Draw maxWeight marker
      if(self.maxWeight < totalWeight) {
        let maxWeightFraction = self.maxWeight / totalWeight;

        // @ts-ignore
        let start = Chartist.polarToCartesian(centerX, centerY, maxRadius + halfLineWidth + 5, 270 + 180 * maxWeightFraction);

        // @ts-ignore
        let end = Chartist.polarToCartesian(centerX, centerY, maxRadius - halfLineWidth - 5, 270 + 180 * maxWeightFraction);

        context.svg.elem("line", {
          x1: start.x,
          y1: start.y,
          x2: end.x,
          y2: end.y,
        }, "ct-gauge-marker");
      }

      //Draw background ARC
      let d = "M"+startX+","+startY+" A"+maxRadius+","+maxRadius+",0,0,0,"+(endX)+","+endY;

      context.svg.elem("path", {
        d: d,
        style: "stroke-width: "+(context.options.donutWidth + 2)+"px"
      }, "ct-gauge-outline", true);

      let dropZone = chartElm.querySelector("svg");

      //Change colors
      chartElm.querySelectorAll(".ct-slice-donut").forEach((slice)=>{
        let conceptIndex = parseInt(slice.getAttribute("ct:meta").replace("index-", ""));
        let concept = self.stories[conceptIndex];

        slice.style.stroke = concept.color;
        slice.style.fill = "none";
        let title =  document.createElementNS("http://www.w3.org/2000/svg","title");
        title.textContent = concept.title;
        slice.appendChild(title);
      });
    });
  }

  save() {
    //Ask for comment
    let dialogRef = this.dialog.open(PuzzleCommentDialogComponent,{
      panelClass: ['reinvent-popup']
    });

    dialogRef.afterClosed().subscribe((value)=>{
      let comment = dialogRef.componentInstance.commentArea.nativeElement.value;

      let anonymousUser = null;
      const userReference =  this.authService.getUserReference();
      if(userReference == null) {
        anonymousUser = this.userService.getAnonymousCookieValue();
      }

      let puzzleAnswer = {
        anonymous_user: anonymousUser,
        user: userReference,
        usedWeight: this.usedWeight,
        stories: this.stories,
        comment: comment,
        time: firebase.firestore.Timestamp.fromDate(new Date()),
        puzzleId: this.puzzleId
      }

      this.questionnaireService.savePuzzleAnswer(puzzleAnswer);
    });
  }
}
