import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentReference} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {AuthService} from "../../service/auth.service";
import moment from "moment/moment";
import {Kblock} from "../../model/kblock.model";
import {ModelService} from "../../service/model.service";
import {Concept} from "../../model/concept.model";
import {User} from "../../model/user.model";
import {KblockService} from "../../service/kblock.service";
import {ConceptService} from "../../service/concept.service";
import {CommentsService} from "../comments.service";
import {Subscription} from "rxjs";

import {TranslateService} from "@ngx-translate/core";

export class Comment {
  content: string;
  user: DocumentReference;
  posted: firebase.firestore.Timestamp;
  edited: firebase.firestore.Timestamp;
  owningReference: DocumentReference;
  id?: string;
  currentlyEditing?: boolean;
}

@Component({
  selector: 'app-commenter',
  templateUrl: './commenter.component.html',
  styleUrls: ['./commenter.component.scss']
})
export class CommenterComponent implements OnInit, OnChanges {

  @Input() owningEntity : Concept | Kblock;

  currentEntity : Concept | Kblock;

  comments: Comment[] = [];
  userReference: DocumentReference;
  user: User;
  editingComment: Comment;

  commentsSubscription : Subscription;

  constructor(private authService: AuthService, public model : ModelService, private kblockService: KblockService, private conceptService : ConceptService, private commentsService : CommentsService,private translate: TranslateService) {
    this.userReference =  this.authService.getUserReference();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.owningEntity !== this.currentEntity) {
      this.reload();
    }
  }

  ngOnInit(): void {
  }

  private reload() {
    this.ngOnDestroy();
    console.log("Reloading comments...");
    this.currentEntity = this.owningEntity;
    this.commentsSubscription = this.commentsService.getCommentsFromOwningEntity(this.owningEntity).subscribe((comments)=>{
      this.comments = comments.sort((c1, c2)=>{
        return c2.posted.toMillis() - c1.posted.toMillis();
      });
    });
  }

  ngOnDestroy() {
    if(this.commentsSubscription != null) {
      this.commentsSubscription.unsubscribe();
      this.commentsSubscription = null;
    }
  }

  getOwningReference() : DocumentReference {
    if((this.owningEntity as Concept).story_rating != null) {
      return this.conceptService.getReference(this.owningEntity as Concept);
    } else {
      return this.kblockService.getkBlockReference(this.owningEntity as Kblock);
    }
  }

  getDateFromTimestamp(timestamp) {
    // @ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;

    if(locale == null) {
      locale = "en";
    }

    return moment(timestamp.toDate()).locale(locale).calendar();
  }

  canEdit(comment : Comment) {
    let admin = false;

    if((this.owningEntity as Concept).story_rating != null) {
      admin = this.model.adminRole();
    } else {
      admin = (this.owningEntity as Kblock).owner == this.userReference;
    }

    if(admin) {
      return true;
    }

    return this.isSelfAuthored(comment);
  }

  isSelfAuthored(comment : Comment) {
    return this.authService.isLoggedIn && this.userReference.isEqual(comment.user);
  }

  deleteComment(comment : Comment) {
    //Make cool material dialog at some point?
    this.translate.get("Really delete comment?").subscribe((confirmMsg)=>{
      if(confirm(confirmMsg)) {
        this.commentsService.deleteComment(comment);
      }
    });
  }

  editComment(comment : Comment) {
    comment.currentlyEditing = true;
  }
}
