<app-sidebar-header></app-sidebar-header>
<div class="sidebar-divider hide-when-minimized"></div>
<ng-container *ngIf="this.model.project">
    <!--<a class="sidebar-entry" routerLink="/{{env.links.viewCatalog}}/{{this.model.project.id}}">
        <mat-icon>art_track</mat-icon>
        <span class="title" translate>View stories</span>
    </a>

    <a class="sidebar-entry" routerLink="/project/{{model.project.id}}"  [attr.title]="model.project.name" *ngIf="this.model.project_id">
        <mat-icon>art_track</mat-icon>
        <span class="title">{{model.project.name}}</span>
    </a> 
    <div class="padding-top"></div>-->
    <a class="sidebar-entry padding-small" [routerLink]="'/'+env.links.viewCatalog+'/'+model.project.id"  [attr.title]="model.project.name" *ngIf="model.project && model.isProjectMember()">
        <img [src]="getImage(model.project)" class="menu-image"/>
        <span class="title" translate>{{model.project.name}}</span>
      </a> 
      <a class="sidebar-entry padding-small"  [routerLink]="'/'+env.links.viewProject+'/'+model.project.id"  [attr.title]="model.project.name" *ngIf="model.project && !model.isProjectMember()">
        <img [src]="getImage(model.project)" class="menu-image"/>
        <span class="title" translate>{{model.project.name}}</span>
      </a> 

      <div class="sidebar-divider hide-when-minimized"></div>
      
    <app-stories-list></app-stories-list>




<!--    <a href="javascript:void(null);" class="sidebar-entry" (click)="createStory()">
        <mat-icon>control_point</mat-icon>
        <span class="title" translate>Add story</span>
    </a>
    <br />
    <a href="javascript:void(null);" class="sidebar-entry" (click)="createTheme()">
        <mat-icon>control_point</mat-icon>
        <span class="title" translate>Create theme</span>
    </a>-->
</ng-container>


<a href="javascript:void(null);" class="sidebar-entry" (click)="toggle()" title="{{'Toggle Menu'|translate}}">
    <ng-container *ngIf="minimized">
        <mat-icon>chevron_right</mat-icon>
        <span class="title" translate>Re-open Menu</span>
    </ng-container>
    <ng-container *ngIf="!minimized">
        <mat-icon>chevron_left</mat-icon>
        <span class="title" translate>Minimize Menu</span>
    </ng-container>
</a>        
