<h1 mat-dialog-title>{{'Attach Image'|translate}}</h1>
<div mat-dialog-content #dialogContent>
    <mat-tab-group (selectedIndexChange)="tabIndexChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label><mat-icon>insert_drive_file</mat-icon>&nbsp;&nbsp;{{'File'|translate}}</ng-template>
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <mat-form-field>
                <mat-label>{{'Select Image'|translate}}</mat-label>
                <ngx-mat-file-input accept=".png,.jpg" multiple="false" placeholder="{{'Select File'|translate}}" [(ngModel)]="fromFile" (change)="checkUploadEnabled($event)"></ngx-mat-file-input>
                <mat-hint align="start">{{'Upload an image located on your device'|translate}}</mat-hint>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
          <div class="cropper-area hidden" #cropperArea>
            <div class="preview"><img #imgPreview /></div>
            <div class="toolbar"><a href="#" (click)="rotate($event, -90)"><mat-icon>rotate_left</mat-icon></a><a href="#" (click)="rotate($event, 90)"><mat-icon>rotate_right</mat-icon></a></div>
          </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label><mat-icon>link</mat-icon>&nbsp;&nbsp;{{'Link'|translate}}</ng-template>
            <mat-form-field #formUrlField>
                <mat-label>{{'Image URL'|translate}}</mat-label>
                <input matInput type="url" (input)="validateUrl($event)" [(ngModel)]="fromUrl" placeholder="https://" (input)="checkUploadEnabled()">
                <mat-hint align="start">{{'Paste a direct link to an image on the web'|translate}}</mat-hint>
            </mat-form-field>
        </mat-tab>
        <mat-tab *ngIf="!disableUnsplash">
            <ng-template mat-tab-label><mat-icon>photo_library</mat-icon>&nbsp;&nbsp;{{'Unsplash'|translate}}</ng-template>
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <mat-form-field>
                <input matInput type="text" placeholder="{{'Search Images'|translate}}" [(ngModel)]="unsplashQuery" (keyup)="unsplashSearchKey($event)" [disabled]="unsplashSearching">
                <mat-hint align="start">{{'Type in a keyword or tag to search the photo library'|translate}}</mat-hint>
                <button matSuffix mat-icon-button (click)="unsplashSearch()" [disabled]="unsplashSearching">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>

            <div class="photo-results" #photoResults>
            </div>
            <div class="navigator">
              <ng-container *ngIf="unsplashPrev">
                <button type="button" mat-button title="{{'Prev'|translate}}" (click)="unsplashBack()">&lt;&lt;</button>
              </ng-container>
              <div class="spacer"></div>
              <ng-container *ngIf="unsplashNext">
                <button type="button" mat-button title="{{'Next'|translate}}" (click)="unsplashMore()">&gt;&gt;</button>
              </ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{'Cancel'|translate}}</button>
    <button mat-button (click)="uploadImage()" cdkFocusInitial mat-flat-button color="primary" [disabled]="uploadDisabled">{{'Upload'|translate}}</button>
</div>
