import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentReference} from "@angular/fire/compat/firestore";
import {User} from "../model/user.model";
import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import * as uuid from 'uuid';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  users : Map<string, User> = new Map();

  usersSubject : Subject<User[]>;

  constructor(private firestore : AngularFirestore, private cookieService: CookieService) {
    this.usersSubject = new Subject();
  }

  private sendUserUpdates() {
    let users = Array.from(this.users.values());

    this.usersSubject.next(users);
  }

  getUser(ref : DocumentReference) : Observable<User> {
    if(!this.users.has(ref.id)) {
      this.firestore.collection("users").doc<User>(ref.id).valueChanges().subscribe((user)=>{
        this.users.set(ref.id, user);

        this.sendUserUpdates();
      });
    } else {
      setTimeout(()=>{
        this.sendUserUpdates();
      }, 0);
    }

    return this.usersSubject.asObservable().pipe(map((users)=>{
      return users.find((user)=>{
        return user.uid === ref.id;
      })
    }));
  }

  getAnonymousCookieValue() : string {
    let anonymousCookieValue = this.cookieService.get("ideate-anonymous-user");
    if(anonymousCookieValue == null || anonymousCookieValue.trim() === "") {
      anonymousCookieValue = uuid.v4();
      console.log("Unknown user, creating anonymouse cookie: ", anonymousCookieValue);
      this.cookieService.set("ideate-anonymous-user", anonymousCookieValue, 365 * 10, "/");
    }

    return anonymousCookieValue;
  }
}
