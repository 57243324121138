import { Component, OnInit } from '@angular/core';
import {ModelService} from "../../service/model.service";
import {ActivePageServiceService} from "../active-page-service.service";
import {environment} from "../../../environments/environment";
import {WorkshopNavigatorDialogComponent} from "../workshop-navigator-dialog/workshop-navigator-dialog.component";

@Component({
  selector: 'app-workshop-navigator',
  templateUrl: './workshop-navigator.component.html',
  styleUrls: ['./workshop-navigator.component.scss']
})
export class WorkshopNavigatorComponent implements OnInit {

  env = environment;

  constructor(public model:ModelService, public activePageService:ActivePageServiceService) { }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  openDialog() {
    WorkshopNavigatorDialogComponent.openDialog().subscribe((workshop)=>{
      if(workshop != null) {
        this.activePageService.navigateTo("/"+this.env.links.workshop+"/"+this.model.project_id+"/"+workshop.id);
      }
    });
  }

  getWorkshops() {
    if(this.model.process_service.process != null) {
      return this.model.process_service.process.workshops.filter((workshop) => {
        return workshop.status != "hidden";
      });
    }

    return [];
  }

  getPrevWorkshop() {
    if(this.model.process_service.process != null) {
      let workshops = this.getWorkshops();

      let currentIndex = workshops.findIndex((workshop)=>{
        return workshop.id == this.model.workshop_id;
      });

      if(currentIndex > 0) {
        return workshops[currentIndex-1];
      }
    }
  }

  getNextWorkshop() {
    if(this.model.process_service.process != null) {
      let workshops = this.getWorkshops();

      let currentIndex = workshops.findIndex((workshop)=>{
        return workshop.id == this.model.workshop_id;
      });

      if(currentIndex < workshops.length-1) {
        return workshops[currentIndex+1];
      }
    }
  }

  prev() {
    let prevWorkshop = this.getPrevWorkshop();
    this.activePageService.navigateTo("/"+this.env.links.workshop+"/"+this.model.project_id+"/"+prevWorkshop.id);
  }

  next() {
    let nextWorkshop = this.getNextWorkshop();
    this.activePageService.navigateTo("/"+this.env.links.workshop+"/"+this.model.project_id+"/"+nextWorkshop.id);
  }

}
