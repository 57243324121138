<ng-container *ngIf="activePageService.currentFavor">
    <div class="toggler" (click)="toggleMenu()">
        <div class="title">{{activePageService.currentFavor.title}}</div>
        <mat-icon *ngIf="!menuOpen">expand_more</mat-icon>
        <mat-icon *ngIf="menuOpen">expand_less</mat-icon>
    </div>
    <div class="toggled {{menuOpen?'open':''}}">
        <ng-container *ngFor="let favor of getWorkshopFavors()">
            <button mat-button (click)="gotoFavor(favor)" class="{{favor.id==activePageService.currentFavor.id?'active':''}}">{{favor.title}}</button>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="activePageService.active_page == env.links.workshop">
    <div class="toggler" *ngIf="model.workshop" (click)="toggleMenu()">
        <div class="title">{{model.workshop.title}}</div>
        <mat-icon *ngIf="!menuOpen">expand_more</mat-icon>
        <mat-icon *ngIf="menuOpen">expand_less</mat-icon>
    </div>
    <div class="toggled {{menuOpen?'open':''}}" *ngIf="model.process_service.process">
        <ng-container *ngFor="let workshop of getWorkshops()">
            <button mat-button (click)="gotoWorkshop(workshop)" class="{{workshop.id==model.workshop_id?'active':''}}">{{workshop.title}}</button>
        </ng-container>
    </div>
</ng-container>
