<div class="dialog-top hidden-on-mobile">
    <div class="participate-heading">{{'Edit text' | translate }}</div>
    <div class="dialog-top-menu">
        <span class="pointer grey" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<div class="e-container">
    <div class="e-column1 e-overflow" >
        <app-editor-js [content]="data.content" [projectId]="data.projectId"></app-editor-js>
    </div>
  <!--  <div class="e-column2 e-overflow">
        <app-kblocks-overview *ngIf="ref_id" [id]="ref_id" [type]="data.ktype" [blocks]="6" [load_data]="false"></app-kblocks-overview>
    </div>-->
</div>