<div class="splashblock">
    <div class="splashblock-splash">
        <b>ideate</b>.nu
    </div>
    <form class="splashblock-content">
        <h1 translate>Reset Password</h1>
        <p>{{'Please enter your email address to request a password reset.'|translate}}</p>
        
        <mat-form-field>
          <mat-label>{{'Email'|translate}}</mat-label>
          <input matInput type="email" matTooltip="{{'Email'|translate}}" #passwordResetEmail required autofocus
                 placeholder="{{'Enter email'|translate}}">
        </mat-form-field>

        <div class="onboarding-options">
            <button mat-flat-button type="submit" color="primary" (click)="authService.ForgotPassword(passwordResetEmail.value)">{{'Reset Password'|translate}}</button>
            <button mat-raised-button routerLink="{{'/'+env.links.login}}">{{'Back'|translate}}</button>
        </div>
    </form>
</div>



