import { Component, OnInit } from '@angular/core';
import {StepInfo} from "./StepInfo";
import {OnboardingService} from "../../../service/onboarding.service";
import {AuthService} from "../../../service/auth.service";
import {User} from "../../../model/user.model";
import {first} from "rxjs/internal/operators/first";

@Component({
    selector: 'app-site-popup',
    templateUrl: './site-popup.component.html',
    styleUrls: ['./site-popup.component.scss']
})
export class SitePopupComponent implements OnInit {
    visible : boolean = false;
    completed : boolean = false;
    currentStep : number = 0;
    steps : StepInfo[] = [];
    user: User;

    constructor(private onboardingService: OnboardingService, private authService: AuthService) { }

    ngOnInit(): void {
        // STUB: Find unread StepInfos in DB for this user and populate steps
        // And set visible to true to show it
        // this.visible = true;
      this.authService.userLoggedIn$.pipe(first()).subscribe((user)=>{
        console.log("Got user:");
        this.user = user;
        if(user.onboardingLastStep == null) {
          user.onboardingLastStep = -1;
        }

        this.onboardingService.findSteps(user.onboardingLastStep).pipe(first()).subscribe((steps)=>{
          console.log("Got steps:", steps);
          this.steps = steps;
          if(this.steps.length > 0) {
            this.visible = true;
          }
        });
      });
    }

    next(){
        if (this.currentStep == this.steps.length-1){
            // Close dialog
            this.completed = true;

            // TODO: Register as read
          this.user.onboardingLastStep = this.steps[this.currentStep].timestamp;
          this.authService.updateUser(this.user);
        } else {
            this.currentStep ++;
        }
    };
}
