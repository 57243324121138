import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { JoinKeys } from '../model/join-keys.model';
import { finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JoinKeysService {

  constructor(private firestore: AngularFirestore) { }


  createJoinKey(key: JoinKeys){
    return new Promise<any>((resolve, reject) =>{
      this.firestore
          .collection("joinkeys")
          .add({...key}).then(res => {key.id=res.id; resolve(res);}, err => reject(err));
  });
  }


  getKeybyId(key_id: string) {
    console.log("key_id: "+key_id );
    return this.firestore.doc<JoinKeys>('joinkeys/' + key_id).valueChanges();
    
  }


}
