import {Component, Input, OnInit} from '@angular/core';
import {OnboardingService} from "../../../service/onboarding.service";
import {StepInfo} from "../site-popup/StepInfo";
import {ImageUploadDialogComponent} from "../../image-upload-dialog/image-upload-dialog.component";
import {Observable, Subject} from "rxjs";
import {Concept} from "../../../model/concept.model";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-onboarding-step-editor',
  templateUrl: './onboarding-step-editor.component.html',
  styleUrls: ['./onboarding-step-editor.component.scss']
})
export class OnboardingStepEditorComponent implements OnInit {

  @Input() editedStep: StepInfo;

  title: string;
  content: string;
  editMode: boolean = false;

  // @ts-ignore
  editor: Quill;

  constructor(private onboardingService: OnboardingService, public dialogRef: MatDialogRef<OnboardingStepEditorComponent>) { }

  ngOnInit(): void {
    if(this.editedStep != null) {
      console.log("Setting up edit mode for:", this.editedStep);
      this.editMode = true;
      this.title = this.editedStep.helpTitle;
      this.content = this.editedStep.content;
    }
  }

  addStep() {
    if(this.editMode) {
      this.editedStep.content = this.content;
      this.editedStep.helpTitle = this.title;

      this.title = "";
      this.content = "";

      console.log("Updating step:", this.editedStep);
      this.onboardingService.updateStep(this.editedStep);

      this.dialogRef.close(this.editedStep);
    } else {
      console.log("Adding new step...");
      let step = new StepInfo();
      step.helpTitle = this.title;
      step.content = this.content;
      step.timestamp = Date.now();

      this.title = "";
      this.content = "";

      console.log("Adding step:", step);
      this.onboardingService.addStep(step);

      this.dialogRef.close(step);
    }
  }

  onEditorOpen(editor) {
    this.editor = editor;
  }

  openUploadDialog(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    ImageUploadDialogComponent.showUploadDialog().subscribe((img)=>{
      if (img == null) {
        return;
      }

      this.editor.insertEmbed(this.editor.getSelection(true).index, 'customImage', {
        src: img.url.small,
        original: img.url.original,
        width: 200
      }, 'user');

      //Make sure text was saved? Seems updateContents does not trigger [(ngmodel)]
      this.content = this.editor.root.innerHTML;
    });
  }

  static showDialog(editedStep: StepInfo) : Observable<StepInfo> {
    let subject = new Subject<StepInfo>();

    const dialog = injector.get(MatDialog);

    const dialogRef = dialog.open(OnboardingStepEditorComponent, {
      panelClass: ['reinvent-popup'],
      autoFocus: false
    });

    dialogRef.componentInstance.editedStep = editedStep;

    dialogRef.afterClosed().subscribe((step)=>{
      subject.next(step);
    });

    return subject.asObservable();
  }
}
