import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModelService} from "../../service/model.service";
import {Subject} from "rxjs";
import {Workshop} from "../../model/process.model";

@Component({
  selector: 'app-workshop-navigator-dialog',
  templateUrl: './workshop-navigator-dialog.component.html',
  styleUrls: ['./workshop-navigator-dialog.component.scss']
})
export class WorkshopNavigatorDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WorkshopNavigatorDialogComponent>, public model:ModelService) { }

  ngOnInit(): void {
  }

  getWorkshops() {
    if(this.model.process_service.process != null) {
      return this.model.process_service.process.workshops.filter((workshop) => {
        return workshop.status != "hidden";
      });
    }

    return [];
  }

  select(workshop) {
    this.dialogRef.close(workshop);
  }

  static openDialog() {
    let subject = new Subject<Workshop>();

    const dialog = injector.get(MatDialog);

    let dialogRef = dialog.open(WorkshopNavigatorDialogComponent, {
      panelClass: ['reinvent-popup']
    });

    dialogRef.afterClosed().subscribe((workshop)=>{
      subject.next(workshop);
    });

    return subject.asObservable();
  }
}
