import { Pipe, PipeTransform } from '@angular/core';
import {kBlockDateCollection} from "../../service/model.service";
import {User} from "../../model/user.model";
import {FilterHiddenKblockPipe} from "./filter-hidden-kblock.pipe";

@Pipe({
  name: 'filterBlockdates'
})
export class FilterBlockdatesPipe implements PipeTransform {

  transform(blockdate: kBlockDateCollection, user:User, currentProjectId:string): unknown {
    let filterHiddenKblockPipe = new FilterHiddenKblockPipe();

    return blockdate.kblocks.filter((kblock)=>{
      return filterHiddenKblockPipe.transform(kblock, user, currentProjectId);
    }).length > 0;
  }

}
