import htmlTemplate from './questionnaire-block.html'

import 'firebase/compat/firestore';
import {Question, Questionnaire, QuestionnaireService, QuestionOption} from '../../questionnaire.service';
import {DocumentReference} from "@angular/fire/compat/firestore";
import {first} from "rxjs/operators";

import * as uuid from 'uuid';
import {EditorJSBlockTranslator} from "../EditorJSBlockTranslator";

export class QuestionnaireBlock {

  private questionnaire : Questionnaire = null;
  private questionnaireRef : DocumentReference = null;
  private api;
  private config;
  private questionnaireService : QuestionnaireService;
  private template;
  private editorHtml;

  static get toolbox() {
    return {
      // @ts-ignore
      title: "Questionnaire",
      icon: "<span class='material-icons'>contact_support</span>"
    }
  }

  constructor({config, api, data}) {
    this.api = api;
    this.config = Object.assign({}, config);

    this.questionnaireService = injector.get(QuestionnaireService);

    console.log("Questionnaire block: ", data);

    this.setup();
    this.load(data);
  }

  setup() {
    this.template = document.createElement('template');
    this.template.innerHTML = EditorJSBlockTranslator.translateHtmlTemplate(htmlTemplate);

    this.editorHtml = this.template.content.querySelector(".questionnaire-block") as HTMLElement;

    let multipleChoiceButton = this.editorHtml.querySelector(".multipleChoiceButton");
    let commentButton = this.editorHtml.querySelector(".commentButton");
    let ratingButton = this.editorHtml.querySelector(".ratingButton");

    multipleChoiceButton.addEventListener("click", ()=>{
      this.addQuestion("multiple_choice");
    });

    commentButton.addEventListener("click", ()=>{
      this.addQuestion("comments");
    });

    ratingButton.addEventListener("click", ()=>{
      this.addQuestion("rating");
    });
  }

  load(data){
    if(data.reference != null) {
      this.questionnaireRef = this.questionnaireService.getQuestionnaireRef(data.reference);
      this.questionnaireService.getQuestionnaire(this.questionnaireRef).pipe(first()).subscribe((questionnaire)=>{
        this.questionnaire = questionnaire;

        this.editorHtml.querySelector("input.showResult").checked = questionnaire.show_result;

  /*      if(questionnaire.title != null) {
          this.editorHtml.querySelector(".questionnaireTitle").value = questionnaire.title;
        }*/

        if(questionnaire.questions != null) {
          questionnaire.questions.forEach((question)=>{
            let questionTpl = this.addQuestion(question.type);

            questionTpl.querySelector("input.question-query").value = question.question;
            questionTpl.setAttribute("data-questionId", question.id);

            switch(question.type) {
              case "multiple_choice": {
                if(question.options != null) {
                  question.options.forEach((option)=>{
                    let optionTpl = this.addOption(questionTpl);
                    optionTpl.querySelector(".optionName").value = option.name;
                  });
                }
                break;
              }
              case "comments":
                break;
              default:
                console.warn("Unknown question type: ", question.type);
            }
          });
        }
      });
    }
  }

  addQuestion(type) {
    let questionTpl = this.template.content.querySelector(".question."+type).cloneNode(true);
    questionTpl.setAttribute("data-questionId", uuid.v4());

    this.setupQuestionActions(questionTpl);

    this.editorHtml.querySelector(".questions").append(questionTpl);

    return questionTpl;
  }

  addOption(question) {
    let optionTpl = this.template.content.querySelector(".multiple_choice_option").cloneNode(true);
    question.querySelector(".options").append(optionTpl);

    return optionTpl;
  }

  setupQuestionActions(question) {
    question.querySelectorAll(".optionButton").forEach((btn)=>{
      btn.addEventListener("click", ()=>{
        this.addOption(question);
      });
    });

    question.querySelector(".delete").addEventListener("click", ()=>{
      question.remove();
    });

  }

  render() {
    return this.editorHtml;
  }

  async save(blockContent) {
    let version = 1;

    if(this.questionnaire != null && this.questionnaire.version != null) {
      version += this.questionnaire.version;
    }

    let show_result = blockContent.querySelector(".showResult").checked;

    let title = "";
    try {
      title = blockContent.querySelector(".questionnaireTitle").value;
    } catch(e) {
      console.warn("No title found in questionnaire");
    }

    let questions = [];

    blockContent.querySelectorAll("div.question").forEach((questionElm)=>{
      let questionQuestion = questionElm.querySelector("input.question-query").value;
      let questionType = questionElm.getAttribute("data-type");
      let questionOptions : QuestionOption[] = [];

      switch(questionType) {
        case "multiple_choice": {
          let optionIndex = 0;
          questionElm.querySelectorAll(".options .multiple_choice_option").forEach((optionElm)=>{
            let answer = optionElm.querySelector("input.optionName").value;
            questionOptions.push({
              name: answer,
              value: optionIndex
            });
            optionIndex++;
          });

          break;
        }
        case "comments":
          break;
        default:
          console.warn("Unknown question type: ", questionType);
      }

      let question : Question = {
        id: questionElm.getAttribute("data-questionId"),
        question: questionQuestion,
        type: questionType
      };

      if(questionOptions.length > 0) {
        question.options = questionOptions;
      }

      questions.push(question);
    });

    let questionnaire : Questionnaire = {
      version: version,
      show_result: show_result,
      questions: questions,
      title: title
    }

    //Save questionnaire and get reference
    if(this.questionnaire != null) {
      //Update old questionnaire
      await this.questionnaireRef.update(questionnaire);
    } else {
      //Save new
      this.questionnaireRef = await this.questionnaireService.saveQuestionnaire(questionnaire);
    }

    return {
      reference: this.questionnaireRef.path
    };
  }

  validate(savedData) {
    return true;
  }
}
