import { Component, OnInit } from '@angular/core';
import {ModelService} from "../../service/model.service";
import {ActivePageServiceService} from "../../frontend/active-page-service.service";
import {Favor} from "../../model/favor.model";
import {environment} from "../../../environments/environment";
import {Workshop} from "../../model/process.model";

@Component({
  selector: 'app-top-menu-workshop-mobile',
  templateUrl: './top-menu-workshop-mobile.component.html',
  styleUrls: ['./top-menu-workshop-mobile.component.scss']
})
export class TopMenuWorkshopMobileComponent implements OnInit {

  env = environment;

  menuOpen: boolean = false;

  constructor(public model : ModelService, public activePageService: ActivePageServiceService) {
    this.activePageService.routeChanged.subscribe(()=>{
      this.closeMenu();
    })
  }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  getWorkshops() {
    if(this.model.process_service.process != null) {
      return this.model.process_service.process.workshops.filter((workshop) => {
        return workshop.status != "hidden";
      });
    }

    return [];
  }

  getWorkshopFavors() {
    return this.model.favors.filter((favor)=>{
      return favor.workshop_id == this.model.workshop_id;
    });
  }

  gotoFavor(favor: Favor) {
    this.closeMenu();
    switch(favor.type) {
      case "DivergeFavor": {
        this.activePageService.navigateTo("/"+this.env.links.divergeFavor+"/"+this.model.project_id+"/"+favor.id);
        break;
      }

      case "StoryRatings": {
        this.activePageService.navigateTo("/"+this.env.links.viewStory+"/"+favor.stories[0]+"/"+favor.id);
        break;
      }

      default: {
        console.warn("Unknown favor type:", favor.type);
      }
    }
  }

  gotoWorkshop(workshop:Workshop) {
    this.closeMenu();
    this.activePageService.navigateTo("/"+this.env.links.workshop+"/"+this.model.project_id+"/"+workshop.id);
  }
}
