import { Component, OnInit } from '@angular/core';
import {Catalog} from "../catalog.service";
import {AuthService} from "../../service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentPage, ModelService} from "../../service/model.service";
import {ProjectService} from "../../service/project.service";

@Component({
  selector: 'app-participant-view-page',
  templateUrl: './participant-view-page.component.html',
  styleUrls: ['./participant-view-page.component.scss'],
  host: {'class': 'flexblock'}
})
export class ParticipantViewPageComponent implements OnInit {

  current_id : string;
  isMobile: boolean;

  constructor(public authService: AuthService, private route: ActivatedRoute, private router:Router, public model:ModelService, private projectService:ProjectService) {
    router.events.subscribe((val) => {
      if (this.route.snapshot.paramMap.get('id') != this.current_id) {
        this.current_id = this.route.snapshot.paramMap.get('id');
        this.model.setSelectedProject(this.current_id);
      }
    });
  }

  ngOnInit(): void {
    //Setup mobile check
    window.onresize = () => {
      this.isMobile = window.innerWidth <= 991;
    }
    this.isMobile = window.innerWidth <= 991;

    this.model.subscribeProjects();

    this.authService.userLoggedIn$.subscribe(user => {
      this.current_id = this.route.snapshot.paramMap.get('id');
      this.model.setSelectedProject(this.current_id);
    });
  }

}
