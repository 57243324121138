import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditDivergeFavorComponent } from 'src/app/favors/edit-diverge-favor/edit-diverge-favor.component';
import { EditFavorComponent } from 'src/app/favors/edit-favor/edit-favor.component';
import { Concept } from 'src/app/model/concept.model';
import { Favor, FavorType } from 'src/app/model/favor.model';
import { Workshop } from 'src/app/model/process.model';
import { ModelService } from 'src/app/service/model.service';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-workshop-widget',
  templateUrl: './workshop-widget.component.html',
  styleUrls: ['./workshop-widget.component.scss']
})
export class WorkshopWidgetComponent implements OnInit {
  @Input() workshop_id : string;
  env=environment;
  selected_favor: Favor;
  workshop : Workshop;
  local_favor_id : string;
  @Input() showBackButton: boolean = true;

  @Input()
  set current_favor(value:Favor) {
    this.selected_favor = value;

    if (value!=null && this.model.process_service.process && this.model.process_service.process.workshops) {
      this.workshop = this.model.process_service.process.workshops.find(ws => ws.id == value.workshop_id);
      this.workshop_id = this.workshop.id;
    }
  }

  @Input()
  set favor_id(id:string) {
    this.local_favor_id = id;
    this.selected_favor = this.model.favors.find( favor => favor.id == id);

    if (this.selected_favor!=null && this.model.process_service.process && this.model.process_service.process.workshops) {
      this.workshop = this.model.process_service.process.workshops.find(ws => ws.id == this.selected_favor.workshop_id);
      this.workshop_id = this.workshop.id;
    }
  }

  FavorType = FavorType;
  constructor(public model: ModelService, private router: Router, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.model.process_service.processLoaded$.subscribe({
        complete: () => {
          console.log("Complete loading. Local favor id:" + this.local_favor_id)
          if (this.selected_favor && this.workshop) return;
          this.selected_favor = this.model.favors.find( favor => favor.id == this.local_favor_id);

      if (this.selected_favor!=null && this.model.process_service.process && this.model.process_service.process.workshops) {
        console.log("Set favor id workshops:" + this.model.process_service.process.workshops.length);

        this.workshop = this.model.process_service.process.workshops.find(ws => ws.id == this.selected_favor.workshop_id);
        this.workshop_id = this.workshop.id;
      }
    }
    });
  }

  ratingCompleted(story:Concept) {
    return this.model.user_story_ratings.some(x => x.story.id == story.id);
  }

  favorString(favor : Favor) {
    if (this.model.user_story_ratings && this.model.concepts && FavorType.StoryRatings==favor.type)
       return "- ("+ this.model.countFavors(favor) +" / "+favor.stories.length + ")";
    else return "";
  }

  favorCompleted(favor : Favor) : boolean {
    if (favor.type == FavorType.StoryRatings)
      return this.model.countFavors(favor) == favor.stories.length;
    else return false;
  }

  navigateTo(destination) {
    document.querySelector(".scrollBlock").scrollTop = 0;
    this.router.navigate([destination]);
  }

  startWorkshop() {
    const workshop_favors = this.model.favors.filter((favor) => {
      return favor.workshop_id == this.workshop_id
    });

    if (workshop_favors.length>0) {
      switch(workshop_favors[0].type) {
        case FavorType.DivergeFavor:
          this.router.navigate(['/' + environment.links.divergeFavor + '/' + this.model.project.id + '/' + workshop_favors[0].id]);
          break;
        case FavorType.StoryRatings:

          const concepts = this.model.concepts.filter((concept) => {
            return this.model.inFavors(workshop_favors[0], concept.id)
          });
          if (concepts.length>0)
          {
              this.router.navigate(['/' + environment.links.viewStory + '/' + concepts[0].id + '/' + workshop_favors[0].id]);
          }
          break;
      }
    }
   }

   deleteFavor(id : string) {
     this.model.favorService.deleteFavor(id);
   }
 
   updateProcess() {
     this.model.process_service.update();
   }
 
   editFavor(input_favor : Favor) {
     let dialogRef;
     if (input_favor.type == FavorType.StoryRatings) {
         dialogRef = this.dialog.open(EditFavorComponent, {
         data: {favor : input_favor},
         panelClass: ['reinvent-popup']
       });
     } else if (input_favor.type == FavorType.DivergeFavor) {
       dialogRef = this.dialog.open(EditDivergeFavorComponent, {
         data: {favor : input_favor},
         panelClass: ['reinvent-popup']
       });
     }
   }
}
