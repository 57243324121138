
import firebase from 'firebase/compat/app';
import { User } from './user.model';
import { DocumentReference} from '@angular/fire/compat/firestore';
import {Like} from "../frontend/like/like.component";
import {Image} from "../frontend/image-upload-dialog/image-upload-dialog.component";


export enum kBlockType {
    User,
    Project,
    Concept
}

export type kReference = { name: string; ref: DocumentReference};

export interface og_image {
    url : string;
}


export interface open_graph {
    ogTitle : string;
    ogDescription : string;
    ogImage : og_image;
}

export interface owner_data {
    displayName : string;
    photoURL : string;
    image: Image;
}

export class Kblock {
    heading: string;
    text: string;
    id : string;
    timestamp :  firebase.firestore.Timestamp;
    owner: DocumentReference;
    references : { name: string; ref: DocumentReference; color: string; project?: DocumentReference}[] = [];
    projects: DocumentReference[];
    link_url : string;
    open_graph : open_graph;
    owner_data : owner_data;
    followers : DocumentReference[];
    likes: Like[] = [];

    //references : Map<string, kReference>[];


    public get datetime() : string
    {
       
        let d: Date = this.timestamp.toDate();
        return d.toLocaleDateString();
    }

    public set time_stamp(d:Date)
    {
        this.timestamp=  firebase.firestore.Timestamp.fromDate(d);
    }


}
