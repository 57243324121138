import { Component, OnInit } from '@angular/core';
import {ModelService} from "../../service/model.service";
import {ActivePageServiceService} from "../active-page-service.service";
import {environment} from "../../../environments/environment";
import {FavorNavigatorDialogComponent} from "../favor-navigator-dialog/favor-navigator-dialog.component";

@Component({
  selector: 'app-favor-navigator',
  templateUrl: './favor-navigator.component.html',
  styleUrls: ['./favor-navigator.component.scss']
})
export class FavorNavigatorComponent implements OnInit {

  env = environment;

  constructor(public model:ModelService, public activePageService:ActivePageServiceService) { }

  ngOnInit(): void {
    this.activePageService.checkWorkshop();
  }

  getStories() {
    if(this.activePageService.currentFavor && this.activePageService.currentFavor.stories) {
      return this.activePageService.currentFavor.stories;
    }
    return [];
  }

  getPrevStory() {
    let stories = this.getStories();

    let currentIndex = stories.findIndex((storyId)=>{
      return storyId == this.model.concept_id;
    });

    if(currentIndex > 0) {
      return stories[currentIndex-1];
    }
  }

  getNextStory() {
    let stories = this.getStories();

    let currentIndex = stories.findIndex((storyId)=>{
      return storyId == this.model.concept_id;
    });

    if(currentIndex < stories.length-1) {
      return stories[currentIndex+1];
    }
  }

  next() {
    let nextStory = this.getNextStory();
    this.activePageService.navigateTo("/"+this.env.links.viewStory+"/"+nextStory+"/"+this.activePageService.currentFavor.id);
  }

  prev() {
    let prevStory = this.getPrevStory();
    this.activePageService.navigateTo("/"+this.env.links.viewStory+"/"+prevStory+"/"+this.activePageService.currentFavor.id);
  }

  openDialog() {
    FavorNavigatorDialogComponent.openDialog();
  }

}
