import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Concept } from 'src/app/model/concept.model';
import { Kblock } from 'src/app/model/kblock.model';
import { Project } from 'src/app/model/project.model';
import { CurrentPage, ModelService } from 'src/app/service/model.service';
import { environment } from 'src/environments/environment';
import {ThumbnailService} from "../../frontend/thumbnail.service";
import {ImageUploadDialogComponent} from "../../frontend/image-upload-dialog/image-upload-dialog.component";
import {Lightbox} from "ngx-lightbox";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {OverlaySpinnerService} from "../../service/overlay-spinner.service";
import {first} from "rxjs/internal/operators/first";

@Component({
  selector: 'app-edit-inspiration-block',
  templateUrl: './edit-inspiration-block.component.html',
  styleUrls: ['./edit-inspiration-block.component.scss']
})
export class EditInspirationBlockComponent implements OnInit, AfterViewInit {

  @ViewChild("inspirationTextInside") inspirationTextInside : ElementRef;

  env = environment;
  kblock : Kblock;
  editMode : boolean = false;
  text : string;
  linkUrl: string;

  // @ts-ignore
  editor: Quill;

  constructor(@Inject(MAT_DIALOG_DATA) public block: Kblock,
                    private router: Router, private lightbox: Lightbox, public sanitizer: DomSanitizer,
                    public dialogRef: MatDialogRef<EditInspirationBlockComponent>, public model: ModelService,
                    private http: HttpClient, private overlaySpinnerService: OverlaySpinnerService)
     {
       this.kblock = block;
     }

  ngOnInit(): void {
    this.text = this.kblock.text;
  }

  ngAfterViewInit() {
  }

  openLightbox(evt: MouseEvent) {
    // @ts-ignore
    if(evt.target.matches("img")) {
      let album = [];
      let startIndex = 0;
      this.inspirationTextInside.nativeElement.querySelectorAll("img").forEach((img, i)=>{
        let src = img.getAttribute("data-original");
        if(src == null) {
          src = img.src;
        }
        album.push({
          src: src
        });

        if(img === evt.target) {
          startIndex = i;
        }
      });

      this.lightbox.open(album, startIndex, {
        fadeDuration: 0.2,
        resizeDuration: 0.2,
        fitImageInViewPort: true,
        alwaysShowNavOnTouchDevices: true,
        centerVertically: true,
        wrapAround: true,
        showImageNumberLabel: true
      });

      console.log("After lightbox!");
    }
  }

  public getkBlockDateTime(kblock : Kblock) : string
  {

      let d: Date = kblock.timestamp.toDate();
      return d.toLocaleDateString();
  }

  shortLink(link : string)
  {
    return (new URL(link)).origin;

  }

  closeDialog() {
    this.dialogRef.close();
  }

    hasPhoto(): boolean {
        if(this.kblock.owner_data != null) {
            return ThumbnailService.getPhotoFromUser(this.kblock.owner_data) != null;
        }
        return false;
    }

    getPhoto():string {
        return ThumbnailService.getPhotoFromUser(this.kblock.owner_data);
    }

  deleteReference(kblock : Kblock, ref : DocumentReference) {
    kblock.projects.forEach( (item, index) => {
        if(item.path == ref.path)
        {
          console.log("match");
        kblock.projects.splice(index,1);
        }
      });

    kblock.references.forEach( (item, index) => {
      if(item.ref === ref) kblock.references.splice(index,1);
    });

    this.model.kBlockService.updatekBlock(kblock);
   }

   openReference(ref : DocumentReference) {
    if (ref.path.startsWith(environment.firebaseVariable.project))
    {
      this.closeDialog();
      this.router.navigate(['/'+environment.links.viewProject+'/'+ref.id]);
    }
    if (ref.path.startsWith(environment.firebaseVariable.concept))
    {
      this.closeDialog();
      this.router.navigate(['/'+environment.links.viewStory+'/'+ref.id]);
    }


   }

   public hasMenuPermission() {
      return this.model.user != null;
   }

   public showReferenceMenu()
   {
     return  (this.model.current_page==CurrentPage.catalog_overview || this.model.current_page==CurrentPage.story_view);
   }

   deletekBlock(){
    this.model.deleteKBlock(this.kblock);
    this.closeDialog();
  }


  openEdit() {
    this.editMode = true;
    this.linkUrl = this.kblock.link_url;
    console.log("Open edit called!");
    console.trace();
  }

  addStoryReference(concept : Concept) {
    this.model.connectConceptKblock(this.kblock, concept);
   }

   addProjectReference(project : Project) {
    this.model.connectProjectKblock(this.kblock, project);
  }

  async submitEdit() {
    this.kblock.text = this.text;
    let linkUrlChanged = false;
    if(this.kblock.link_url !== this.linkUrl) {
      this.kblock.link_url = this.linkUrl;
      if(this.kblock.link_url.trim().length > 0) {
        this.overlaySpinnerService.show("Updating opengraph...");
        const ogsResult:any = await this.http.get("https://europe-west3-ideate-project.cloudfunctions.net/fetchOpengraph?url=" + this.kblock.link_url).pipe(first()).toPromise();
        if (ogsResult != null) {
          this.kblock.open_graph = ogsResult;
        }
        this.overlaySpinnerService.hide();
      } else {
        //Link removed
        this.kblock.open_graph = null;
      }
    }
    await this.model.kBlockService.updatekBlock(this.kblock);

    this.editMode = false;
  }

  cancelEdit() {
    this.text = this.kblock.text;
    this.linkUrl = this.kblock.link_url;
    this.editMode = false;
  }

  setFocus(editor) {
    this.editor = editor;
    editor.focus()
  }

  openUploadDialog(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();

    ImageUploadDialogComponent.showUploadDialog().subscribe((img)=>{
      if (img == null) {
        return;
      }

      if(img != null) {
        this.editor.insertEmbed(this.editor.getSelection(true).index, 'customImage', {
          src: img.url.small,
          original: img.url.original,
          width: 200
        }, 'user');

        //Make sure text was saved? Seems updateContents does not trigger [(ngmodel)]
        this.text = this.editor.root.innerHTML;
      }
    });
  }

}
