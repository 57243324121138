import {Injectable} from "@angular/core";
import {AuthService} from "../../service/auth.service";
import {Catalog, CatalogService, Story} from "../../frontend/catalog.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {User} from "../../model/user.model";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})export class AuthGuardHelper {

  env = environment;

  constructor(private authService: AuthService, private catalogService: CatalogService, private _snackBar : MatSnackBar, private router: Router) {
  }

  public checkStoryRole(id: string, user: User, wantedRoles: string[]) : Promise<boolean> {
    if(user == null) {
      //Anonym can never have roles
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject)=> {
      this.catalogService.getStoryRefFromId(id).get().then((story) => {
        if (story.exists) {
          const storyData = story.data() as Story;

          this.checkCatalogRole(storyData.project.id, user, wantedRoles).then((hasRole) => {
            resolve(hasRole);
          });

        } else {
          //Cannot be admin on a non existing story
          resolve(false);
        }
      });
    });
  }

  public checkCatalogRole(id: string, user: User, wantedRoles: string[]) : Promise<boolean> {
    if(user == null) {
      //Anonym can never have roles
      return Promise.resolve(false);
    }
    return new Promise((resolve, reject)=>{
      this.catalogService.getCatalogRefFromId(id).get().then((catalog)=>{
        if(catalog.exists) {
          const catalogData = catalog.data() as Catalog;

          let userRole = catalogData.participants_role.find((participantRole)=>{
            return participantRole.email === user.email && (wantedRoles.includes(participantRole.role));
          });

          console.log("Found admin role: ", userRole, user);

          //If userRole exists, user had admin or owner role
          resolve(userRole != null);
        } else {
          //Cannot be admin on a catalog that does not exist
          resolve(false);
        }
      });
    });
  }

  public checkStoryAccess(id:string, user: User) : Promise<boolean> {
    return new Promise((resolve, reject)=>{
      this.catalogService.getStoryRefFromId(id).get().then((story)=>{
        if(story.exists) {
          const storyData = story.data() as Story;

          this.checkCatalogAccess(storyData.project.id, user).then((shouldNavigate)=>{
            resolve(shouldNavigate);
          });

        } else {
          resolve(false);
        }
      });
    });
  }

  public checkCatalogAccess(id:string, user: User) : Promise<boolean> {
    return new Promise((resolve, reject)=>{
      this.catalogService.getCatalogRefFromId(id).get().then((catalog)=>{
        if(catalog.exists) {
          const catalogData = catalog.data() as Catalog;

          if(catalogData.published === "public" || catalogData.published === "link") {
            //Catalog was public, or link public, let everyone through
            resolve(true);
          } else {
            //Catalog is private, check user permissions
            if(user != null) {
              let userRole = catalogData.participants_role.find((participantRole)=>{
                return participantRole.email === user.email;
              });

              //User can see if he has a role on this catalog
              resolve(userRole != null);
            } else {
              //Anonymous user cannot be member of catalog
              resolve(false);
            }
          }

        } else {
          resolve(false);
        }
      });
    });
  }

  public navigateTo(navigationLink: string = '/') {
    this.router.navigate([navigationLink]);  
  }


  public showSnackbarThenNavigate(msg: string, navigationLink: string = '/', timeout: number = 2000) {
    this._snackBar.open(msg, "", {
      duration: timeout,
    }).afterDismissed().subscribe(() => {
      this.router.navigate([navigationLink]);
    });
  }
}
