import {Component, OnInit, OnDestroy, Inject, EventEmitter, Output, Input, OnChanges, ElementRef} from '@angular/core';
import {Kblock, kBlockType} from 'src/app/model/kblock.model';
import {KblockService} from 'src/app/service/kblock.service';
import {AuthService} from 'src/app/service/auth.service';
import {environment} from 'src/environments/environment';

import {ProjectService} from 'src/app/service/project.service';
import {ConceptService} from 'src/app/service/concept.service';
import {ActivatedRoute, Router} from '@angular/router';

import {ModelService, CurrentPage} from 'src/app/service/model.service';
import {CreateKblocksComponent} from '../create-kblocks/create-kblocks.component';
import {MatDialog} from '@angular/material/dialog';

//import {CaviDraggableHTML5} from "../../frontend/CaviDraggable";


export enum allmine {
  all,
  mine
}

@Component({
  selector: 'app-kblocks-overview',
  templateUrl: './kblocks-overview.component.html',
  styleUrls: ['./kblocks-overview.component.scss']
})

export class KblocksOverviewComponent implements OnInit, OnDestroy {

  ktype = kBlockType;

  //concepts : Concept[];
  new_kblock: Kblock = new Kblock();
  env = environment;


  mine_allblocks: string = "all";
  edit_block_nr: number = -1; // used to set the block selected
  @Input() blocks: number = 5;
  @Input() type: kBlockType;
  @Input() load_data: boolean = true;
  @Input() create: boolean = false;


  private _id: string;

  @Input() set id(value: string) {

    if (this._id != value) {
      this._id = value;

      this.loadKblocks();
    }
  }

  get id(): string {

    return this._id;

  }

  private _name: string;
  get name(): string {

    return this._name;

  }


  @Input() set name(value: string) {
    if (this._name != value) {
      this._name = value;
    }
  }


  @Output() item_selected = new EventEmitter<Kblock>();


  constructor(public model: ModelService, private route: ActivatedRoute, private kblockService: KblockService, public authService: AuthService, public dialog: MatDialog, public projectService: ProjectService, private concept_service: ConceptService, private router: Router, private elementRef: ElementRef) {

  }


  ngOnInit(): void {
    this.loadKblocks();

  }


  loadKblocks() {
    if (!this.load_data) return;
    if (this.type != null) {
      if (this.id) {
        this.model.loadkBlocks(this.type, this.blocks, this.id, this.name);

      } else {
        this.model.loadkBlocks(this.type, this.blocks);
      }
    }
  }

  ngAfterViewInit() {


  }


  ngOnDestroy() {

  }

  createInspirationBlock() {
    const dialogRef = this.dialog.open(CreateKblocksComponent, {
      panelClass: ['reinvent-popup', 'inspiration-dialog-container'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        //Setup correct project on kblock
        const kblock = result as Kblock;
        this.model.kBlockService.updatekBlock(Object.assign({}, kblock));
      }
    });
  }


  changeAllMine() {
    this.model.showAllorMine(this.mine_allblocks === "all");
  }
}


