import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Comment} from "../commenter/commenter.component";
import {DocumentReference} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {CommentsService} from "../comments.service";

@Component({
  selector: 'app-commenter-editor',
  templateUrl: './commenter-editor.component.html',
  styleUrls: ['./commenter-editor.component.scss']
})
export class CommenterEditorComponent implements OnInit {

  @Input() owningReference : DocumentReference;
  @Input() userReference : DocumentReference;
  @Input() editingComment : Comment;

  editorContent: string;

  constructor(private commentsService : CommentsService) {}

  ngOnInit(): void {
    if(this.editingComment != null) {
      this.editorContent = this.editingComment.content;
    }
  }

  postComment() {
    if(this.editingComment != null) {
      this.editingComment.content = this.editorContent;
      this.editingComment.edited = firebase.firestore.Timestamp.fromDate(new Date());
      this.commentsService.updateComment(this.editingComment);
      this.editingComment.currentlyEditing = false;
    } else {
      let comment = {
        content: this.editorContent,
        posted: firebase.firestore.Timestamp.fromDate(new Date()),
        user: this.userReference,
        edited: null,
        owningReference: this.owningReference
      }

      this.commentsService.saveComment(comment);
    }

    this.editorContent = "";
  }

  cancelEdit() {
    this.editingComment.currentlyEditing = false;
  }
}
