import {Component, Input, OnInit} from '@angular/core';
import {Story} from "../catalog.service";


@Component({
  selector: 'app-story-catalog-view',
  templateUrl: './story-catalog-view.component.html',
  styleUrls: ['./story-catalog-view.component.scss']
})
export class StoryCatalogViewComponent implements OnInit {
  @Input() story : Story;

  thumbnailUrl: string;

  constructor() { }

  ngOnInit(): void {
    if(this.story.image != null) {
      this.thumbnailUrl = this.story.image.url.small;
    } else {
      this.thumbnailUrl = this.story.image_name;
    }
  }
}
