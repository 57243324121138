import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Kblock } from '../model/kblock.model';
import { ProjectService } from './project.service';
import { AuthService } from './auth.service';
import { ConceptService } from './concept.service';
import { BehaviorSubject, observable, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';

export enum kQueryType {
  user,
  project,
  all
}

export enum kBlockCursor {
  first,
  more,
  last
}



interface QueryConfig {
  reference: DocumentReference, //  path to collection
  user_reference : DocumentReference,
  type: kQueryType,
  limit?: number, // limit per query
  reverse?: boolean, // reverse order?
  prepend?: boolean, // prepend to source?
  all? : boolean // get all blocks or just mine
}

@Injectable({
  providedIn: 'root'
})
export class KblockService {


    // Source data
    private _done = new BehaviorSubject(false);
    private _loading = new BehaviorSubject(false);
    private _data = new BehaviorSubject([]);
    private kblock_subscription : Subscription;

    cursor_history : Kblock[] = [];
    cursor_status : kBlockCursor = kBlockCursor.first;

    private query: QueryConfig;
    data: Observable<Kblock[]> =this._data.asObservable();
    done: Observable<boolean> = this._done.asObservable();
    loading: Observable<boolean> = this._loading.asObservable();


  constructor(private firestore: AngularFirestore, private projectService : ProjectService, private authService : AuthService, private concept_service : ConceptService) { }


  backButton() : boolean
  {
    return this.cursor_history.length>0;
  }

  moreButton() : boolean
  {
    return (this.cursor_status ==  kBlockCursor.first) || (this.cursor_status ==  kBlockCursor.more);
  }

  getkBlocks(user_ref : DocumentReference) {
    return this.firestore.collection('kblocks', ref => ref.where('owner', 'in', [user_ref]).orderBy('timestamp','desc') ).snapshotChanges();
}

getkBlocksProject(project_ref : DocumentReference) {
  return this.firestore.collection('kblocks', ref => ref.where('projects', 'array-contains-any', [project_ref]).orderBy('timestamp','desc') ).snapshotChanges();
}

initGetkBlocks(reference : DocumentReference, user_reference : DocumentReference, type:kQueryType, blocks: number, opts) {
  this.query = {
    reference,
    user_reference,
    type,
    limit: blocks,
    reverse: false,
    prepend: false,
    all : true,
    ...opts
  }

  this.resetAndInitBlocks();
}

resetAndInitBlocks()
{
  this._data = new BehaviorSubject([]);
  this._done.next(false);
  this._loading.next(false);
  this.data = this._data.asObservable();
  this.cursor_history = [];
  this.cursor_status = kBlockCursor.first;
  this.first();


  //this.data = this._data.asObservable().pipe(scan((acc,val) => { return this.query.prepend? val.concat(acc) : acc.concat(val)}));
}

first() {
  let first;
  if (this.query.all)
  {
    if (this.query.type == kQueryType.user){
      first = this.firestore.collection('kblocks', ref => { return ref.where('followers', 'array-contains-any', [this.query.user_reference]).orderBy('timestamp','desc').limit(this.query.limit)});
    }
    else {
      first = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains-any', [this.query.reference]).orderBy('timestamp','desc').limit(this.query.limit)});
    }
  } else {
    if (this.query.type == kQueryType.user){
      first = this.firestore.collection('kblocks', ref => { return ref.where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').limit(this.query.limit)});
    }
    else {
      first = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains', this.query.reference).where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').limit(this.query.limit)});
    }

  }
   this.mapAndUpdate(first);
}


updatekBlockFilter(all : boolean) {
  this.query.all = all;
  this.resetAndInitBlocks();
}

mapAndUpdate(col : AngularFirestoreCollection<any>)
{
  if (this._done.value || this._loading.value) {return}

  //loading
  this._loading.next(true);
  //Map

  if (this.kblock_subscription)
    this.kblock_subscription.unsubscribe();

  this.kblock_subscription = col.snapshotChanges().subscribe(arr => {
    /*let values = arr.map(snap => {
      const data = snap.payload.doc.data()
      const doc = snap.payload.doc
      return { ...data, doc }
    })*/
    let values = arr.map(e => {
      let block: Kblock = {
        id: e.payload.doc.id,
        ...(e.payload.doc.data() as {})
      } as Kblock;
      if (block.open_graph && block.open_graph.ogImage && Array.isArray(block.open_graph.ogImage)){
        block.open_graph.ogImage = block.open_graph.ogImage[0];
      }
      if (block.open_graph && block.open_graph.ogImage && block.open_graph.ogImage.url && block.open_graph.ogImage.url.startsWith("//"))
      {
        let url = new URL(block.link_url);
        block.open_graph.ogImage.url=url.protocol+block.open_graph.ogImage.url;
      }
      else if (block.open_graph && block.open_graph.ogImage && block.open_graph.ogImage.url &&block.open_graph.ogImage.url.startsWith("/"))
      {
        let url = new URL(block.link_url);
        block.open_graph.ogImage.url=url.origin+block.open_graph.ogImage.url;
      }


      return block;
    });

    this._data.next(values);

    this._loading.next(false);

    if(values.length<this.query.limit) {
      this._done.next(true);
      this.cursor_status = kBlockCursor.last;

    } else {
      this.cursor_status = kBlockCursor.more;
    }
  })
};

  triggerPaginationUpdate(kblock: Kblock) {
    this._data.next([kblock]);
  }

// Determines the doc snapshot to paginate query

private getCursor() : Kblock
{
  const current = this._data.value;
  if (current.length) {
    return this.query.prepend ? current[0] : current[current.length-1];
  }
  return null;
}

// Retrieves additional data from firestore
more() {
  const cursor = this.getCursor();
  if (!cursor) {return;}
  let more;
  this.cursor_history.push(cursor);

  if (this.query.all)
  {
    if (this.query.type == kQueryType.user){
      more = this.firestore.collection('kblocks', ref => { return ref.where('followers', 'array-contains-any', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
    else {
      more = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains-any', [this.query.reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
  }else {
    if (this.query.type == kQueryType.user){
      more = this.firestore.collection('kblocks', ref => { return ref.where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
    else {
      more = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains', [this.query.reference]).where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
  }
  this.mapAndUpdate(more);


}

back() {
  this._done.next(false);
  this._loading.next(false);
  if (this.cursor_history.length>=1)
    this.cursor_history.pop();

  if (this.cursor_history.length<1)
  {
    this.cursor_status = kBlockCursor.first;
    this.first();
    return;
  }
  const cursor = this.cursor_history[this.cursor_history.length-1];


  let more;


  if (this.query.all)
  {
    if (this.query.type == kQueryType.user){
      more = this.firestore.collection('kblocks', ref => { return ref.where('followers', 'array-contains-any', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
    else {
      more = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains-any', [this.query.reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
  }else {
    if (this.query.type == kQueryType.user){
      more = this.firestore.collection('kblocks', ref => { return ref.where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
    else {
      more = this.firestore.collection('kblocks', ref => { return ref.where('projects', 'array-contains', [this.query.reference]).where('owner', 'in', [this.query.user_reference]).orderBy('timestamp','desc').startAfter(cursor.timestamp).limit(this.query.limit)});
    }
  }
  this.mapAndUpdate(more);
}


createkBlock(k_block: Kblock){


  return new Promise<any>((resolve, reject) =>{
    this.firestore
        .collection("kblocks")
        .add({...k_block})
        .then(res => {k_block.id=res.id; resolve(res);}, err => reject(err));
});
}

getkBlock(k_block_id: string) {
  return this.firestore.doc<Kblock>('kblocks/' + k_block_id).valueChanges({
    idField: "id"
  });
}

getkBlockReference(kblock: Kblock) : DocumentReference {
  return this.firestore.doc<Kblock>('kblocks/' + kblock.id).ref;
}

updatekBlock(k_block: Kblock){
  const clone = Object.assign({}, k_block);
   delete clone.id;
   return this.firestore.doc('kblocks/' + k_block.id).update(clone);
}

deletekBlock(k_block_id: string){
    this.firestore.doc('kblocks/' + k_block_id).delete();
}

getAllKblocksFromProject(projectRef) : Observable<Kblock[]> {
  return this.firestore.collection<Kblock>("kblocks", (query) => {
    return query.where("projects", "array-contains-any", [projectRef]);
  }).valueChanges({
    idField: "id"
  });
}

async updateColorFromReference(ref:DocumentReference, name, color) : Promise<void> {
  //Now update all kblocks to new color...
  let kblocks = await this.getAllKblocksFromProject(ref).pipe(first()).toPromise()

  let promises = [];

  for(let kblock of kblocks) {
    let updated = false;
    kblock.references.forEach((reference)=>{
      if(reference.ref.id === ref.id) {
        reference.name = name;
        reference.color = color;
        updated = true;
      }
    });
    promises.push(this.updatekBlock(kblock));
  }

  await Promise.all(promises);
}

}
