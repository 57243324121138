import htmlTemplate from './kblock-block.html'
import {CatalogService} from '../../catalog.service';
import {Kblock} from '../../../model/kblock.model';
import {EditorJSBlockTranslator} from "../EditorJSBlockTranslator";
import {KblockSelectDialogComponent} from "../../kblock-select-dialog/kblock-select-dialog.component";

export class KblockBlock {

  static get toolbox() {
    return {
      title: "Kblock",
      icon: "<span class='material-icons'>web</span>"
    }
  }

  private readonly editorHtml : HTMLElement;
  private template;
  private catalogService: CatalogService;
  private dataLoaded = false;

  constructor({api, config, data}) {

    this.catalogService = injector.get(CatalogService);

    this.template = document.createElement('template');
    this.template.innerHTML = EditorJSBlockTranslator.translateHtmlTemplate(htmlTemplate);

    this.editorHtml = this.template.content.querySelector(".kblock-block") as HTMLElement;

    this.editorHtml.querySelector("button").addEventListener("click", ()=>{
      this.showDialog();
    });

    if(data != null && data.id != null) {
      this.dataLoaded = true;
      this.catalogService.getKblock(data.id).subscribe((kblock) => {
        this.loadKblock(kblock);
      });
    }
  }

  rendered() {
    if(!this.dataLoaded) {
      this.showDialog();
    }
  }

  showDialog() {
    KblockSelectDialogComponent.showDialog().subscribe((kblock)=>{
      if(kblock != null) {
        this.loadKblock(kblock);
      }
    });
  }

  loadKblock(kblock: Kblock) {
    console.log(kblock);
    this.editorHtml.querySelector(".kblock_text").innerHTML = kblock.text;
    this.editorHtml.querySelector(".kblocks_date").textContent = kblock.timestamp.toDate().toDateString();
    this.editorHtml.querySelector("aside").classList.remove("hidden");
    this.editorHtml.querySelector("button").classList.add("hidden");
    this.editorHtml.setAttribute("data-id", kblock.id);
  }

  render() {
    return this.editorHtml;
  }

  save(blockContent) {
    return {
      id: blockContent.getAttribute("data-id")
    }
  }
}
