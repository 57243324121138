

    <ng-container *ngIf="!isMobile">
      <!-- <app-story-menu class="scrollblock"></app-story-menu>
        <app-left-start-menu class="scrollblock"></app-left-start-menu>
        <app-left-start-menu class="scrollblock"></app-left-start-menu>-->
        <app-story-sidebar class="scrollblock" [story_id]="model.concept.id" *ngIf="!isMobile"></app-story-sidebar>


       <div class="scrollblock">
          <app-catalog-menu class="scrollblock"  *ngIf="!isMobile"></app-catalog-menu>
   
         
           <div class="normal-page-content">
               <ng-container *ngTemplateOutlet="content"></ng-container>
   
           </div>
       </div>
   </ng-container>
   
   
   <div class="scrollblock row no-gutters" *ngIf="isMobile">
       <div class="format-one-container">
           <div class="format-one-column">
   
               <div>
                   <ng-container *ngTemplateOutlet="content">
                   </ng-container>
               </div>
           </div>
       </div>
   </div>


<!--
<div class="container-fluid page-max-width" *ngIf="model.project">
  <div class="justify-content-md-center">
    <div class="col">
      <div>
        <div class="format-one-container">
          <div class="format-one-column">
            <app-catalog-menu></app-catalog-menu>
            <div class="format-column1-content">
              <ng-container *ngTemplateOutlet="content"></ng-container>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->


<ng-template #content>        
  <div class="page-padding">  
<div class="heading3" translate>Project</div>
<div class="input_header" translate>Project name</div>
<mat-label class="input_description">{{"Provide a name for your project"|translate}}</mat-label>
<br/>
<mat-form-field class="input_form_field_small">
  <input class="input_field" matInput type="text" [(ngModel)]="model.project.name"
         matTooltip="{{'Concept name'|translate}}">
</mat-form-field>
<br/><br/>

<div class="input_header" translate>Short name</div>
<mat-label
  class="input_description">{{"Provide a short name for your project (shown in blocks)"|translate}}</mat-label>
<br/>
<mat-form-field class="input_form_field_small">
  <input class="input_field" matInput type="text" [(ngModel)]="model.project.kid"
         matTooltip="{{'Hashtag'|translate}}"/>
</mat-form-field>
<br/> <br/>

<div>
  <mat-label>{{'Pick a color'|translate}}</mat-label>
  <div class="currentColor" #currentColor></div>
  <color-github
    [colors]="['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB']"
    [color]="getCurrentColor()" (onChangeComplete)="colorChangeComplete($event)"></color-github>
</div>

<br/>
<br/>

<button mat-button color="primary" [matMenuTriggerFor]="menu">{{"Delete project"|translate}}</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="deleteProject()">{{"Delete project"|translate}}</button>
</mat-menu>
<br/>
<br/>

<div class="heading3" translate>Participants</div>
<mat-label
  class="input_description">{{"A list of participants and their role in the project"|translate}}</mat-label>
<br/><br/>

<div *ngIf="model.project.participants_role && displayedColumns">
  <table class="participant-table">
    <tr>
      <th>{{"Name"|translate}}</th>
      <th>{{"Email"|translate}}</th>
      <th>{{"Role"|translate}}</th>
      <th> {{"Action"|translate}}</th>

    </tr>


    <tr *ngFor="let element of model.project.participants_role">

      <td class="participant-td">{{element.name}}</td>
      <td class="participant-td"> {{element.email}}</td>
      <td class="participant-td">
        <mat-form-field appearance="standard">
          <mat-select [(ngModel)]="element.role" name="role">
            <mat-option *ngFor="let amode of adm_modes" [value]="amode">
              {{amode|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </td>
      <td class="participant-td">
        <button *ngIf="model.user && model.user.email!=element.email" mat-button color="primary"
                (click)="removeUser(element.id)">{{"Remove"|translate}}</button>
      </td>


    </tr>


  </table>


</div>


<br/>
<mat-form-field appearance="standard">
  <mat-label class="input_description">{{"Email to add"|translate}}</mat-label>

  <input class="input_field" matInput type="text" [(ngModel)]="email"
         matTooltip="{{'Email to add'|translate}}">
</mat-form-field>
<button mat-button color="primary" (click)="addUser()">{{"Add user"|translate}}</button>

<br/>
<br/>


<button mat-raised-button color="primary" (click)="updateProject()">{{"Save"|translate}}</button>
<br/>
<br/>
</div>
</ng-template>