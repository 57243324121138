




<ng-container *ngIf="!isMobile">
    <!-- <app-story-menu class="scrollblock"></app-story-menu>
      <app-left-start-menu class="scrollblock"></app-left-start-menu>
      <app-left-start-menu class="scrollblock"></app-left-start-menu>-->
      <app-story-sidebar class="scrollblock" [story_id]="model.concept.id" *ngIf="!isMobile"></app-story-sidebar>


     <div class="scrollblock">
        <app-catalog-menu class="scrollblock"  *ngIf="!isMobile"></app-catalog-menu>
 
       
         <div class="normal-page-content">
            <div class="page-padding">
            <app-participant-view [fullpage]="true" [catalog]="model.project" *ngIf="model.project"></app-participant-view>
        </div>
 
         </div>
     </div>
 </ng-container>
 
 
 <div class="scrollblock row no-gutters" *ngIf="isMobile">
     <div class="format-one-container">
         <div class="format-one-column">
 
             <div>
                <div class="page-padding">
                <app-participant-view [fullpage]="true" [catalog]="model.project" *ngIf="model.project"></app-participant-view>
            </div>

             </div>
         </div>
     </div>
 </div>

  <!--



<div class="container-fluid page-max-width">
    <div class="row justify-content-md-center">
        <div class="col">
            <div class="no-mobile" *ngIf="!isMobile">
                <div class="format-one-container">
                    <div class="format-one-column">
                        <app-catalog-menu></app-catalog-menu>
                        <div class="format-column1-content">
                            <app-participant-view [fullpage]="true" [catalog]="model.project" *ngIf="model.project"></app-participant-view>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row no-gutters" *ngIf="isMobile">
                <div class="format-one-container">
                    <div class="format-one-column">
                        <app-catalog-menu></app-catalog-menu>
                        <div class="format-column1-content">
                            <app-participant-view [fullpage]="true" [catalog]="model.project" *ngIf="model.project"></app-participant-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->