<div class="top-inspiration hidden-on-mobile">
    <div class="participate-heading">{{'Create a new project' | translate }}</div>
    <div class="kblock-menu ">
        <span class="pointer grey" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<div class="content" >
    <div class="input_header" translate>Project name</div>
    <mat-form-field class="input_form_field_small">
        <mat-label class="input_description" translate>Provide a name for your project</mat-label><br/>
        <input class="input_field" matInput type="text" [(ngModel)]="project.name"  matTooltip="{{'Project name' | translate}}">
    </mat-form-field><br/><br/>

    <div class="input_header" translate>Short name</div><br/>
    <mat-form-field class="input_form_field_small">
        <mat-label class="input_description" translate>Provide a short name for your catalog</mat-label><br/>
        <input class="input_field" matInput type="text" [(ngModel)]="project.kid"  matTooltip="{{'Hashtag' | translate}}"/>
    </mat-form-field><br/>

    <div>
        <mat-label>{{'Pick a color'|translate}}</mat-label>
        <div class="currentColor" #currentColor style="background-color: #fff"></div>
        <color-github [colors]="['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB']" color="#fff" (onChangeComplete)="colorChangeComplete($event)"></color-github>
    </div>

    <div mat-dialog-actions>
        <button mat-button color="primary" (click)="cancel()">{{'Cancel' | translate}}</button>
        <button mat-raised-button color="primary" (click)="createProject()">{{'Create' | translate}}</button>
    </div>
</div>


