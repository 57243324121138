<div class="format-column1-content-ext">


  <div>
    <div>


      <!--
      <mat-radio-group aria-label="Select an option" [(ngModel)]="mine_allblocks" (change)="changeAllMine()">
        <mat-radio-button value="all">All blocks</mat-radio-button>
        <mat-radio-button value="mine">My blocks</mat-radio-button>
      </mat-radio-group>
      <br/><br/> -->
    </div>

    <div class="kblock-flow">    <!--      <div class="kblock-outer">
 <div class="kBlocks-edit participate" *ngIf="authService.isLoggedIn else notLoggedIn">
      <div class="white-background">
        <div class="participate-heading">{{'Contribute with inspiration' | translate }}</div>
        <div class="kblock-edit-inner">

          <div class="kblock-edit-main ">

                  <quill-editor [(ngModel)]="new_kblock.text" placeholder="{{'Contribute with links, texts and other ideas ...'|translate}}">
          <div quill-editor-toolbar>
                    <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>

                      </span>
            <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>

                      </span>
            <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
          </div>

        </quill-editor>

      </div>

        <div class="kblock-edit-action">
          <button mat-stroked-button color="primary" class="button-full-width"  (click)="createkBlock()">{{'Post' | translate}}</button>
        </div>
      </div>

      </div>

    </div>


</div>-->



      <div class="kblock-outer" *ngIf="create ">
        <div class="new-button" (click)="createInspirationBlock()">
          <div class="button-icon">
            <mat-icon aria-hidden="false" aria-label="Add">
              <div class="button-icon-size">add_circle</div>
            </mat-icon>
          </div>

          <div class="button-title"> Create inspiration block</div>
        </div>
      </div>


      <!-- Iterate through kblocks -->
      <div *ngFor="let kblock of model.kblocks; let i = index">

        <div class="kblock-outer">
          <ng-container *ngIf="kblock | filterHiddenKblock:authService.userData:model.project_id">
            <app-inspiration-block [kblock]="kblock"></app-inspiration-block>
          </ng-container>
          <!-- <app-kblocks-date-overview *ngIf="id && model.project" [type]="ktype.Project" [blocks]="30" [id]="id" [name]="model.project.name"></app-kblocks-date-overview>
          <app-kblocks-date-overview *ngIf="id && model.project" [type]="ktype.Project" [blocks]="30" [id]="id" [name]="model.project.name"></app-kblocks-date-overview>      -->

        </div>


      </div>
    </div>


    <div class="nextprev">

      <div>
        <button *ngIf="model.kBlockService.backButton()" mat-button color="primary"
                (click)="this.model.kBlocksBack()">{{'Previous page'|translate}}</button>
      </div>

      <div>
        <button *ngIf="model.kBlockService.moreButton()" mat-button color="primary"
                (click)="this.model.kBlocksMore()">{{'Next page'|translate}}</button>
      </div>

    </div>

    <br/>
    <loading-spinner *ngIf="model.kBlockLoading() | async"></loading-spinner>
    <br/>
  </div>


</div>
