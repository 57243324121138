<div *ngIf="user; then loggedInUserBlock else anonymousUserBlock"></div>
<ng-template #loggedInUserBlock>
  <div class="userinfo logged-in-user {{isSelf()?'self':''}}">
    <i class="material-icons">account_circle</i>
    <div class="username">{{user.displayName}}</div>
  </div>
</ng-template>

<ng-template #anonymousUserBlock>
  <div class="userinfo {{isSelf()?'self':''}}">
    <i class="material-icons">account_circle</i>
    <div class="username">Anonymous Visitor</div>
  </div>
</ng-template>
