<!-- Menu on the first page, desktop (active) -->

<app-sidebar-header></app-sidebar-header>
<div class="sidebar-divider hide-when-minimized">

</div>    

<div class="menu-heading hide-when-minimized">{{'left-start-menu.projects'|translate}}</div>

<ng-container *ngFor="let project of getProjects(showAllProjects)">
    <a class="project dropProject sidebar-entry" [attr.tag-color]="project.color" [routerLink]="'/'+env.links.viewCatalog+'/'+project.id" [attr.title]="project.name" [attr.data-projectId]="project.id">
        <img [src]="getImage(project)" class="menu-image"/>
        <span class="title">{{project.name}}</span>
    </a>
</ng-container>
<a href="javascript:void(null);" class="sidebar-entry" (click)="toggleShowAll()" *ngIf="showMoreProjectOption" >
    <ng-container *ngIf="showAllProjects">
        <mat-icon>expand_less</mat-icon>
        <span class="title">{{'left-start-menu.less-projects'|translate}}</span>
    </ng-container>
    <ng-container *ngIf="!showAllProjects">
        <mat-icon>expand_more</mat-icon>
        <span class="title">{{'left-start-menu.more-projects'|translate}}</span>
    </ng-container>
</a>

<!--<a class="sidebar-entry" [routerLink]="'/'+env.links.viewProjects">
    <mat-icon>view_module</mat-icon>
    <span class="title" translate>View all projects</span>
</a>-->
<a href="javascript:void(null);" class="sidebar-entry" (click)="createProject(true)">
    <mat-icon>control_point</mat-icon>
    <span class="title">{{'left-start-menu.new-project'|translate}}</span>
</a>

<div class="sidebar-divider-top-margin  hide-when-minimized"></div><br/>
<a class="project dropProject sidebar-entry" [routerLink]="'/'+env.links.viewProjects">
    <mat-icon>grid_view</mat-icon>
    <span class="title">{{'top-menu.all-projects' | translate }}</span>
</a>



<div class="sidebar-divider-top-margin  hide-when-minimized"></div>

<div class="welcome hide-when-minimized">
    {{'left-start-menu.new-to-ideate'|translate}}<br/> <a href="https://ideate.nu/introduktion">{{'left-start-menu.beginners-guide'|translate}}</a>
    <br />
</div>


<br />

<a href="javascript:void(null);" class="sidebar-entry" (click)="toggle()" title="Toggle Menu">
    <ng-container *ngIf="minimized">
        <mat-icon>chevron_right</mat-icon>
        <span class="title">{{'left-start-menu.reopen'|translate}}</span>
    </ng-container>
    <ng-container *ngIf="!minimized">
        <mat-icon>chevron_left</mat-icon>
        <span class="title">{{'left-start-menu.minimize'|translate}}</span>
    </ng-container>
</a>
