

    <ng-container *ngIf="!isMobile">
        <!-- <app-story-menu class="scrollblock"></app-story-menu>
          <app-left-start-menu class="scrollblock"></app-left-start-menu>
          <app-left-start-menu class="scrollblock"></app-left-start-menu>-->
          <app-story-sidebar class="scrollblock" [story_id]="model.concept.id" *ngIf="!isMobile"></app-story-sidebar>


         <div class="scrollblock">
            <app-catalog-menu class="scrollblock"  *ngIf="!isMobile"></app-catalog-menu>
     
           
             <div class="normal-page-content">
                 <ng-container *ngTemplateOutlet="content"></ng-container>
     
             </div>
         </div>
     </ng-container>
     
     
     <div class="scrollblock row no-gutters" *ngIf="isMobile">
         <div class="format-one-container">
             <div class="format-one-column">
     
                 <div>
                     <ng-container *ngTemplateOutlet="content">
                     </ng-container>
                 </div>
             </div>
         </div>
     </div>


<!--

<div class="container-fluid page-max-width">
    <div class="justify-content-md-center">
        <div class="col">
            <div>
                <div class="format-one-container">
                    <div class="format-one-column">
                        <app-catalog-menu></app-catalog-menu>
                        <div class="format-column1-content" *ngIf="model.project">



                            <ng-container *ngTemplateOutlet="content"></ng-container>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->



<ng-template #content>
    <div class="page-padding">
    <div class="heading3" translate>Share through a link</div>

    <mat-label class="input_description">{{'Provide a short introduction to the project. You can add questions about the audience in the text'|translate}}</mat-label>
    <br/> <br/>   <hr/>
    <app-member-info [catalog]="this.model.project" [isAdmin]="this.model.adminRole()" [includeEditor]="true"></app-member-info>
    <hr/> <br/>    <br/>

    <div *ngIf="model.project.join_code"><mat-label class="input_description">{{"This is the URL for allowing people to join this project. You can send the link in an invitation or send the link code and ask people to add it to join the project." |translate}} <br/> <br/>
            <span id="join-link"><a href="{{env.base_url}}{{env.links.joinCatalog}}/{{model.project.join_code}}">{{env.base_url}}{{env.links.joinCatalog}}/{{model.project.join_code}}</a></span></mat-label>
        <button mat-button color="primary"  [cdkCopyToClipboard]="env.base_url+env.links.joinCatalog+'/'+model.project.join_code">{{"Copy Link"|translate}}</button>
        <br/> <br/>
    </div>

    <button *ngIf="!model.project.join_code" mat-button color="primary" (click)="generateJoinCode()">{{"Generate join code"|translate}}</button>

    <div class="heading3" translate>Make project public</div>
    <mat-label class="input_description">{{"Project public or private?"|translate}}</mat-label><br/><br/>
    <mat-form-field>
        <mat-select [(ngModel)]="model.project.published" name="published">
            <mat-option *ngFor="let ptype of publish_types" [value]="ptype">
                {{ptype|translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <br/>   <br/>



    <mat-label class="input_description">{{'Can non memebers see inspiration blocks?'|translate}}</mat-label>
    <br/>
    <mat-slide-toggle [(ngModel)]="model.project.nonMemberInspirations">{{"Allow non members to see inspiration blocks"|translate}}</mat-slide-toggle>
    <br/>   <br/>

    <ng-container *ngIf="model.project.published == 'public' || model.project.published == 'link'">
        <mat-label class="input_description">{{"The public url for this catalog"|translate}}</mat-label><br/>
        <span id="join-link"><a href="{{getPublicUrl()}}">{{getPublicUrl()}}</a></span>
        <button mat-button color="primary"  [cdkCopyToClipboard]="getPublicUrl()">{{"Copy Link"|translate}}</button>

        <br/>  <br/>
        <br/>
    </ng-container>

    <mat-label class="input_description">{{"How can users join the project?"|translate}}</mat-label><br/><br/>
    <mat-form-field>
        <mat-select [(ngModel)]="model.project.joinMethod" name="joinMethod">
            <mat-option *ngFor="let jtype of join_methods" [value]="jtype">
                {{jtype|translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <br/>   <br/>

    <button mat-raised-button color="primary" (click)="updateProject()">{{"Save"|translate}}</button>
    <br/>
    <br/>

</div>

</ng-template>